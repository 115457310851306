import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

// import {TradingAccountType} from "../../gateways/TfboGateway/TfboGateway.types";

const useUserStats = (options: UseQueryOptions) => {
	options.queryKey = ['userStats'];

	options.queryFn = async () => {
		try {
			const response = await axios({
				url: '',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},

				data: {
					payload: [{ module: 'account', action: 'stats', parameters: {} }],
				},
			});

			return response.data.payload[0].result.data;

			// TODO: DELETE once the server send real data for JapanSpread accounts
			// // duplicate the live account and set platformAccountType = JapanSpread
			// let data = response.data.payload[0].result.data;
			// // @ts-ignore
			// const liveAccount = data.find(({account}) => account.id === 10423961);
			// if (liveAccount) {
			// 	data = [...data, {...liveAccount, platformAccountType: TradingAccountType.JapanSpread, account: {...liveAccount.account, id: liveAccount.account.id + 1,  accountNumber: liveAccount.account.accountNumber + 1}}]
			// }
			//
			// console.info("queryKey = ['userStats']: -> ", { data });
			//
			// return data;
		} catch (e) {}
	};

	return useQuery(options);
};

export default useUserStats;
