interface Languages {
	'ms-MY': string;
	'pt-BR': string;
	'ar-SA': string;
}

const languages: Languages = {
	'ms-MY': 'ms',
	'pt-BR': 'pt',
	'ar-SA': 'ar',
};

export const changeLangForPortal = (language: string) => {
	return languages[language as keyof Languages] || language;
};
