import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';

import styles from './WtrToggle.module.scss';

interface WtrToggleProps {
	onChange: () => void;
	icon: IconProp;
	isActive: boolean;
	id: string;
	activeBackgroundStyling?: boolean;
	position?: 'vertical' | 'horizontal';
	size?: 'sm' | 'md';
}

/**
 * Custom toggle component :)
 * @param onChange onChange event
 * @param icon Semantic ui icon array
 * @param activeBackgroundStyling Color the toggle with the main color (topaz)
 * @param position The layout of the toggle - if no position is specified it will fallback to vertical
 * @param size Sizing of the toggle - sm / md (if no size is specified it will fallback to md)
 */
const WtrToggle = ({
	onChange,
	icon,
	isActive,
	id,
	activeBackgroundStyling = false,
	position = 'vertical',
	size = 'md',
}: WtrToggleProps) => {
	const horizontalStyling = position === 'horizontal' && styles.horizontal;
	return (
		<div className={cn(styles.container, horizontalStyling)}>
			<input type="checkbox" className={styles.checkbox} onChange={onChange} id={id} />
			<label className={cn(styles.toggle, activeBackgroundStyling && styles.active, styles[size])} htmlFor={id}>
				<div className={cn(styles.icon, isActive ? styles.start : styles.end, styles[size])}>
					<FontAwesomeIcon className={cn(styles.faIcon, horizontalStyling, styles[size])} icon={icon} />
				</div>
			</label>
		</div>
	);
};

export default WtrToggle;
