import { useTranslation } from 'react-i18next';

const useShortTranslation = (languageString: string) => {
	const { t } = useTranslation();
	const tt = (name: string, options?: Object) => {
		return t(`${languageString}${name}`, options);
	};
	return tt;
};

export default useShortTranslation;
