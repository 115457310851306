import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import { MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';

import LotsAwareInput from './LotsAwareInput';
import styles from './NumberInput.module.scss';
import FormattedNumberInput from './FormattedNumberInput';

interface Incrementor {
	quantityValues?: number[];
	min?: number;
	max?: number;
	step?: number;
}

interface NumberInputProps {
	lotAware: boolean;
	incrementor: Incrementor;
	roundLot?: number;
	marketItem?: MarketItem;
	value: any;
	disabled?: boolean;
	onChange: (value: number) => void;
	amountErrorClass?: boolean;
	decimalPrecision?: number;
	allowNegativeValues?: boolean;
}

const NumberInput = ({
	lotAware,
	incrementor,
	value,
	roundLot,
	decimalPrecision,
	marketItem,
	disabled,
	onChange,
	amountErrorClass,
	allowNegativeValues = false,
}: NumberInputProps) => {
	const incrementQuantity = () => {
		if (disabled) {
			return;
		}
		const { step } = incrementor;
		const decPrec = marketItem?.decPrec;
		let currentValue = 0;
		if (value && !isNaN(value)) {
			currentValue = value;
		}
		if (step) {
			currentValue = currentValue + step;
			onChange(+currentValue.toFixed(decPrec));
		} else {
			onChange(findNextValue(true, currentValue));
		}
	};

	const decrementQuantity = () => {
		if (disabled) {
			return;
		}
		const { step } = incrementor;
		const decPrec = marketItem?.decPrec;
		let currentValue = 0;
		if (value && !isNaN(value)) {
			currentValue = value;
		}
		if (step && (allowNegativeValues || currentValue > step)) {
			currentValue = currentValue - step;
			onChange(+currentValue.toFixed(decPrec));
		} else {
			onChange(findNextValue(false, currentValue));
		}
	};

	const handleIsAllowed = (value: number) => {
		if (value) {
			const { max = Infinity } = incrementor;
			return value >= 0 && value <= max * 10;
		}
		return true;
	};

	const findNextValue = (isIncrement: boolean, value: number) => {
		const { quantityValues, min = -Infinity, max = Infinity } = incrementor;

		if (quantityValues) {
			if (value < min) {
				return min;
			}
			if (value > max) {
				return max;
			}

			if (isIncrement) {
				return quantityValues.find((element) => element > value) ?? max;
			} else {
				return (
					quantityValues
						.slice()
						.reverse()
						.find((element) => element < value) ?? min
				);
			}
		}

		return value;
	};

	return (
		<div
			className={cn(styles.container, disabled && styles.disabled, amountErrorClass && styles.hasError)}
			onMouseDown={(e: any) => {
				e.stopPropagation();
			}}
		>
			<div className={styles.button} onClick={decrementQuantity}>
				<FontAwesomeIcon icon={['far', 'minus']} className={styles.icon} />
			</div>
			{lotAware ? (
				<LotsAwareInput
					value={value}
					roundLot={roundLot}
					marketItem={marketItem}
					disabled={disabled}
					onChange={onChange}
					isAllowed={handleIsAllowed}
				/>
			) : (
				<FormattedNumberInput
					value={value}
					decimalPrecision={decimalPrecision !== undefined ? decimalPrecision : marketItem?.decPrec}
					onChange={onChange}
					isAllowed={handleIsAllowed}
					disabled={disabled}
					allowNegative={allowNegativeValues}
				/>
			)}
			<div className={styles.button} onClick={incrementQuantity}>
				<FontAwesomeIcon icon={['far', 'plus']} className={styles.icon} />
			</div>
		</div>
	);
};

export default NumberInput;
