import React, {useContext, useEffect, useMemo, useState} from 'react';

import { useHistory } from 'react-router';

import cn from 'classnames';

import { default as AppContext } from '../../../contexts/AppContext';
import { default as DashboardContext } from '../../../contexts/DashboardContext';
import { AppComponentType } from '../../../utils/functions/enums';
import { changeLangForPortal } from '../../../utils/functions/changeLanguageForPortal';
import useForceRerender from '../../../utils/hooks/useForceRerender';
import useObservable from '../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../utils/hooks/usePromiseFactory';
import postMessageToTP from '../../../utils/functions/postMessageToTp';

import Routes from '../../../setup/routes';

import handleDepositRedirect from '../../../utils/functions/handleDepositRedirect';

import Loading from '../../components/Loading/Loading';

import accountStatusStore, { StatusStore } from '../../../store/accountStatusStore';

import authStore, { AuthStore } from '../../../store/authStore';

import { useSwitchToLive } from '../../../utils/hooks/system/useSwitchMode';

import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';

import styles from './IframeThinkPortal.module.scss';

export interface IframeThinkPortalProps {
	id: string;
	src: string;
}

export interface PostMessageToTpType {
	message: string;
	accountId?: string;
	lang?: string;
	status?: string | null;
	transactionId?: string | null;
}

const IframeThinkPortal: React.FC<IframeThinkPortalProps> = ({ id, src }) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const tradingAccount = dashboardContext.tradingAccount;
	const activeTradingAccount = useSelectedTradingAccount();
	const componentType = dashboardContext.presentComponentType;

	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);
	const isDemoMode = authStore((store: AuthStore) => store.isDemoMode);

	const hasAnyAccount = accountStatusStore((state: StatusStore) => state.hasAnyAccount);
	const history = useHistory();

	const [showLoader, setShowLoader] = useState<boolean>(true);

	let language = appContext.languageSettings;

	const switchToLive = useSwitchToLive();

	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	useObservable(dashboardContext.getPropertyChangeStream('tradingAccount'), (change) => {
		if (change.newValue.length !== change.oldValue.length) {
			// this is hiding an underlying issue but is solving something else so will do for now
			// if the portal is rendereded and we recieve a new trading account means that a creation process was sucessfull
			// so let's reload the page - this will fetch all fresh data and the new account will be visible in the account selector which is not the case now
			//
			// still some errors will be thrown in the console and hidden by the refresh - the reason is that the new account is not handled properly
			// it's okay for now
			window.location.reload();
			// dashboardContext.presentComponentType = AppComponentType.Watchlist;
			// history.push(Routes.trader.watchlist);
			// // switchAccounts();
			// forceRerender();
			// return;
		}

		promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	// const switchAccounts = () => {
	// 	if (hasAnyAccount) {
	// 		const switchAccount: TradingAccount = dashboardContext.tradingAccount[dashboardContext.tradingAccount.length - 1];
	// 		dashboardContext.activeTradingAccount = [switchAccount];
	// 		dashboardContext.selectedTradingAccount = +switchAccount.id;
	// 	}
	// };

	useEffect(() => {
		if (!src) return;
		// Listen to post messages from Think Portal iFrame
		window.addEventListener('message', tpPostMessageListener);

		return () => {
			window.removeEventListener('message', tpPostMessageListener);
		};
	}, [src]);

	const tpPostMessageListener = (event: any) => {
		if (event.origin === process.env.REACT_APP_THINK_PORTAL) {
			const { message: messageType, action, acceptCharset } = event.data;

			switch (messageType) {
				case 'tradePage':
					dashboardContext.presentComponentType = AppComponentType.Watchlist;
					history.push(Routes.trader.watchlist);
					forceRerender();
					break;
				case 'underReview':
					dashboardContext.presentComponentType = AppComponentType.Watchlist;
					history.push(Routes.trader.watchlist);
					forceRerender();
					break;
				case 'deposit':
					dashboardContext.presentComponentType = AppComponentType.Funds;
					history.push(Routes.trader.funds);
					forceRerender();
					break;
				case 'switchLive':
					switchToLive();
					break;
				case 'uploadLater':
					if (isLiveMode) {
						dashboardContext.presentComponentType = AppComponentType.Watchlist;
						history.push(Routes.trader.watchlist);
						forceRerender();
						break;
					}
					switchToLive();
					break;
				// setInfoMessage('SUCCESSFUL_ACCOUNT_CREATION');
				// setShowModal(true);
				// dashboardContext.presentComponentType = AppComponentType.Watchlist;
				// history.push(Routes.trader.watchlist);
				// forceRerender();
				// break;
				case 'openDemo':
				case 'openLive':
					// both are handled in `useObservable(dashboardContext.getPropertyChangeStream('tradingAccount'), (change) => {` above
					forceRerender();
					break;
				case 'depositRedirect':
					handleDepositRedirect(action, acceptCharset);
					break;
				case 'restart':
					console.log('reload portal', event);
					// dashboardContext.tfboConnectionError = true;
					break;
				case 'loggedIn':
					setTimeout(() => setShowLoader(false), 1500);
					break;
			}
		}
	};

	useEffect(() => {
		if (!src) return;
		setTimeout(() => forceRerender(), 5000);

		// Listen to post messages from Think Portal iFrame
		window.addEventListener('message', tpPostMessageListener);

		return () => {
			window.removeEventListener('message', tpPostMessageListener);
		};
	}, [src]);

	const navigateToPortalPage = () => {
		if (!src) return;
		// Get account id from context
		let accountId = '';
		if (activeTradingAccount) {
			accountId = activeTradingAccount.providerAccountId;
		} else if (hasAnyAccount && dashboardContext.tradingAccount.length > 0) {
			accountId = dashboardContext.tradingAccount[0].providerAccountId;
		}
		// Set iframe url for Funds / Account / Reports page
		switch (componentType) {
			case AppComponentType.OpenAccount:
				let message = { message: 'openLive' };

				// if (appContext.isJapanAccount) {
				// 	if (hasLiveAccount) {
				// 		message = { message: 'completeOnboarding' };
				// 	}
				// }

				if (isDemoMode) {
					message = { message: 'openDemo' };
				}

				postMessageToTP(message);
				break;

			case AppComponentType.CompleteOnboarding:
				postMessageToTP({ message: 'completeOnboarding' });
				break;
			case AppComponentType.Status:
				// Add deposit information to SSO request if Funds iframe is loading after a deposit attempt
				const status = localStorage.getItem('depositStatus');
				const transactionId = localStorage.getItem('transactionId');

				postMessageToTP({
					message: 'status',
					status: status,
					transactionId: transactionId,
					lang: changeLangForPortal(language),
				});
				break;
			case AppComponentType.Deposits:
				postMessageToTP({ message: 'deposit', accountId: accountId, lang: changeLangForPortal(language) });
				break;
			case AppComponentType.Funds:
				localStorage.removeItem('depositStatus');
				localStorage.removeItem('transactionId');
				postMessageToTP({ message: 'funds', accountId: accountId, lang: changeLangForPortal(language) });
				break;
			case AppComponentType.Reports:
				postMessageToTP({ message: 'reports', accountId: accountId, lang: changeLangForPortal(language) });
				break;
			case AppComponentType.UploadDocuments:
				postMessageToTP({ message: 'uploadDocuments', accountId: accountId, lang: changeLangForPortal(language) });
				break;
		}
	};

	useEffect(() => {
		const onBlur = () => {
			dashboardContext.accountPopupToggle = !dashboardContext.accountPopupToggle;
		};

		window.addEventListener('blur', onBlur);

		return () => {
			window.removeEventListener('blur', onBlur);
		};
	}, []);

	// generated this key in order to prevent force re-renders because of references updates
	const tradingAccountIdsKey = useMemo(() => [...tradingAccount.map(( acc) => acc?.providerAccountId), activeTradingAccount?.providerAccountId].join(''), [tradingAccount, activeTradingAccount])

	useEffect(() => {
		console.info(">>>",{showLoader, src, tradingAccount, tradingAccountIdsKey, componentType})
		navigateToPortalPage();
	}, [showLoader, src, tradingAccountIdsKey, componentType]);

	return (
		<>
			<div className={styles.container}>
				{showLoader && <Loading className={styles.loader} size="200" />}
				<iframe
					className={cn(showLoader && styles.displayNone)}
					title="tp-iframe"
					id={id}
					width="100%"
					height="100%"
					src={src}
				></iframe>
			</div>
		</>
	);
};

export default IframeThinkPortal;
