import {
	HistoryTick,
	MarketItem,
	PriceQuote,
	TradingAccount,
	TradingPositionState,
} from '../../../gateways/RfpGateway/rfp.types';
import { TradingPositionSide, TradingPositionType } from '../../../utils/functions/enums';
import { GymTradingAccount } from '../Accounts/GymTradingAccount';

import { GymTradingPosition } from './GymTradingPosition';

export class GymPositionUtils {
	static generatePositionId(): number {
		return Date.now();
	}

	static getDefaultAmount(account: TradingAccount, marketItem: MarketItem) {
		const instrumentDetails = account.tradingInstruments?.instruments[marketItem.code];
		if (instrumentDetails) {
			return instrumentDetails.minAmount;
		}
		return 1;
	}

	static buildPosition(
		account: TradingAccount,
		marketItem: MarketItem,
		isBuy: boolean,
		priceQuote: PriceQuote,
		simulationId: string,
		stopLoss: number | undefined,
		takeProfit: number | undefined,
		amount: number
	): GymTradingPosition {
		const position = new GymTradingPosition(
			account.id,
			marketItem.feedId,
			marketItem.code,
			TradingPositionState.open,
			isBuy ? TradingPositionSide.Buy : TradingPositionSide.Sell,
			TradingPositionType.Market,
			simulationId
		);

		position.marketItem = marketItem;
		position.qCcy = marketItem.ccy;
		position.dec = marketItem.decPrec;
		position.qty = amount ?? GymPositionUtils.getDefaultAmount(account, marketItem);
		position.posId = GymPositionUtils.generatePositionId();
		position.lastDataCloseTime = priceQuote.t;
		position.lastDataClosePrice = isBuy ? priceQuote.a : priceQuote.b;
		position.oP = isBuy ? priceQuote.a : priceQuote.b;
		position.oT = priceQuote.t;
		position.grossProfit = 0;
		position.tp = takeProfit ?? 0;
		position.sl = stopLoss ?? 0;

		return position;
	}

	static buildPendingPosition(
		account: TradingAccount,
		marketItem: MarketItem,
		price: number,
		isBuy: boolean,
		historyTick: HistoryTick,
		positionType: TradingPositionType,
		simulationId: string,
		stopLoss: number,
		takeProfit: number,
		amount?: number
	) {
		const position = new GymTradingPosition(
			account.id,
			marketItem.feedId,
			marketItem.code,
			TradingPositionState.pending,
			isBuy ? TradingPositionSide.Buy : TradingPositionSide.Sell,
			positionType,
			simulationId
		);

		position.oT = historyTick.closeTime;
		position.marketItem = marketItem;
		position.qCcy = marketItem.ccy;
		position.prc = price;
		position.dec = marketItem.decPrec;
		position.qty = amount ?? GymPositionUtils.getDefaultAmount(account, marketItem);
		position.posId = GymPositionUtils.generatePositionId();
		position.lastDataCloseTime = historyTick.closeTime;
		position.lastDataClosePrice = historyTick.close;
		position.tp = takeProfit ?? 0;
		position.sl = stopLoss ?? 0;
		position.eT = 0;

		return position;
	}

	static validateMargin(account: GymTradingAccount, position: GymTradingPosition): Boolean {
		const posUsedMargin = position.calcUsedMargin(account);
		return posUsedMargin < account.freeMargin;
	}
}
