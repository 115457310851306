import React from 'react';
import MoneyFormatter from '../../../../../components/MoneyFormatter';

interface CalcSwapValueProps {
	data: any;
	type: string;
}
const CalcSwapValue = ({ data, type }: CalcSwapValueProps) => {
	const record = data.row.original;
	return <>{!record.isGroupRow && <MoneyFormatter money={Number(record[type])} />}</>;
};

export default CalcSwapValue;
