import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { MarketItem } from '../../../../../../../../gateways/RfpGateway/rfp.types';

import styles from './WeekInformation.module.scss';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface WeekInformationProps {
	marketItem: MarketItem;
}

const WeekInformation: FC<WeekInformationProps> = ({ marketItem }): JSX.Element => {
	const { t } = useTranslation();

	if (!marketItem.tradingSessions) {
		return <></>;
	}

	const getTradingSessionInfo = (day: number) => {
		const tradingSessions = marketItem.tradingSessions?.sessions[day];
		if (tradingSessions) {
			return tradingSessions.map((session, index) => {
				if (session.isOpen) {
					return session && session.schedule ? (
						<div
							key={`${session.schedule.start}-${marketItem.code}-${marketItem.exchangeTicker}-${day}`}
							className={cn(styles.value)}
						>
							{session.schedule.start}-{session.schedule.end}
						</div>
					) : (
						<div
							key={`${session.schedule.end}-${marketItem.code}-${marketItem.exchangeTicker}-${day}`}
							className={cn(styles.value)}
						>
							{t(`en:CLOSED_TITLE`)}
						</div>
					);
				}
				return (
					<div
						key={`${session.schedule.end}-${marketItem.code}-${marketItem.exchangeTicker}-${day}`}
						className={cn(styles.value)}
					>
						{t(`en:CLOSED_TITLE`)}
					</div>
				);
			});
		}
		return <></>;
	};

	return (
		<div className={cn(styles.container)}>
			{days.map((day, index) => (
				<div key={`${day}-${marketItem.code}-${marketItem.exchangeTicker}`} className={cn(styles.dayAndTimeWrapper)}>
					<div className={cn(styles.label)}>{t(`en:${day}`)}:</div>
					<div className={cn(styles.valuesWrapper)}>{getTradingSessionInfo(index)}</div>
				</div>
			))}
		</div>
	);
};

export default WeekInformation;
