import React from 'react';

import styles from './Tutorials.module.scss';

interface SingleTutorialType {
	id: string;
	url: string;
	title: string;
	openVideo: (id: string, placeholder: string) => void;
}

const SingleTutorial: React.FC<SingleTutorialType> = ({ id, url, title, openVideo }) => {
	return (
		<div key={`${id}`} className={styles.videoArticle} onClick={() => openVideo(id, url)}>
			<div>
				<img src={url} alt={title} className={styles.image} />
			</div>
			<div className={styles.descriptionWrapper}>
				<p className={styles.headline}>{title}</p>
			</div>
		</div>
	);
};
export default SingleTutorial;
