import { formatNumberWithCommas } from '../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

export const generateFXMaxPositionArray = (inputData: any) => {
	if (!inputData) {
		return null;
	}

	const arr: any = [];
	Object.entries(inputData).forEach(([fxPair, value]) => {
		const [leadCurrency, secondaryCurrency] = fxPair.split('/');
		const pairLimit = [leadCurrency + secondaryCurrency, value];

		const existingLeadEntry = arr.find((element: any) => element.leadCurrency === leadCurrency);
		if (existingLeadEntry) {
			existingLeadEntry.limits.push(pairLimit);
		} else {
			arr.push({ leadCurrency: leadCurrency, limits: [pairLimit] });
		}
	});

	return arr;
};

export const generateFXMaxPositionTable = (array: any, translationFunc: any, language: string) => {
	if (!array) {
		return '';
	}

	let tableContent = `<table><thead>
		<th>${translationFunc('wtr:TRADING_CURRENCY_PAIRS')}</th><th>${translationFunc(
		'en:REPORT_BASE_CURRENCY'
	)}</th><th>${translationFunc('wtr:TIER_MAX_POSITION')}</th>
	</thead>`;
	array.forEach((entry: any) => {
		const rows = entry.limits.length;
		entry.limits.forEach((limitEntry: string, index: number) => {
			tableContent += `<tr>`;
			if (index === 0) {
				tableContent += `<td rowspan="${rows}">${entry.leadCurrency}</td>`;
			}
			tableContent += `
                    <td>${limitEntry[0]}</td>
                    <td>${formatNumberWithCommas(limitEntry[1], 0, language)}</td>
                </tr>`;
		});
		tableContent += '</tbody>\n';
	});
	tableContent += '</table>';

	return tableContent;
};
