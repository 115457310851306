import { MutableRefObject, useContext, useEffect, useMemo, useRef } from 'react';

import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import quoteStore from '../../../../../store/QuoteStore/quoteStore';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import { DEFAULT_FEED_ID } from '../../../../../utils/functions/WatchlistUtils';
import { MarketItem, PriceQuote } from '../../../../../gateways/RfpGateway/rfp.types';
import { getCurrentPrice } from '../../../../../utils/functions/calculations';
import { RFP } from '../../../../../gateways/RfpGateway/rfpConstants';

const useDetachWatchList = (sortingRef: MutableRefObject<Record<string, PriceQuote>>) => {
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const mappedWatchlist = dashboardContext.mappedWatchlist;
	const showOrderTicket = dashboardContext.showOrderTicket;
	const confirmOrderTicket = dashboardContext.newOrderModalToggle.confirmOrder;
	const showConfirmTicket = dashboardContext.showConfirmTicket;

	const setQuote = quoteStore.use.setQuote();
	const currentWatchList = watchListStore.use.currentWatchList();

	const priceFeed = useRef<any>();
	const subIdRef = useRef<string>();

	const unsubscribeInstruments = () => {
		if (rfpGatewayContext && priceFeed.current) {
			rfpGatewayContext.unsubscribePriceQuote(priceFeed.current);
			priceFeed.current = undefined;
		}
	};

	const calcChangePercentage = (marketItem: MarketItem) => {
		let dailyPercent = 0;
		const currentPriceQuote = getCurrentPrice(marketItem.feedId, marketItem.code, rfpGatewayContext);
		if (currentPriceQuote) {
			const previousClosePrice = dashboardContext.previousDayClosePrices[marketItem.code];
			const ask = currentPriceQuote.a;
			const bid = currentPriceQuote.b;

			if (ask && bid && previousClosePrice && previousClosePrice !== 0) {
				const averageCurrentPrice = (ask + bid) / 2;
				dailyPercent = ((averageCurrentPrice - previousClosePrice) / previousClosePrice) * 100;
			}
		}

		return dailyPercent;
	};

	const tableData = useMemo(() => {
		const items: any[] = [];
		const codes = [];

		if (mappedWatchlist) {
			const watchListCodes = mappedWatchlist[currentWatchList].flatMap((code) => code._code);
			let symbols: string[] = [];
			for (let symbol of watchListCodes) {
				const marketItem = rfpGatewayContext?.getMarketItem(symbol);

				if (marketItem) {
					symbols.push(marketItem.code);

					const obj = {
						code: marketItem.code,
						symbol: marketItem.displayName,
						name: marketItem.fullName,
						change: calcChangePercentage(marketItem),
						low: marketItem.code,
						high: marketItem.code,
						sell: marketItem.code,
						buy: marketItem.code,
					};
					items.push(obj);
					codes.push(symbol);
				}
			}

			if (rfpGatewayContext && symbols.length > 0) {
				rfpGatewayContext.send(RFP.queuePreviousDayClosePrice, { code: symbols });
			}
		}

		return { items, codes };
	}, [mappedWatchlist, currentWatchList, showOrderTicket, confirmOrderTicket, showConfirmTicket]);

	const subscribeToNewInstruments = (codes: string[]) => {
		if (rfpGatewayContext && tableData.items.length > 0 && tableData.codes.length > 0) {
			subIdRef.current = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, codes, (priceQuote) => {
				setQuote(priceQuote);
				sortingRef.current = {
					...sortingRef.current,
					[priceQuote.c]: priceQuote,
				};
			});
		}
	};

	useEffect(() => {
		if (subIdRef.current) {
			unsubscribeInstruments();
		}

		subscribeToNewInstruments(tableData.codes);

		return () => {
			unsubscribeInstruments();
		};
	}, [tableData]);

	return tableData;
};

export default useDetachWatchList;
