import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import {
	instrumentExistsInDynamicWatchlists,
	instrumentExistsInWatchlist,
	maxInstrumentsPerWatchlist,
} from '../../../../../utils/functions/WatchlistUtils';

import AddToWatchlist from '../AddToWatchlist';

import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';

import styles from './WatchlistsList.module.scss';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

interface WatchlistsListProps {
	hasLeftIcon?: boolean;
}

const WatchlistsList: FC<WatchlistsListProps> = () => {
	const dashboardContext = useContext(DashboardContext);
	const getTranslatedWLName = useGetTranslatedWLName();
	const { t } = useTranslation();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	return (
		<div>
			<div className={styles.titleContainer}>
				<div className={styles.titleWatchlist}>{t('wtr:SELECT_WATCHLIST')}</div>
			</div>
			<div className={styles.watchlistContainer}>
				{dashboardContext.mappedWatchlist !== null &&
					Object.getOwnPropertyNames(dashboardContext.mappedWatchlist).map((watchlistName: string) => {
						if (
							instrumentExistsInDynamicWatchlists(
								watchlistName,
								dashboardContext.defaultWatchLists,
								isSpreadBettingAccount
							)
						) {
							return;
						}
						const maxReached = maxInstrumentsPerWatchlist(dashboardContext.mappedWatchlist!, watchlistName);
						return (
							<div
								className={
									maxReached &&
									dashboardContext.selectedInstrument &&
									dashboardContext.mappedWatchlist &&
									!instrumentExistsInWatchlist(
										dashboardContext.mappedWatchlist,
										watchlistName,
										dashboardContext.selectedInstrument
									)
										? styles.maxItemContainer
										: styles.itemContainer
								}
							>
								<div className={styles.watchlistItem}>
									<OverlayTrigger
										delay={{ show: 750, hide: 0 }}
										key={watchlistName}
										placement="bottom-end"
										overlay={<Tooltip id={watchlistName}>{getTranslatedWLName(watchlistName)}</Tooltip>}
									>
										<>
											<span style={{ cursor: 'default' }}>{getTranslatedWLName(watchlistName)}</span>
											{maxReached && <div className={styles.maxErrorMessage}>{t('wtr:MAX_INSTRUMENT_ERROR')}</div>}
										</>
									</OverlayTrigger>
								</div>

								<AddToWatchlist watchlistName={watchlistName} maxReached={maxReached} action={'ADD'} />
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default WatchlistsList;
