import { PriceType, TimeScale, HistoryRequest } from '../../../gateways/RfpGateway/rfp.types';

export class GymHistoryRequest implements HistoryRequest {
	reqId: string;
	feedId: string;
	code: string;
	priceType: PriceType;
	timescale: TimeScale;
	startTime: number;
	endTime: number;

	currentTradeTimeMillis?: number;

	constructor(historyRequest: HistoryRequest, currentTradeTimeMillis?: number) {
		this.reqId = GymHistoryRequest.generateUniqueHistoryId();
		this.feedId = historyRequest.feedId;
		this.code = historyRequest.code;
		this.startTime = historyRequest.startTime ?? 0;
		this.endTime = historyRequest.endTime ?? 0;
		this.priceType = historyRequest.priceType;
		this.timescale = historyRequest.timescale;
		if (currentTradeTimeMillis) {
			this.currentTradeTimeMillis = currentTradeTimeMillis;
		}
	}

	cloneWithNewRequestId(): GymHistoryRequest {
		// Deep cloning - make a deep copy of the current request
		const cloneObj: GymHistoryRequest = JSON.parse(JSON.stringify(this));
		return new GymHistoryRequest(cloneObj);
	}

	public static generateUniqueHistoryId(): string {
		const suffix = Math.floor(Math.random() * 999);
		const prefix = Date.now();
		return `hs-gym-${prefix}-${suffix}`;
	}
}
