import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import { useHistory } from 'react-router';

import WtrTooltip from '../../../shared/WtrTooltip/WtrTooltip';

import uniqueId from '../../../utils/functions/uniqueId';

import { SignalsListType } from '../Signals';

import AppContext from '../../../contexts/AppContext';

import DashboardContext from '../../../contexts/DashboardContext';

import { getRelevantFormattedPrice } from '../helpers';

import { AppComponentType, TicketLayout } from '../../../utils/functions/enums';

import Routes from '../../../setup/routes';

import SignalsEmptyState from '../SignalsEmptyState/SignalsEmptyState';

import Loading from '../../../views/components/Loading/Loading';

import SignalPercentElement from './SignalPercentElement/SignalPercentElement';

import styles from './SignalsList.module.scss';
import SortingChevronIcons from './SortingChevronIcons/SortingChevronIcons';
import SignalsFilter from './SignalsFilter/SignalsFilter';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { instrumentGroupProps } from '../../../utils/functions/constants';
import InstrumentIcon from '../../../views/components/GroupBadge/InstrumentIcon';
import tradingAccountStore from '../../../store/tradingAccountStore';

type fieldNames = 'instrument' | 'confidence' | 'order' | null;
type sortingDirections = 'ASC' | 'DESC' | null;

export interface FilterConditions {
	assetClass: string;
	confidenceRange: number[];
	orderType: string;
}
interface SignalsListProps {
	data: SignalsListType[];
	loading: boolean;
	hasError: boolean;
	hasBorder?: boolean;
	hasHeader?: boolean;
	hasFilter?: boolean;
	hasHorizontalConfidenceLayout?: boolean;
	doNavigate?: boolean;
}

export const initialFiltersState = {
	assetClass: 'all',
	confidenceRange: [20, 100],
	orderType: 'all',
};

const SignalsList = ({
	data,
	hasHeader = true,
	hasFilter = true,
	hasHorizontalConfidenceLayout = false,
	doNavigate = false,
	loading,
	hasError,
	hasBorder = false,
}: SignalsListProps) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const history = useHistory();
	const { t } = useTranslation();
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const [triggerAnimation, setTriggerAnimation] = useState<boolean>(false);
	const [sorting, setSorting] = useState<{ sortingDirection: sortingDirections; fieldName: fieldNames }>({
		sortingDirection: null,
		fieldName: null,
	});
	const [filterConditions, setFilterConditions] = useState<FilterConditions>(initialFiltersState);

	const fieldNamesList: fieldNames[] = ['instrument', 'confidence', 'order'];

	const getValueToSort = (signal: SignalsListType, fieldName: string | null) => {
		if (fieldName === 'instrument') return signal.Product.name;
		if (fieldName === 'confidence') return signal.Report.confidence;
		if (fieldName === 'order') return signal.Report.status_text;

		return '';
	};

	const sortSignals = (direction: 'ASC' | 'DESC' | null, fieldName: fieldNames) => {
		const signalList = data.filter((signal) => {
			const matchConfidence =
				signal.Report.confidence >= filterConditions.confidenceRange[0] &&
				signal.Report.confidence <= filterConditions.confidenceRange[1];

			const matchAssetClass =
				filterConditions.assetClass === 'all' || filterConditions.assetClass === signal.Group.Category.slug;

			const matchOrder =
				filterConditions.orderType === 'all' || filterConditions.orderType === signal.Report.status_text;

			return matchConfidence && matchAssetClass && matchOrder;
		});

		if (direction === null) return signalList;

		const compare = (currentSignal: SignalsListType, nextSignal: SignalsListType) => {
			const currentSignalValue = getValueToSort(currentSignal, fieldName)!;
			const nextSignalValue = getValueToSort(nextSignal, fieldName)!;

			if (currentSignalValue < nextSignalValue) return -1;

			if (currentSignalValue > nextSignalValue) return 1;

			return 0;
		};

		const sortedData = signalList.sort(compare);

		const sorted = direction === 'ASC' ? sortedData : sortedData.reverse();

		return sorted;
	};

	const handleClickSort = (fieldName: fieldNames) => {
		const { sortingDirection } = sorting;

		setSorting((prevState: { sortingDirection: sortingDirections; fieldName: fieldNames }) => {
			if (sortingDirection === 'ASC') {
				return { sortingDirection: prevState.fieldName !== fieldName ? 'ASC' : 'DESC', fieldName };
			} else if (sortingDirection === 'DESC') {
				return { sortingDirection: prevState.fieldName !== fieldName ? 'ASC' : null, fieldName };
			} else {
				// if the sorting direction was last null
				return { sortingDirection: 'ASC', fieldName };
			}
		});
	};

	const handleOpenFilter = () => {
		setTriggerAnimation(!triggerAnimation);
		if (!isFilterOpen) setIsFilterOpen(true);
	};

	const formatOrderStatusTranslation = (orderType: string) => {
		return t(`wtr:${orderType.split(' ').join('_').toUpperCase()}`);
	};
	const handleSignalClick = (signalData: any) => {
		dashboardContext.closeAllOtherTabs();
		appContext.currentlySelectedSignal = signalData;
		if (doNavigate) {
			dashboardContext.presentComponentType = AppComponentType.Signals;
			history.push(Routes.trader.signals, { from: window.location.pathname });
		}
	};

	const handleChangeSlider = (value: number[] | number) => {
		if (Array.isArray(value)) {
			setFilterConditions({ ...filterConditions, confidenceRange: value });
		}
	};

	const handleChangeDropdown = (dropdownName: 'assetClass' | 'orderType', { value }: any) => {
		setFilterConditions({ ...filterConditions, [dropdownName]: value });
	};

	const handleClearFilters = () => {
		setFilterConditions(initialFiltersState);
	};

	const hasFilters = Object.entries(initialFiltersState).toString() !== Object.entries(filterConditions).toString();

	return (
		<>
			<div className={cn(styles.container, hasBorder && styles.border)}>
				{hasFilter && (
					<div className={styles.headerContainer}>
						<div className={styles.titleContainer}>
							<span className={styles.title}>{t('wtr:SIGNALS')}</span>
							{data?.length !== 0 && (
								<WtrTooltip content={t('en:FILTER')}>
									<div className={styles.filterIconHolder} onClick={handleOpenFilter}>
										<FontAwesomeIcon icon={['far', 'filter']} className={styles.filterIcon} />
										{hasFilters && <div className={styles.hasFiltersIndicator} />}
									</div>
								</WtrTooltip>
							)}
						</div>
						{isFilterOpen && (
							<SignalsFilter
								setOpen={setIsFilterOpen}
								triggerAnimation={triggerAnimation}
								filterConditions={filterConditions}
								handleChangeSlider={handleChangeSlider}
								handleChangeDropdown={handleChangeDropdown}
								data={data}
							/>
						)}
					</div>
				)}

				{loading ? (
					<Loading className={styles.loader} />
				) : (
					<div className={styles.scrollableWrapper}>
						{data?.length && !hasError ? (
							<>
								{sortSignals(sorting.sortingDirection, sorting.fieldName).length === 0 ? (
									<SignalsEmptyState
										fullHeight
										message={t('wtr:NO_FILTER_RESULTS')}
										clearFilters
										handleClearFilters={handleClearFilters}
									/>
								) : (
									<table className={styles.signalsTable}>
										{hasHeader && (
											<thead>
												<tr className={styles.tableHeadersRow}>
													{fieldNamesList.map((fieldName: fieldNames) => (
														<th key={uniqueId()} onClick={() => handleClickSort(fieldName)}>
															<div className={styles.header}>
																<span
																	className={cn(
																		sorting.sortingDirection !== null &&
																			sorting.fieldName === fieldName &&
																			styles.selected
																	)}
																>
																	{fieldName === 'instrument' && t('en:INSTRUMENT')}
																	{fieldName === 'confidence' && t('wtr:CONFIDENCE')}
																	{fieldName === 'order' && t('en:Order')}
																</span>
																<div className={styles.sortingChevtonsContainer}>
																	{sorting.fieldName === fieldName && (
																		<SortingChevronIcons sortType={sorting.sortingDirection} />
																	)}
																</div>
															</div>
														</th>
													))}
												</tr>
											</thead>
										)}
										<tbody>
											{sortSignals(sorting.sortingDirection, sorting.fieldName).map((signalData) => (
												<tr
													key={signalData.id}
													className={cn(
														styles.signalItem,
														appContext.currentlySelectedSignal?.id === signalData.id && styles.selected
													)}
													onClick={() => handleSignalClick(signalData)}
												>
													<td className={styles.signalsItemWrapper}>
														{signalData.marketItem && (
															<div className={styles.iconContainer}>
																<OverlayTrigger
																	key="instrumentCategoryInfo"
																	delay={{ show: 750, hide: 0 }}
																	placement="bottom"
																	overlay={
																		<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
																			{signalData.marketItem.grp &&
																				t(instrumentGroupProps[signalData.marketItem.grp]?.name)}
																		</Tooltip>
																	}
																>
																	<div className={styles.badge}>
																		<InstrumentIcon marketItem={signalData.marketItem} />
																	</div>
																</OverlayTrigger>
															</div>
														)}
														<div className={styles.instrumentInfo}>
															<span className={styles.code}>{signalData.marketItem?.displayName}</span>
															<span className={styles.description}>{signalData.marketItem?.fullName}</span>
														</div>
													</td>
													<td>
														<div className={styles.confidencePercent}>
															<SignalPercentElement
																percent={signalData.Report.confidence}
																hasHorizontalLayout={hasHorizontalConfidenceLayout}
															/>
														</div>
													</td>
													<td>
														<div className={styles.orderStats}>
															<span className={styles.orderType}>
																{formatOrderStatusTranslation(signalData.Report.status_text)}
															</span>
															<span>{getRelevantFormattedPrice(signalData, 'entry', isSpreadBettingAccount)}</span>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								)}
							</>
						) : (
							<SignalsEmptyState message={t('wtr:NO_SIGNALS_AVAILABLE')} fullHeight />
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default SignalsList;
