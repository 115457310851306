import { createContext } from 'react';

import clone from 'clone';

import { Optional } from '../utils/functions/Nullable';
import { ObservableObject } from '../utils/functions/Observables';
import { PositionSide, TTMarginRuleItem } from '../gateways/RfpGateway/rfp.types';

import { Resolver } from '../utils/functions/Ioc';

import { default as Lazy } from '../utils/functions/Lazy';
import { TradingPositionSide } from '../utils/functions/enums';

type IMarginRulesContextProperties = {
	accountId?: Optional<number>;
	marginRules: TTMarginRuleItem[];
	futureMarginReqId?: Optional<string>;
	futureMarginValue?: Optional<number>;
};

const defaultValues = new Lazy<IMarginRulesContextProperties>(() => {
	return {
		accountId: null,
		marginRules: [],
		futureMarginReqId: null,
		futureMarginValue: null,
	};
});

interface IMarginRulesContext extends IMarginRulesContextProperties {
	reset(): void;
}

// Had to implement it this way because the current architecture doesn't allow to use proper simple React Contexts,
// or connect to Zustand store in the websocket subscriptions handler file
export class MarginRulesContextProvider
	extends ObservableObject<MarginRulesContextProvider, keyof IMarginRulesContextProperties>
	implements IMarginRulesContext
{
	private m_propertyValues: IMarginRulesContextProperties;
	private static _instance = new Lazy(() => {
		return new MarginRulesContextProvider();
	});

	public static get instance(): MarginRulesContextProvider {
		return this._instance.getValue();
	}

	public get accountId(): Optional<number> | undefined {
		return this.m_propertyValues.accountId;
	}

	public set accountId(value: Optional<number> | undefined) {
		this.raiseAndSetIfChanged('accountId', value, this.m_propertyValues.accountId, (value) => {
			this.m_propertyValues.accountId = value;
		});
	}

	public get marginRules(): TTMarginRuleItem[] {
		return this.m_propertyValues.marginRules;
	}

	public set marginRules(value: TTMarginRuleItem[]) {
		this.raiseAndSetIfChanged('marginRules', value, this.m_propertyValues.marginRules, (value) => {
			this.m_propertyValues.marginRules = value;
		});
	}

	public get futureMarginReqId(): Optional<string> | undefined {
		return this.m_propertyValues.futureMarginReqId;
	}

	public set futureMarginReqId(value: Optional<string> | undefined) {
		this.raiseAndSetIfChanged('futureMarginReqId', value, this.m_propertyValues.futureMarginReqId, (value) => {
			this.m_propertyValues.futureMarginReqId = value;
		});
	}

	public get futureMarginValue(): Optional<number> | undefined {
		return this.m_propertyValues.futureMarginValue;
	}

	public set futureMarginValue(value: Optional<number> | undefined) {
		this.raiseAndSetIfChanged('futureMarginValue', value, this.m_propertyValues.futureMarginValue, (value) => {
			this.m_propertyValues.futureMarginValue = value;
		});
	}

	private constructor() {
		super();
		this.m_propertyValues = clone(defaultValues.getValue());
	}

	public reset(): void {
		this.m_propertyValues = {
			...clone(defaultValues.getValue()),
		};
	}

	public resetFutureMargin(): void {
		this.m_propertyValues = {
			...clone(defaultValues.getValue()),
			accountId: this.accountId,
			marginRules: this.marginRules,
		};
	}
	
	public getPositionSideValue(side?: TradingPositionSide): PositionSide {
		switch (side) {
			case TradingPositionSide.Buy:
				return PositionSide.BUY;
			case TradingPositionSide.Sell:
				return PositionSide.SELL
			default:
				return PositionSide.PSD_UNKNOWN;
		}
	}
}

export default createContext<MarginRulesContextProvider>(
	Resolver.isSet && Resolver.isRegistered(MarginRulesContextProvider)
		? Resolver.resolve(MarginRulesContextProvider)
		: MarginRulesContextProvider.instance
);
