import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetMFAEnabled = () => {
	const queryKey = ['useGetMFAEnabled'];

	const queryFn = async () => {
		try {
			const response = await axios({
				url: '/user/tfa/status',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data.status === 'ENABLED'
		} catch (e) {
			return false;
		}
	};

	return useQuery({ queryKey, queryFn });
};

export default useGetMFAEnabled;
