abstract class StringUtils {
	public static isString(value: any): value is string {
		return value != null && (typeof value === 'string' || value instanceof String);
	}

	public static equalsIgnoreCase(value: string, compare: string): boolean;
	public static equalsIgnoreCase(value: string, ...compare: string[]): boolean;
	public static equalsIgnoreCase(value: string, compare: string | string[]): boolean {
		return Array.isArray(compare)
			? compare.every(
					(compValue) =>
						value === compValue ||
						(value != null && compValue != null && value.toLowerCase() === compValue.toLowerCase())
			  )
			: value === compare || (value != null && compare != null && value.toLowerCase() === compare.toLowerCase());
	}

	public static isNullOrEmpty(value: string | null | undefined): boolean {
		return value == null || value === '';
	}

	public static isNullOrWhiteSpace(value: string | null | undefined): boolean {
		return value == null || value.trim() === '';
	}

	public static evaluateTemplate(template: string, context: { [k: string]: any }): string {
		let returnValue = template;
		if (!this.isNullOrWhiteSpace(returnValue)) {
			/* eslint no-template-curly-in-string: off */
			const regex = new RegExp('{([^}]+)}', 'gm');
			let match: ReturnType<typeof regex['exec']>;
			while ((match = regex.exec(returnValue)) !== null) {
				// This is necessary to avoid infinite loops with zero-width matches
				if (match.index === regex.lastIndex) {
					regex.lastIndex++;
				}

				//replace template variable if match is found
				if (match.length >= 2) {
					const search = match[0];
					const key = match[1];
					const replace = context != null && context[key] != null ? `${context[key]}` : '';
					returnValue = returnValue.replace(search, replace);
				}
			}
		}
		return returnValue;
	}
}

export default {
	isString: StringUtils.isString,
	equalsIgnoreCase: StringUtils.equalsIgnoreCase,
	isNullOrEmpty: StringUtils.isNullOrEmpty,
	isNullOrWhiteSpace: StringUtils.isNullOrWhiteSpace,
	evaluateTemplate: StringUtils.evaluateTemplate,
};
