import React, { useContext } from 'react';

import { DropdownProps } from 'semantic-ui-react';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEnabledLangOptions, TLanguageOptions } from '../../features/Dashboard/Settings/settingsOptions';
import i18n from '../../../setup/i18n';
import AppContext from '../../../contexts/AppContext';
import WtrDropdown from '../WtrDropdown/WtrDropdown';

import styles from './LangSelect.module.scss';

interface LangSelectProps {
	defaultLang: string;
	onChange?: (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => void;
	className?: string;
	selection?: boolean;
	disabled?: boolean;
	customIcon?: boolean;
}

const LangSelect: React.FC<LangSelectProps> = ({
	defaultLang,
	onChange,
	className,
	selection,
	disabled,
	customIcon,
}) => {
	const appContext = useContext(AppContext);

	const enabledLangOptions = getEnabledLangOptions();

	const dropdownItem = (item: TLanguageOptions) => {
		return (
			<div className={cn(styles.itemWrapper, defaultLang === item.value && styles.itemWrapper_active)}>
				<span className={styles.dropdownItem}>{item.shortText}</span>
				{defaultLang === item.value && <FontAwesomeIcon className="activeIcon" icon={['far', 'check']} />}
			</div>
		);
	};

	const options = enabledLangOptions.map((language: TLanguageOptions) => ({
		key: language.value,
		text: dropdownItem(language),
		value: language.value,
	}));

	const handleChange = (_: any, { value }: any): void => {
		appContext.isArabic = value === 'ar-SA';
		appContext.languageSettings = value;
		i18n.changeLanguage(value);
	};

	if (!defaultLang) return null;

	return (
		<WtrDropdown
			selection={selection}
			inline={!selection}
			options={options}
			defaultValue={i18n.language || (defaultLang === 'en-US' ? 'en' : defaultLang)}
			onChange={onChange ? onChange : handleChange}
			className={cn(className)}
			disabled={disabled}
			icon={
				<FontAwesomeIcon
					className={styles.dropdownIcon}
					icon={customIcon ? ['fas', 'caret-down'] : ['far', 'chevron-down']}
				/>
			}
		/>
	);
};

export default LangSelect;
