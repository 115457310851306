import React, { MutableRefObject, useContext, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { createColumnHelper } from '@tanstack/react-table';
import cn from 'classnames';

import { useMarketItemsMap } from '../../../../components/MarketItemFormatter/useMarketItemsMap';
import DashboardContext from '../../../../../contexts/DashboardContext';
import styles from '../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import MarketItemIcon from '../../../../components/GroupBadge/MarketItemIcon';
import PercentChange from '../../../Markets/MarketsGridNew/components/PercentChange';
import LowOrHigh, { LowOrHighType } from '../../../Markets/MarketsGridNew/components/LowOrHigh';
import { PriceQuote } from '../../../../../gateways/RfpGateway/rfp.types';
import SellOrBuyButton, { SellOrBuyType } from '../../../Markets/MarketsGridNew/components/SellOrBuyButton';
import DetachWatchlistPercentChange from './components/DetachWatchlistPercentChange';

const useDetachWatchListColumn = (sortingRef: MutableRefObject<Record<string, PriceQuote>>) => {
	const dashboardContext = useContext(DashboardContext);

	const { t } = useTranslation();

	const quantityType = dashboardContext.quantityType;

	const marketsItemMap = useMarketItemsMap();

	const columnHelper = createColumnHelper<any>();

	const changeCaption = useMemo(() => {
		const caption = t('wtr:CHANGE');
		return caption[caption.length - 1] === ':' ? caption.slice(0, -1) : caption;
	}, []);

	const columns = useMemo(
		() => [
			columnHelper.accessor('symbol', {
				id: 'symbol',
				header: () => <span>{t('en:SYMBOL')}</span>,
				cell: (info) => (
					<div className={cn(styles.symbolCell)}>
						<MarketItemIcon marketItem={marketsItemMap[info.row.original.code]} />
						<span className={cn(styles.symbolText)}>{info.getValue().replace('_SB', '')}</span>
					</div>
				),
				enableHiding: false,
				minSize: 100,
			}),
			columnHelper.accessor((row) => row.name, {
				id: 'name',
				header: () => <span>{t('wtr:FULL_NAME')}</span>,
				cell: (info) => <span className={cn(styles.verticalCenterAlign)}>{info.getValue()}</span>,
				minSize: 150,
			}),
			columnHelper.accessor('change', {
				id: 'change',
				header: () => <span>{changeCaption}</span>,
				cell: (info) => (
					<div className={cn(styles.verticalCenterAlign)}>
						<DetachWatchlistPercentChange info={info} marketItem={marketsItemMap[info.row.original.buy]} />
					</div>
				),
				minSize: 100,
			}),
			columnHelper.accessor('low', {
				id: 'low',
				header: () => <span>{t('en:LOW')}</span>,
				cell: (info) => (
					<div className={cn(styles.verticalCenterAlign)}>
						<LowOrHigh marketItemCode={info.getValue()} type={LowOrHighType.Low} />
					</div>
				),
				minSize: 100,
				sortingFn: (rowA, rowB) => {
					const a = sortingRef.current[rowA.original.low];
					const b = sortingRef.current[rowB.original.low];

					if (!a || !b) {
						return 0;
					}

					return a.l - b.l;
				},
			}),
			columnHelper.accessor('high', {
				id: 'high',
				header: () => <span>{t('en:HIGH')}</span>,
				cell: (info) => (
					<div className={cn(styles.verticalCenterAlign)}>
						<LowOrHigh marketItemCode={info.getValue()} type={LowOrHighType.High} />
					</div>
				),
				minSize: 100,
				sortingFn: (rowA, rowB) => {
					const a = sortingRef.current[rowA.original.high];
					const b = sortingRef.current[rowB.original.high];

					if (!a || !b) {
						return 0;
					}

					return a.h - b.h;
				},
			}),
			columnHelper.accessor('sell', {
				id: 'sell',
				header: () => <span>{t('en:SELL')}</span>,
				cell: (info) => (
					<div className={cn(styles.verticalCenterAlign)}>
						<SellOrBuyButton marketItem={marketsItemMap[info.row.original.sell]} type={SellOrBuyType.Sell} />
					</div>
				),
				sortingFn: (rowA, rowB) => {
					const a = sortingRef.current[rowA.original.sell];
					const b = sortingRef.current[rowB.original.sell];

					if (!a || !b) {
						return 0;
					}

					return a.b - b.b;
				},
				enableResizing: false,
				enableHiding: false,
				size: 100,
				minSize: 100,
				maxSize: 100,
			}),
			columnHelper.accessor('buy', {
				id: 'buy',
				header: () => <span>{t('en:BUY')}</span>,
				cell: (info) => (
					<div className={cn(styles.verticalCenterAlign)}>
						<SellOrBuyButton marketItem={marketsItemMap[info.row.original.buy]} type={SellOrBuyType.Buy} />
					</div>
				),
				sortingFn: (rowA, rowB) => {
					const a = sortingRef.current[rowA.original.buy];
					const b = sortingRef.current[rowB.original.buy];

					if (!a || !b) {
						return 0;
					}

					return a.a - b.a;
				},
				enableResizing: false,
				enableHiding: false,
				size: 100,
				minSize: 100,
				maxSize: 100,
			}),
		],
		[Object.keys(marketsItemMap).length, quantityType]
	);

	return columns;
};

export default useDetachWatchListColumn;
