import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';

import AppContext from '../../contexts/AppContext';
import Loading from '../../views/components/Loading/Loading';

import styles from './Calendar.module.scss';
import Insights from './Insights';

// keep this guy here as it's a temporary (I hope) patch :))))))))))))))))))))))))))))))))))))))
const FallBackCalendar = ({ language }: { language: string }) => {
	const containerRef = useRef<HTMLDivElement>();

	const languageMap: { [key: string]: string } = {
		it: 'it-IT',
		ja: 'ja-JP',
		th: 'th-TH',
		'pt-BR': 'pt-PT',
	};

	useEffect(() => {
		if (containerRef.current) {
			const config = document.createElement('script');
			config.type = 'text/javascript';
			config.innerHTML = `var fxcalendar_config = {
                gridselector: "#fxst_grid",
                filterselector: "#fxst_filter",
                columns: "None",
                showeventlink: "0", // if you want to disable event link, put a "0"
                showcountrylink: "0",  // if you want to disable country link, put a "0"
                culture: "${languageMap[language]}"
            };`;
			containerRef.current.appendChild(config);

			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://calendar.fxstreet.com/scripts/main/?version=0.0.2';
			containerRef.current.appendChild(script);
		}
	}, []);

	return (
		<>
			<div id="calendar" className={styles.fallBackCalendar} ref={containerRef}>
				<div id="fxst_filter"></div>
				<div id="fxst_grid"></div>
			</div>
		</>
	);
};

const Calendar = () => {
	const calRef = useRef<HTMLDivElement>();
	const [showLoader, setShowLoader] = useState<boolean>(true);

	const { languageSettings } = useContext(AppContext);

	const languageMap: { [key: string]: string } = {
		ar: 'ar-SA',
		'zh-Hans': 'zh-CN',
		'zh-Hant': 'zh-CHT',
	};

	useEffect(() => {
		if (calRef.current) {
			calRef.current.toggleAttribute('fxs-widget', true);

			const script = document.createElement('script');
			script.src = 'https://staticcontent.fxstreet.com/widgets-v2/stable/fxs-widgets.js';
			script.async = true;
			script.defer = true;
			calRef.current.parentNode!.appendChild(script);
		}
	}, []);

	useEffect(() => {
		setTimeout(() => setShowLoader(false), 1000);
	}, []);

	if (!localStorage.getItem('fxStreet')) {
		return (
			<>
				{showLoader && <Loading size="200" className={styles.loader} />}
				<div className={cn(styles.calendar, showLoader && styles.displayNone)}>
					<Insights />
				</div>
			</>
		);
	}

	// fallback to the previous implementation of FXStreet calendar for  the following languages
	const missingLanguge = ['it', 'ja', 'th', 'pt-BR'].includes(languageSettings);
	if (missingLanguge) return <FallBackCalendar language={languageSettings} />;

	return (
		<div className={styles.calendarContainer}>
			<div
				culture={languageMap[languageSettings] || languageSettings}
				className={styles.calendar}
				ref={calRef}
				name="calendar"
			></div>
		</div>
	);
};

export default Calendar;
