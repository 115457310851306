import { HistoryTick, PriceQuote, TradingPositionState } from '../../../gateways/RfpGateway/rfp.types';

import { GymTradingAccount } from './GymTradingAccount';
import { GymTradingPosition } from '../Positions/GymTradingPosition';
import { GymHistoryManager } from '../HistoryData/GymHistoryManager';
import { GymSimulation } from '../Simulations/GymSimulation';

export class GymTradingManager {
	private static instance: GymTradingManager;

	/**
	 * The GymTradingManager's constructor should always be private to prevent direct
	 * construction calls with the `new` operator.
	 */
	private constructor() {}

	/**
	 * The static method that controls the access to the singleton instance.
	 */
	public static sharedInstance(): GymTradingManager {
		if (!GymTradingManager.instance) {
			GymTradingManager.instance = new GymTradingManager();
		}
		return GymTradingManager.instance;
	}

	restorePosition(account: GymTradingAccount, position: GymTradingPosition, simulation: GymSimulation) {
		account.positionManager.restorePosition(position);

		if (
			(position.state === TradingPositionState.open || position.state === TradingPositionState.pending) &&
			position.marketItem
		) {
			GymHistoryManager.sharedInstance().requestRefQuotes(account, position.marketItem, simulation);
		}
	}

	openOrder(account: GymTradingAccount, position: GymTradingPosition, simulation: GymSimulation) {
		position.aId = account.id;
		account.positionManager.openOrder(position);

		if (position.marketItem) {
			GymHistoryManager.sharedInstance().requestRefQuotes(account, position.marketItem, simulation);
		}
	}

	modifyPosition(account: GymTradingAccount, position: GymTradingPosition) {
		account.positionManager.modifyPosition(position);
	}

	partialClosePosition(
		account: GymTradingAccount,
		position: GymTradingPosition,
		amount: number,
		dataItem: HistoryTick,
		priceQuote: PriceQuote
	) {
		account.positionManager.partialClosePosition(position, amount, dataItem, priceQuote);
	}

	closePosition(account: GymTradingAccount, position: GymTradingPosition) {
		account.positionManager.closePosition(position);
	}

	deletePosition(account: GymTradingAccount, position: GymTradingPosition) {
		account.positionManager.deletePosition(position);
	}
}
