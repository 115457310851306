import { useContext } from 'react';

import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';
import authStore, { AuthStore } from '../../../store/authStore';
import pipStore from '../../../store/PipsStore/pipStore';

const useUpdatePipsPreferences = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const { email } = authStore((store: AuthStore) => store.userProfile);
	const setStopLossPips = pipStore.use.setStopLossPips();
	const setTakeProfitPips = pipStore.use.setTakeProfitPips();

	return () => {
		const pipsObject = {
			takeProfit: 30,
			stopLoss: 30,
		};

		if (!appContext.userPreferences) {
			const localPrefs = localStorage.getItem(`userPref-${email}`);

			if (localPrefs !== null && localPrefs !== 'null') {
				const parsedPrefs = JSON.parse(localPrefs);
				pipsObject.takeProfit = parsedPrefs.user_prefs.trading.settings[dashboardContext.accountType].pips.takeProfit;
				pipsObject.stopLoss = parsedPrefs.user_prefs.trading.settings[dashboardContext.accountType].pips.stopLoss;
			}

			setTakeProfitPips(pipsObject.takeProfit);
			setStopLossPips(pipsObject.stopLoss);
		} else {
			appContext.userPreferences.user_prefs.platform.lang._code = appContext.languageSettings;
			if (
				appContext.userPreferences.user_prefs.trading.settings[dashboardContext.accountType] &&
				appContext.userPreferences.user_prefs.trading.settings[dashboardContext.accountType]?.pips
			) {
				pipsObject.takeProfit =
					appContext.userPreferences.user_prefs.trading.settings[dashboardContext.accountType]?.pips?.takeProfit || 30;
				pipsObject.stopLoss =
					appContext.userPreferences.user_prefs.trading.settings[dashboardContext.accountType]?.pips?.stopLoss || 30;
			}

			setTakeProfitPips(pipsObject.takeProfit);
			setStopLossPips(pipsObject.stopLoss);
		}
	};
};

export default useUpdatePipsPreferences;