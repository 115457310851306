import { createContext } from 'react';
import clone from 'clone';

import { Resolver } from '../utils/functions/Ioc';
import { default as Lazy } from '../utils/functions/Lazy';
import { Optional } from '../utils/functions/Nullable';
import { ObservableObject } from '../utils/functions/Observables';
import { TfboApiMethodMeta } from '../gateways/TfboGateway/TfboGateway.types';
import {
	limitProfitLossValues,
	octStoredValues,
	TPreferenceResponse,
} from '../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { SubscriptionInfo, SubscriptionModalState } from '../utils/functions/subscriptionUtils';
import { SignalsListType } from '../pages/Signals/Signals';

export type TAppTheme = 'light' | 'dark' | string;

export type TTradingMode = 'LIVE' | 'DEMO';

export type TAppCountry = {
	id: number;
	text: string;
	value: string;
	code: string;
	phoneCode: string;
	liveCurrency: string;
	demoCurrency: string;
};

export type TAppPhoneCode = {
	text: string;
	value: string;
};

export type TAppCurrency = {
	countryId: number;
	currency: string;
};

type TGetUserIpResponsePayloadResult = (typeof TfboApiMethodMeta)['utility.getUserIp']['responsePayload']['result'];

type TLoginResponsePayloadResult = (typeof TfboApiMethodMeta)['authentication.login']['responsePayload']['result'];

type TLoginResponseStatus = (typeof TfboApiMethodMeta)['authentication.login']['responsePayload']['status'];

type TFeedbackResponsePayloadResult = (typeof TfboApiMethodMeta)['profile.feedback']['responsePayload']['result'];

type TForgotPasswordResponsePayloadResult =
	(typeof TfboApiMethodMeta)['authentication.forgotPassword']['responsePayload']['result'];

type TSendEmailVerificationResponsePayloadResult =
	(typeof TfboApiMethodMeta)['emailValidation.sendVerificationCode']['responsePayload']['result'];

type TAccountStatsResponsePayloadResult = (typeof TfboApiMethodMeta)['account.stats']['responsePayload']['result'];

type TAppContextProperties = {
	isLoading: boolean;
	accessToken: Optional<string>;

	feedbackModal: boolean;
	contactModal: boolean;
	openSettings: boolean;
	statusModal: boolean;
	reconnectModal: boolean;
	appTheme: TAppTheme;
	accountStats: TAccountStatsResponsePayloadResult['data'];
	ipLocation: Optional<TGetUserIpResponsePayloadResult>;
	email: Optional<string>;
	userId: Optional<number>;
	userCountryISO: Optional<string>;
	countryData: Optional<any>;
	emailVerification: Optional<TSendEmailVerificationResponsePayloadResult>;
	feedbackResponse: Optional<TFeedbackResponsePayloadResult>;
	firstName: Optional<string>;
	isLoggedIn: boolean;
	isTwofaEnabled: boolean;
	isArabic: boolean;
	lastName: Optional<string>;
	loginResponse: Optional<TLoginResponsePayloadResult>;
	loginResponseStatus: Optional<TLoginResponseStatus>;
	modalId: Optional<string>;
	phoneNumber: { phone: string; phoneCountryCode: number };
	resetPasswordInfo: Optional<TForgotPasswordResponsePayloadResult>;
	selectedLanguage: string;
	twofaBarcodeKey: Optional<string>;
	twofaSecretKey: Optional<string>;
	userPreferences: TPreferenceResponse | null;
	isChildWindow: Optional<boolean>;
	tradingMode: TTradingMode;
	userPreferenceError: boolean;
	languageSettings: string;
	isJapanAccount: boolean;
	agreementPendingVersion: string | null;
	subscriptionInfo: SubscriptionInfo | null;
	subscriptionModal: SubscriptionModalState | null;
	canFetchSubscrInfo: boolean;
	currentlySelectedSignal: SignalsListType | null;
	limitProfitLossValues: limitProfitLossValues | null;
	octStoredValues: octStoredValues | null;
};

const defaultValues = new Lazy<TAppContextProperties>(() => {
	return {
		isLoading: false,
		isArabic: localStorage.getItem('language') === 'ar-SA',
		accessToken: null,
		appTheme: localStorage.getItem('wt-theme') || 'light',
		accountStats: [],
		autoShowModal: false,
		statusModal: false,
		noDemoAccountModal: false,
		unapprovedAppModal: false,
		insufficientFundModal: false,
		incompleteApplication: false,
		resetAccountModal: false,
		underReviewModal: false,
		successfulActCreation: false,
		feedbackModal: false,
		contactModal: false,
		openSettings: false,
		reconnectModal: false,
		ipLocation: null,
		email: null,
		emailVerification: null,
		feedbackResponse: null,
		firstName: null,
		isLoggedIn: false,
		isTwofaEnabled: false,
		lastName: null,
		loginResponse: null,
		loginResponseStatus: null,
		modalId: null,
		phoneNumber: { phone: '', phoneCountryCode: 0 },
		resetPasswordInfo: null,
		selectedLanguage: 'English',
		twofaBarcodeKey: null,
		twofaSecretKey: null,
		userPreferences: null,
		userId: null,
		isChildWindow: false,
		tradingMode: 'LIVE',
		userPreferenceError: false,
		languageSettings: '',
		isJapanAccount: false,
		agreementPendingVersion: null,
		userCountryISO: null,
		countryData: null,
		subscriptionInfo: null,
		subscriptionModal: null,
		canFetchSubscrInfo: false,
		currentlySelectedSignal: null,
		limitProfitLossValues: {},
		octStoredValues: {},
	};
});

interface IAppContext extends TAppContextProperties {
	reset(): void;
	logout(): void;
}

export class AppContextProvider
	extends ObservableObject<AppContextProvider, keyof TAppContextProperties>
	implements IAppContext
{
	private m_propertyValues: TAppContextProperties;
	private static _instance = new Lazy(() => {
		return new AppContextProvider();
	});

	public static get instance(): AppContextProvider {
		return this._instance.getValue();
	}

	public get isLoading(): boolean {
		return this.m_propertyValues.isLoading;
	}

	public set isLoading(value: boolean) {
		this.raiseAndSetIfChanged('isLoading', value, this.m_propertyValues.isLoading, (value) => {
			this.m_propertyValues.isLoading = value;
		});
	}

	public get isArabic(): boolean {
		return this.m_propertyValues.isArabic;
	}

	public set isArabic(value: boolean) {
		this.raiseAndSetIfChanged('isArabic', value, this.m_propertyValues.isArabic, (value) => {
			this.m_propertyValues.isArabic = value;
		});
	}

	public get userPreferenceError(): boolean {
		return this.m_propertyValues.userPreferenceError;
	}

	public set userPreferenceError(value: boolean) {
		this.raiseAndSetIfChanged('userPreferenceError', value, this.m_propertyValues.userPreferenceError, (value) => {
			this.m_propertyValues.userPreferenceError = value;
		});
	}

	public get languageSettings(): string {
		return this.m_propertyValues.languageSettings;
	}

	public set languageSettings(value: string) {
		this.raiseAndSetIfChanged('languageSettings', value, this.m_propertyValues.languageSettings, (value) => {
			this.m_propertyValues.languageSettings = value;
		});
	}

	public get accessToken(): Optional<string> {
		return this.m_propertyValues.accessToken;
	}

	public set accessToken(value: Optional<string>) {
		this.raiseAndSetIfChanged('accessToken', value, this.m_propertyValues.accessToken, (value) => {
			this.m_propertyValues.accessToken = value;
		});
	}

	public get appTheme(): TAppTheme {
		return this.m_propertyValues.appTheme;
	}

	public set appTheme(value: TAppTheme) {
		localStorage.setItem('wt-theme', value);
		this.raiseAndSetIfChanged('appTheme', value, this.m_propertyValues.appTheme, (value) => {
			this.m_propertyValues.appTheme = value;
		});
	}

	public get accountStats(): TAccountStatsResponsePayloadResult['data'] {
		return this.m_propertyValues.accountStats;
	}

	public set accountStats(value: TAccountStatsResponsePayloadResult['data']) {
		this.raiseAndSetIfChanged('accountStats', value, this.m_propertyValues.accountStats, (value) => {
			this.m_propertyValues.accountStats = value;
		});
	}

	public get ipLocation(): Optional<TGetUserIpResponsePayloadResult> {
		return this.m_propertyValues.ipLocation;
	}

	public set ipLocation(value: Optional<TGetUserIpResponsePayloadResult>) {
		this.raiseAndSetIfChanged('ipLocation', value, this.m_propertyValues.ipLocation, (value) => {
			this.m_propertyValues.ipLocation = value;
		});
	}

	public get email(): Optional<string> {
		return this.m_propertyValues.email;
	}

	public set email(value: Optional<string>) {
		this.raiseAndSetIfChanged('email', value, this.m_propertyValues.email, (value) => {
			this.m_propertyValues.email = value;
		});
	}

	public get countryData(): Optional<any> {
		return this.m_propertyValues.countryData;
	}

	public set countryData(value: Optional<any>) {
		this.raiseAndSetIfChanged('countryData', value, this.m_propertyValues.countryData, (value) => {
			this.m_propertyValues.countryData = value;
		});
	}

	public get userCountryISO(): Optional<string> {
		return this.m_propertyValues.userCountryISO;
	}

	public set userCountryISO(value: Optional<string>) {
		if (value) {
			// Setup is Japan Account
			this.m_propertyValues.isJapanAccount = value.toUpperCase() === 'JP';
		}

		this.raiseAndSetIfChanged('userCountryISO', value, this.m_propertyValues.userCountryISO, (value) => {
			this.m_propertyValues.userCountryISO = value;
		});
	}

	public get userId(): Optional<number> {
		return this.m_propertyValues.userId;
	}

	public set userId(value: Optional<number>) {
		this.raiseAndSetIfChanged('userId', value, this.m_propertyValues.userId, (value) => {
			this.m_propertyValues.userId = value;
		});
	}

	public get emailVerification(): Optional<TSendEmailVerificationResponsePayloadResult> {
		return this.m_propertyValues.emailVerification;
	}

	public set emailVerification(value: Optional<TSendEmailVerificationResponsePayloadResult>) {
		this.raiseAndSetIfChanged('emailVerification', value, this.m_propertyValues.emailVerification, (value) => {
			this.m_propertyValues.emailVerification = value;
		});
	}

	public get feedbackResponse(): Optional<TFeedbackResponsePayloadResult> {
		return this.m_propertyValues.feedbackResponse;
	}

	public set feedbackResponse(value: Optional<TFeedbackResponsePayloadResult>) {
		this.raiseAndSetIfChanged('feedbackResponse', value, this.m_propertyValues.feedbackResponse, (value) => {
			this.m_propertyValues.feedbackResponse = value;
		});
	}

	public get firstName(): Optional<string> {
		return this.m_propertyValues.firstName;
	}

	public set firstName(value: Optional<string>) {
		this.raiseAndSetIfChanged('firstName', value, this.m_propertyValues.firstName, (value) => {
			this.m_propertyValues.firstName = value;
		});
	}

	public get isLoggedIn(): boolean {
		return this.m_propertyValues.isLoggedIn;
	}

	public set isLoggedIn(value: boolean) {
		this.raiseAndSetIfChanged('isLoggedIn', value, this.m_propertyValues.isLoggedIn, (value) => {
			this.m_propertyValues.isLoggedIn = value;
		});
	}

	public get isTwofaEnabled(): boolean {
		return this.m_propertyValues.isTwofaEnabled;
	}

	public set isTwofaEnabled(value: boolean) {
		this.raiseAndSetIfChanged('isTwofaEnabled', value, this.m_propertyValues.isTwofaEnabled, (value) => {
			this.m_propertyValues.isTwofaEnabled = value;
		});
	}

	public get lastName(): Optional<string> {
		return this.m_propertyValues.lastName;
	}

	public set lastName(value: Optional<string>) {
		this.raiseAndSetIfChanged('lastName', value, this.m_propertyValues.lastName, (value) => {
			this.m_propertyValues.lastName = value;
		});
	}

	public get loginResponse(): Optional<TLoginResponsePayloadResult> {
		return this.m_propertyValues.loginResponse;
	}

	public set loginResponse(value: Optional<TLoginResponsePayloadResult>) {
		this.raiseAndSetIfChanged('loginResponse', value, this.m_propertyValues.loginResponse, (value) => {
			this.m_propertyValues.loginResponse = value;
		});
	}

	public get loginResponseStatus(): Optional<TLoginResponseStatus> {
		return this.m_propertyValues.loginResponseStatus;
	}

	public set loginResponseStatus(value: Optional<TLoginResponseStatus>) {
		this.raiseAndSetIfChanged('loginResponseStatus', value, this.m_propertyValues.loginResponseStatus, (value) => {
			this.m_propertyValues.loginResponseStatus = value;
		});
	}

	public get modalId(): Optional<string> {
		return this.m_propertyValues.modalId;
	}

	public set modalId(value: Optional<string>) {
		this.raiseAndSetIfChanged('modalId', value, this.m_propertyValues.modalId, (value) => {
			this.m_propertyValues.modalId = value;
		});
	}

	public get phoneNumber(): { phone: string; phoneCountryCode: number } {
		return this.m_propertyValues.phoneNumber;
	}

	public set phoneNumber(value: { phone: string; phoneCountryCode: number }) {
		this.raiseAndSetIfChanged('phoneNumber', value, this.m_propertyValues.phoneNumber, (value) => {
			this.m_propertyValues.phoneNumber = value;
		});
	}

	public get resetPasswordInfo(): Optional<TForgotPasswordResponsePayloadResult> {
		return this.m_propertyValues.resetPasswordInfo;
	}

	public set resetPasswordInfo(value: Optional<TForgotPasswordResponsePayloadResult>) {
		this.raiseAndSetIfChanged('resetPasswordInfo', value, this.m_propertyValues.resetPasswordInfo, (value) => {
			this.m_propertyValues.resetPasswordInfo = value;
		});
	}

	public get selectedLanguage(): string {
		return this.m_propertyValues.selectedLanguage;
	}

	public set selectedLanguage(value: string) {
		this.raiseAndSetIfChanged('selectedLanguage', value, this.m_propertyValues.selectedLanguage, (value) => {
			this.m_propertyValues.selectedLanguage = value;
		});
	}

	public get twofaBarcodeKey(): Optional<string> {
		return this.m_propertyValues.twofaBarcodeKey;
	}

	public set twofaBarcodeKey(value: Optional<string>) {
		this.raiseAndSetIfChanged('twofaBarcodeKey', value, this.m_propertyValues.twofaBarcodeKey, (value) => {
			this.m_propertyValues.twofaBarcodeKey = value;
		});
	}

	public get twofaSecretKey(): Optional<string> {
		return this.m_propertyValues.twofaSecretKey;
	}

	public set twofaSecretKey(value: Optional<string>) {
		this.raiseAndSetIfChanged('twofaSecretKey', value, this.m_propertyValues.twofaSecretKey, (value) => {
			this.m_propertyValues.twofaSecretKey = value;
		});
	}

	public get userPreferences(): TPreferenceResponse | null {
		return this.m_propertyValues.userPreferences;
	}

	public set userPreferences(value: TPreferenceResponse | null) {
		this.raiseAndSetIfChanged('userPreferences', value, this.m_propertyValues.userPreferences, (value) => {
			this.m_propertyValues.userPreferences = value;
		});
	}

	public get limitProfitLossValues(): limitProfitLossValues | null {
		return this.m_propertyValues.limitProfitLossValues;
	}

	public set limitProfitLossValues(value: limitProfitLossValues | null) {
		this.raiseAndSetIfChanged('limitProfitLossValues', value, this.m_propertyValues.limitProfitLossValues, (value) => {
			this.m_propertyValues.limitProfitLossValues = value;
		});
	}

	public get octStoredValues(): octStoredValues | null {
		return this.m_propertyValues.octStoredValues;
	}

	public set octStoredValues(value: octStoredValues | null) {
		this.raiseAndSetIfChanged('octStoredValues', value, this.m_propertyValues.octStoredValues, (value) => {
			this.m_propertyValues.octStoredValues = value;
		});
	}

	public get isChildWindow(): Optional<boolean> {
		return this.m_propertyValues.isChildWindow;
	}

	public set isChildWindow(value: Optional<boolean>) {
		this.raiseAndSetIfChanged('isChildWindow', value, this.m_propertyValues.isChildWindow, (value) => {
			this.m_propertyValues.isChildWindow = value;
		});
	}
	public get tradingMode(): TTradingMode {
		return this.m_propertyValues.tradingMode;
	}

	public set tradingMode(value: TTradingMode) {
		this.raiseAndSetIfChanged('tradingMode', value, this.m_propertyValues.tradingMode, (value) => {
			this.m_propertyValues.tradingMode = value;
		});
	}
	public get feedbackModal(): boolean {
		return this.m_propertyValues.feedbackModal;
	}
	public set feedbackModal(value: boolean) {
		this.raiseAndSetIfChanged('feedbackModal', value, this.m_propertyValues.feedbackModal, (value) => {
			this.m_propertyValues.feedbackModal = value;
		});
	}
	public get reconnectModal(): boolean {
		return this.m_propertyValues.reconnectModal;
	}
	public set reconnectModal(value: boolean) {
		this.raiseAndSetIfChanged('reconnectModal', value, this.m_propertyValues.reconnectModal, (value) => {
			this.m_propertyValues.reconnectModal = value;
		});
	}
	public get contactModal(): boolean {
		return this.m_propertyValues.contactModal;
	}
	public set contactModal(value: boolean) {
		this.raiseAndSetIfChanged('contactModal', value, this.m_propertyValues.contactModal, (value) => {
			this.m_propertyValues.contactModal = value;
		});
	}
	public get openSettings(): boolean {
		return this.m_propertyValues.openSettings;
	}
	public set openSettings(value: boolean) {
		this.raiseAndSetIfChanged('openSettings', value, this.m_propertyValues.openSettings, (value) => {
			this.m_propertyValues.openSettings = value;
		});
	}
	public get statusModal(): boolean {
		return this.m_propertyValues.statusModal;
	}
	public set statusModal(value: boolean) {
		this.raiseAndSetIfChanged('statusModal', value, this.m_propertyValues.statusModal, (value) => {
			this.m_propertyValues.statusModal = value;
		});
	}

	public get isJapanAccount(): boolean {
		return this.m_propertyValues.isJapanAccount;
	}

	public set isJapanAccount(value: boolean) {
		this.raiseAndSetIfChanged('isJapanAccount', value, this.m_propertyValues.isJapanAccount, (value) => {
			this.m_propertyValues.isJapanAccount = value;
		});
	}

	public get agreementPendingVersion(): string | null {
		return this.m_propertyValues.agreementPendingVersion;
	}

	public set agreementPendingVersion(value: string | null) {
		this.raiseAndSetIfChanged(
			'agreementPendingVersion',
			value,
			this.m_propertyValues.agreementPendingVersion,
			(value) => {
				this.m_propertyValues.agreementPendingVersion = value;
			}
		);
	}

	public get subscriptionInfo(): SubscriptionInfo | null {
		return this.m_propertyValues.subscriptionInfo;
	}

	public set subscriptionInfo(value: SubscriptionInfo | null) {
		this.raiseAndSetIfChanged('subscriptionInfo', value, this.m_propertyValues.subscriptionInfo, (value) => {
			this.m_propertyValues.subscriptionInfo = value;
		});
	}

	public get subscriptionModal(): SubscriptionModalState | null {
		return this.m_propertyValues.subscriptionModal;
	}

	public set subscriptionModal(value: SubscriptionModalState | null) {
		this.raiseAndSetIfChanged('subscriptionModal', value, this.m_propertyValues.subscriptionModal, (value) => {
			if (
				value?.isOpen === this.m_propertyValues.subscriptionModal?.isOpen &&
				value?.reason === this.m_propertyValues.subscriptionModal?.reason
			) {
				return;
			}

			this.m_propertyValues.subscriptionModal = value;
		});
	}

	public get canFetchSubscrInfo(): boolean {
		return this.m_propertyValues.canFetchSubscrInfo;
	}

	public set canFetchSubscrInfo(value: boolean) {
		this.raiseAndSetIfChanged('canFetchSubscrInfo', value, this.m_propertyValues.canFetchSubscrInfo, (value) => {
			this.m_propertyValues.canFetchSubscrInfo = value;
		});
	}

	public get currentlySelectedSignal(): SignalsListType | null {
		return this.m_propertyValues.currentlySelectedSignal;
	}

	public set currentlySelectedSignal(value: SignalsListType | null) {
		this.raiseAndSetIfChanged(
			'currentlySelectedSignal',
			value,
			this.m_propertyValues.currentlySelectedSignal,
			(value) => {
				this.m_propertyValues.currentlySelectedSignal = value;
			}
		);
	}

	private constructor() {
		super();
		this.m_propertyValues = clone(defaultValues.getValue());
	}

	public reset(): void {
		const defaultVals = clone(defaultValues.getValue());
		this.m_propertyValues = {
			...defaultVals,
			appTheme: this.appTheme,
		};
	}

	public logout(): void {
		const defaultVals = clone(defaultValues.getValue());
		this.m_propertyValues = {
			...defaultVals,
			ipLocation: this.ipLocation,
			isChildWindow: this.isChildWindow,
			appTheme: this.appTheme,
		};
	}
}

export default createContext<AppContextProvider>(
	Resolver.isSet && Resolver.isRegistered(AppContextProvider)
		? Resolver.resolve(AppContextProvider)
		: AppContextProvider.instance
);
