import React from 'react';

// Define a custom error handler for imports
const handleImportError = (error: any) => {
    // Implement custom error handling, like logging the error or displaying a user-friendly message
    console.log(error);
};

// Type definition for the import function
type ComponentImport = () => Promise<{ default: React.ComponentType<any> }>;

// The lazyRetry function that retries a component import
const lazyRetry = (componentImport: ComponentImport): Promise<{ default: React.ComponentType<any> }> =>
    new Promise((resolve, reject) => {
        const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');

        componentImport()
            .then((component) => {
                console.log(">>>> Attempting to import ", component?.default?.name, storageKey);
                // Reset the refresh flag in sessionStorage
                window.sessionStorage.setItem(storageKey, 'false');
                if (component === undefined) {
                    window.sessionStorage.setItem(storageKey, 'true');
                    window.location.reload(); // Reload the page
                }

                console.log(">>>> Succeeded to import ", storageKey);
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    console.log(">>>> Error to import + REFRESHING", storageKey);

                    // If not refreshed yet, reload the page and set the flag
                    window.sessionStorage.setItem(storageKey, 'true');
                    window.location.reload();
                } else {
                    console.log(">>>> Error to import + HANDLE ERROR", storageKey);

                    handleImportError(error);
                    reject(error); // Pass the error to be handled elsewhere if necessary
                }
            });
    });

export default lazyRetry;