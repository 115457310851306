import { useContext, useMemo } from 'react';

import DashboardContext from '../../../contexts/DashboardContext';

import {
	createMarketItemGroupMap,
	createMarketItemTierMap,
} from '../../../utils/functions/marketItems/marketItemGroupMapFormatter';
import tradingAccountStore from '../../../store/tradingAccountStore';

export const useMarketItemFormatter = () => {
	const dashboardContext = useContext(DashboardContext);
	const marketItems = dashboardContext.marketItems;
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

	return useMemo(() => {
		if (marketItems.length === 0) {
			return {};
		}

		return isJapanSubscriptionAccount ? createMarketItemTierMap(marketItems) : createMarketItemGroupMap(marketItems);
	}, [marketItems.length, isJapanSubscriptionAccount]);
};
