import { USER_EMAIL_KEY } from '../setup/config';

import { create } from './create';

import createSelectors from './createSelectors';

export type UserStats = Array<object>;

export interface UserProfile {
	email?: string;
	[key: string]: any;
}
export interface UserPhoneNumbersProps {
	phone: string;
	phoneCountryCode: string;
	mobile: string;
	mobileCountryCode: string;
}

export enum TradingMode {
	Demo = 'DEMO',
	Live = 'LIVE',
}

export interface AuthStoreActions {
	setTradingMode: (mode: TradingMode) => void;
	setTradingAccountsListsMode: (mode: TradingMode) => void;
	setApplicationStatus: (applicationStatus: string) => void;
	setUserProfile: (user: UserProfile) => void;
	setUserProfileProp: (prop: keyof UserProfile, value: any) => void;
	setUserStats: (stats: UserStats) => void;
	setUserStatsProp: (prop: keyof UserStats, value: any) => void;
	setIsAuthenticated: (isAuthenticated: boolean) => void;
	setIsMFAEnabled: (isMFAEnabled: boolean | undefined) => void;
	setSetupMFA: (setupMFA: boolean) => void;
	setDisableMFA: (disableMFA: boolean) => void;
	setReinialize: (reinitialize: number) => void;
	setIsJapanAccount: (isJapanAccount: boolean) => void;
	setIsSouthAfricaAccount: (isSouthAfricaAccount: boolean) => void;
	setIsRfpConnected: (isRfpConnected: boolean) => void;
	setAccountAvatar: (avatar: number) => void;
	setPhoneNumbers: (userPhoneNumbers: UserPhoneNumbersProps) => void;
	reset: () => void;
}
export interface AuthStoreValues {
	tradingMode: TradingMode;
	tradingAccountsListsMode: TradingMode;
	isDemoMode: boolean;
	isLiveMode: boolean;
	applicationStatus: string | undefined;
	userProfile: UserProfile;
	userStats: UserStats;
	isAuthenticated: boolean;
	isMFAEnabled: boolean | undefined;
	setupMFA: boolean;
	disableMFA: boolean;
	reinitialize: number | undefined;
	isRfpConnected: boolean;
	accountAvatar: number;
	userPhoneNumbers: UserPhoneNumbersProps;

	// TODO: Think in a better approach for the account user.countryISO based restrictions
	isJapanAccount: boolean;
	isSouthAfricaAccount: boolean;
}

const storedTradingMode = (localStorage.getItem('tradingMode') as TradingMode) || TradingMode.Live;

export const initialStateAuthStore: AuthStoreValues = {
	applicationStatus: undefined,
	isAuthenticated: false,
	userStats: [],
	userProfile: { email: localStorage.getItem(USER_EMAIL_KEY) || '' },
	tradingMode: storedTradingMode,
	tradingAccountsListsMode: storedTradingMode,
	isDemoMode: storedTradingMode === TradingMode.Demo,
	isLiveMode: storedTradingMode === TradingMode.Live,
	isMFAEnabled: undefined,
	setupMFA: false,
	disableMFA: false,
	reinitialize: undefined,
	isJapanAccount: false,
	isSouthAfricaAccount: false,
	isRfpConnected: false,
	accountAvatar: 0,
	userPhoneNumbers: {
		phone: '',
		phoneCountryCode: '',
		mobile: '',
		mobileCountryCode: '',
	},
};

export type AuthStore = AuthStoreValues & AuthStoreActions;

const authStore = create<AuthStore>()((set: any) => ({
	...initialStateAuthStore,

	setTradingMode: (tradingMode: TradingMode) => {
		set({ isLiveMode: tradingMode === TradingMode.Live, isDemoMode: tradingMode === TradingMode.Demo, tradingMode });
		localStorage.setItem('tradingMode', tradingMode);
	},

	setTradingAccountsListsMode: (tradingAccountsListsMode: TradingMode) => set({ tradingAccountsListsMode }),

	setApplicationStatus: (applicationStatus: string) => set({ applicationStatus }),

	setUserProfile: (userProfile: UserProfile) => {
		set({ userProfile });
	},

	setUserProfileProp: (prop: keyof UserProfile, value: any) => {
		if (prop === 'email') {
			localStorage.setItem(USER_EMAIL_KEY, value);
		}
		set((state: AuthStore) => ({ userProfile: { ...state.userProfile, [prop]: value } }));
	},

	setUserStats: (userStats: UserStats) => set({ userStats }),
	setUserStatsProp: (prop: keyof UserStats, value: any) =>
		set((state: AuthStore) => ({ userStats: { ...state.userStats, [prop]: value } })),

	setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),

	setIsMFAEnabled: (isMFAEnabled: boolean | undefined) => set({ isMFAEnabled }),

	setSetupMFA: (setupMFA: boolean) => set({ setupMFA }),

	setDisableMFA: (disableMFA: boolean) => set({ disableMFA }),

	setReinialize: (reinitialize: number) => set({ reinitialize }),

	setIsJapanAccount: (isJapanAccount: boolean) => set({ isJapanAccount }),

	setIsSouthAfricaAccount: (isSouthAfricaAccount: boolean) => set({ isSouthAfricaAccount }),

	setIsRfpConnected: (isRfpConnected: boolean) => set({ isRfpConnected }),
	setAccountAvatar: (accountAvatar: number) => set({ accountAvatar }),
	setPhoneNumbers: (userPhoneNumbers: UserPhoneNumbersProps) => set({ userPhoneNumbers }),

	reset: () => set({ ...initialStateAuthStore }),
}));

export default createSelectors(authStore);
