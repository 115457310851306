import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Button/Button';

import styles from './DetachedModal.module.scss';

interface DetachedModalProps {
	title: string | JSX.Element;
	body: string | JSX.Element | undefined;
	hideAction: () => void;
	closeModal?: () => void;
	successTitle: string;
	successAction: any;
	show: boolean;
	dockAction?: any;
	dockActionCustom?: boolean;
	withPadding?: boolean;
	buttonDisabledClass?: string;
}

const DetachedModal = ({
	title,
	body,
	successTitle,
	successAction,
	hideAction,
	closeModal,
	show,
	dockAction,
	withPadding = true,
	buttonDisabledClass,
	dockActionCustom,
}: DetachedModalProps) => {
	const { t } = useTranslation();

	return (
		<div>
			<Modal dialogClassName={cn(styles.modal, !body ? styles.noBody : '')} centered scrollable show={show}>
				<Modal.Header
					className={cn({
						[styles.modalHeader]: true,
						[styles.noPadding]: !withPadding,
					})}
				>
					{dockAction && !dockActionCustom && (
						<div className={styles.dockIconContainer}>
							<FontAwesomeIcon
								icon={['fas', 'external-link-alt']}
								size="1x"
								className={styles.iconTheme}
								onClick={() => dockAction()}
							/>
						</div>
					)}

					<Modal.Title
						className={cn({
							[styles.modalTitle]: true,
							[styles.noPadding]: !withPadding,
						})}
					>
						{title}
					</Modal.Title>
					{dockActionCustom && closeModal && (
						<div className={styles.dockIconContainerCustom}>
							<FontAwesomeIcon
								icon={['fas', 'external-link-alt']}
								size="1x"
								className={styles.iconTheme}
								onClick={() => dockAction()}
							/>
							<FontAwesomeIcon
								icon={['fas', 'xmark']}
								size="1x"
								className={styles.iconClose}
								onClick={() => closeModal()}
							/>
						</div>
					)}
				</Modal.Header>
				{body && <Modal.Body className={styles.modalBody}>{body}</Modal.Body>}
				<Modal.Footer className={styles.modalFooter}>
					<div className={styles.modalButtons}>
						<Button
							variant="secondary"
							size="lg"
							label={t('en:CANCEL')}
							className={styles.cancelButton}
							onClick={hideAction}
							isFullWidth
						/>
						<Button
							size="lg"
							label={successTitle}
							className={buttonDisabledClass ? buttonDisabledClass : styles.button}
							onClick={successAction}
							isFullWidth
						/>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DetachedModal;
