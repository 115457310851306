import React, { useContext, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router';

import AppContext from '../../../contexts/AppContext';
import { default as InstrumentContext, InstrumentContextProvider } from '../../../contexts/InstrumentContext';
import DashboardContext from '../../../contexts/DashboardContext';
import { changeLangForPortal } from '../../../utils/functions/changeLanguageForPortal';
import { AppComponentType } from '../../../utils/functions/enums';
import { Resolver } from '../../../utils/functions/Ioc';
import useForceRerender from '../../../utils/hooks/useForceRerender';
import useObservable from '../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../utils/hooks/usePromiseFactory';
import Dashboard from '../Dashboard/Dashboard';
import Markets from '../Markets/Markets';
import IframeThinkPortal from '../ThinkPortal/IframeThinkPortal';

import AuthLayout from '../../../layouts/AuthLayout';

import { Calendar } from '../../../pages/Calendar';

import useGenerateSSOToken from '../../../utils/mutations/useGenerateSSOToken';

import { Tutorials } from '../../../pages/Tutorials';

import styles from './DashboardContainerComponent.module.scss';

const DashboardContainerComponent = React.memo(() => {
  const appContext = useContext(AppContext);
  const dashboardContext = useContext(DashboardContext);
  const promiseFactory = usePromiseFactory();
  const forceRerender = useForceRerender();
  const email = appContext.email;
  const [url, setUrl] = useState('');

  const { mutate: generateSSOToken } = useGenerateSSOToken();
  const location = useLocation();

  const renderDisplayInit: { [key in AppComponentType]?: boolean } = {
    [AppComponentType.Markets]: false,
    [AppComponentType.Watchlist]: false,
    [AppComponentType.Calendar]: false,
    [AppComponentType.Portal]: false,
    [AppComponentType.Tutorials]: false
  };
  const toRender = useRef<{ [key in AppComponentType]?: boolean }>({
    ...renderDisplayInit
    // [AppComponentType.Watchlist]: true,
  });

  const toDisplay = useRef<{ [key in AppComponentType]?: boolean }>({
    ...renderDisplayInit
    // [AppComponentType.Watchlist]: true,
  });

  if (dashboardContext.presentComponentType) {
    toRender.current[dashboardContext.presentComponentType] = true;
    toDisplay.current[dashboardContext.presentComponentType] = true;
  }

  const instrumentContextProvider = Resolver.resolve(InstrumentContextProvider);

  const portalIframeComponents = [
    AppComponentType.Funds,
    AppComponentType.OpenAccount,
    AppComponentType.Reports,
    AppComponentType.Status,
    AppComponentType.Deposits,
    AppComponentType.UploadDocuments,
    AppComponentType.CompleteOnboarding
  ];

  useEffect(() => {
    if (!dashboardContext.presentComponentType) return;
    toDisplay.current = renderDisplayInit;

    if (portalIframeComponents.includes(dashboardContext.presentComponentType)) {
      if (!toRender.current[AppComponentType.Portal]) {
        thinkPortalAuth();
      }

      toRender.current[AppComponentType.Portal] = true;
      toDisplay.current[AppComponentType.Portal] = true;
      return;
    }
    // unmount markets page explicitly as potential performance optimisation
    toRender.current[AppComponentType.Markets] = false;

    toRender.current[dashboardContext.presentComponentType] = true;
    toDisplay.current[dashboardContext.presentComponentType] = true;
  }, [dashboardContext.presentComponentType, location]);

  useObservable(dashboardContext.getPropertyChangeStream('presentComponentType'), () => {
    promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
      forceRerender();
    });
  });

  const thinkPortalAuth = () => {
    generateSSOToken(
      {},
      {
        onSuccess: (data) => {
          setUrl(
            `${process.env.REACT_APP_THINK_PORTAL}/dashboard?ssoToken=${
              data.ssoCode
            }&email=${email}&lang=${changeLangForPortal(appContext.languageSettings)}&keepmesignedin=yes&referrer=wtr`
          );
        }
      }
    );
  };

  return (
      <div className={styles.fullWidthHeight}>
        <InstrumentContext.Provider value={instrumentContextProvider}>
          <AuthLayout
            hasHeaderSearch={
              !toDisplay.current[AppComponentType.Portal] &&
              !toDisplay.current[AppComponentType.Signals] &&
              !toDisplay.current[AppComponentType.Tutorials]
            }
          >
            <div
              className={styles.fullWidthHeight}
              style={toDisplay.current[AppComponentType.Portal] ? { display: 'block' } : { display: 'none' }}
            >
              {/* {tfboConnectionError && <ErrorPage />} */}
              {toRender.current[AppComponentType.Portal] && <IframeThinkPortal id="tp-iframe" src={url}/>}
            </div>

            <div
              className={styles.fullWidthHeight}
              style={toDisplay.current[AppComponentType.Markets] ? { display: 'block' } : { display: 'none' }}
            >
              {toRender.current[AppComponentType.Markets] && <Markets/>}
            </div>

            <div
              className={styles.fullWidthHeight}
              style={toDisplay.current[AppComponentType.Calendar] ? { display: 'block' } : { display: 'none' }}
            >
              {toRender.current[AppComponentType.Calendar] && <Calendar/>}
            </div>

            <div
              className={styles.fullWidthHeight}
              style={toDisplay.current[AppComponentType.Tutorials] ? { display: 'block' } : { display: 'none' }}
            >
              {toRender.current[AppComponentType.Tutorials] && <Tutorials/>}
            </div>
            {/* <div */}
            {/* 	className={styles.fullWidthHeight} */}
            {/* 	style={toDisplay.current[AppComponentType.Signals] ? { display: 'block' } : { display: 'none' }} */}
            {/* > */}
            {/* 	{toDisplay.current[AppComponentType.Signals] && toRender.current[AppComponentType.Signals] && <Signals />} */}
            {/* </div> */}

            {/* Important: Use visibility:'hidden' instead of display:'none' to prevent charts from reseting their scaling.
						This div should be below other divs to prioritize the active tab in the document. */}
            <div
              className={styles.fullWidthHeight}
              style={
                toDisplay.current[AppComponentType.Watchlist]
                  ? { height: '100%', visibility: 'visible' }
                  : { height: 0, visibility: 'hidden' }
              }
            >
              {toRender.current[AppComponentType.Watchlist] && <Dashboard/>}
            </div>
          </AuthLayout>
        </InstrumentContext.Provider>
      </div>
  );
});

export default DashboardContainerComponent;
