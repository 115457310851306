import React, { useState, useEffect, useContext } from 'react';

import cn from 'classnames';
import { uniqueRecord } from '../../../../utils/functions/uniqueArticle';
import EmptyNews from '../../../components/EmptyNews/EmptyNews';
import InstrumentHeader from '../../../components/InstrumentSummary/InstrumentHeader';
import NewsArticle from '../../Markets/NewsWidget/NewsArticle';
import { RFPNews } from '../../../../gateways/RfpGateway/rfp.types';

import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';

import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';
import CloseButton from '../../../components/CloseButton/CloseButton';
import JapanNewsDisclaimer from '../../../components/JapanNewsDisclaimer/JapanNewsDisclaimer';

import styles from './Newsfeed.module.scss';

import { getKeywords } from './NewsKeywords';
import AppContext from '../../../../contexts/AppContext';

const Newsfeed = React.memo(
	() => {
		const dashboardContext = useContext(DashboardContext);
		const promiseFactory = usePromiseFactory();
		const forceRerender = useForceRerender();
		const selectedInstrument = dashboardContext.selectedInstrument;
		const liveTradeNews = dashboardContext.tradeNews;
		const appContext = useContext(AppContext);
		const isChildWindow = appContext.isChildWindow;

		useObservable(dashboardContext.getPropertyChangeStream('selectedInstrument', 'tradeNews'), async (change) => {
			await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
			forceRerender();
		});

		const [matchingArticles, setMatchingArticles] = useState<RFPNews[] | any>([]);

		useEffect(() => {
			updateCode();
			//eslint-disable-next-line
		}, [selectedInstrument, liveTradeNews]);

		const updateCode = (): void => {
			if (
				selectedInstrument != null &&
				Object.keys(selectedInstrument).length > 0 &&
				selectedInstrument &&
				selectedInstrument !== undefined
			) {
				const filterCode = selectedInstrument.code;
				const formattedCode = filterCode.slice(0, 3) + '/' + filterCode.slice(3, 6);
				newsFilter(formattedCode, filterCode);
			}
		};

		//FIXME: optimisation
		const newsFilter = (formattedCode: string, filterCode: string): void => {
			let matchingRecords: RFPNews[] | any = [];
			let keywords: string[] = getKeywords(filterCode);
			for (let i = 0; i < liveTradeNews.length; i++) {
				const bodyText: string[] = liveTradeNews[i].body.split(/<p>| /);
				for (let j = 0; j < bodyText.length; j++) {
					if (
						bodyText[j] === formattedCode ||
						bodyText[j].includes(filterCode) ||
						bodyText[j].includes(formattedCode) ||
						keywords.includes(bodyText[j].toUpperCase())
					) {
						matchingRecords.push(liveTradeNews[i]);
					}
				}
			}

			const unique = uniqueRecord(matchingRecords);
			const sorted = unique.sort((a: RFPNews, b: RFPNews) => {
				return +b.time - +a.time;
			});
			setMatchingArticles(sorted);
		};

		return (
			<>
				<InstrumentHeader showButtons={true} headerActions={true} resizeHeader={false} />
				<div className={cn(styles.newsfeedContainer, isChildWindow && styles.containerChildWindow)}>
					<div className={styles.closeButtonLocation}>
						<CloseButton
							onClick={() => {
								dashboardContext.showNewsFeed = false;
							}}
						/>
					</div>
					{matchingArticles.length > 0 ? (
						matchingArticles.map((article: RFPNews) => {
							return (
								<div key={article.id} style={{ backgroundColor: 'white' }}>
									<NewsArticle headline={article.headline} time={article.time} id={article.id} />
								</div>
							);
						})
					) : (
						<EmptyNews />
					)}
				</div>
				{matchingArticles.length > 0 && <JapanNewsDisclaimer />}
			</>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps === nextProps) {
			return true;
		}
		return false;
	}
);

export default Newsfeed;
