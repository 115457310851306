import { create } from 'zustand';

import createSelectors from '../createSelectors';

export const ALL_STRING = 'All';

export enum Category {
  ALL = 'All',
  ETF = 'ETF',
  COMMODITIES = 'Commodities',
  CRYPTO = 'Crypto',
  ENERGY = 'Energy',
  EQUITIES = 'Equities',
  FOREX = 'Forex',
  INDICES = 'Indices',
  METALS = 'Metals'
}

export enum Period {
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export type FiltersState = {
  currency: string;
  setCurrency: (currency: string) => void;
  isCurrencyOpen: boolean;
  toggleOpenCurrency: (value?: boolean) => void;

  exchangeCountryCode: string;
  setExchangeCountryCode: (currency: string) => void;
  isExchangeCountryCodeOpen: boolean;
  toggleOpenExchangeCountryCode: (value?: boolean) => void;

  sector: string;
  setSector: (currency: string) => void;
  isSectorOpen: boolean;
  toggleOpenSector: (value?: boolean) => void;

  category: Category;
  setCategory: (category: Category) => void;
  isCategoryOpen: boolean;
  toggleOpenCategory: (value?: boolean) => void;

  period: Period;
  setPeriod: (period: Period) => void;
  isPeriodOpen: boolean;
  toggleOpenPeriod: (value?: boolean) => void;

  closeAllFilters: () => void;
  setAllFiltersToDefault: () => void;
};

const marketTableFilterStore = create<FiltersState>((set) => ({
  currency: ALL_STRING,
  setCurrency: (currency: string) => {
    set((state) => ({
      ...state,
      currency
    }));
  },
  isCurrencyOpen: false,
  toggleOpenCurrency: (value?: boolean) => {
    set((state) => ({
      ...state,
      isCurrencyOpen: value || !state.isCurrencyOpen,
      isExchangeCountryCodeOpen: false,
      isSectorOpen: false,
      isCategoryOpen: false,
      isPeriodOpen: false
    }));
  },
  exchangeCountryCode: ALL_STRING,
  setExchangeCountryCode: (exchangeCountryCode: string) => {
    set((state) => ({
      ...state,
      exchangeCountryCode
    }));
  },
  isExchangeCountryCodeOpen: false,
  toggleOpenExchangeCountryCode: (value?: boolean) => {
    set((state) => ({
      ...state,
      isExchangeCountryCodeOpen: value || !state.isExchangeCountryCodeOpen,
      isCurrencyOpen: false,
      isSectorOpen: false,
      isCategoryOpen: false,
      isPeriodOpen: false
    }));
  },
  sector: ALL_STRING,
  setSector: (sector: string) => {
    set((state) => ({
      ...state,
      sector
    }));
  },
  isSectorOpen: false,
  toggleOpenSector: (value?: boolean) => {
    set((state) => ({
      ...state,
      isSectorOpen: value || !state.isSectorOpen,
      isCurrencyOpen: false,
      isExchangeCountryCodeOpen: false,
      isCategoryOpen: false,
      isPeriodOpen: false
    }));
  },
  category: Category.ALL,
  setCategory: (category: Category) => {
    set((state) => ({
      ...state,
      category
    }));
  },
  isCategoryOpen: false,
  toggleOpenCategory: (value?: boolean) => {
    set((state) => ({
      ...state,
      isCategoryOpen: value || !state.isCategoryOpen,
      isCurrencyOpen: false,
      isExchangeCountryCodeOpen: false,
      isSectorOpen: false,
      isPeriodOpen: false
    }));
  },
  period: Period.Daily,
  setPeriod: (period: Period) => {
    set((state) => ({
      ...state,
      period
    }));
  },
  isPeriodOpen: false,
  toggleOpenPeriod: (value?: boolean) => {
    set((state) => ({
      ...state,
      isPeriodOpen: value || !state.isPeriodOpen,
      isCurrencyOpen: false,
      isExchangeCountryCodeOpen: false,
      isSectorOpen: false,
      isCategoryOpen: false
    }));
  },
  closeAllFilters: () => {
    set((state) => ({
      ...state,
      isCurrencyOpen: false,
      isExchangeCountryCodeOpen: false,
      isSectorOpen: false,
      isCategoryOpen: false,
      isPeriodOpen: false
    }));
  },
  setAllFiltersToDefault: () => {
    set((state) => ({
      ...state,
      currency: ALL_STRING,
      exchangeCountryCode: ALL_STRING,
      sector: ALL_STRING,
      category: Category.ALL,
    }));

  }
}));

export default createSelectors(marketTableFilterStore);
