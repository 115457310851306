import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { getEnabledLangOptions } from '../views/features/Dashboard/Settings/settingsOptions';

const loadPath = '/locales/{{lng}}/{{ns}}.json';

function genRandonNumber(length: number) {
	const chars = '0123456789';
	const charLength = chars.length;
	let result = '';
	for (var i = 0; i < length; i++) {
		result += chars.charAt(Math.floor(Math.random() * charLength));
	}
	return result;
}

const HASH = genRandonNumber(10);

i18n
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(ChainedBackend)
	.init({
		react: {
			useSuspense: false,
			transKeepBasicHtmlNodesFor: ['br', 'b', 'i', 'strong'],
		},
		backend: {
			backends: [LocalStorageBackend, HttpBackend],
			backendOptions: [
				{
					expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
					defaultVersion: "v" + HASH
				},
				{
					loadPath,
				},
			],
		},
		detection: {
			order: ['querystring', 'localStorage', 'navigator'],
			lookupQuerystring: 'lang',
			lookupLocalStorage: 'language',
			lookupCookie: 'cookie',
			caches: ['localStorage', 'cookie'],
		},
		// debug: true,
		// lng: navigator.language,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		supportedLngs: getEnabledLangOptions().map((lang) => lang.value),
		keySeparator: '',
		ns: ['andr', 'en', 'wtr', 'Vietnamese_VI_updated', 'err_codes_1', 'instruments'],
	});

export default i18n;
