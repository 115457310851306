import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as AppContext } from '../../../contexts/AppContext';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';
import { forceCloseModal } from '../../../utils/hooks/useForceCloseModal';

import Modal from '../../../shared/Modal/Modal';
import { SUPPORT_EMAIL_GENERAL, SUPPORT_EMAIL_JAPAN } from '../../../setup/config';
import { PhoneNumbers } from '../../../utils/functions/enums';

import styles from './ContactModal.module.scss';

const ContactModal = () => {
	const appContext = useContext(AppContext);
	const forceRerender = useForceRerender();
	const { t } = useTranslation();

	useObservable(appContext.getPropertyChangeStream('contactModal', 'languageSettings'), (change) => forceRerender());

	const showContactModal = appContext.contactModal;
	const languageSettings = appContext.languageSettings;

	const supportEmail = appContext.isJapanAccount ? SUPPORT_EMAIL_JAPAN : SUPPORT_EMAIL_GENERAL;

	const showCustomCountry = () => {
		switch (languageSettings) {
			case 'es':
				return [t('wtr:Spain'), PhoneNumbers.ES];
			case 'it':
				return [t('wtr:Italy'), PhoneNumbers.IT];
			case 'pt-BR':
				return ['Brasil', PhoneNumbers.BR];
			default:
				return [t('wtr:SouthAfrica'), PhoneNumbers.SA];
		}
	};

	let countriesInfos = [
		[t('wtr:UnitedKingdom'), PhoneNumbers.UK],
		[t('wtr:Australia'), PhoneNumbers.AU],
		showCustomCountry(),
		[t('wtr:Cyprus'), PhoneNumbers.CY],
		[t('wtr:Japan'), PhoneNumbers.JA_INTL],
	];

	const ContactOption = (location: string, number: string, hasPhonePlus: boolean = true) => {
		return (
			<div className={styles.optionContainer} key={location}>
				<div className={styles.option}>
					<FontAwesomeIcon icon={['far', 'phone']} className={styles.icon} />
					<span className={styles.location}>{`${location}:`}</span>
					<span dir="ltr" className={styles.number}>
						{hasPhonePlus && '+'}
						{number}
					</span>
				</div>
			</div>
		);
	};

	const handleCloseModal = () => {
		appContext.contactModal = false;
		forceCloseModal('fade modal');
	};

	return (
		<Modal show={showContactModal} onHide={handleCloseModal} centered>
			<Modal.Header className={styles.headerText}>{t('en:NAVIGATION_CONTACTUS')}</Modal.Header>
			<Modal.Body className={styles.modalBody}>
				{appContext.isJapanAccount && ContactOption(t('wtr:Japan'), PhoneNumbers.JA_LOCAL, false)}
				{!appContext.isJapanAccount && countriesInfos.map(([name, phone]) => ContactOption(name, phone))}
				<div className={styles.optionContainer}>
					<div className={styles.option}>
						<FontAwesomeIcon icon={['far', 'envelope']} className={styles.icon} />
						<span className={styles.email}>
							<a className={styles.mailLink} href={`mailto:${supportEmail}`}>
								{t('en:CONTACTUS_EMAIL_SUBTITLE')}
							</a>
						</span>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ContactModal;
