import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';
import Routes from '../../../../../setup/routes';

import authStore from '../../../../../store/authStore';

import DashboardContext from '../../../../../contexts/DashboardContext';
import { AppComponentType } from '../../../../../utils/functions/enums';

import { useCountryPrivacyPolicy } from '../../../../../utils/hooks/useCountryPrivacyPolicy';

import styles from './CommunityList.module.scss';

interface ICommunityList {
	handleToggleOpen: (e?: React.PointerEvent) => void;
}

const CommunityList = ({ handleToggleOpen }: ICommunityList) => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();
	const history = useHistory();
	const upvoty = useRef<HTMLDivElement>();
	const communityRef = useRef<HTMLInputElement>(null);
	const dashboardContext = useContext(DashboardContext);

	const userProfile = authStore.use.userProfile();
	const isJapanAccount = authStore.use.isJapanAccount();

	const privacyPolicyLink = useCountryPrivacyPolicy();

	useEffect(() => {
		if (upvoty.current) {
			const div = document.createElement('div');
			div.setAttribute('data-upvoty', '');

			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `${process.env.REACT_APP_UPVOTY_URL_EMBEDED}`;
			script.defer = true;

			const identify = document.createElement('script');
			identify.type = 'text/javascript';
			identify.innerHTML = `
					upvoty.init('identify', {
						user: {
							id: '${userProfile.id}',
							name: '${userProfile.fullName}',
							email: '${userProfile.email}',
						},
						baseUrl: '${process.env.REACT_APP_UPVOTY_URL_BASE}',
						publicKey: '${process.env.REACT_APP_UPVOTY_URL_PUBLICKEY}',
					});
				`;

			upvoty.current.appendChild(div);
			// upvoty.current.appendChild(script)
			upvoty.current.appendChild(identify);
		}
	}, [userProfile]);

	const handleClickOutside = (event: any) => {
		if (communityRef.current && !communityRef.current.contains(event.target)) {
			handleToggleOpen();
		}
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			handleToggleOpen();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('click', handleClickOutside);
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const handleAcademyClick = () => {
		if (isJapanAccount) {
			dashboardContext.userGuide = true;
			return;
		}
		handleToggleOpen();
		dashboardContext.presentComponentType = AppComponentType.Tutorials;
		history.push(Routes.trader.tutorials);
	};

	return (
		<div className={cn(styles.popup, appContext.isArabic && styles.popupIsArabic)} ref={communityRef}>
			<div className={styles.content}>
				<div className={styles.wrapperItems}>
					<div className={styles.itemContent} onClick={handleAcademyClick}>
						<div className={styles.item}>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon
									icon={['far', isJapanAccount ? 'book-open-cover' : 'clapperboard-play']}
									className={styles.icon}
								/>
							</div>
							<p>{isJapanAccount ? t('en:USER_GUIDE') : 'Think Academy'}</p>
						</div>
					</div>

					{!isJapanAccount && (
						<div className={styles.itemContent} onClick={() => {
							window.open(process.env.REACT_APP_UPVOTY_URL, '_blank');
							handleToggleOpen();
						}}>
							<div
								className={styles.item}
							>
								<div className={styles.iconWrapper}>
									<FontAwesomeIcon icon={['far', 'people-group']} className={styles.icon} />
								</div>
								<p>{t('en:COMMUNITY_TITLE')}</p>
							</div>
						</div>
					)}
					<div className={styles.itemContent} onClick={() => {
						appContext.feedbackModal = true;
						handleToggleOpen();
					}}>
						<div
							className={styles.item}
						>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon icon={['far', 'message-dots']} className={styles.icon} />
							</div>
							<p>{t('wtr:WTR_NAVIGATION_FEEDBACK')}</p>
						</div>
					</div>
					<div className={styles.itemContent} onClick={() => {
						appContext.contactModal = true;
						handleToggleOpen();
					}}>
						<div
							className={cn(styles.item, styles.itemNoBorder)}
						>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon icon={['far', 'phone']} className={styles.icon} />
							</div>
							<p>{t('en:NAVIGATION_CONTACTUS')}</p>
						</div>
					</div>
					<div className={styles.itemContent} onClick={() => {
						window.open(privacyPolicyLink, '_blank', 'noopener,noreferrer');
					}}>
						<div
							className={cn(styles.item, styles.itemNoBorder)}
						>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon icon={['far', 'lock']} className={styles.icon} />
							</div>
							<p>{t('en:PRIVACY_POLICY')}</p>
						</div>
					</div>
				</div>
			</div>

			<div ref={upvoty} />
		</div>
	);
};

export default CommunityList;
