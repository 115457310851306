import React, { useEffect, useRef, useState } from 'react';
import { Subscription, Observable, Subject } from 'observable-fns';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { default as Nullable, Optional } from '../../../utils/functions/Nullable';
import { default as PromiseFactory } from '../../../utils/functions/PromiseFactory';
import styles from './SearchableList.module.scss';

//#region SearchableListInput
interface ISearchableListInputProps {
	inputContainerAttributes?: Optional<React.HTMLAttributes<HTMLDivElement> & React.ClassAttributes<HTMLDivElement>>;
	inputAttributes?: Optional<React.InputHTMLAttributes<HTMLInputElement> & React.ClassAttributes<HTMLInputElement>>;
	searchIconAttributes?: Optional<Partial<FontAwesomeIconProps>>;
	clearIconAttributes?: Optional<Partial<FontAwesomeIconProps>>;
	inputThrottling?: Optional<number>;
	onOpen: () => any;
}

const SearchableListInput = React.memo<ISearchableListInputProps>(
	(props) => {
		const promiseFactoryRef = React.useRef(new PromiseFactory());
		let searchInputElementRef = useRef<any>();
		const [inputHasValue, setInputHasValue] = useState(false);

		async function onInputElementChange(value: string, delay?: Optional<number>): Promise<void> {
			if (delay != null && delay > 0) {
				return promiseFactoryRef.current.throttle('onInputElementChange', delay, async (resolve, reject) => {
					resolve(await onInputElementChange(value));
				});
			}
			if (value.length > 0 != inputHasValue) {
				setInputHasValue(value.length > 0);
			}
			return Promise.resolve();
		}

		const inputElementAttributes = Nullable.of(props.inputAttributes)
			.mapNullable((attributes) => {
				const classNames = [styles.searchInput, attributes?.className].filter((item) => item != null).join(' ');
				return { type: 'text', ...(attributes || {}), ...{ className: classNames } };
			})
			.map((attributes) => {
				const onInputChange = attributes.onChange;
				attributes.onChange = (event) => {
					const evnt = { ...event };
					onInputElementChange(evnt.target.value, props.inputThrottling).then(() => {
						if (onInputChange != null && evnt != null) {
							onInputChange(evnt);
						}
					});
				};
				attributes.onBlur = () => {
					setInputHasValue(false);
				};
				return attributes;
			})
			.get();

		searchInputElementRef = (inputElementAttributes.ref as typeof searchInputElementRef) || searchInputElementRef;

		const inputContainerElementAttributes = Nullable.of(props.inputContainerAttributes)
			.mapNullable((attributes) => {
				const classNames = [styles.searchInputContainer, attributes?.className]
					.filter((item) => item != null)
					.join(' ');
				return { ...(attributes || {}), ...{ className: classNames } };
			})
			.map((attributes) => {
				attributes.onClick = () => {
					Nullable.of(searchInputElementRef.current).run((element: HTMLInputElement) => {
						element.focus();
						props.onOpen();
					});
				};
				return attributes;
			})
			.get();

		const searchIconAttributes = Nullable.of(props.searchIconAttributes)
			.mapNullable((attributes) => {
				const classNames = [styles.searchIcon, attributes?.className].filter((item) => item != null).join(' ');
				return { icon: faSearch, ...(attributes || {}), ...{ className: classNames } } as FontAwesomeIconProps;
			})
			.get();

		const clearIconAttributes = Nullable.of(props.clearIconAttributes)
			.mapNullable((attributes) => {
				const classNames = [styles.closeIcon, attributes?.className].filter((item) => item != null).join(' ');
				attributes = { icon: faTimes, ...(attributes || {}), ...{ className: classNames } };
				const onMouseDown = attributes.onMouseDown;
				attributes.onMouseDown = (event) => {
					if (onMouseDown != null) {
						onMouseDown(event);
					}
					if (searchInputElementRef.current != null) {
						const element = searchInputElementRef.current as unknown as HTMLInputElement;
						element.value = '';
						if (typeof inputElementAttributes.onChange == 'function') {
							inputElementAttributes.onChange({ target: element } as React.ChangeEvent<HTMLInputElement>);
						}
					}
				};
				return attributes as FontAwesomeIconProps;
			})
			.get();

		return (
			<>
				<div {...inputContainerElementAttributes}>
					{<FontAwesomeIcon {...searchIconAttributes} />}
					<input
						autoComplete="off"
						spellCheck="false"
						name="search"
						{...inputElementAttributes}
						ref={searchInputElementRef}
					/>
					{inputHasValue && <FontAwesomeIcon {...clearIconAttributes} />}
				</div>
			</>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps == nextProps ||
			(prevProps != null &&
				nextProps != null &&
				(['inputAttributes', 'inputContainerAttributes'] as Array<keyof typeof prevProps>).every(
					(key) => prevProps[key] == nextProps[key]
				))
		);
	}
);

//#endregion

//#region SearchableListResult

const SearchableListResult = React.memo<{ observable: Observable<JSX.Element> }>(({ observable }) => {
	const subscriptionRef = useRef<Optional<Subscription<JSX.Element>>>();
	const [renderedResults, setRenderedResults] = useState<JSX.Element>();

	useEffect(() => {
		return () => {
			if (subscriptionRef.current != null) {
				subscriptionRef.current.unsubscribe();
				subscriptionRef.current = null;
			}
		};
	}, []);

	useEffect(() => {
		if (subscriptionRef.current != null) {
			subscriptionRef.current.unsubscribe();
			subscriptionRef.current = null;
		}
		subscriptionRef.current = observable.subscribe((value) => setRenderedResults(value));
	}, [observable]);

	return <>{renderedResults !== null && renderedResults}</>;
});

//#endregion

//#region SearchableList

interface ISearchableListProps<T extends any> {
	data: T[] | (() => T[]);
	filter: (item: T, searchString: string) => boolean;
	render: (matches: T[], searchString?: Optional<string>) => JSX.Element;
	onClose: () => any;
	throttle?: Optional<number>;
	inputAttributes?: Optional<ISearchableListInputProps> | Optional<() => ISearchableListInputProps>;
}

export default function SearchableList<T>(props: React.PropsWithChildren<ISearchableListProps<T>>): JSX.Element {
	const renderedResultsSubjectRef = React.useRef(new Subject<JSX.Element>());
	const formElementRef = useRef(null);

	useEffect(() => {
		return Nullable.of(globalThis.window)
			.map((window) => window.document)
			.map((document) => {
				const clickHandler = (event: MouseEvent): void => {
					const element = formElementRef.current as unknown as HTMLElement;
					if (!(element === null || element.contains(event.target as any))) {
						(props.inputAttributes as any).inputAttributes.ref.current.value = '';
						props.onClose();
						renderedResultsSubjectRef.current.next(<></>);
					}
				};
				document.addEventListener('mousedown', clickHandler);
				return () => document.removeEventListener('mousedown', clickHandler);
			})
			.orElseGet(() => () => {});
	}, []);

	function filterData(searchString: string): T[] {
		return Nullable.of(props.data)
			.map((data) => (typeof data === 'function' ? data() : data))
			.map((data) => data.filter((item) => props.filter(item, searchString)))
			.orElseGet(() => []);
	}

	const searchableInputAttributes = Nullable.of(props.inputAttributes)
		.mapNullable((attributes) => (attributes != null && typeof attributes === 'function' ? attributes() : attributes))
		.mapNullable((attributes) => {
			return { ...(attributes || {}), ...{ inputAttributes: attributes?.inputAttributes || {} } };
		})
		.map((attributes) => {
			const onInputFocus = attributes.inputAttributes.onFocus;
			attributes.inputAttributes.onFocus = (event) => {
				const searchString = event?.target?.value;
				const filteredData = filterData(searchString);
				renderedResultsSubjectRef.current.next(props.render(filteredData, searchString));
				if (onInputFocus != null) {
					onInputFocus(event);
				}
			};

			const onInputChange = attributes.inputAttributes.onChange;
			attributes.inputAttributes.onChange = (event) => {
				const searchString = event?.target?.value;
				const filteredData = filterData(searchString);
				renderedResultsSubjectRef.current.next(props.render(filteredData, searchString));
				if (onInputChange != null) {
					onInputChange(event);
				}
			};
			return attributes;
		})
		.get();

	function onFormSubmitted(event: React.FormEvent<HTMLFormElement>): void {
		if (event != null) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	return (
		<>
			<form
				onSubmit={onFormSubmitted}
				action="#"
				style={{ display: 'block', position: 'relative' }}
				ref={formElementRef}
			>
				<SearchableListInput
					onOpen={searchableInputAttributes.onOpen}
					inputThrottling={props.throttle}
					inputAttributes={searchableInputAttributes.inputAttributes}
					inputContainerAttributes={searchableInputAttributes.inputContainerAttributes}
					searchIconAttributes={searchableInputAttributes.searchIconAttributes}
					clearIconAttributes={searchableInputAttributes.clearIconAttributes}
				/>
				<SearchableListResult observable={renderedResultsSubjectRef.current} />
			</form>
		</>
	);
}

//#endregion
