import React from 'react';

import Routes from '../../../../../setup/routes';
import { AppComponentType } from '../../../../../utils/functions/enums';

import { Item } from './';

const Trade = () => {
	return (
		<Item
			component={AppComponentType.Watchlist}
			route={Routes.trader.watchlist}
			icon={'chart-candlestick'}
			tooltip="en:Trade"
		/>
	);
};

export default Trade;
