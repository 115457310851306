import React from 'react';
import cn from 'classnames';

import styles from './SignalPercentElement.module.scss';

interface SignalPercentElementProps {
	percent: number;
	hasHorizontalLayout?: boolean;
}

const SignalPercentElement = ({ percent, hasHorizontalLayout }: SignalPercentElementProps) => {
	let barCount;
	if (percent <= 20) {
		barCount = styles.oneBar;
	} else if (percent >= 40 && percent < 60) {
		barCount = styles.twoBars;
	} else if (percent >= 60 && percent < 80) {
		barCount = styles.threeBars;
	} else if (percent >= 80 && percent < 100) {
		barCount = styles.fourBars;
	} else if (percent === 100) {
		barCount = '';
	}

	return (
		<div className={cn(styles.container, hasHorizontalLayout && styles.containerHorizontalLayout)}>
			<div className={cn(styles.signalBars, styles.sizingBox, barCount)}>
				{/* 20% */}
				<div className={cn(styles.firstBar, styles.bar)}></div>
				{/* 40% */}
				<div className={cn(styles.secondBar, styles.bar)}></div>
				{/* 60% */}
				<div className={cn(styles.thirdBar, styles.bar)}></div>
				{/* 80% */}
				<div className={cn(styles.fourthBar, styles.bar)}></div>
				{/* 100% */}
				<div className={cn(styles.fifthBar, styles.bar)}></div>
			</div>
			<span className={styles.percent}>{percent}%</span>
		</div>
	);
};

export default SignalPercentElement;
