import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './SortingChevronIcons.module.scss';

interface SortingChevronIconsProps {
	sortType: 'ASC' | 'DESC' | null;
}

const SortingChevronIcons = ({ sortType }: SortingChevronIconsProps) => {
	if (sortType === null) return null;

	return (
		<>
			{sortType === 'ASC' ? (
				<FontAwesomeIcon className={styles.chevronIcon} icon={['fas', 'chevron-up']} />
			) : (
				<FontAwesomeIcon className={styles.chevronIcon} icon={['fas', 'chevron-down']} />
			)}
		</>
	);
};

export default SortingChevronIcons;
