/* eslint import/no-webpack-loader-syntax: off */
import { EventEmitter } from 'events';

import { Observable, Subscription } from 'observable-fns';

import { Optional } from '../../utils/functions/Nullable';
import { default as Lazy } from '../../utils/functions/Lazy';

import { TRfpDataMessageMap, TRfpMessages, TRfpSendParams } from './rfpConstants';
import {
	RFPConnectPayload,
	RFPConnectResult,
	PriceQuote,
	TimeScale,
	HistoryTick,
	PriceType,
	MarketItem,
} from './rfp.types';
import { QuantityType } from '../../utils/functions/enums';

//#region Config

export interface IRfpGatewayConfig {
	baseUrl: string;
	defaultFeedId?: string;
	webSocket: {
		connectTimeout: number;
		feedDelay?: number;
		simulateQuotes?: boolean;
		maxRequestsPerSecond?: Optional<number>;
		autoReconnect:
			| false
			| {
					interval: number;
			  };
	};
	debugHandler?: Optional<(...args: any) => void>;
}

//#endregion

//#region DataObject

export interface IDataObjectSubscription {
	subscribe<T extends keyof TRfpDataMessageMap>(destination: T, callback: (data: TRfpDataMessageMap[T]) => any): string;
	unsubscribe(subId: any): void;
}

//#endregion

//#region PriceSubscription
export interface IPriceQuoteSubscription {
	subscribePriceQuote(feedId: string, codes: string[], callback: (data: PriceQuote) => any): string;
	unsubscribePriceQuote(subId: any): void;
}

//#endregion

//#region PriceFeed

export interface IPriceFeedSubscription {
	feedId: string;
	code: string;
}

export interface IPriceFeed {
	readonly id: string;
	readonly subscriptions: ReadonlyArray<IPriceFeedSubscription>;
	readonly stream: Observable<PriceQuote>;
	subscribe(subscription: IPriceFeedSubscription): Promise<void>;
	subscribe(subscriptions: IPriceFeedSubscription[]): Promise<void>;
	unsubscribe(subscription: IPriceFeedSubscription): Promise<void>;
	unsubscribe(subscriptions: IPriceFeedSubscription[]): Promise<void>;
	unsubscribe(predicate: (subscription: IPriceFeedSubscription) => boolean): Promise<void>;
}

//#endregion

//#region HistoryFeed

export interface IHistoryFeedSubscription {
	reqId: string;
	feedId: string;
	code: string;
	priceType?: PriceType;
	timescale: TimeScale;
	tickCount?: number;
}

export interface IHistoryFeed {
	readonly id: string;
	readonly subscriptions: ReadonlyArray<IHistoryFeedSubscription>;
	readonly stream: Observable<HistoryTick[]>;
	fetchTicks(historyFeedSubscription: IHistoryFeedSubscription, timeoutDelay?: number): Promise<HistoryTick[]>;
	subscribe(subscription: IHistoryFeedSubscription): Promise<void>;
	unsubscribe(subscription: IHistoryFeedSubscription): Promise<void>;
	unsubscribe(predicate: (subscription: IHistoryFeedSubscription) => boolean): Promise<void>;
}

//#endregion

//#region RfpGateway

export interface IRfpGatewayEventMap {
	connected: () => any;
	disconnected: () => any;
	socketError: (error?: Error) => any;
}

export interface IRfpConnectionInfo {
	username: string;
	password: string;
	tfboSessionId: string;
	tfboToken: string;
}

export default abstract class RfpGateway {
	private readonly m_eventEmitter: Lazy<EventEmitter> = new Lazy<EventEmitter>(() => new EventEmitter());
	private m_debugHandler: Optional<(...args: any) => void> = null;
	protected readonly m_config: IRfpGatewayConfig;
	protected m_connectionInfo: Optional<IRfpConnectionInfo> = null;

	protected get eventEmitter(): EventEmitter {
		return this.m_eventEmitter.getValue();
	}

	public get debugHandler(): Optional<(...args: any) => void> {
		return this.m_debugHandler;
	}

	public set debugHandler(value: Optional<(...args: any) => void>) {
		this.m_debugHandler = value;
	}

	public get config(): Readonly<IRfpGatewayConfig> {
		return this.m_config;
	}

	public abstract get connected(): boolean;

	public abstract get isAppInBackgroundMode(): boolean;
	public abstract set setAppInBackgroundMode(value: boolean);

	/**
	 * Key is feedId-code, values are PriceQuotes for the same feedId
	 */
	public abstract get mapQuotesPrices(): ReadonlyMap<string, PriceQuote>;

	/**
	 * Key is sub id, values are HistoryTick[]
	 */
	public abstract get mapHistoryData(): ReadonlyMap<string, HistoryTick[]>;
	public abstract set setupHistoryData(reqId: string);
	public abstract set deleteMapHistoryData(reqId: string);

	public abstract get mapMarketItems(): ReadonlyMap<string, MarketItem>;
	public abstract get priceFeeds(): ReadonlyArray<IPriceFeed>;

	public abstract get historyFeeds(): ReadonlyArray<IHistoryFeed>;

	public abstract get dataObjectSubscriptions(): IDataObjectSubscription;

	public get connectionInfo(): Optional<Readonly<IRfpConnectionInfo>> {
		return this.m_connectionInfo;
	}

	protected constructor(config: IRfpGatewayConfig) {
		this.m_config = config;
		if (config.debugHandler != null) {
			this.debugHandler = config.debugHandler;
		}
	}

	public abstract connect(
		connectPayloadProvider: () => RFPConnectPayload | Promise<RFPConnectPayload>
	): Promise<RFPConnectResult>;

	public abstract createPriceFeed(id: string): IPriceFeed;

	public abstract requestTfboLogin(): this;
	public abstract requestTfboEndpoint(): this;

	public abstract deletePriceFeed(id: string): this;
	public abstract deletePriceFeed(priceFeed: IPriceFeed): this;
	public abstract deletePriceFeed(predicate: (priceFeed: IPriceFeed) => boolean): this;

	public abstract createHistoryFeed(id: string): IHistoryFeed;

	public abstract deleteHistoryFeed(id: string): this;
	public abstract deleteHistoryFeed(historyFeed: IHistoryFeed): this;
	public abstract deleteHistoryFeed(predicate: (historyFeed: IHistoryFeed) => boolean): this;

	public abstract fetchHistoryTicks(
		historyFeedSubscription: IHistoryFeedSubscription,
		timeoutDelay?: number,
		historyFeed?: IHistoryFeed
	): Promise<HistoryTick[]>;

	public abstract updateQuantityType(accountId: number, quantityType: QuantityType): Promise<void>;

	public abstract send<T extends keyof TRfpMessages['send']>(destination: T, ...args: TRfpSendParams<T>): Promise<void>;

	public abstract hasSubscription<T extends keyof TRfpMessages['subscribe']>(destination: T): boolean;

	public abstract subscribeFor<T extends keyof TRfpDataMessageMap>(
		destination: T,
		callback: (data: TRfpDataMessageMap[T]) => any
	): string;
	public abstract unsubscribeFor(subId: any): void;

	public abstract subscribePriceQuote(
		feedId: string,
		codes: string[],
		callback: (priceQuote: PriceQuote) => any
	): string;
	public abstract unsubscribePriceQuote(subId: any): void;

	public abstract subscribe<T extends keyof TRfpMessages['subscribe']>(
		destination: T
	): Promise<Observable<TRfpMessages['subscribe'][T]>>;
	public abstract subscribe<T extends keyof TRfpMessages['subscribe']>(
		destination: T,
		callback: (message: TRfpMessages['subscribe'][T]) => any
	): Promise<Subscription<TRfpMessages['subscribe'][T]>>;

	public abstract unsubscribe<T extends keyof TRfpMessages['subscribe']>(destination: T): Promise<void>;

	public abstract disconnect(): Promise<void>;

	public abstract getQuotePrices(feedId: string, code: string): PriceQuote | undefined;
	public abstract setPriceQuote(priceQuote: PriceQuote): void;

	public abstract getMarketItem(code: string, feedId?: string): MarketItem | undefined;

	public on<K extends keyof IRfpGatewayEventMap>(event: K, listener: IRfpGatewayEventMap[K]): this {
		this.eventEmitter.on(event, listener);
		return this;
	}

	public once<K extends keyof IRfpGatewayEventMap>(event: K, listener: IRfpGatewayEventMap[K]): this {
		this.eventEmitter.once(event, listener);
		return this;
	}

	public off<K extends keyof IRfpGatewayEventMap>(event: K, listener: IRfpGatewayEventMap[K]): this {
		this.eventEmitter.off(event, listener);
		return this;
	}

	public removeAllListeners<K extends keyof IRfpGatewayEventMap>(event?: K): this {
		this.eventEmitter.removeAllListeners(event);
		return this;
	}

	protected emit<K extends keyof IRfpGatewayEventMap>(event: K, ...data: Parameters<IRfpGatewayEventMap[K]>): this {
		try {
			this.eventEmitter.emit(event, ...data);
		} catch (err) {
			this.debug(`Unhandled exception when emitting ${event}`, err);
		}
		return this;
	}

	protected debug(...args: any[]): void {
		if (this.debugHandler != null) {
			try {
				this.debugHandler(...args);
			} catch (err) {}
		}
	}
}

//#endregion
