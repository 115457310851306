import React, { useState, useEffect, useContext } from 'react';

import Modal from '../../../../shared/Modal/Modal';

import { RFPNews } from '../../../../gateways/RfpGateway/rfp.types';

import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';
import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';

import styles from './NewsWidgetStyles.module.scss';

const NewsPopup = () => {
	const dashboardContext = useContext(DashboardContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const [open, setOpen] = useState<boolean>(true);
	const [article, setArticle] = useState<RFPNews | any>([]);

	const id = dashboardContext.selectedNewsContent;
	const tradeNews = dashboardContext.tradeNews;

	useObservable(dashboardContext.getPropertyChangeStream('selectedNewsContent', 'tradeNews'), () => {
		promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	const handleClose = (): void => {
		setOpen(!open);
		dashboardContext.selectedNewsContent = -1;
	};

	useEffect(() => {
		const news = tradeNews.filter((article: RFPNews) => article.id === id);
		setArticle(news[0]);
	}, []);

	return (
		<Modal size="lg" centered show={open} onHide={handleClose}>
			<div className={styles.modalContainer}>
				<Modal.Title>
					<div className={styles.heading}>{article.headline}</div>
				</Modal.Title>
				{article && (
					<Modal.Body>
						<div className={styles.textContainer}>
							<div className={styles.bodyText} dangerouslySetInnerHTML={{ __html: article.body }}></div>
						</div>
					</Modal.Body>
				)}
			</div>
		</Modal>
	);
};

export default NewsPopup;
