import { ChartTimezone } from './enums';

const convertChartTimeZone = (value: string) => {
	if (value === ChartTimezone.NYT) {
		return ChartTimezone.NYT;
	} else if (value === ChartTimezone.GMT) {
		return ChartTimezone.GMT;
	}
	return ChartTimezone.Local;
};

export default convertChartTimeZone;
