import React, { useContext } from 'react';

import { Popup as SemanticUiPopup } from 'semantic-ui-react';

import AppContext from '../../../contexts/AppContext';
import './WtrPopup.module.scss';

/**
 * Wrapper component that allows you to stylize the Semantic Ui React Popup Component
 */
const WtrPopup = ({ children, ...rest }: any) => {
	const { appTheme } = useContext(AppContext);
	rest.className = `${rest.className || ''} ${appTheme}`;

	return <SemanticUiPopup {...rest}>{children}</SemanticUiPopup>;
};

WtrPopup.Content = SemanticUiPopup.Content;
WtrPopup.Header = SemanticUiPopup.Header;

export default WtrPopup;
