import { useEffect, useRef } from 'react';
import { Optional } from '../functions/Nullable';
import { Subscription, Observable } from '../functions/Observables';

type TSubscriptionHandler<T extends any = any> =
	| ((value: T) => void)
	| {
			onNext: (value: T) => void;
			onError?: (error: any) => void;
			onComplete?: () => void;
	  };

class Hooks {
	public static useObservable<T extends any = any>(
		observable: Observable<T>,
		subscriptionHandler: TSubscriptionHandler<T>
	): Subscription<T>;
	public static useObservable<T extends any = any>(
		observable: Observable<T>,
		subscriptionHandler: TSubscriptionHandler<T>,
		dependencies: any[]
	): Subscription<T>;
	public static useObservable<T extends any = any>(
		observable: Observable<T>,
		subscriptionHandler: TSubscriptionHandler<T>,
		dependencies?: Optional<any[]>
	): Subscription<T> {
		const subscriptionRef = useRef<Optional<Subscription<T>>>();
		if (subscriptionRef.current == null) {
			subscriptionRef.current =
				typeof subscriptionHandler === 'function'
					? observable.subscribe(subscriptionHandler)
					: observable.subscribe(
							subscriptionHandler.onNext,
							subscriptionHandler.onError,
							subscriptionHandler.onComplete
					  );
		}
		useEffect(() => {
			if (subscriptionRef.current == null) {
				subscriptionRef.current =
					typeof subscriptionHandler === 'function'
						? observable.subscribe(subscriptionHandler)
						: observable.subscribe(
								subscriptionHandler.onNext,
								subscriptionHandler.onError,
								subscriptionHandler.onComplete
						  );
			}
			return () => {
				if (subscriptionRef.current != null) {
					subscriptionRef.current.unsubscribe();
					subscriptionRef.current = null;
				}
			};
		}, dependencies || []);

		return subscriptionRef.current!;
	}
}

export default Hooks.useObservable;
