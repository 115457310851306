import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Routes from '../../../setup/routes';

export function AuthGuard({ component: Component, isValidSession, ...rest }: { component: any; [key: string]: any }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				isValidSession === true ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: Routes.account.login,
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}
