import React from 'react';
import cn from 'classnames';
import { TFunctionResult } from 'i18next';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WtrPopup from '../../../../../../components/WtrPopup/WtrPopup';

import styles from './InfoElement.module.scss';

interface InfoElementProps {
	title: string;
	value: string | number | TFunctionResult;
	setRed?: boolean;
	setRedPL?: boolean;
	setRedPip?: boolean;
	id?: string;
	showMarginTooltip?: boolean;
	className?: string;
	valueClassName?: string;
	isArabic?: boolean;
}

const InfoElement = ({
	title,
	value,
	setRed,
	setRedPL,
	setRedPip,
	id,
	showMarginTooltip,
	className,
	valueClassName,
	isArabic,
}: InfoElementProps) => {
	const { t } = useTranslation();

	const applyColorStyling = () => {
		if (setRed || setRedPL || setRedPip) {
			return styles.red;
		} else if (!setRed && id && !setRedPip) {
			return styles.green;
		} else {
			return styles.border;
		}
	};

	return (
		<div className={cn(styles.infoElement, className)}>
			<div className={styles.infoGroup}>
				<span className={styles.border}>{title}</span>
				{showMarginTooltip && (
					<WtrPopup
						position={isArabic ? 'right center' : 'left center'}
						content={<div className={styles.popupText}>{t('wtr:INFO_MARGIN')}</div>}
						trigger={<FontAwesomeIcon className={styles.icon} icon={['fas', 'info-circle']}></FontAwesomeIcon>}
					/>
				)}
				<span className={cn(applyColorStyling(), styles.infoElementValue, valueClassName)}>{value}</span>
			</div>
		</div>
	);
};

export default InfoElement;
