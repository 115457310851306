import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'semantic-ui-react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../../../../../contexts/AppContext';

import DashboardContext from '../../../../../../contexts/DashboardContext';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';

import WtrSlider from '../../../../../../shared/WtrSlider/WtrSlider';
import usePlayNotificationSound from '../../../../../../utils/hooks/usePlayNotificationSound';

import tradingAccountStore from '../../../../../../store/tradingAccountStore';

import styles from './SettingsModule.module.scss';
import WtrToggle from '../../../../../../shared/WtrToggle/WtrToggle';
import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';
import { Resolver } from '../../../../../../utils/functions/Ioc';
import RfpGateway from '../../../../../../gateways/RfpGateway/RfpGateway';
import { useFillContextsFromPreferences } from '../../../../../../utils/hooks/preferences/useFillContextsFromPreferences';
import { QUANTITY_TYPE, QuantityType } from '../../../../../../utils/functions/enums';
import Button from '../../../../../../shared/Button';

interface tradingSettingsProps {
	setSettings: (settings: any) => void;
	handleCloseModal: (settings: any) => void;
	setShowInfoModalBackground: (settings: any) => void;
	showInfoModalBackground: boolean;
	settings: any;
}

const TradingSettings: React.FC<tradingSettingsProps> = ({
	settings,
	setSettings,
	handleCloseModal,
	setShowInfoModalBackground,
	showInfoModalBackground,
}) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const playSound = usePlayNotificationSound();
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;
	const isTradersGymActive = tradersGymContext.isActive;
	const isArabic = appContext.isArabic;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isOneClickTradingActive = tradingAccountStore.use.isOneClickTradingActive();

	const [volumeScale, setVolumeScale] = useState<number>(
		settings.notificationSoundVolume || dashboardContext.notificationSoundVolume
	);
	const [soundMuted, setSoundMuted] = useState<boolean>(
		settings.notificationSoundMuted === undefined
			? dashboardContext.notificationSoundMuted
			: settings.notificationSoundMuted
	);
	const oneClickTrading = dashboardContext.oneClickTrading;
	const divRefArrow = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (divRefArrow.current && !divRefArrow.current.contains(event.target as Node)) {
				setShowInfoModalBackground(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const { mutate: savePreferences } = useSaveUserPreferences();
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const accountType = dashboardContext.accountType;
	const qunatityTypeButton = appContext.userPreferences!.user_prefs.trading.settings[accountType]!._quantityType;

	const handleQuantityTypeChange = (quantityType: string) => {
		const RFPConnection = Resolver.resolve(RfpGateway);
		const tradingAccount = dashboardContext.tradingAccount;
		let updatedQuantityType = 'Amount';

		if (appContext.isJapanAccount) {
			updatedQuantityType = 'Amount';
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._quantityType = 'Amount';
		} else if (quantityType === 'Amount') {
			dashboardContext.quantityType = 'Amount';
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._quantityType = 'Amount';
			updatedQuantityType = 'Amount';
			dashboardContext.quantityType = 'Amount';
		} else if (quantityType === 'Lots') {
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._quantityType = 'Lots';
			updatedQuantityType = 'Lots';
			dashboardContext.quantityType = 'Lots';
		} else {
		}

		if (tradingAccount.length > 0) {
			let updatedQuantityType: number;
			if (appContext.isJapanAccount || quantityType === 'Amount') {
				updatedQuantityType = QuantityType.Amount;
			} else {
				updatedQuantityType = QuantityType.Lots;
			}
			tradingAccount.forEach((account) => {
				setTimeout(() => {
					// Skip gym accounts
					if (account.accountType === 'GYM') {
						return;
					}

					RFPConnection.updateQuantityType(account.id, updatedQuantityType);
				}, 1000);
			});
		}
		savePreferences();
		fillContextsFromPreferences(appContext.userPreferences!);
	};

	const handleOneClickTradingChange = () => {
		const updatedSettings = {
			...settings,
			oneClickTrading: !settings.oneClickTrading,
		};

		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._mode = updatedSettings.oneClickTrading
			? '1-Click'
			: 'Regular';

		savePreferences();

		fillContextsFromPreferences(appContext.userPreferences!);

		setSettings(updatedSettings);
		setShowInfoModalBackground(false);
	};
	const handleTradeFromChart = () => {
		const newStateTradeFromChart = !settings.tradeFromChartEnabled;
		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._tradeFromChartEnabled =
			newStateTradeFromChart;

		const updatedSettings = {
			...settings,
			tradeFromChartEnabled: newStateTradeFromChart,
		};

		dashboardContext.tradeFromChartEnabled = newStateTradeFromChart;

		savePreferences();

		fillContextsFromPreferences(appContext.userPreferences!);

		setSettings(updatedSettings);
	};

	const handleSliderAfterChange = (value: number) => {
		const isMuted = value > 0 ? false : true;
		setSoundMuted(isMuted);
		setVolumeScale(value);
		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundVolume = value;
		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundMuted = isMuted;
		setSettings({ ...settings, notificationSoundVolume: value, notificationSoundMuted: isMuted });
		playSound(isMuted, value);

		savePreferences();
		fillContextsFromPreferences(appContext.userPreferences!);
	};

	const handleSliderChange = (value: number) => {
		const isMuted = value > 0 ? false : true;
		setSoundMuted(isMuted);
		setVolumeScale(value);
		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundVolume = value;
		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundMuted = isMuted;

		savePreferences();
		fillContextsFromPreferences(appContext.userPreferences!);
	};

	const handleChangeMute = () => {
		const newMuted = !soundMuted;
		setSoundMuted(newMuted);
		appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundMuted = newMuted;

		savePreferences();
		fillContextsFromPreferences(appContext.userPreferences!);

		playSound(newMuted, volumeScale);
	};

	const showConfirmModal = () => {
		if (settings.oneClickTrading) {
			handleOneClickTradingChange();
		} else {
			setShowInfoModalBackground(true);
		}
	};

	return (
		<>
			<div className={styles.platformTab}>
				{showInfoModalBackground && (
					<>
						<div ref={divRefArrow} className={styles.infoModalWrapper}>
							<h1>{t('en:ONE_CLICK_TRADING_CONFIG_ACTIVE')}</h1>
							<div className={styles.buttonsContainer}>
								<Button
									size="lg"
									label={t('en:NOT_NOW')}
									className={styles.button}
									onClick={() => {
										setShowInfoModalBackground(false);
									}}
									variant="outline"
									isFullWidth={true}
								/>
								<Button
									size="lg"
									label={t('en:ACTIVATE')}
									className={styles.button}
									onClick={handleOneClickTradingChange}
									variant="primary"
									isFullWidth={true}
								/>
							</div>
						</div>
						<div className={styles.infoModalWrapperBackground}></div>
					</>
				)}
				<div className={styles.closeIcon} onClick={handleCloseModal}>
					<FontAwesomeIcon icon={['fal', 'xmark-large']} className={styles.icon} />
				</div>
				<div className={styles.profileSetingsHeader}>
					<div className={styles.platformTabName}>{t('en:TRADING')}</div>
				</div>
				<div className={styles.profileSetingsContent}>
					{!appContext.isJapanAccount && (
						<div className={styles.settingsPairs}>
							<div className={styles.settingsPairsName}>{t('en:QUANTITY_TYPE')}</div>

							<div className={styles.settingsQuantityButtons}>
								<div
									className={cn(
										styles.quantityButton,
										qunatityTypeButton === 'Amount' ? styles.quantityButtonSelected : ''
									)}
									onClick={() => handleQuantityTypeChange('Amount')}
								>
									<div className={styles.outerCircle}>
										<div className={styles.innerCircle}></div>
									</div>
									<div className={styles.label}>{t('en:QT_AMOUNT')}</div>
								</div>
								<div
									onClick={() => handleQuantityTypeChange('Lots')}
									className={cn(
										styles.quantityButton,
										qunatityTypeButton === 'Lots' ? styles.quantityButtonSelected : ''
									)}
								>
									<div className={styles.outerCircle}>
										<div className={styles.innerCircle}></div>
									</div>
									<div className={styles.label}>{t('en:QT_LOTS')}</div>
								</div>
							</div>
						</div>
					)}

					<div className={styles.settingsPairs}>
						<div className={styles.settingsPairsName}>{t('wtr:ONE_CLICK_TRADING')}</div>
						<div className={styles.settingsPairsPhone}>
							<div className={styles.checkContainer} onClick={showConfirmModal}>
								<div className={cn(settings.oneClickTrading ? styles.toggleButtonChecked : styles.toggleButton)}>
									<Radio toggle checked={settings.oneClickTrading} />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.settingsPairs}>
						<div className={styles.settingsPairsName}>{t('wtr:CHARTIQ_CHART_TRADE')} </div>
						<div className={styles.settingsPairsPhone}>
							<div className={styles.checkContainer} onClick={handleTradeFromChart}>
								<div className={cn(settings.tradeFromChartEnabled ? styles.toggleButtonChecked : styles.toggleButton)}>
									<Radio toggle checked={settings.tradeFromChartEnabled} />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.settingsPairs}>
						<div className={styles.settingsPairsName}>{t('wtr:NOTIFICATION_SOUND_VOLUME')} </div>
						<div className={styles.settingsPairsPhone}>
							<div className={styles.rangeContainer}>
								<div className={styles.iconContainer} onClick={() => handleChangeMute()}>
									<FontAwesomeIcon icon={['fas', soundMuted === true ? 'volume-slash' : 'volume']} />
								</div>

								<div className={styles.slider}>
									<WtrSlider
										reverse={isArabic}
										max={100}
										value={soundMuted === true ? 0 : volumeScale}
										onChange={handleSliderChange}
										onAfterChange={handleSliderAfterChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TradingSettings;
