import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface CalendarParams {
	usi: string;
	lang: string;
}

const useCalendarToken = (parameters: CalendarParams) => {
	const queryKey = ['calendarToken', parameters];
	const queryFn = async () => {
		try {
			const response = await axios({
				url: '',
				data: {
					payload: [
						{
							module: 'utility',
							action: 'createHandshakeUrl',
							parameters: { page: 'economic_calendar', ...parameters },
						},
					],
				},
			});
			return response.data.payload[0].result;
		} catch (e) {
			return e
		}
	};

	return useQuery({ queryKey, queryFn });
};

export default useCalendarToken;
