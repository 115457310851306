import {
	HistoryTick,
	MarketItem,
	PriceQuote,
	RFPDataObjectType,
	TradingPosition,
	TradingPositionState,
} from '../../../gateways/RfpGateway/rfp.types';
import { isMarketItemType, MarketItemType } from '../../../utils/functions/calculations';
import { TradingPositionSide, TradingPositionType } from '../../../utils/functions/enums';
import { GymTradingAccount } from '../Accounts/GymTradingAccount';
import { GymRefQuotesStore } from '../HistoryData/GymRefQuotesStore';

import { GymPositionUtils } from './GymPositionUtils';

export class GymTradingPosition implements TradingPosition {
	aId: number;
	state: TradingPositionState; // Default state is pending
	side: TradingPositionSide;
	code: string;
	f: string;
	type?: TradingPositionType | undefined;
	oP: number = NaN;
	cP: number = NaN;
	prc: number = NaN;
	comm?: number | undefined;
	swap?: number | undefined;
	qty: number = 0;
	oT?: number | undefined;
	cT?: number | undefined;
	eT?: number | undefined;
	sl: number = NaN;
	tp: number = NaN;
	posId: number | undefined;
	qCcy?: string | undefined;
	dec: number = -1;
	openAskPrice?: number;
	openBidPrice?: number;
	closeAskPrice?: number;
	closeBidPrice?: number;
	private _grossProfit: number = NaN;
	public get grossProfit(): number {
		return this._grossProfit;
	}
	public set grossProfit(value: number) {
		if (!isNaN(value)) {
			this._grossProfit = value;
			this.netProfit = value;
		}
	}
	dividend?: number | undefined;
	marketItem?: MarketItem | undefined;
	currentPrice?: number | undefined;
	pips?: number | undefined;
	tpInPips: number = NaN;
	slInPips: number = NaN;
	additionalSubscriptionPair: Set<string> = new Set<string>();
	netProfit: number = NaN;
	comment?: string | undefined;
	dataObjectType = RFPDataObjectType.TradingPosition;
	baseCurrency?: string | undefined;
	cancelRequestSent?: boolean | undefined;
	trailingStop?: number = NaN;
	/*
	 * Position will belong to specific simulation
	 */
	private _simulationId: string;
	public get simulationId(): string {
		return this._simulationId;
	}
	public set simulationId(value: string) {
		this._simulationId = value;
	}

	private _lastDataClosePrice: number = 0;
	public get lastDataClosePrice(): number {
		return this._lastDataClosePrice;
	}
	public set lastDataClosePrice(value: number) {
		this._lastDataClosePrice = value;
		this.currentPrice = this._lastDataClosePrice;
	}
	private _lastDataCloseTime: number = 0;
	public get lastDataCloseTime(): number {
		return this._lastDataCloseTime;
	}
	public set lastDataCloseTime(value: number) {
		this._lastDataCloseTime = value;
	}

	deepCopy() {
		const copyPos = new GymTradingPosition(
			this.aId,
			this.f,
			this.code,
			this.state,
			this.side,
			this.type ?? TradingPositionType.Market,
			this._simulationId
		);
		Object.assign(copyPos, this);

		return copyPos;
	}

	constructor(
		accountId: number,
		feedId: string,
		code: string,
		state: TradingPositionState,
		side: TradingPositionSide,
		type: TradingPositionType,
		simulationId: string
	) {
		this.aId = accountId;
		this.f = feedId;
		this.code = code;
		this.state = state;
		this.side = side;
		this.type = type;
		this._simulationId = simulationId;
	}

	updatePosition(account: GymTradingAccount, dataItem: HistoryTick, priceQuote: PriceQuote): boolean {
		const accountCurrency = account.baseCurrency;
		this.calcProfit(account, priceQuote, accountCurrency);

		if (this.lastDataCloseTime >= priceQuote.t) {
			return false;
		}

		this.lastDataClosePrice = this.getCurrentPrice(priceQuote);
		this.lastDataCloseTime = priceQuote.t;

		switch (this.state) {
			case TradingPositionState.open: {
				return this.updateActivePosition(dataItem, accountCurrency);
			}
			case TradingPositionState.pending: {
				return this.updatePendingPosition(account, dataItem, priceQuote, accountCurrency);
			}
			default:
				break;
		}
		return false;
	}

	updateActivePosition(dataItem: HistoryTick, accountCurrency: string): boolean {
		let res = false;
		switch (this.side) {
			case TradingPositionSide.Buy: {
				if (this.hasStopLoss() && dataItem.low <= this.sl) {
					this.cP = this.sl;
					this.cT = dataItem.closeTime;
					this.comment = 'Position was close by triggered stop loss';
					this.setPositionInClosedState(accountCurrency);
					res = true;
				}

				if (this.hasTakeProfit() && dataItem.high >= this.tp) {
					this.cP = this.tp;
					this.cT = dataItem.closeTime;
					this.comment = 'Position was close by triggered take profit';
					this.setPositionInClosedState(accountCurrency);
					res = true;
				}
				break;
			}
			case TradingPositionSide.Sell: {
				if (this.hasStopLoss() && dataItem.high >= this.sl) {
					this.cP = this.sl;
					this.cT = dataItem.closeTime;
					this.comment = 'Position was close by triggered stop loss';
					this.setPositionInClosedState(accountCurrency);
					res = true;
				}

				if (this.hasTakeProfit() && dataItem.low <= this.tp) {
					this.cP = this.tp;
					this.cT = dataItem.closeTime;
					this.comment = 'Position was close by triggered take profit';
					this.setPositionInClosedState(accountCurrency);
					res = true;
				}

				break;
			}
		}

		if (res) {
			GymTradingPosition.storePosition(this);
		}
		return res;
	}

	updatePendingPosition(
		account: GymTradingAccount,
		dataItem: HistoryTick,
		priceQuote: PriceQuote,
		accountCurrency: string
	): boolean {
		let shouldSetInOpenState = false;

		switch (this.side) {
			case TradingPositionSide.Buy: {
				switch (this.type) {
					case TradingPositionType.Limit: {
						if (dataItem.low <= this.prc) {
							shouldSetInOpenState = true;
						}
						break;
					}
					case TradingPositionType.Stop: {
						if (dataItem.high >= this.prc) {
							shouldSetInOpenState = true;
						}
						break;
					}
					default:
						break;
				}

				break;
			}
			case TradingPositionSide.Sell: {
				switch (this.type) {
					case TradingPositionType.Limit: {
						if (dataItem.high >= this.prc) {
							shouldSetInOpenState = true;
						}
						break;
					}
					case TradingPositionType.Stop: {
						if (dataItem.low <= this.prc) {
							shouldSetInOpenState = true;
						}
						break;
					}
					default:
						break;
				}

				break;
			}
		}

		if (shouldSetInOpenState) {
			this.oT = dataItem.closeTime;
			this.oP = this.prc;
			this.state = TradingPositionState.open;
			this.type = TradingPositionType.Market;

			if (this.posId && !GymPositionUtils.validateMargin(account, this)) {
				GymTradingPosition.removePositionStore(this.simulationId, this.posId);
				this.state = TradingPositionState.deleted;

				throw new Error('Failed to open pending position. Insufficient Available Margin!');
			}

			this.calcProfit(account, priceQuote, accountCurrency);
		}

		GymTradingPosition.storePosition(this);

		return shouldSetInOpenState;
	}

	calcUsedMargin(account: GymTradingAccount): number {
		if (this.aId !== account.id) {
			throw Error('Trying to calcUsedMargin with wrong account data');
		}

		if (this.state !== TradingPositionState.open) {
			return 0;
		}

		let usedMargin = 0.0;
		if (!isNaN(this.oP)) {
			if (this.marketItem) {
				const quoteCurrency = this.qCcy ?? '';
				let effectivePrice = GymRefQuotesStore.sharedInstance().getPrice(
					quoteCurrency,
					account.baseCurrency,
					this.lastDataCloseTime,
					this.simulationId
				);
				if (isNaN(effectivePrice) || effectivePrice === 0) {
					effectivePrice = 1;
				}

				if (!isMarketItemType(this.marketItem, MarketItemType.FOREX)) {
					effectivePrice *= this.oP;
				}

				usedMargin = account.leverage * effectivePrice * this.qty;
			}
		}

		return usedMargin;
	}

	multiplayer() {
		return this.side === TradingPositionSide.Buy ? 1 : -1;
	}

	hasStopLoss() {
		return !isNaN(this.sl) && this.sl > 0;
	}

	hasTakeProfit() {
		return !isNaN(this.tp) && this.tp > 0;
	}

	setPositionInClosedState(accountCurrency: string) {
		this.state = TradingPositionState.closed;

		this.calcProfitOnClosedPositionWithAccountCurrency(accountCurrency);
	}

	getCurrentPrice(priceQuote: PriceQuote) {
		return this.side === TradingPositionSide.Buy ? priceQuote.b : priceQuote.a;
	}

	calcInPips(priceQuote: PriceQuote) {
		if (this.dec !== -1) {
			const currentPrice = this.getCurrentPrice(priceQuote);
			if (currentPrice !== 0 && !isNaN(currentPrice)) {
				if (this.tp !== 0.0 && !isNaN(this.tp)) {
					this.tpInPips = Math.abs(currentPrice - this.tp) * this.multiplayer();
				}

				if (this.sl !== 0.0 && !isNaN(this.sl)) {
					this.slInPips = Math.abs(currentPrice - this.sl) * this.multiplayer();
				}

				if (this.state === TradingPositionState.pending) {
					if (this.oP !== 0.0 && !isNaN(this.oP)) {
						this.pips = Math.abs(this.oP - currentPrice) * this.multiplayer();
					}
				}
			}
		}
	}

	calcProfitOnClosedPositionWithAccountCurrency(accountCurrency: string) {
		const closePrice = this.cP;
		const openPrice = this.oP;
		const digits = this.dec;
		const amount = this.qty;
		const quoteCurrency = this.qCcy;
		if (quoteCurrency && closePrice) {
			switch (this.state) {
				case TradingPositionState.canceled: {
					this.pips = NaN;
					if (digits !== -1) {
						this.pips =
							(closePrice - openPrice) * Math.pow(10, digits !== 0 ? digits - 1 : digits) * this.multiplayer();
					}

					if (!this.baseCurrency) {
						if (!this.marketItem) {
							this.grossProfit = 0;
							return;
						}

						this.baseCurrency = this.marketItem.bCcy;
					}

					const plPositionCurrency = (closePrice - openPrice) * this.multiplayer() * amount;

					if (!(this.qCcy === accountCurrency)) {
						const effectivePrice = GymRefQuotesStore.sharedInstance().getPrice(
							quoteCurrency,
							accountCurrency,
							this.lastDataCloseTime,
							this.simulationId
						);

						if (isNaN(effectivePrice)) {
							this.grossProfit = 0;
						} else {
							this.grossProfit = plPositionCurrency * effectivePrice;
						}
					} else {
						this.grossProfit = plPositionCurrency;
					}

					break;
				}
				default:
					break;
			}
		}
	}

	calcProfit(account: GymTradingAccount, priceQuote: PriceQuote, accountCurrency: string) {
		switch (this.state) {
			case TradingPositionState.closed: {
				if (isNaN(this.grossProfit)) {
					this.calcProfitOnClosedPositionWithAccountCurrency(accountCurrency);
				}
				break;
			}
			case TradingPositionState.open: {
				this.calcInPips(priceQuote);

				if (isNaN(this.oP) || this.oP === 0) {
					return;
				}

				const currentPrice = this.getCurrentPrice(priceQuote);

				this.pips = NaN;
				const digits = this.dec;
				if (digits !== -1) {
					this.pips = (currentPrice - this.oP) * Math.pow(10, digits !== 0 ? digits - 1 : digits) * this.multiplayer();
				}

				if (!this.baseCurrency) {
					if (!this.marketItem) {
						this.grossProfit = 0;
						return;
					}

					this.baseCurrency = this.marketItem.bCcy;
				}

				const amount = this.qty;
				const plPositionCurrency = (currentPrice - this.oP) * this.multiplayer() * amount;

				const basePositionCurrency = this.qCcy;
				if (this.marketItem && basePositionCurrency && basePositionCurrency !== accountCurrency) {
					this.grossProfit = GymRefQuotesStore.sharedInstance().convertToBaseCurrency(
						account,
						basePositionCurrency,
						this.marketItem.feedId,
						this.marketItem.code,
						this.side === TradingPositionSide.Buy,
						plPositionCurrency,
						currentPrice,
						this.lastDataCloseTime,
						this.simulationId
					);
				} else {
					this.grossProfit = plPositionCurrency;
				}

				break;
			}
			case TradingPositionState.pending: {
				this.calcInPips(priceQuote);
				break;
			}
			case TradingPositionState.deleted: {
				break;
			}
		}
	}

	private static buildKey(simulationId: string, posId: number | undefined) {
		return `${simulationId}_${posId}`;
	}

	private static ignoreProps(this: any, key: any, value: any) {
		if (['marketItem'].indexOf(key) > -1) return undefined;
		else return value;
	}

	static storePosition(position: GymTradingPosition) {
		if (localStorage) {
			localStorage.setItem(
				GymTradingPosition.buildKey(position.simulationId, position.posId),
				JSON.stringify(position, GymTradingPosition.ignoreProps)
			);
		}
	}

	static restoreTradingPosition(simulationId: string, positionId: number): GymTradingPosition | undefined {
		if (localStorage) {
			const positionData = localStorage.getItem(GymTradingPosition.buildKey(simulationId, positionId));
			if (positionData) {
				const position = JSON.parse(positionData);
				if (position) {
					const copyPos = new GymTradingPosition(
						position.aId,
						position.f,
						position.code,
						position.state,
						position.side,
						position.type ?? TradingPositionType.Market,
						position._simulationId
					);
					Object.assign(copyPos, position);

					if (position.additionalSubscriptionPair.length > 0) {
						copyPos.additionalSubscriptionPair = new Set<string>(...position.additionalSubscriptionPair);
					} else {
						copyPos.additionalSubscriptionPair = new Set<string>();
					}
					// TODO: GYM set MarketItem prop
					return copyPos;
				}
			}
		}
		return undefined;
	}

	static removePositionStore(simulationId: string, posId: number) {
		if (localStorage) {
			localStorage.removeItem(GymTradingPosition.buildKey(simulationId, posId));
		}
	}
}
