export class OrderedSet<T> extends Array<T> {
	private equalsFn: (a: T, b: T) => boolean;

	/**
	 * @param values: values to insert after set creation
	 */
	constructor(values?: Array<T>) {
		super();

		this.equalsFn = (a: T, b: T) => a === b;

		if (values) {
			this.addAll(values);
		}
	}

	add(value: T): OrderedSet<T> {
		if (!value) {
			return this;
		}

		this.push(value);
		return this;
	}

	push(value: T): number {
		if (!value) {
			return this.size();
		}

		if (this.has(value) === false) {
			return super.push(value);
		} else {
			return this.size();
		}
	}

	has(value: T): boolean {
		if (!value) {
			return false;
		}

		let found = false;

		super.forEach((item) => {
			if (this.equalsFn(value, item) === true) {
				found = true;
			}
		});

		return found;
	}

	hasAll(values: Array<T>): boolean {
		if (!values || values.length === 0) {
			return false;
		}

		return values.every((item) => this.has(item));
	}

	addAll(values: Array<T>): void {
		if (!values || values.length === 0) {
			return;
		}

		values.forEach((item) => this.push(item));
	}

	size(): number {
		return this.length;
	}

	remove(value: T): boolean {
		if (!value) {
			return false;
		}

		// Find item in the list
		let foundIdx = -1;
		super.forEach((item, index) => {
			if (this.equalsFn(value, item) === true) {
				foundIdx = index;
			}
		});

		// If item has been found => remove it from the list
		if (foundIdx !== -1) {
			super.splice(foundIdx, 1);
			return true;
		} else {
			return false;
		}
	}

	removeAll(values: Array<T>): boolean {
		if (!values || values.length === 0) {
			return false;
		}
		return values.every((item) => this.remove(item));
	}

	clear(): void {
		super.splice(0, this.size());
	}

	toArray(): Array<T> {
		let result: Array<T> = [];
		super.forEach((item) => result.push(item));
		return result;
	}

	equals(other: OrderedSet<T>): boolean {
		if (!other) {
			return false;
		}
		return other.size() === this.size() && this.every((item) => other.has(item));
	}
}
