import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from './EmptySearch.module.scss';

type TEmptySearchProps = {
	invalidSearchTerm: string;
};

const EmptySearch = React.memo(
	({ invalidSearchTerm }: TEmptySearchProps) => {
		const { t } = useTranslation();

		return (
			<div className={styles.emptyStateContainer}>
				<div>
					<FontAwesomeIcon icon={['fas', 'search']} className={styles.emptyIcon}></FontAwesomeIcon>
				</div>
				<div className={styles.noMatch}>
					{t('wtr:NO_MATCHES')} <span className={styles.searchTerm}>{`'${invalidSearchTerm}'`}</span>
				</div>
				<span className={styles.tickerMessage}>{t('wtr:MODIFY_SEARCH')}</span>
			</div>
		);
	},
	(prevProps, nextProps) => {
		const keys: Array<keyof typeof prevProps> = ['invalidSearchTerm'];
		return keys.every((key) => prevProps[key] == nextProps[key]);
	}
);

export default EmptySearch;
