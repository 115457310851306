import React, { useContext } from 'react';

import AppContext from '../../../contexts/AppContext';
import { defineBaseCurrencySymbol } from '../../../utils/functions/calculations';
import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';
import { formatNumberWithCommas } from '../../features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

const MoneyFormatter = ({ money }: { money: number }) => {
	let value = parseFloat(money!.toString());

	const selectedTradingAccount = useSelectedTradingAccount();
	const { languageSettings: language, isArabic, isJapanAccount } = useContext(AppContext);

	let result = null;

	if (!isNaN(value) && selectedTradingAccount) {
		const symbol = defineBaseCurrencySymbol(selectedTradingAccount!);
		const decPrec = isJapanAccount ? 0 : 2;
		// all number related to Japan account must be rounding with floor function
		if (isJapanAccount) {
			value = Math.floor(value);
		}

		if (isArabic) {
			result =
				value < 0
					? `${formatNumberWithCommas(Math.abs(value), decPrec, language)} ${symbol} -`
					: `${formatNumberWithCommas(value, decPrec, language)} ${symbol}`;
		} else {
			result =
				value < 0
					? `- ${symbol} ${formatNumberWithCommas(Math.abs(value), decPrec, language)}`
					: `${symbol} ${formatNumberWithCommas(value, decPrec, language)}`;
		}
	}

	return <>{result}</>;
};

export default MoneyFormatter;
