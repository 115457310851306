import React, { useState } from 'react';

import Loading from '../../views/components/Loading/Loading';
import useFetchDataFromYoutube from '../../utils/hooks/useFetchDataFromYoutube';
import WtrModal from '../../shared/WtrModal';
import ServiceIssuesModal from '../TradersGym/Accounts/ServiceIssuesModal/ServiceIssuesModal';

import SingleTutorial from './SingleTutorial';

import styles from './Tutorials.module.scss';

const Tutorials = () => {
	const [videoId, setVideoId] = useState<string>('');
	let { data: items, isLoading: loading, isError: errorFromYoutube, refetch } = useFetchDataFromYoutube();

	const handleOpenVideo = (id: string) => {
		setVideoId(id);
	};
	const handleCloseVideo = () => {
		setVideoId('');
	};

	const setIsOpenServiceIssuesModal = () => {
		refetch();
	};

	return (
		<div className={styles.container}>
			{videoId && (
				<>
					<WtrModal open={!!videoId} onClose={handleCloseVideo} size="large">
						{/* this is the only way I found in order to get the video to autoplay and to be well positioned */}
						<div className="ui active embed" style={{ position: 'static' }}>
							<div className="embed">
								<iframe
									frameBorder="0"
									height="100%"
									width="100%"
									allow="autoplay"
									scrolling="no"
									src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
									title="Embedded content from youtube."
									// allow="autoplay"
								></iframe>
							</div>
						</div>
					</WtrModal>
				</>
			)}

			{loading && (
				<div className={styles.loadingTutorials}>
					<Loading />
				</div>
			)}

			{errorFromYoutube && <ServiceIssuesModal open={errorFromYoutube} setOpen={setIsOpenServiceIssuesModal} />}

			{items && (
				<div className={styles.list}>
					{items
						.filter((item) => item.title !== 'Private video')
						.map((video) => (
							<div className={styles.embedVideo} key={video.id}>
								<SingleTutorial
									key={video.id}
									id={video.snippet?.resourceId.videoId}
									url={video.snippet?.thumbnails?.maxres?.url}
									title={video.snippet?.title}
									openVideo={handleOpenVideo}
								/>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default Tutorials;
