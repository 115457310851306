import React from 'react';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import MoneyFormatter from '../../../../../components/MoneyFormatter';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';

interface CalcGrossPlProps {
	data: any;
	closed?: boolean;
}

const CalcGrossPl = ({ data, closed = false }: CalcGrossPlProps) => {
	const quotes = quoteStore.use.quotes();

	const record = data.row.original;

	if (closed) {
		return (
			<div className={cn(styles.alignRight, record.grossPL > 0 ? styles.greenCurrent : styles.redCurrent)}>
				<MoneyFormatter money={parseFloat(record.grossPL)} />
			</div>
		);
	}

	if (record.isGroupRow) {
		record.grossPL = record.subRows.reduce((totalPl: number, gridRecord: any) => {
			return (
				+totalPl +
				(isNaN((gridRecord.position?.grossProfit || Number.NaN) as number) ? 0 : +gridRecord?.position?.grossProfit!)
			);
		}, 0);
	} else {
		record.grossPL = (record.position?.grossProfit || Number.NaN).toFixed(2);
	}

	const grossPLValue = isNaN((record.grossPL || Number.NaN) as number) ? 0 : (record.grossPL as number);

	return (
		<div
			onClick={data.row.getToggleExpandedHandler()}
			className={cn(styles.alignRight, grossPLValue > 0 ? styles.greenCurrent : styles.redCurrent)}
		>
			<MoneyFormatter money={Number(grossPLValue)} />
		</div>
	);
};

export default CalcGrossPl;
