import { default as Lazy } from './Lazy';
export type DelayedPromise<T = void> = Promise<T> & { cancel: () => void };

export default class PromiseFactory {
	private static readonly _default = new Lazy<PromiseFactory>(() => new PromiseFactory());
	private readonly m_singletonPromises = new Map<string, Promise<any>>();
	private readonly m_thottledPromises = new Map<string, DelayedPromise<any>>();

	public static get default(): PromiseFactory {
		return this._default.getValue();
	}

	public promise<T = void>(
		executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void
	): Promise<T> {
		return new Promise(executor);
	}

	public singleton<T = void>(
		key: string,
		executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
		timeout?: number
	): Promise<T> {
		let promise = this.m_singletonPromises.get(key);
		if (promise == null) {
			promise = this.promise(executor);
			promise.finally(() => {
				this.m_singletonPromises.delete(key);
			});
			this.m_singletonPromises.set(key, promise);
		}
		return promise;
	}

	public delay(delay: number): DelayedPromise<void>;
	public delay<T = void>(
		delay: number,
		executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void
	): DelayedPromise<T>;
	public delay<T = void>(
		delay: number,
		executor?: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void
	): DelayedPromise<T | void> {
		let interval: any = null;
		const promise = new Promise<T | void>((resolve, reject) => {
			interval = setTimeout(() => {
				interval = null;
				executor != null ? executor(resolve, reject) : resolve();
			}, Math.round(Math.max(0, delay)));
		});
		return new Proxy(promise, {
			get: (target, prop, receiver) => {
				if (prop == 'cancel') {
					return () => {
						clearInterval(interval);
					};
				}
				const value = (target as any)[prop];
				return typeof value == 'function' ? value.bind(target) : value;
			},
		}) as DelayedPromise<T>;
	}

	public throttle<T = void>(key: string, delay: number): DelayedPromise<void>;
	public throttle<T = void>(
		key: string,
		delay: number,
		executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void
	): DelayedPromise<T>;
	public throttle<T = void>(
		key: string,
		delay: number,
		executor?: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void
	): DelayedPromise<T | void> {
		let promise = this.m_thottledPromises.get(key);
		if (promise != null) {
			promise.cancel();
		}
		promise = executor != null ? this.delay(delay, executor) : this.delay(delay);
		promise.finally(() => {
			this.m_thottledPromises.delete(key);
		});
		this.m_thottledPromises.set(key, promise);
		return promise;
	}
}
