import React, {useCallback, useContext, useState} from 'react';

import AppContext from '../../../contexts/AppContext';

import WtrPopup from '../WtrPopup/WtrPopup';

import CommunityList from './components/CommunityList/CommunityList';
import CommunityIcon from './components/CommunityIcon/CommunityIcon';

const Community = () => {
	const appContext = useContext(AppContext);
	const { isArabic } = appContext;

	const [open, setOpen] = useState<boolean>(false);

	const handleToggleOpen = useCallback((e?: React.PointerEvent) => {
		if (e) {
			e.stopPropagation();
		}
		setOpen(!open);
	}, [open]);

	return (
		<>
			<WtrPopup
				on={['click']}
				open={open}
				position={isArabic ? 'bottom right' : 'bottom left'}
				basic
				trigger={
					<div>
						<CommunityIcon open={open} onClick={handleToggleOpen} />
					</div>
				}
				content={<CommunityList handleToggleOpen={handleToggleOpen} />}
			/>
		</>
	);
};

export default Community;
