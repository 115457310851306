import React, { useState, useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import AppContext from '../../../contexts/AppContext';
import { tiers } from '../../../setup/subscriptionsConfig';
import Modal from '../../../shared/Modal';
import Button from '../../../shared/Button/Button';
import {
	getActiveTier,
	getTierDisplayName,
	SubscriptionStatus,
	SubscriptionTier,
	Tier,
} from '../../../utils/functions/subscriptionUtils';
import { SubscriptionModalReason } from '../../../utils/functions/subscriptionUtils';

import useSubscriptionModal from '../../../utils/hooks/useSubscriptionModal';
import Checkbox from '../../../shared/Checkbox/Checkbox';

import Carousel from '../Carousel/Carousel';

import useSubscriptionInfo from '../../../utils/hooks/useSubscriptionInfo';
import CurrencyDetails from '../CurrencyDetails/CurrencyDetails';

import authStore, { AuthStore } from '../../../store/authStore';

import useSubscribe from '../../../utils/subscriptions/useSubscribe';
import useChangeTier from '../../../utils/subscriptions/useChangeTier';
import { submitDynamicForm } from '../../../utils/functions/submitDynamicForm';

import styles from './SubscriptionSelector.module.scss';
import TierTile from './TierTile';

interface SubscriptionSelectorProps {
	isOpen: boolean;
	reason: SubscriptionModalReason;
	preselectedTier: SubscriptionTier;
	accountId: string;
	startFromTierNum?: number;
	onCancel: () => void;
}

type ModalView = 'selector' | 'confirm' | 'details';

const providerAcceptCharset = 'Shift-JIS';
/**
 * Japan Tier selection modal
 * @param isOpen If the modal is open
 * @param reason Subscription-related circumstance that makes modal necessary
 * @param preselectedTier Current subscription tier
 */
const SubscriptionSelector = ({
	isOpen,
	reason,
	preselectedTier,
	accountId,
	startFromTierNum,
	onCancel,
}: SubscriptionSelectorProps) => {
	const [modalView, setModalView] = useState<ModalView>('selector');
	const [hasUserAgreement, setHasUserAgreement] = useState(false);
	const [selectedTier, setSelectedTier] = useState<SubscriptionTier>(preselectedTier);
	const [isRequesting, setIsRequesting] = useState(false);
	const [subscriptionInfo] = useSubscriptionInfo();
	const { setModalStateProps } = useSubscriptionModal();
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);

	let disabledTierId: SubscriptionTier | null = null;
	if (reason === SubscriptionModalReason.ManageSubscription) {
		disabledTierId = preselectedTier;
	}
	const isConfirmDisabled = !selectedTier || selectedTier === disabledTierId;
	const selectedTierInfo = tiers.find((tier: Tier) => tier.id === selectedTier);

	const { mutate: subscribe } = useSubscribe(accountId);
	const { mutate: changeTier } = useChangeTier(accountId);

	const handleSelectTier = (tierId: SubscriptionTier): void => {
		setSelectedTier(tierId);
	};

	const handleCancel = () => {
		setModalView('selector');
		onCancel();

		if (
			![SubscriptionStatus.Active, SubscriptionStatus.Existing, SubscriptionStatus.Unpaid].includes(
				subscriptionInfo.status!
			)
		) {
			appContext.canFetchSubscrInfo = true;
		}
	};

	const handleSubscribe = () => {
		setModalView('confirm');
	};

	const handleMoreDetails = () => {
		setModalView('details');
	};

	const handleConfirmSubscribe = () => {
		if (!accountId || !selectedTier || selectedTier === disabledTierId) {
			console.debug('===>>> Unexpected failure to proceed with `subscribe/manage subscription!`');
			return;
		}

		if (
			[
				SubscriptionModalReason.SubscriptionForNewUser,
				SubscriptionModalReason.SubscribeFromAlert,
				SubscriptionModalReason.SubscriptionForExistingUser,
			].includes(reason)
		) {
			subscribe(selectedTier, {
				onSuccess: (data) => {
					if (!data.action?.type || !data.action?.url || !data.action?.fields) {
						appContext.subscriptionModal = {
							isOpen: true,
							reason: SubscriptionModalReason.IncorrectParamsForBJP,
							text: null,
						};
						return;
					}

					submitDynamicForm(
						data.action.type,
						data.action.url,
						data.action.fields,
						data.acceptCharset || providerAcceptCharset
					);
				},
			});
		}

		if (
			[
				SubscriptionModalReason.ManageSubscription,
				SubscriptionModalReason.UpgradeSubscription,
				SubscriptionModalReason.Instrument,
				SubscriptionModalReason.Volume,
				SubscriptionModalReason.OrderSizeUpgradable,
				SubscriptionModalReason.UpgradeToAccessGym,
			].includes(reason)
		) {
			const onSuccess = (result: string) => {
				console.debug('===>>> onSuccess callback for ManageSubscription/UpgradeSubscription called!`');

				setModalStateProps({
					reason: SubscriptionModalReason.ChangeTierResponse,
					isOpen: true,
					text: t(`wtr:SUBSCRIPTION_OP_RESPONSE_${result.toUpperCase()}`),
				});

				setIsRequesting(false);

				// appContext.canFetchSubscrInfo = false;
				appContext.canFetchSubscrInfo = true;
			};

			changeTier(selectedTier, { onSuccess });
		}

		setIsRequesting(true);
	};

	const handleClickAgreementBox = (e: React.ChangeEvent<HTMLInputElement>) => {
		setHasUserAgreement((e.target as HTMLInputElement).checked);
	};

	const isDowngrade = getActiveTier(subscriptionInfo) && selectedTier < subscriptionInfo.nextTier!;
	const isUpgrade = getActiveTier(subscriptionInfo) && selectedTier !== subscriptionInfo.nextTier && !isDowngrade;
	// const isFirstSubscription = !isDowngrade && !isUpgrade;

	if (modalView === 'selector') {
		let subscribeButtonText = t('wtr:MODIFY_TEXT');
		if (isDowngrade) {
			subscribeButtonText = t('wtr:DOWNGRADE');
		} else if (isUpgrade) {
			subscribeButtonText = t('wtr:UPGRADE');
		}

		const modalTitle =
			reason === SubscriptionModalReason.ManageSubscription
				? t('wtr:MANAGE_SUBSCRIPTIONS')
				: t('wtr:TIER_SELECTOR_MODAL_TITLE', { courseName: getTierDisplayName(selectedTier) });

		const CTA_TRANS_TEXTS: Partial<Record<SubscriptionModalReason, string>> = {
			[SubscriptionModalReason.UpgradeToAccessGym]: isLiveMode ? 'wtr:UPGRADE_TIER_TO_ACCESS_GYM_TEXT' : undefined,
		};

		return (
			<Modal show={isOpen} centered dialogClassName={styles.modalDialog}>
				<Modal.Header className={styles.modalHeader}>{modalTitle}</Modal.Header>
				{CTA_TRANS_TEXTS[reason] && (
					<p className={styles.ctaText}>
						<Trans i18nKey={CTA_TRANS_TEXTS[reason]} />
					</p>
				)}

				<div className={styles.tierInfoContainer}>
					<Carousel selectedTierInfo={selectedTierInfo} onMoreDetails={handleMoreDetails} />
				</div>

				<Modal.Body className={styles.modalBody}>
					<div className={styles.tilesContainer}>
						{tiers.map((tier, index) => {
							const tierNum = index + 1;
							if (startFromTierNum && tierNum < startFromTierNum) {
								return null;
							}

							const isSelected = tier.id === selectedTier;
							const isCurrentTier = tier.id === subscriptionInfo.tier;

							let titleText = null;
							if (isDowngrade) {
								if (isSelected && !isCurrentTier) {
									titleText = t('wtr:NEXT_MONTH');
								} else if (isCurrentTier) {
									titleText = t('wtr:CURRENT_TIER');
								}
							}

							return (
								<TierTile
									key={tier.id}
									tier={tier}
									onSelectTier={handleSelectTier}
									isSelected={isSelected}
									title={titleText}
									reason={reason}
									isNewUser={reason === SubscriptionModalReason.SubscriptionForNewUser}
								/>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button variant="secondary" size="lg" label={t('wtr:CANCEL_TEXT')} onClick={handleCancel} />
					<Button
						variant="primary"
						size="lg"
						label={subscribeButtonText}
						onClick={handleSubscribe}
						disabled={isConfirmDisabled}
					/>
				</Modal.Footer>
			</Modal>
		);
	}

	if (modalView === 'confirm') {
		const isNewSubscribe = [
			SubscriptionModalReason.SubscriptionForNewUser,
			SubscriptionModalReason.SubscribeFromAlert,
			SubscriptionModalReason.SubscriptionForExistingUser,
		].includes(reason);

		const isTierChange = [
			SubscriptionModalReason.ManageSubscription,
			SubscriptionModalReason.UpgradeSubscription,
			SubscriptionModalReason.Instrument,
			SubscriptionModalReason.Volume,
			SubscriptionModalReason.UpgradeToAccessGym,
		].includes(reason);

		let confirmButtonText;
		let modalText;
		let modalExplanation;

		if (isNewSubscribe) {
			confirmButtonText = t('wtr:CONFIRM_TEXT');

			modalText = t('wtr:CONFIRM_TIER_MODAL_TEXT', {
				currentTier: getTierDisplayName(selectedTier),
			});
		}
		if (isTierChange) {
			if (isDowngrade) {
				confirmButtonText = t('wtr:CONFIRM_DOWNGRADE_MODAL_BUTTON');
				modalText = t('wtr:CONFIRM_DOWNGRADE_MODAL_TEXT', {
					currentTier: getTierDisplayName(subscriptionInfo.nextTier),
					selectedTier: getTierDisplayName(selectedTier),
				});
				modalExplanation = t('wtr:CONFIRM_DOWNGRADE_MODAL_EXPLANATION');
			} else if (isUpgrade) {
				confirmButtonText = t('wtr:CONFIRM_UPGRADE_MODAL_BUTTON');
				modalText = t('wtr:CONFIRM_UPGRADE_MODAL_TEXT', {
					currentTier: getTierDisplayName(subscriptionInfo.nextTier),
					selectedTier: getTierDisplayName(selectedTier),
				});
				modalExplanation = t('wtr:CONFIRM_UPGRADE_MODAL_EXPLANATION');
			}
		}

		return (
			<Modal show={isOpen} centered dialogClassName={styles.modalDialog}>
				<Modal.Header className={styles.confirmModalHeader}>{t('wtr:CONFIRM_TIER_TERMS_TITLE')}</Modal.Header>
				<Modal.Body className={styles.confirmModalBody}>
					<div className={styles.confitmTextWrapper}>
						{modalText && <p className={styles.courseChangeTitle}>{modalText}</p>}
						{modalExplanation && <p>{modalExplanation}</p>}
					</div>

					<Checkbox
						isChecked={hasUserAgreement}
						onChange={handleClickAgreementBox}
						labelContents={t('wtr:CONFIRM_TIER_SUBSCRIPTION_TERMS')}
						isLabelContentsInteractive
					/>
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button variant="secondary" size="lg" label={t('wtr:CANCEL_TEXT')} onClick={handleCancel} />
					<Button
						variant="primary"
						size="lg"
						label={confirmButtonText}
						onClick={handleConfirmSubscribe}
						disabled={!hasUserAgreement || !selectedTier || isRequesting}
					/>
				</Modal.Footer>
			</Modal>
		);
	}

	// let result: Record<string, string[]> = {};
	// if (selectedTierInfo) {
	// 	selectedTierInfo.instruments.forEach((item: string) => {
	// 		let prefix = item.substring(0, item.indexOf('/'));
	// 		if (!result[prefix]) {
	// 			result[prefix] = [];
	// 		}

	// 		result[prefix].push(item);
	// 	});
	// }

	// const displayIntetestLimits = (openInterestLimitCurrency: any) => {
	// 	if (!openInterestLimitCurrency) return null;
	// 	return Object.keys(openInterestLimitCurrency).map((limit: any) => (
	// 		<div>
	// 			<span className={styles.groupName}>{limit}: </span> <span>{openInterestLimitCurrency[limit].join('、')}</span>
	// 		</div>
	// 	));
	// };

	// const displayInstruments = Object.keys(result).map((key) => {
	// 	const openInterestLimitCurrency = tiers[2].openInterestLimit[key.toLowerCase()];

	// 	return (
	// 		<div className={styles.instrumentGroupContainer}>
	// 			<div>
	// 				<span className={styles.groupName}>{key}</span> - <span>{[...result[key]].join('、')}</span>
	// 			</div>
	// 			{selectedTierInfo && selectedTierInfo.id === 'Tier3' && (
	// 				<>
	// 					{openInterestLimitCurrency && <span>{t('OPEN_INTEREST_LIMIT')}</span>}
	// 					{displayIntetestLimits(openInterestLimitCurrency)}
	// 				</>
	// 			)}
	// 		</div>
	// 	);
	// });

	// if (modalView === 'details') {
	// 	return (
	// 		<Modal show={isOpen} centered dialogClassName={styles.modalDialog}>
	// 			<Modal.Header className={styles.detailsModalHeader}>
	// 				<div className={styles.wrapper}>
	// 					<div>
	// 						<FontAwesomeIcon
	// 							className={styles.backButton}
	// 							icon={['fas', 'arrow-left']}
	// 							onClick={() => setModalView('selector')}
	// 						/>
	// 					</div>
	// 					<div className={styles.title}>コース3 取引可能な通貨ペア</div>
	// 				</div>
	// 			</Modal.Header>
	// 			<Modal.Body className={cn(styles.detailsModalBody, styles.scroll)}>{displayInstruments}</Modal.Body>
	// 		</Modal>
	// 	);
	// }

	if (modalView === 'details' && selectedTierInfo) {
		return (
			<CurrencyDetails
				isOpen={isOpen}
				selectedTierInfo={selectedTierInfo}
				tiers={tiers}
				onGoBack={() => setModalView('selector')}
			/>
		);
	}

	return null;
};

export default SubscriptionSelector;
