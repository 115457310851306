import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import { defaultSubscriptionInfo, SubscriptionInfo } from '../functions/subscriptionUtils';
import usePromiseFactory from "./usePromiseFactory";
import useForceRerender from "./useForceRerender";
import useObservable from '../../utils/hooks/useObservable';

type useSubscriptionInfoInterface = [SubscriptionInfo, (updateProps: Partial<SubscriptionInfo>) => void];

const useSubscriptionInfo = (): useSubscriptionInfoInterface => {
	const appContext = useContext(AppContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), async () => {
		await promiseFactory.throttle('appContext.propertyChanged', 100);
		forceRerender();
	});

	const setSubscrInfoProps = (updateProps: Partial<SubscriptionInfo>) => {
		appContext.subscriptionInfo = {
			...(appContext.subscriptionInfo || defaultSubscriptionInfo),
			...updateProps,
		};
	};

	return [appContext.subscriptionInfo || defaultSubscriptionInfo, setSubscrInfoProps];
};

export default useSubscriptionInfo;
