import moment from 'moment';
import {useContext} from 'react';

import DashboardContext, {DashboardContextProvider} from '../../contexts/DashboardContext';

import {addToastNotification} from '../functions/toastNotificationUtils';

import usePlayNotificationSound from './usePlayNotificationSound';

const formatDateExpNotifyBody = (body: string) => {
	const dateFormat = 'YYYY MMM DD HH:mm';
	const expDateStr = body.split(': ')[1];
	const formattedExpDateStr = expDateStr?.replace(
		expDateStr.split(' ', 3).join(' '),
		expDateStr.split(' ', 3).reverse().join(' ')
	);
	const expDate = moment(formattedExpDateStr);
	return body.split(':')[0] + ': ' + expDate.format(dateFormat);
};

const handleStateDispatch = (dashboardContext: DashboardContextProvider) => {
	dashboardContext.orderConfirm.new = false;
	dashboardContext.orderConfirm.edit = false;
	dashboardContext.orderConfirm.close = false;
	dashboardContext.toggleOverlay = -1;
};

export default (): ((event: any) => void) => {
	const dashboardContext = useContext(DashboardContext);
	const playSound = usePlayNotificationSound();

	return (event: any) => {
		let messageBody = event.message;
		if (event.messageType === 'EXPIRATION') {
			messageBody = formatDateExpNotifyBody(messageBody);
		}

		if (event.messageType === 'ERROR') {
			addToastNotification('danger', messageBody);
			handleStateDispatch(dashboardContext);
		} else {
			addToastNotification('success', messageBody);
		}

		if (!['ERROR', 'LIMIT_ORDER', 'PRICE_UPDATED', 'STOP_ORDER', 'TP', 'SL'].includes(event.messageType)) {
			playSound();
		}
	};
};
