import { useContext, useRef } from 'react';

import sound from '../../docs/sound.wav';

import DashboardContext from '../../contexts/DashboardContext';

import useObservable from './useObservable';
import useForceRerender from './useForceRerender';

const notificationSound: HTMLAudioElement = new Audio(sound);

const usePlayNotificationSound = () => {
	const forceRerender = useForceRerender();

	const context = useContext(DashboardContext);
	useObservable(context.getPropertyChangeStream('notificationSoundMuted', 'notificationSoundVolume'), forceRerender);

	return (isMuted?: boolean, soundVolume?: number) => {
		const { notificationSoundMuted, notificationSoundVolume } = context;
		notificationSound.currentTime = 0;
		notificationSound.volume = soundVolume !== undefined ? soundVolume * 0.01 : notificationSoundVolume * 0.01;
		notificationSound.muted = isMuted !== undefined ? isMuted : notificationSoundMuted;
		notificationSound.play();
	};
};

export default usePlayNotificationSound;
