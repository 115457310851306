import { useQuery, useQueryClient } from '@tanstack/react-query';

import axios from 'axios';

import authStore, { AuthStore } from '../../store/authStore';
import tradingAccountStore from '../../store/tradingAccountStore';

const useSignalsAccessRestriction = ({ enabled }: { enabled: boolean }) => {
	const queryKey = 'signalsUserRestriction';
	const queryClient = useQueryClient();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	const email = authStore((store: AuthStore) => store.userProfile.email);

	const checkIfSignalsAreAccessible = async () => {
		// WTR-4826 - Enable Traders Gym for Funded Trader accounts
		if (isFundedTrader) {
			return true;
		}

		if (!email) {
			return false;
		}

		const url = `${process.env.REACT_APP_SIGNALS_URL}/check-access/${email}`;

		try {
			const response = await axios(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data?.allowed;
		} catch (error) {
			console.error(error);
			return true;
		}
	};

	return useQuery([queryKey], checkIfSignalsAreAccessible, {
		enabled: enabled,
		initialData: () => {
			return queryClient.getQueryData([queryKey]);
		},
	});
};

export default useSignalsAccessRestriction;
