import moment from 'moment';
import { useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import axios from 'axios';

import AppContext from '../../contexts/AppContext';

import { SignalsListType } from '../../pages/Signals/Signals';
import productMap from '../../pages/Signals/productsMap.json';
import { SignalsLangMap, signalsLangMap } from '../../views/features/Dashboard/Settings/settingsOptions';
import authStore, { AuthStore } from '../../store/authStore';
import tradingAccountStore from '../../store/tradingAccountStore';
import RfpGatewayContext from '../../contexts/RfpGatewayContext';

interface IMappedData {
	symbol: string;
	decPrec: number;
	description: string;
	icon: string;
	slug?: string;
	sbSymbol?: string;
}

const useSignals = () => {
	const queryKey = ['signalsList'];
	const appContext = useContext(AppContext);
	const rfpGateway = useContext(RfpGatewayContext);
	const userProfile = authStore((store: AuthStore) => store.userProfile);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const fetchSignalsList = async () => {
		// Code that help expand productsMap
		// //@ts-ignore
		// const filteredSignals = {}
		//
		// const convertData = [add your objects]
		//
		// //@ts-ignore
		// instrumentContext.instruments.forEach(instrument => {
		// 	convertData.forEach(item => {
		// 		if (instrument.displayName === item['ThinkMarkets']) {
		// 			//@ts-ignore
		// 			filteredSignals[item['Signal Centre Product Name']] = {
		// 				"symbol": instrument.code,
		// 				"assetId": item["Acuity Asset ID"],
		// 				"decPrec": instrument.decPrec,
		// 				"description": instrument.fullName,
		// 				"icon": instrument.grp
		// 			}
		// 		}
		// 	})
		// })
		//
		// //@ts-ignore
		// console.log(filteredSignals)

		const productsMap = Object.values(productMap);

		const filteredProductMap = productsMap.reduce((acc: any[], product: any) => {
			if (isSpreadBettingAccount && product?.sbSymbol) {
				acc.push(product.assetId);
			} else if (!isSpreadBettingAccount) {
				acc.push(product.assetId);
			}
			return acc;
		}, []);

		const { data: response } = await axios(
			`${process.env.REACT_APP_SIGNALS_URL}/signal-centre-reports/${userProfile.email}`,
			{
				method: 'POST',
				data: {
					StartDate: moment().subtract(3, 'day').startOf('day').format(),
					EndDate: moment().endOf('day').format(),
					LanguageCode: signalsLangMap[appContext.languageSettings as SignalsLangMap],
					AssetIds: filteredProductMap,
					IncludeExpired: false,
					Period: 'intraday',
					Count: 1000,
				},
			}
		);

		const data = response?.data
			?.map((signal: SignalsListType) => {
				const mapped: IMappedData = productMap[signal.Product.name as keyof typeof productMap];
				if (mapped) {
					signal.marketItem = rfpGateway?.getMarketItem(
						isSpreadBettingAccount ? (mapped.sbSymbol as string) : mapped.symbol
					);
					signal.Product.name = isSpreadBettingAccount ? (mapped.sbSymbol as string) : mapped.symbol;
					signal.Product.description = mapped.description;
					signal.Product.decPrec = mapped.decPrec;
					signal.Product.icon = mapped.icon;
					mapped.slug && (signal.Group.Category.slug = mapped.slug);
				}

				return signal;
			})
			.filter((signal: SignalsListType) => signal.marketItem)
			.filter((signal: SignalsListType) =>
				['Buy Limit', 'Sell Limit', 'Live Trade', 'Buy Stop', 'Sell Stop'].includes(signal.Report.status_text)
			)
			.sort((a: any, b: any) => {
				if (a.Report.confidence === b.Report.confidence) return 0;
				return a.Report.confidence > b.Report.confidence ? -1 : 1;
			});

		return data;
	};

	const getNextExpiry = (data: SignalsListType[]): moment.Moment | null => {
		let closestExpiry: moment.Moment | null = null;

		data.forEach((signal) => {
			const expiry = moment(signal.Report.expiry);
			if (!closestExpiry || expiry.isBefore(closestExpiry)) {
				closestExpiry = expiry;
			}
		});

		return closestExpiry;
	};

	const queryClient = useQueryClient();

	const closestExpiry = getNextExpiry(queryClient.getQueryData(queryKey) || []);
	const staleTime = closestExpiry ? closestExpiry.diff(moment()) : 0;

	return useQuery(queryKey, fetchSignalsList, {
		cacheTime: 0,
		staleTime,
		enabled: false,
	});
};

export default useSignals;
