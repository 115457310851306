import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';

import tradingAccountStore from '../../../../../store/tradingAccountStore';

import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import {
  instrumentExistsInDynamicWatchlists,
  maxInstrumentsPerWatchlist
} from '../../../../../utils/functions/WatchlistUtils';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';

import styles from './NewWatchlistsList.module.scss';

interface WatchlistsListProps {
  instrumentExistsInWatchlist: boolean;
  marketItemCode: string;
}

const NewWatchlistsList: FC<WatchlistsListProps> = ({
  instrumentExistsInWatchlist,
  marketItemCode
}) => {
  const dashboardContext = useContext(DashboardContext);
  const getTranslatedWLName = useGetTranslatedWLName();
  const { t } = useTranslation();

  const selectedTradingAccountWatchlist = dashboardContext.watchlist;
  const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
  const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
  const mappedWatchlist = dashboardContext.mappedWatchlist;

  const handleAddToWatchlist = (maxReached: boolean, watchlistName: string) => {
    if (maxReached) return;

    const updatePreferenceObject = selectedTradingAccountWatchlist.find(
      (watchlist) => watchlist._name === watchlistName
    );

    if (updatePreferenceObject) {
      updatePreferenceObject.instrument.push({ _code: marketItemCode });
      dashboardContext.mappedWatchlist = {
        ...dashboardContext.mappedWatchlist,
        [watchlistName]: updatePreferenceObject.instrument
      };
    }

    saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
  };

  const handleRemoveFromWatchlist = (watchlistName: string) => {
    if (dashboardContext.mappedWatchlist !== null) {
      const updatePreferenceObject = selectedTradingAccountWatchlist.find(
        (watchlist) => watchlist._name === watchlistName
      );

      if (updatePreferenceObject) {
        const removed =
          Object.values(dashboardContext.mappedWatchlist[watchlistName])
            .filter(
              (item) => item._code !== marketItemCode
            );

        dashboardContext.mappedWatchlist = {
          ...dashboardContext.mappedWatchlist,
          [watchlistName]: removed
        };

        updatePreferenceObject.instrument = removed;
      }
    }

    saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <div className={styles.titleWatchlist}>{t('wtr:SELECT_WATCHLIST')}</div>
      </div>
      <div className={styles.watchlistContainer}>
        {dashboardContext.mappedWatchlist !== null &&
          Object.getOwnPropertyNames(dashboardContext.mappedWatchlist)
            .map((watchlistName: string) => {
              if (
                instrumentExistsInDynamicWatchlists(
                  watchlistName,
                  dashboardContext.defaultWatchLists,
                  isSpreadBettingAccount
                )
              ) {
                return null;
              }

              const maxReached = maxInstrumentsPerWatchlist(dashboardContext.mappedWatchlist!, watchlistName);
              const exists = mappedWatchlist![watchlistName].map(({ _code }) => _code).includes(marketItemCode);

              return (
                <div
                  key={`${watchlistName}-${marketItemCode}`}
                  className={
                    maxReached &&
                    dashboardContext.selectedInstrument &&
                    dashboardContext.mappedWatchlist &&
                    instrumentExistsInWatchlist
                      ? styles.maxItemContainer
                      : styles.itemContainer
                  }
                  onClick={() => {
                    if (exists) {
                      handleRemoveFromWatchlist(watchlistName);
                    } else {
                      handleAddToWatchlist(maxReached, watchlistName);
                    }
                  }}
                >
                  <div className={styles.watchlistItem}>
                    <OverlayTrigger
                      delay={{ show: 750, hide: 0 }}
                      key={watchlistName}
                      placement="bottom-end"
                      overlay={<Tooltip id={watchlistName}>{getTranslatedWLName(watchlistName)}</Tooltip>}
                    >
                      <>
                        <span>{getTranslatedWLName(watchlistName)}</span>
                        {maxReached && <div className={styles.maxErrorMessage}>{t('wtr:MAX_INSTRUMENT_ERROR')}</div>}
                      </>
                    </OverlayTrigger>
                  </div>

                  <div>
                    {exists && (
                      <FontAwesomeIcon
                        icon={['fas', 'check-circle']}
                        className={styles.existsInWatchlistIcon}
                      />
                    )}
                    {!exists && (
                      <FontAwesomeIcon
                        icon={['far', 'plus-circle']}
                        className={styles.addToWatchlistIcon}
                      />
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default NewWatchlistsList;
