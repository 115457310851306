import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import Modal from '../../../shared/Modal';
import styles from './CurrencyDetails.module.scss';
import cn from 'classnames';
import { Tier } from '../../../utils/functions/subscriptionUtils';
import { useTranslation } from 'react-i18next';
import { generateFXMaxPositionTable, generateFXMaxPositionArray } from '../../../utils/functions/currencyPairsUtils';

import AppContext from '../../../contexts/AppContext';

interface ICurrencyDetails {
	isOpen: boolean;
	onGoBack: () => void;
	selectedTierInfo: Tier;
	tiers: Tier[];
	// children: React.ReactNode;
}

const CurrencyDetails = ({ isOpen, onGoBack, selectedTierInfo, tiers }: ICurrencyDetails) => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();

	let result: Record<string, string[]> = {};
	if (selectedTierInfo) {
		selectedTierInfo.instruments.forEach((item: string) => {
			let prefix = item.substring(0, item.indexOf('/'));
			if (!result[prefix]) {
				result[prefix] = [];
			}

			result[prefix].push(item);
		});
	}

	const tableHTML = () => {
		const inputData = selectedTierInfo.maxOpenInstrumentPosition;
		return generateFXMaxPositionTable(generateFXMaxPositionArray(inputData), t, appContext.languageSettings);
	};

	return (
		<Modal show={isOpen} centered dialogClassName={styles.modalDialog} contentClassName={styles.modalContent}>
			<Modal.Header className={styles.detailsModalHeader}>
				<div className={styles.wrapper}>
					<div>
						<FontAwesomeIcon className={styles.backButton} icon={['fas', 'arrow-left']} onClick={() => onGoBack()} />
					</div>
					<div className={styles.title}>{`${t(`wtr:${selectedTierInfo?.key}`)} ${t(
						'wtr:TRADABLE_CURRENCY_PAIRS'
					)}`}</div>
				</div>
			</Modal.Header>
			<Modal.Body className={cn(styles.detailsModalBody, styles.scroll)}>
				<div className={styles.tableContainer} dangerouslySetInnerHTML={{ __html: tableHTML() }}></div>
			</Modal.Body>
		</Modal>
	);
};

export default CurrencyDetails;
