import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Carousel.module.scss';
import cn from 'classnames';
import { formatNumberWithCommas } from '../../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../contexts/AppContext';

interface CarouselProps {
	selectedTierInfo: any;
	onMoreDetails: () => void;
}

const Carousel = ({ selectedTierInfo, onMoreDetails }: CarouselProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);

	const [currentIndex, setCurrentIndex] = useState(0);

	const tierPerks = ['currencyPairs', 'monthlyAmount', 'minPerOrder', 'maxPerOrder', 'maxPosition'];
	const tierPerksTranslations = [
		t('wtr:TIER_CURRENCY_PAIRS'),
		t('wtr:TIER_MONTHLY_AMOUNT'),
		t('wtr:TIER_MIN_PER_ORDER'),
		t('wtr:TIER_MAX_PER_ORDER'),
		t('wtr:TIER_MAX_POSITION'),
	];

	const CURRENCY_INDEX_IN_ARRAY = 0;
	const carouselData = tierPerks.map((item) => selectedTierInfo[item]);
	const isCurrencySlide = currentIndex === CURRENCY_INDEX_IN_ARRAY;

	const scrollCarousel = () => {
		if (currentIndex === carouselData.length - 1) {
			return setCurrentIndex(0);
		}
		return setCurrentIndex((prevState) => prevState + 1);
	};

	useEffect(() => {
		const interval = setInterval(() => scrollCarousel(), 5000);

		return () => clearInterval(interval);
	});

	const handleCarouselClick = (key: number) => {
		if (key === currentIndex) return;

		setCurrentIndex(key);
	};

	const perkValue = carouselData[currentIndex];

	return (
		<div className={styles.container}>
			<span className={styles.tierInfo}>
				{isNaN(parseInt(perkValue)) ? perkValue : formatNumberWithCommas(perkValue, 0, appContext.languageSettings)}{' '}
				{tierPerksTranslations[currentIndex]}
			</span>
			{isCurrencySlide &&
				(selectedTierInfo.id !== 'Tier1' ? (
					<span className={styles.viewDetails} onClick={onMoreDetails}>
						{t('wtr:VIEW_DETAILS')}
					</span>
				) : (
					<div className={styles.details}>{selectedTierInfo.instruments.join('、')}</div>
				))}
			<div className={cn(styles.caroucel, !isCurrencySlide && styles.noDetailsTopMargin)}>
				{carouselData.map((_item, key) => (
					<div
						key={key}
						onClick={() => handleCarouselClick(key)}
						className={cn(styles.carouselDot, currentIndex === key && styles.active)}
					></div>
				))}
			</div>
		</div>
	);
};

export default Carousel;
