import React, {useContext} from 'react';

import cn from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from 'react-i18next';

import statusStore, {StatusStore} from '../../store/accountStatusStore';

import {TradersGymContext, TradersGymContextType} from '../../pages/TradersGym/TradersGymContext';

import styles from './AccountStatusCTA.module.scss';

interface AccountStatusCTAProps {
    className?: string;
    labelClass?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    icon?: IconProp;
    size?: 'sm' | 'md' | 'lg' | 'fluid';
    type?: 'submit';
    variant?: 'primary' | 'secondary' | 'danger' | 'outline';
    smallFont?: boolean;
    isFullWidth?: boolean;
    isFullHeight?: boolean;
    label?: string;
}

const AccountStatusCTA: React.FC<AccountStatusCTAProps> = (
    {
        className,
        labelClass,
        disabled = false,
        onClick,
        icon,
        size = 'md',
        type,
        variant = 'primary',
        smallFont = false,
        isFullWidth = false,
        isFullHeight = false,
        label,
    }
) => {
    const _className = cn(
        smallFont ? styles.smallFont : '',
        className,
        styles.button,
        styles[size],
        styles[variant],
        {
            [styles.disabled]: disabled,
            [styles.outlineDisabled]: disabled && variant === 'outline',
        },
        isFullWidth ? styles.fullWidth : '',
        isFullHeight ? styles.fullHeight : ''
    );

    const {t} = useTranslation();
    const permissions = statusStore((state: StatusStore) => state.permissions);
    const accountMenuAction = permissions?.accountMenuAction;

    const gymContext = useContext(TradersGymContext);
    const {tradersGymContext} = gymContext as TradersGymContextType;

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (tradersGymContext.isActive) {
            tradersGymContext.isActive = false;
        }

        if (!disabled && typeof onClick === 'function') {
            onClick(event);
        }
    };

    if (!accountMenuAction || !label) return null;

    return (
        <button disabled={disabled} type={type} className={_className} onClick={handleButtonClick} aria-label={label}>
            {icon && <FontAwesomeIcon icon={icon}/>}
            <span className={cn(labelClass && labelClass, styles.buttonLabel)}>{t(label)}</span>
        </button>
    );
};

export default AccountStatusCTA;
