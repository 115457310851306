import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import {
	defaultSubscriptionModalState,
	SubscriptionModalReason,
	SubscriptionModalState,
} from '../functions/subscriptionUtils';

const useSubscriptionModal = () => {
	const appContext = useContext(AppContext);

	const setModalStateProps = (setProps: Partial<SubscriptionModalState>) => {
		appContext.subscriptionModal = {
			...(appContext.subscriptionModal || defaultSubscriptionModalState),
			...setProps,
		};
	};

	const setIsModalOpen = (isOpen: boolean) => {
		setModalStateProps({ isOpen });
	};

	const setModalReason = (reason: SubscriptionModalReason) => {
		setModalStateProps({ reason });
	};

	const resetModalStateProps = () => {
		setModalStateProps({
			reason: null,
			isOpen: false,
			instrument: null,
			text: null,
		});
	};

	return {
		modalState: appContext.subscriptionModal || defaultSubscriptionModalState,
		setIsModalOpen,
		setModalReason,
		setModalStateProps,
		resetModalStateProps,
	};
};

export default useSubscriptionModal;
