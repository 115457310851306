import React, { useContext } from 'react';

import DashboardContext from '../../../../../contexts/DashboardContext';
import Routes from '../../../../../setup/routes';
import AppContext from '../../../../../contexts/AppContext';

import { AppComponentType } from '../../../../../utils/functions/enums';

import { Item } from './';
import authStore from '../../../../../store/authStore';

const Guide = () => {
	const isJapanAccount = authStore.use.isJapanAccount();

	const dashboardContext = useContext(DashboardContext);

	const handleClick = () => {
		if (isJapanAccount) {
			dashboardContext.userGuide = true;
			return false;
		}
		return true;
	};

	return (
		<Item
			// skipIndicator
			icon={isJapanAccount ? 'book-open-cover' : 'clapperboard-play'}
			tooltip={isJapanAccount ? 'en:USER_GUIDE' : 'Think Academy'}
			route={Routes.trader.tutorials}
			component={AppComponentType.Tutorials}
			preClick={handleClick}
		/>
	);
};

export default Guide;
