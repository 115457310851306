import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';
import Button from '../../../../../../shared/Button';

import {
	isPasswordValidLength,
	isPasswordLowercaseLetter,
	isPasswordSpecialCharacters,
	isPasswordSingleUpperLetter,
	isPasswordSingleDigit,
} from '../../../../../../utils/functions/passwordValidation';

import useChangePassword from '../../../../../../utils/mutations/useChangePassword';

import styles from './SettingsModule.module.scss';

interface passwordValidations {
	validSatisfied: boolean;
	lowercaseSatisfied: boolean;
	uppercaseSatisfied: boolean;
	digitSatisfied: boolean;
	specialSatisfied: boolean;
}

interface securitySettingsProps {
	handleUpdatePasswordSettings: (values: Record<string, string>) => void;
	setDisabled: (value: boolean) => void;
	handleCloseModal: (value: boolean) => void;
}

const PasswordSettings: React.FC<securitySettingsProps> = ({
	handleCloseModal,
	setDisabled,
	handleUpdatePasswordSettings,
}) => {
	const { mutate: changePassword } = useChangePassword();

	const tt = useShortTranslation('wtr:');

	const [inputTypeCurrentPassword, setInputTypeCurrentPassword] = useState<string>('password');
	const [inputTypeNewPassword, setInputTypeNewPassword] = useState<string>('password');
	const [uppercaseCheck, setUppercaseCheck] = useState<boolean>(false);
	const [lowercaseCheck, setLowercaseCheck] = useState<boolean>(false);
	const [numberCheck, setNumberCheck] = useState<boolean>(false);
	const [specialCheck, setSpecialCheck] = useState<boolean>(false);
	const [passwordLenghtCheck, setPasswordLenghtCheck] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const [invalid, setInvalid] = useState(false);
	const [dontMatch, setDontMatch] = useState(false);
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [showPasswordPopup, setShowPasswordPopup] = useState(false);
	const [currentPassword, setCurrentPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');

	const [passwordValidations, setPasswordValidations] = useState<passwordValidations>({
		validSatisfied: false,
		lowercaseSatisfied: false,
		uppercaseSatisfied: false,
		digitSatisfied: false,
		specialSatisfied: false,
	});
	const { t } = useTranslation();

	const showCurrentPassword = () => {
		if (inputTypeCurrentPassword === 'password') {
			setInputTypeCurrentPassword('text');
		} else {
			setInputTypeCurrentPassword('password');
		}
	};
	const showNewPassword = () => {
		if (inputTypeNewPassword === 'password') {
			setInputTypeNewPassword('text');
		} else {
			setInputTypeNewPassword('password');
		}
	};

	const isNewPasswordValid = (value: string) => {
		if (value !== '') {
			const isLengthValid = isPasswordValidLength(value);
			const isLowercaseValid = isPasswordLowercaseLetter(value);
			const isUppercaseValid = isPasswordSingleUpperLetter(value);
			const isDigitValid = isPasswordSingleDigit(value);
			const isSpecialCharValid = isPasswordSpecialCharacters(value);

			setPasswordValidations({
				validSatisfied: isLengthValid,
				lowercaseSatisfied: isLowercaseValid,
				uppercaseSatisfied: isUppercaseValid,
				digitSatisfied: isDigitValid,
				specialSatisfied: isSpecialCharValid,
			});

			setPasswordLenghtCheck(isLengthValid);
			setLowercaseCheck(isLowercaseValid);
			setUppercaseCheck(isUppercaseValid);
			setNumberCheck(isDigitValid);
			setSpecialCheck(isSpecialCharValid);
		}
	};

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
		const value = event.target.value;

		setShowPasswordPopup(key === 'newPassword');

		if (key === 'newPassword' && event.target.value.length < 15) {
			setNewPassword(value);
			isNewPasswordValid(value);

			if (newPassword.trim() !== '') {
				setErrorMessage(false);
			} else {
				setErrorMessage(false);
			}
		}

		switch (key) {
			case 'currentPassword':
				setCurrentPassword(value);
				break;
		}
	};

	useEffect(() => {
		if (currentPassword.trim() !== '' || newPassword.trim() !== '') {
			setDontMatch(false);
			setIsDisabled(true);
			const validationFailed =
				!passwordValidations.validSatisfied ||
				!passwordValidations.lowercaseSatisfied ||
				!passwordValidations.uppercaseSatisfied ||
				!passwordValidations.digitSatisfied ||
				!passwordValidations.specialSatisfied;

			const validationSucceed =
				passwordValidations.validSatisfied &&
				passwordValidations.lowercaseSatisfied &&
				passwordValidations.uppercaseSatisfied &&
				passwordValidations.digitSatisfied &&
				passwordValidations.specialSatisfied;

			if (validationFailed) {
				setInvalid(true);
				return;
			}

			if (validationSucceed && currentPassword.trim() !== '') {
				setDontMatch(false);
				setInvalid(false);
				setErrorMessage(false);
				setDisabled(false);
				setIsDisabled(false);

				handleUpdatePasswordSettings({
					currentPassword: currentPassword,
					newPassword: newPassword,
					confirmPassword: newPassword,
				});
				return;
			}
		}

		handleUpdatePasswordSettings({
			currentPassword: '',
			newPassword: '',
		});
	}, [newPassword, currentPassword]);

	const handleUpdatePassword = () => {
		if (
			!passwordValidations.validSatisfied ||
			!passwordValidations.lowercaseSatisfied ||
			!passwordValidations.uppercaseSatisfied ||
			!passwordValidations.digitSatisfied ||
			!passwordValidations.specialSatisfied
		) {
			return null;
		}

		changePassword(
			{
				currentPassword: currentPassword,
				newPassword: newPassword,
			},
			{
				onSuccess: (result) => {
					if (result === 'FAIL') {
						setErrorMessage(true);
					} else if (result === 'OK') {
						setCurrentPassword('');
						setNewPassword('');
						setUppercaseCheck(false);
						setLowercaseCheck(false);
						setNumberCheck(false);
						setSpecialCheck(false);
						setPasswordLenghtCheck(false);
					}
				},
				onError: (err) => {
					setErrorMessage(true);

					console.debug('Error retrieving changePassword from TFBO gateway.  Exception: ', err);
				},
			}
		);
	};

	return (
		<>
			<div className={cn(styles.platformTab, styles.platformTabSecurity)}>
				<div className={styles.closeIcon} onClick={handleCloseModal}>
					<FontAwesomeIcon icon={['fal', 'xmark-large']} className={styles.icon} />
				</div>
				<div className={styles.profileSetingsHeader}>
					<div className={styles.platformTabName}>{t('wtr:CHANGE_PASS')}</div>
				</div>
				<div className={styles.profileSetingsContent}>
					<form className={cn(styles.settingsPairs, styles.passwordInputWrapper)}>
						<input
							className={cn(errorMessage ? [styles.newPasswordInput, styles.invalidPassword] : styles.newPasswordInput)}
							id={`current-password`}
							type={inputTypeCurrentPassword}
							placeholder={t('wtr:CURRENT_PASSWORD')}
							value={currentPassword}
							onChange={(event) => handleChangeInput(event, 'currentPassword')}
							autoComplete="current-password"
						/>
						<FontAwesomeIcon
							className={styles.eyeIcon}
							icon={['far', inputTypeCurrentPassword === 'password' ? 'eye' : 'eye-slash']}
							onClick={showCurrentPassword}
						/>
					</form>
					{errorMessage && <div className={styles.errorMessage}>{tt('WTR_INVALID_PASSWORD')}</div>}
					<form className={cn(styles.settingsPairs, styles.passwordInputWrapper)}>
						<input
							className={styles.newPasswordInput}
							id={`new-password`}
							type={inputTypeNewPassword}
							placeholder={t('wtr:WTR_NEW_PASSWORD')}
							value={newPassword}
							onChange={(event) => handleChangeInput(event, 'newPassword')}
							autoComplete="new-password"
						/>
						<FontAwesomeIcon
							className={styles.eyeIcon}
							icon={['far', inputTypeNewPassword === 'password' ? 'eye' : 'eye-slash']}
							onClick={showNewPassword}
						/>
					</form>
					<div>
						<div className={styles.settingsPairPassword}>
							<div className={uppercaseCheck ? styles.conditionSuccess : ''}>
								<FontAwesomeIcon
									icon={['far', uppercaseCheck ? 'circle-plus' : 'circle-minus']}
									className={styles.icon}
								/>
								{t('wtr:SETTINGS_UPPERCASE_LETTER')}
							</div>
							<div className={lowercaseCheck ? styles.conditionSuccess : ''}>
								<FontAwesomeIcon
									icon={['far', lowercaseCheck ? 'circle-plus' : 'circle-minus']}
									className={styles.icon}
								/>
								{t('en:SETTINGS_LOWERCASE_LETTER')}
							</div>
							<div className={numberCheck ? styles.conditionSuccess : ''}>
								<FontAwesomeIcon icon={['far', numberCheck ? 'circle-plus' : 'circle-minus']} className={styles.icon} />
								{t('en:SETTINGS_NUMBER')}
							</div>
							<div className={specialCheck ? styles.conditionSuccess : ''}>
								<FontAwesomeIcon
									icon={['far', specialCheck ? 'circle-plus' : 'circle-minus']}
									className={styles.icon}
								/>
								{t('en:SETTINGS_SPECIAL')}
							</div>
							<div className={passwordLenghtCheck ? styles.conditionSuccess : ''}>
								<FontAwesomeIcon
									icon={['far', passwordLenghtCheck ? 'circle-plus' : 'circle-minus']}
									className={styles.icon}
								/>
								{t('en:SETTINGS_CHARACTERS_LENGTH')}
							</div>
						</div>
					</div>
					<div className={cn(styles.settingsButtonsPassword)}>
						<Button
							label={t('en:CANCEL')}
							variant="outline"
							size="lg"
							className={styles.cancelButton}
							onClick={() => handleCloseModal(false)}
						/>
						<Button
							label={t('en:UPDATE')}
							variant="primary"
							size="lg"
							className={styles.saveButton}
							onClick={() => handleUpdatePassword()}
							disabled={isDisabled}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PasswordSettings;
