import EUR from './eur.svg';
import GBP from './gbp.svg';
import AUD from './aud.svg';
import CAD from './cad.svg';
import CHF from './chf.svg';
import NZD from './nzd.svg';
import SGD from './sgd.svg';
import ZAR from './zar.svg';
import USD from './usd.svg';
import JPY from './jpy.svg';

export type CurrencyFlag = 'EUR' | 'GBP' | 'AUD' | 'CAD' | 'CHF' | 'NZD' | 'SGD' | 'ZAR' | 'USD' | 'JPY' | string;

const flags: { [key in CurrencyFlag]: any } = {
	EUR,
	GBP,
	AUD,
	CAD,
	CHF,
	NZD,
	SGD,
	ZAR,
	USD,
	JPY,
};

export default (code: CurrencyFlag): string => flags[code];
