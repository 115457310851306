import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/ja';
import 'moment/locale/ms-my';
import 'moment/locale/pt-br';
import 'moment/locale/zh-cn';
import 'moment/locale/th';
import 'moment/locale/ar-sa';
import 'moment/locale/vi';
import 'moment/locale/es';
import 'moment/locale/it';

export const translateDate = (date: string | Date, language: string, format?: string) => {
	const lang =
		language === 'zh-Hans' || language === 'zh-Hant' ? 'zh-cn' : language === 'ar-SA' ? 'en' : language.toLowerCase();
	var localLocale = moment(new Date(date));
	localLocale.locale(lang);
	return format ? localLocale.format(format) : localLocale;
};
