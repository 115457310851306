import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import statusStore, { StatusStore } from '../../store/accountStatusStore';

import { TradersGymContext, TradersGymContextType } from '../../pages/TradersGym/TradersGymContext';

import useSelectedTradingAccount from './useSelectedTradingAccount';

const useOrderTicketAccess = () => {
	const setShowModal = statusStore((state: StatusStore) => state.setShowModal);
	const setInfoMessage = statusStore((state: StatusStore) => state.setInfoMessage);
	const tradeButtons = statusStore((state: StatusStore) => (state.permissions || {}).tradeButtons);
	const appContext = useContext(AppContext);
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;

	const realTradingAccount = useSelectedTradingAccount();
	const gymTradingAccount = tradersGymContext.gymTradingAccount;

	return () => {
		if (tradeButtons === 'accountStatus') {
			setShowModal(true);
			appContext.statusModal = true;

			return false;
		}

		const selectedTradingAccount = tradersGymContext.isActive ? gymTradingAccount : realTradingAccount;

		if (tradeButtons === 'orderTicket' && !selectedTradingAccount?.balance) {
			setInfoMessage('INSUFFICIENT_FUNDS');
			setShowModal(true);
			appContext.statusModal = true;

			return false;
		}

		return true;
	};
};

export default useOrderTicketAccess;
