import React, { FC, useContext, useMemo } from 'react';
import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';

import { formatNumberWithCommas } from '../../../Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import styles from '../MarketsTable.module.scss';

interface PercentChangeProps {
  change: number;
}

const PercentChange: FC<PercentChangeProps> = ({ change }): JSX.Element => {
  const appContext = useContext(AppContext);
  const languageSettings = appContext.languageSettings;

  const isNumberPositive = useMemo(() => {
    if (!change) {
      return false;
    }

    return Number(change) > 0;
  }, [change]);

  return (
    <div
      className={cn({
        [styles.positive]: isNumberPositive,
        [styles.negative]: !isNumberPositive
      })}
      style={{ direction: 'ltr' }}
    >
      {isNumberPositive ? '+' : ''}
      {
        change
          ? `${formatNumberWithCommas(change, 2, languageSettings)}%`
          : 'N/A'
      }
    </div>
  );
};

export default PercentChange;