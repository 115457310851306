import { IMarketItemRecord } from '../../views/features/Dashboard/InstrumentSearch/InstrumentSearch';
import { MarketItem } from '../../gateways/RfpGateway/rfp.types';

export function sortSearchResults(instruments: IMarketItemRecord[] | MarketItem[], searchString: string) {
	return instruments.sort((instrumentA, instrumentB) => {
		function instrumentScore(instrument: IMarketItemRecord | MarketItem) {
			const exchangeTickerOrCode =
				instrument.exchangeTicker && instrument.exchangeTicker !== '' ? instrument.exchangeTicker : instrument.code;
			if (exchangeTickerOrCode.substring(0, searchString?.length).toUpperCase() === searchString?.toUpperCase())
				return 2;
			if (instrument.fullName.substring(0, searchString?.length).toUpperCase() === searchString?.toUpperCase())
				return 1;
			return 0;
		}
		if (instrumentScore(instrumentA) === 2 && instrumentScore(instrumentB) === 2) {
			const codeA =
				instrumentA.exchangeTicker && instrumentA.exchangeTicker !== '' ? instrumentA.exchangeTicker : instrumentA.code;
			const codeB =
				instrumentB.exchangeTicker && instrumentB.exchangeTicker !== '' ? instrumentB.exchangeTicker : instrumentB.code;
			return codeA.localeCompare(codeB);
		} else if (instrumentScore(instrumentA) === 1 && instrumentScore(instrumentB) === 1) {
			return instrumentA.fullName.localeCompare(instrumentB.fullName);
		}
		return instrumentScore(instrumentA) > instrumentScore(instrumentB) ? -1 : 1;
	});
}
