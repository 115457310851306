import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { ACCESS_TOKEN_KEY, isDevelopment, MFA_ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './config';

// Talk with the Auth service by default
axios.defaults.baseURL = process.env.REACT_APP_AUTH_SERVICE;
axios.defaults.withCredentials = false;
axios.defaults.headers['Content-Type'] = 'application/json';

type RetriedAxiosRequest = InternalAxiosRequestConfig & { _retried?: boolean };

let refreshTokenPromise: any;

const retryRequest = (originalRequest: RetriedAxiosRequest) => {
	if (originalRequest._retried) {
		return null;
	}

	originalRequest._retried = true;

	if (!refreshTokenPromise) {
		// check for an existing in-progress request
		// if nothing is in-progress, start a new refresh token request
		refreshTokenPromise = axios({
			url: '/auth/refresh',
			method: 'POST',
			data: {
				accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
				refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
			},
		}).then((res: AxiosResponse) => {
			if (res?.status === 200) {
				localStorage.setItem(ACCESS_TOKEN_KEY, res.data.accessToken);
				localStorage.setItem(REFRESH_TOKEN_KEY, res.data.refreshToken);
				refreshTokenPromise = null; // clear state
				delete originalRequest.headers.Authorization;

				return axios(originalRequest);
			}
		});
	}

	return refreshTokenPromise.then(() => {
		return axios(originalRequest);
	});
};

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
	// isDevelopment && console.debug(`[request] [${JSON.stringify(config)}]`);
	let token: string | null = '';

	if (config.url?.startsWith('/auth/tfa')) {
		token = localStorage.getItem(MFA_ACCESS_TOKEN_KEY);
	}

	token = `Bearer ${token || localStorage.getItem(ACCESS_TOKEN_KEY)}`;

	if (token && !config.headers['Authorization']) {
		config.headers['Authorization'] = `${token}`;
	}

	// if the URL is empty it's a TFBO request so change the baseURL and method to POST
	if (!config.url) {
		config.method = 'POST';
		config.baseURL = process.env.REACT_APP_TFBO;
	}

	// reading preferences - change the base URL for this request
	if (config.url === '/v2/preferences') {
		config.baseURL = process.env.REACT_APP_USER_PREFERENCES_URL;
	}

	if ((config.url || '').includes('acuity-trading')) {
		config.headers['Session'] = `${token}`;
		config.headers['Token'] = `${token}`;
	}

	return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
	// isDevelopment && console.error(`[request error] [${JSON.stringify(error)}]`);
	return Promise.reject(error);
};

const onResponse = async (response: AxiosResponse): Promise<AxiosResponse> => {
	// isDevelopment && console.debug(`[response] [${JSON.stringify(response)}]`);
	const originalRequest = response.config;
	const status = response.data?.payload ? response.data?.payload[0]?.status : response.data.status;

	if (
		status === 'NOT_AUTHORIZED' ||
		status === 400  ||
		(status === 403 && (originalRequest.url?.includes('sso') || originalRequest.url?.includes('tfa')))
	) {
		const retried = await retryRequest(originalRequest);

		if (retried) {
			return retried;
		}
	}

	return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
	// isDevelopment && console.error(`[response error] [${JSON.stringify(error)}]`);
	const originalRequest: RetriedAxiosRequest | undefined = error?.response?.config;
	const status = error?.response?.status;

	if (status === 403 && (originalRequest?.url?.includes('sso') || originalRequest?.url?.includes('tfa'))) {
		const retried = await retryRequest(originalRequest);

		if (retried) {
			return retried;
		}
	}

	throw error;
};

axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);
