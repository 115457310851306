import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import { useTranslation } from 'react-i18next';
import { addCommas } from '../helpers';
import AppContext from '../../../../../../contexts/AppContext';
import { getCurrentPrice } from '../../../../../../utils/functions/calculations';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';

interface CurrentPriceProps {
	data: any;
	gym?: boolean;
}
const CurrentPrice = ({ data, gym = false }: CurrentPriceProps) => {
	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const languageSettings = appContext.languageSettings;

	const { t } = useTranslation();

	const quotes = quoteStore.use.quotes();

	const [prevState, setPrevState] = useState(0);

	const record = data.row.original;

	if (!record.position) {
		record.position = record.subRows?.[0].position;
	}

	let current = record.current;

	if (!gym) {
		const currentPrice = getCurrentPrice(record.position?.f, record.position?.code, rfpGatewayContext);
		current =
			(record.side === 'SELL' ? currentPrice?.a.toFixed(record.decPrec) : currentPrice?.b.toFixed(record.decPrec)) ||
			record.current;

		record.current = current;
	}

	useEffect(() => {
		return () => {
			if (current !== prevState) {
				setPrevState(current);
			}
		};
	}, [current]);

	const colorValue = current && prevState && current >= prevState ? 'green' : 'red';
	return (
		<div className={cn(styles.alignRight, colorValue === 'green' ? styles.greenCurrent : styles.redCurrent)}>
			{isNaN(current) ? t('wtr:NA') : addCommas(current, languageSettings, record?.decPrec)}
		</div>
	);
};

export default CurrentPrice;
