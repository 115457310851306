export const submitDynamicForm = (
	method: string,
	url: string,
	params: any,
	acceptCharset?: string,
	target?: string
) => {
	var form = document.createElement('form');
	form.action = url;
	form.method = method;
	form.target = target || '_self';
	form.acceptCharset = acceptCharset || 'UTF-8';

	if (params) {
		for (var key in params) {
			var input = document.createElement('textarea');
			input.name = key;
			input.value = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key];
			form.appendChild(input);
		}
	}

	form.style.display = 'none';
	document.body.appendChild(form);
	form.submit();
	document.body.removeChild(form);
};
