import React from 'react';

import { formatNumberWithCommas } from '../../../../../Watchlist/Instrument/formattedQuoteNumber';

import styles from './RequirementLine.module.scss';

type RequirementLineProps = {
	rangeMin: number;
	rangeMax: number;
	value: number;
	languageSettings: string;
	isLastRange?: boolean;
};

const RequirementLine = ({ rangeMin, rangeMax, value, languageSettings, isLastRange }: RequirementLineProps) => (
	<div className={styles.line}>
		<span>
			{rangeMin}
			{isLastRange ? '+' : '-' + rangeMax}
		</span>
		<span>{formatNumberWithCommas(value ?? 0, 2, languageSettings)}%</span>
	</div>
);

export default RequirementLine;
