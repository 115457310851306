import React, { FC, CSSProperties } from 'react';
import cn from 'classnames';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Cell, flexRender } from '@tanstack/react-table';

import { TableMarketItem } from '../../../../../utils/functions/marketItems/marketItemGroupMapFormatter';

import styles from '../MarketsTable.module.scss';

interface DragAlongCellProps {
	cell: Cell<any, unknown>;
	handleSelectedInstrument: (cell: Cell<any, unknown>) => void;
}

const DragAlongCell: FC<DragAlongCellProps> = ({ cell, handleSelectedInstrument }) => {
	const { isDragging, setNodeRef, transform } = useSortable({
		id: cell.column.id,
	});

	const style: CSSProperties = {
		opacity: isDragging ? 0.8 : 1,
		position: 'relative',
		transform: CSS.Translate.toString(transform),
		transition: 'width transform 0.2s ease-in-out',
		width: cell.column.getSize(),
		zIndex: isDragging ? 1 : 0,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		lineHeight: '1.5',
	};

	return (
		<td
			{...{
				key: cell.id,
				className: cn({
					[styles.tableData]: true,
					[styles.td]: true,
				}),
				ref: setNodeRef,
				style,
				onClick: () => handleSelectedInstrument(cell),
			}}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</td>
	);
};

export default DragAlongCell;
