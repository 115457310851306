import { ENVIRONMENT_NAME } from '../../../../setup/config';

export type TLanguageOptions = {
	shortText?: string;
	value: string;
	disabled?: boolean;
	rfpLang: string;
	captchaLang: string;
	signalsLang: string;
	calendarLang: string;
	tradingView: string;
	text?: string;
};

const hasBrackets = ENVIRONMENT_NAME !== 'production' && ENVIRONMENT_NAME !== 'preview-production';

export enum LanguageCodes {
	ENGLISH = 'en',
	CHINESE_SIMPLIFIED = 'zh-Hans',
	CHINESE_TRADITIONAL = 'zh-Hant',
	DEUTSCH = 'de',
	JAPANESE = 'ja',
	VIETNAMESE = 'vi',
	THAI = 'th',
	PORTUGUESE = 'pt-BR',
	ARABIC = 'ar-SA',
	ITALIAN = 'it',
	SPANISH = 'es',
}

export const languageOptions: TLanguageOptions[] = [
	{
		shortText: 'English',
		value: LanguageCodes.ENGLISH,
		disabled: false,
		rfpLang: 'en',
		captchaLang: 'en',
		signalsLang: 'en-gb',
		calendarLang: 'en',
		tradingView: 'en',
	},
	{
		shortText: '简体中文', // Chinese Simplified
		value: LanguageCodes.CHINESE_SIMPLIFIED,
		disabled: false,
		rfpLang: 'cn',
		captchaLang: 'zh-CN',
		signalsLang: 'zh-cn',
		calendarLang: 'cs',
		tradingView: 'zh',
	},
	{
		shortText: '繁體中文', // Chinese Traditional
		value: LanguageCodes.CHINESE_TRADITIONAL,
		disabled: false,
		rfpLang: 'ct',
		captchaLang: 'zh-TW',
		signalsLang: 'zh-cht',
		calendarLang: 'ct',
		tradingView: 'zh_TW',
	},
	{
		shortText: 'Deutsch', // German
		value: LanguageCodes.DEUTSCH,
		disabled: false,
		rfpLang: 'de',
		captchaLang: 'de',
		signalsLang: 'de-de',
		calendarLang: 'de',
		tradingView: 'de',
	},
	{
		shortText: '日本語', // Japanese
		value: LanguageCodes.JAPANESE,
		disabled: false,
		rfpLang: 'ja',
		captchaLang: 'ja',
		signalsLang: 'ja-jp',
		calendarLang: 'ja',
		tradingView: 'ja',
	},
	{
		shortText: 'Tiếng Việt', // Vietnamese
		value: LanguageCodes.VIETNAMESE,
		disabled: false,
		rfpLang: 'vi',
		captchaLang: 'vi',
		signalsLang: 'vi-vn',
		calendarLang: 'vi',
		tradingView: 'vi',
	},
	{
		shortText: 'ไทย', // Thai
		value: LanguageCodes.THAI,
		disabled: false,
		rfpLang: 'th',
		captchaLang: 'th',
		signalsLang: 'th-th',
		calendarLang: 'th',
		tradingView: 'th',
	},
	{
		shortText: 'Português', // Portguese
		value: LanguageCodes.PORTUGUESE,
		disabled: false,
		rfpLang: 'pt',
		captchaLang: 'pt',
		signalsLang: 'pt-br',
		calendarLang: 'pt',
		tradingView: 'pt',
	},
	{
		shortText: 'العربية', // Arabic
		value: LanguageCodes.ARABIC,
		disabled: false,
		rfpLang: 'ar_sa',
		captchaLang: 'ar',
		signalsLang: 'ar-ae',
		calendarLang: 'ar',
		tradingView: 'ar',
	},
	{
		shortText: 'Italiano', // Italian
		value: LanguageCodes.ITALIAN,
		disabled: false,
		rfpLang: 'it',
		captchaLang: 'it',
		signalsLang: 'it-it',
		calendarLang: 'it',
		tradingView: 'it',
	},
	{
		shortText: 'Español', // Spanish
		value: LanguageCodes.SPANISH,
		disabled: false,
		rfpLang: 'es',
		captchaLang: 'es',
		signalsLang: 'es-es',
		calendarLang: 'es',
		tradingView: 'es',
	},
].map((lang) => {
	if (hasBrackets) {
		lang.shortText += ` (${lang.value})`;
	}
	return lang;
});

export const getEnabledLangOptions = (allowedLanguages: string[] = []): TLanguageOptions[] => {
	const enabledLangOptions = languageOptions.filter((languageOption: TLanguageOptions) => {
		if (languageOption.disabled) {
			return false;
		}

		if (allowedLanguages.length && !allowedLanguages.includes(languageOption.value)) {
			return false;
		}

		return true;
	});

	return enabledLangOptions;
};

export const getInactivityLogoutOptions = (t: (string: string) => string) => [
	{ text: t('wtr:15_Minutes'), value: '900' },
	{ text: t('wtr:60_Minutes'), value: '3600' },
	{ text: t('wtr:360_Minutes'), value: '21600' },
];
export const getAccountIconOptions = (num?: number) => [
	{
		key: 1,
		value: 1,
		image: { avatar: true, src: '/avatars/1.svg' },
	},
	{
		key: 2,
		value: 2,
		image: { avatar: true, src: '/avatars/2.svg' },
	},
	{
		key: 3,
		value: 3,
		image: { avatar: true, src: '/avatars/3.svg' },
	},
	{
		key: 4,
		value: 4,
		image: { avatar: true, src: '/avatars/4.svg' },
	},
	{
		key: 5,
		value: 5,
		image: { avatar: true, src: '/avatars/5.svg' },
	},
	{
		key: 6,
		value: 6,
		image: { avatar: true, src: '/avatars/6.svg' },
	},
	{
		key: 7,
		value: 7,
		image: { avatar: true, src: '/avatars/7.svg' },
	},
	{
		key: 8,
		value: 8,
		image: { avatar: true, src: '/avatars/8.svg' },
	},
	{
		key: 9,
		value: 9,
		image: { avatar: true, src: '/avatars/9.svg' },
	},
	{
		key: 10,
		value: 10,
		image: { avatar: true, src: '/avatars/10.svg' },
	},
	{
		key: 11,
		value: 11,
		image: { avatar: true, src: '/avatars/11.svg' },
	},
	{
		key: 12,
		value: 12,
		image: { avatar: true, src: '/avatars/12.svg' },
	},
	{
		key: 13,
		value: 13,
		image: { avatar: true, src: '/avatars/13.svg' },
	},
	{
		key: 14,
		value: 14,
		image: { avatar: true, src: '/avatars/14.svg' },
	},
	{
		key: 15,
		value: 15,
		image: { avatar: true, src: '/avatars/15.svg' },
	},
	{
		key: 16,
		value: 16,
		image: { avatar: true, src: '/avatars/16.svg' },
	},
	{
		key: 17,
		value: 17,
		image: { avatar: true, src: '/avatars/17.svg' },
	},
	{
		key: 18,
		value: 18,
		image: { avatar: true, src: '/avatars/18.svg' },
	},
	{
		key: 19,
		value: 19,
		image: { avatar: true, src: '/avatars/19.svg' },
	},
	{
		key: 20,
		value: 20,
		image: { avatar: true, src: '/avatars/20.svg' },
	},
	{
		key: 21,
		value: 21,
		image: { avatar: true, src: '/avatars/21.svg' },
	},
	{
		key: 22,
		value: 22,
		image: { avatar: true, src: '/avatars/22.svg' },
	},
	{
		key: 23,
		value: 23,
		image: { avatar: true, src: '/avatars/23.svg' },
	},
	{
		key: 24,
		value: 24,
		image: { avatar: true, src: '/avatars/24.svg' },
	},
	{
		key: 25,
		value: 25,
		image: { avatar: true, src: '/avatars/25.svg' },
	},
	{
		key: 26,
		value: 26,
		image: { avatar: true, src: '/avatars/26.svg' },
	},
	{
		key: 27,
		value: 27,
		image: { avatar: true, src: '/avatars/27.svg' },
	},
	{
		key: 28,
		value: 28,
		image: { avatar: true, src: '/avatars/28.svg' },
	},
	{
		key: 29,
		value: 29,
		image: { avatar: true, src: '/avatars/29.svg' },
	},
	{
		key: 30,
		value: 30,
		image: { avatar: true, src: '/avatars/30.svg' },
	},
	{
		key: 31,
		value: 31,
		image: { avatar: true, src: '/avatars/31.svg' },
	},
	{
		key: 32,
		value: 32,
		image: { avatar: true, src: '/avatars/32.svg' },
	},
	{
		key: 33,
		value: 33,
		image: { avatar: true, src: '/avatars/33.svg' },
	},
	{
		key: 34,
		value: 34,
		image: { avatar: true, src: '/avatars/34.svg' },
	},
	{
		key: 35,
		value: 35,
		image: { avatar: true, src: '/avatars/35.svg' },
	},
];

export const rfpLangMap = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.rfpLang }),
	{} as { string: string }
);
export type RfpLangMap = keyof typeof rfpLangMap;
export const captchaLangMap = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.captchaLang }),
	{} as { string: string }
);
export type CaptchaLangMap = keyof typeof captchaLangMap;
export const signalsLangMap: { string: string } = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.signalsLang }),
	{} as { string: string }
);
export type SignalsLangMap = keyof typeof signalsLangMap;
export const calendarLangMap: { string: string } = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.calendarLang }),
	{} as { string: string }
);
export type CalendarLangMap = keyof typeof calendarLangMap;
export const tradingViewLangMap: { string: string } = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.tradingView }),
	{} as { string: string }
);
export type TradingViewLangMap = keyof typeof tradingViewLangMap;
