import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import { formatNumberWithCommas } from '../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';
import { defineBaseCurrencySymbol } from '../functions/calculations';

/**
 * formats the incoming data to a string containing the currency symbol and the value
 * @param accountId The selected trading account
 * @returns the formatted values ready for displaying with the corresponding symbols
 */
const useMoneyFormatter = (accountId: any) => {
	const appContext = useContext(AppContext);
	const symbol = defineBaseCurrencySymbol(accountId);

	return (value: number) : string => {
		const formattedValue = formatNumberWithCommas(Math.floor(value), 0, appContext.languageSettings);
		if (accountId?.isJapanAccount) return `${symbol} ${formattedValue}`;

		return `${symbol} ${formatNumberWithCommas(value, 2, appContext.languageSettings)}`;
	};
};

export default useMoneyFormatter;
