import { TradingPositionLimitType } from '../../utils/functions/enums';

export const compareLimits = (a?: TradingPositionLimitType[], b?: TradingPositionLimitType[]) => {
	if (a && b && a.length === b.length) {
		for (var i = 0; i < a.length; i++) {
			if (!b.includes(a[i])) {
				return false;
			}
		}
		return true;
	}
	return false;
};
