import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Button from '../../../../shared/Button/Button';

import { RFP } from '../../../../gateways/RfpGateway/rfpConstants';
import { convertAmountToLot } from '../../../../utils/functions/calculations';
import {
	CloseTradeRequest,
	TradingAccount,
	TradingPosition,
	TradingPositionState,
} from '../../../../gateways/RfpGateway/rfp.types';

import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import RfpGatewayContext from '../../../../contexts/RfpGatewayContext';
import AppContext from '../../../../contexts/AppContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import useForceRerender from '../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../utils/hooks/usePromiseFactory';
import CloseButton from '../../../components/CloseButton/CloseButton';
import { formatNumberWithCommas } from '../Watchlist/Instrument/formattedQuoteNumber';
import DetachedModal from '../ChartPanel/NewOrderModals/DetachedModal/DetachedModal';
import { QUANTITY_TYPE, TicketLayout } from '../../../../utils/functions/enums';
import { TradersGymContext, TradersGymContextType } from '../../../../pages/TradersGym/TradersGymContext';
import { GymPositionManagerDelegate } from '../../../../pages/TradersGym/Positions/GymPositionManager';
import { GymTradingAccount } from '../../../../pages/TradersGym/Accounts/GymTradingAccount';
import { GymTradingManager } from '../../../../pages/TradersGym/Accounts/GymTradingManager';
import { GymTradingPosition } from '../../../../pages/TradersGym/Positions/GymTradingPosition';

import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../store/tradingAccountStore';

import styles from './CancelOrderTicket.module.scss';
import tradingViewStore from '../../../../store/tradingViewStore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CancelOrderTicket = () => {
	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;

	const realTradingAccount = useSelectedTradingAccount();
	const gymTradingAccount = tradersGymContext.gymTradingAccount;

	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();

	const subIdRef = useRef<string | undefined>(undefined);

	const languageSettings = appContext.languageSettings;
	const selectedPosition = dashboardContext.selectedPosition;
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const selectedMarketItem = dashboardContext.selectedInstrument;
	const showCancelTicket = dashboardContext.showCancelTicket;
	const inputValidation = dashboardContext.inputValidation;
	const tradingPositions = tradersGymContext.isActive
		? tradersGymContext.gymTradingAccount?.activePositions ?? []
		: dashboardContext.getTradingPositions();
	const headerSelected = dashboardContext.headerSelected;
	const quantityType = dashboardContext.quantityType;
	const closeOrder = dashboardContext.modalToggle.closeOrder;
	const isArabic = appContext.isArabic;
	const setTicketLayout = tradingViewStore.use.setTicketLayout();

	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');
	const [currentPriceQuote, setCurrentPriceQuote] = useState<any>({ a: 0, b: 0 });
	const [cancelExpiry, setCancelExpiry] = useState('');
	const [resizeText, setResizeText] = useState(false);
	const [selectedTradingAccount, setSelectedTradingAccount] = useState<TradingAccount>(realTradingAccount);

	const isSummaryPosition = selectedPosition && selectedPosition[0] && selectedPosition.length > 1;
	const isIndividualPosition = selectedPosition && selectedPosition[0] && selectedPosition.length === 1;
	const allOrdersLength = tradingPositions.filter(
		(pos: TradingPosition) => pos.state === TradingPositionState.pending && pos.aId === selectedTradingAccount?.id
	).length;
	const isAllOrders =
		selectedPosition && selectedPosition[0]
			? selectedPosition.length === allOrdersLength && allOrdersLength > 1 && headerSelected
			: false;

	const [quantity, setQuantity] = useState<string>('');
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	useEffect(() => {
		if (tradersGymContext.isActive && gymTradingAccount) {
			setSelectedTradingAccount(gymTradingAccount);
		} else {
			setSelectedTradingAccount(realTradingAccount);
		}
	}, [realTradingAccount, gymTradingAccount]);

	useObservable(appContext.getPropertyChangeStream('appTheme'), (_change) => forceRerender());

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'selectedPosition',
			'selectedInstrument',
			'showCancelTicket',
			'inputValidation',
			'tradingPositions',
			'headerSelected',
			'quantityType',
			'detailedInformation',
			'tradingAccount',
			'modalToggle'
		),
		(_change) => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	useEffect(() => {
		const localLanguage = localStorage.getItem('language') ?? 'en';
		if (localLanguage === 'de') setResizeText(true);
		if (tradersGymContext.gymTradingAccount) {
			tradersGymContext.gymTradingAccount.positionManager.addDelegate(positionsDelegate);
		}
		return () => {
			if (tradersGymContext.gymTradingAccount) {
				tradersGymContext.gymTradingAccount.positionManager.removeDelegate(positionsDelegate);
			}
		};
	}, []);

	const positionsDelegate: GymPositionManagerDelegate = {
		positionOpened(_sender, _position) {
			hideComponent();
		},
		positionDeleted(_sender, _position) {
			hideComponent();
		},
	};

	useEffect(() => {
		if (selectedPosition && selectedPosition.length > 0) {
			const firstPosition = selectedPosition[0];
			const selectedInstrumentDetails = selectedTradingAccount?.tradingInstruments?.instruments[firstPosition.code];
			const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
			if (quantityType === QUANTITY_TYPE.AMOUNT) {
				setQuantity(
					formatNumberWithCommas(firstPosition.qty, selectedInstrumentDetails?.amtDPrec ?? 0, languageSettings)
				);
			} else if (quantityType === QUANTITY_TYPE.LOTS) {
				setQuantity(
					formatNumberWithCommas(
						convertAmountToLot(firstPosition.qty, instrumentRndLot),
						selectedInstrumentDetails?.lotDPrec ?? 2,
						languageSettings
					)
				);
			}
			firstPosition.eT === 0
				? setCancelExpiry(t('wtr:GTC'))
				: setCancelExpiry(moment(firstPosition.eT).format('D/MMM/YYYY').toUpperCase());
		}
	}, [selectedPosition, quantityType]);

	useEffect(() => {
		if (rfpGatewayContext && selectedMarketItem) {
			// unsubscribe previous quotes
			if (subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			}

			// subscribe for price quote
			subIdRef.current = rfpGatewayContext.subscribePriceQuote(
				selectedMarketItem.feedId,
				[selectedMarketItem.code],
				(priceQuote) => {
					if (!tradersGymContext.isActive) {
						setCurrentPriceQuote(priceQuote);
					}
				}
			);
		}

		// unsubscribe price quote on unmount
		return () => {
			if (rfpGatewayContext && subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				subIdRef.current = undefined;
			}
		};
	}, [selectedPosition, selectedMarketItem, rfpGatewayContext]);

	useEffect(() => {
		if (tradersGymContext.priceQuote) {
			setCurrentPriceQuote(tradersGymContext.priceQuote);
		}
	}, [tradersGymContext.priceQuote]);

	const hideComponent = () => {
		if (ticketLayout === TicketLayout.Dock) {
			dashboardContext.showCancelTicket = false;
		} else {
			dashboardContext.modalToggle.closeOrder = false;
			forceRerender();
		}
	};

	const dockClosePosition = () => {
		setTicketLayout(TicketLayout.Dock);
		dashboardContext.modalToggle.closeOrder = false;
		dashboardContext.showCancelTicket = true;
		forceRerender();
	};
	const undockClosePosition = () => {
		setTicketLayout(TicketLayout.Undock);
		dashboardContext.modalToggle.closeOrder = true;
		dashboardContext.showCancelTicket = false;
		forceRerender();
	};
	const closeModal = () => {
		dashboardContext.modalToggle.closeOrder = false;
		dashboardContext.showCancelTicket = false;
		forceRerender();
	};

	const closePosition = (selectedAccount: TradingAccount, position: TradingPosition) => {
		if (rfpGatewayContext && position.posId) {
			if (position.cancelRequestSent === undefined) {
				const summaryParams: CloseTradeRequest = {
					posId: position.posId,
					trAccountId: selectedAccount.id,
				};
				rfpGatewayContext.send(RFP.closeTrade, summaryParams);
				//position.cancelRequestSent = true;
			}
			dashboardContext.orderConfirm.cancel = true;
		} else {
			console.log('position id is missing');
		}
	};

	const closeGymPosition = (selectedAccount: GymTradingAccount, position: GymTradingPosition) => {
		if (tradersGymContext.isActive) {
			GymTradingManager.sharedInstance().closePosition(selectedAccount, position);
		}
	};

	const closePositions = () => {
		if (selectedTradingAccount) {
			dashboardContext.orderConfirm.edit = false;
			selectedPosition.forEach((element: TradingPosition, index: number) => {
				if (tradersGymContext.isActive) {
					closeGymPosition(selectedTradingAccount as GymTradingAccount, element as GymTradingPosition);
				} else {
					// 100 millis delay for each interaction
					setTimeout(() => {
						closePosition(selectedTradingAccount, element);
					}, index * 100);
				}
			});
		}
		hideComponent();
	};

	const typeOfPosition = useMemo(() => selectedPosition[0]?.state, [selectedPosition]);

	const header = useMemo(() => {
		if (isIndividualPosition && typeOfPosition === 'PENDING') {
			return (
				<div className={cn(resizeText && styles.resizeHeaderText)}>
					{tt('REMOVE_ORDER')}{' '}
					<b>{selectedPosition && selectedPosition[0] ? selectedPosition[0]?.marketItem.displayName : ''}</b>?
					{ticketLayout === TicketLayout.Dock ? (
						<div className={styles.iconContainer}>
							<OverlayTrigger
								key="detachOrderTicket"
								placement={isArabic ? 'right' : 'left'}
								overlay={<Tooltip className="my-tooltip">{t('en:DETACH')}</Tooltip>}
							>
								<div className={styles.icon}>
									<FontAwesomeIcon icon={['fas', 'external-link-alt']} size="1x" onClick={undockClosePosition} />
								</div>
							</OverlayTrigger>
						</div>
					) : null}
				</div>
			);
		} else if (isSummaryPosition && !isAllOrders && typeOfPosition !== 'PENDING') {
			return (
				<div
					dangerouslySetInnerHTML={{
						__html: tt('CLOSE_FOR', {
							length: selectedPosition.length,
							code: selectedPosition && selectedPosition[0] ? selectedPosition[0]?.marketItem.displayName : '',
						}),
					}}
				></div>
			);
		} else if (isSummaryPosition && typeOfPosition === 'PENDING' && !isAllOrders) {
			return (
				<div
					dangerouslySetInnerHTML={{
						__html: tt('REMOVE_ORDER_IN', {
							length: selectedPosition.length,
							code: selectedPosition && selectedPosition[0] ? selectedPosition[0]?.marketItem.displayName : '',
						}),
					}}
				></div>
			);
		} else if (isAllOrders && typeOfPosition !== 'PENDING') {
			return <div dangerouslySetInnerHTML={{ __html: tt('CLOSE_ALL_POSITIONS', { length: allOrdersLength }) }}></div>;
		} else if (isAllOrders && typeOfPosition === 'PENDING') {
			return <div dangerouslySetInnerHTML={{ __html: tt('REMOVE_ALL_ORDERS', { length: allOrdersLength }) }}></div>;
		}
		return '';
	}, [isIndividualPosition, typeOfPosition, isSummaryPosition, isAllOrders, selectedPosition, allOrdersLength]);

	const closeTicket = (
		<>
			<div className={ticketLayout === TicketLayout.Dock ? styles.ticketInfo : ''}>
				<div className={ticketLayout === TicketLayout.Dock ? styles.closeContainer : styles.closeContainerModal}>
					{isIndividualPosition && (
						<>
							<div className={styles.closeFields}>
								<div>{t('en:SIDE')}</div>
								<div>
									{selectedPosition && selectedPosition[0] && selectedPosition[0].side === 'BUY'
										? t('en:BUY')
										: t('en:SELL')}
								</div>
							</div>
							<div className={styles.closeFields}>
								<div>{t(isSpreadBettingAccount ? 'en:POUND_PER_POINT' : `en:QT_${quantityType.toUpperCase()}`)}</div>
								<div className={styles.amountInput}>{quantity}</div>
							</div>
							<div className={styles.closeFields}>
								<div>{t('en:WILL_OPEN_AT')}</div>
								<div>
									{selectedPosition &&
										selectedPosition[0] &&
										formatNumberWithCommas(selectedPosition[0].prc, selectedPosition[0].dec, languageSettings)}
								</div>
							</div>
							<div className={styles.closeFields}>
								<div>{t('en:CURRENT_RATE')}</div>
								<div>
									{selectedPosition[0].side === 'BUY'
										? formatNumberWithCommas(currentPriceQuote.b!, selectedPosition[0].dec, languageSettings)
										: formatNumberWithCommas(currentPriceQuote.a!, selectedPosition[0].dec, languageSettings)}
								</div>
							</div>
							<div className={styles.closeFields}>
								<div>{t('en:EXPIRY')}</div>
								<div className={styles.date}>{selectedPosition && selectedPosition[0] && cancelExpiry}</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);

	const footer = (
		<div className={styles.orderModalFooter}>
			<div className={styles.modalPartialButtons}>
				<Button variant="secondary" size="lg" label={t('en:CANCEL')} onClick={hideComponent} />
				<Button
					variant="primary"
					size="lg"
					label={t('en:REMOVE')}
					className={cn(inputValidation.highAmount ? styles.disableButton : '')}
					onClick={closePositions}
				/>
			</div>
		</div>
	);

	return (
		<>
			{ticketLayout === TicketLayout.Dock && showCancelTicket && (
				<>
					<div className={styles.closeButtonLocation}>
						<CloseButton onClick={() => hideComponent()} />
					</div>

					<div className={styles.header}>{header}</div>
					{closeTicket}
					{footer}
				</>
			)}
			{ticketLayout === TicketLayout.Undock && closeOrder && (
				<DetachedModal
					title={header}
					body={isIndividualPosition ? closeTicket : undefined}
					hideAction={hideComponent}
					successAction={closePositions}
					successTitle={t('en:REMOVE')}
					dockActionCustom={true}
					show={true}
					closeModal={closeModal}
					dockAction={dockClosePosition}
				/>
			)}
		</>
	);
};

export default CancelOrderTicket;
