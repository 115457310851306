import { createContext } from 'react';
import clone from 'clone';
import { Resolver } from '../utils/functions/Ioc';
import { default as Lazy } from '../utils/functions/Lazy';
import { ObservableObject } from '../utils/functions/Observables';
import { HistorySize } from '../gateways/RfpGateway/rfp.types';

export type TChart = {
	id: string;
	isEmpty?: boolean;
	symbol?: string;
	layout: {
		[key: string]: any;
	};
	chartParams?: any;
};

export type TChartPeriodicity = {
	period: number;
	interval?: number;
	timeUnit: string;
};

type TChartContextProperties = {
	activeChart: string[];
	charts: TChart[];
	periodicityChartId: string;
};

const defaultValues = new Lazy<TChartContextProperties>(() => {
	return {
		activeChart: [],
		charts: [
			{
				layout: {},
				id: '/',
				isEmpty: true,
				symbol: '/',
				chartParams: {},
			},
		],
		periodicityChartId: '',
	};
});

interface IChartContext extends TChartContextProperties {
	reset(): void;
	addChartPanelTab(tab: TChart): void;
	setSingleActiveChart(value: string): void;
	setActiveChart(value: string): void;
	resetActiveChart(): void;
	setSymbol(value: string): void;
	setLayout(value: TChart['layout']): void;
}

export class ChartContextProvider
	extends ObservableObject<ChartContextProvider, keyof TChartContextProperties>
	implements IChartContext
{
	private m_propertyValues: TChartContextProperties;
	private historySizeRecords: Map<string, HistorySize> = new Map<string, HistorySize>();

	private static _instance = new Lazy(() => {
		return new ChartContextProvider();
	});

	public static get instance(): ChartContextProvider {
		return this._instance.getValue();
	}

	public getHistorySizeRecord(reqId: string) {
		return this.historySizeRecords.get(reqId);
	}

	public setHistorySizeRecord(historySize: HistorySize) {
		// console.log(`Added HistorySize reqId=${historySize.reqId} code=${historySize.code}`);
		return this.historySizeRecords.set(historySize.reqId, historySize);
	}

	public get activeChart(): any {
		return this.m_propertyValues.activeChart;
	}

	public set activeChart(value: any) {
		this.raiseAndSetIfChanged('activeChart', value, this.m_propertyValues.activeChart, (value) => {
			this.m_propertyValues.activeChart = value;
		});
	}

	public get charts(): TChart[] {
		return this.m_propertyValues.charts;
	}

	public set charts(value: TChart[]) {
		this.raiseAndSetIfChanged('charts', value, this.m_propertyValues.charts, (value) => {
			this.m_propertyValues.charts = value;
		});
	}

	public get periodicityChartId(): string {
		return this.m_propertyValues.periodicityChartId;
	}

	public set periodicityChartId(value: string) {
		this.raiseAndSetIfChanged('periodicityChartId', value, this.m_propertyValues.periodicityChartId, (value) => {
			this.m_propertyValues.periodicityChartId = value;
		});
	}

	private constructor() {
		super();
		this.m_propertyValues = clone(defaultValues.getValue());
	}

	public reset(): void {
		this.m_propertyValues = clone(defaultValues.getValue());
	}

	public addChartPanelTab(tab: TChart): void {
		this.charts = [...this.charts, tab];
	}

	public setSingleActiveChart(value: string): void {
		this.activeChart = [value];
	}

	public setActiveChart(value: string): void {
		this.activeChart = [...this.activeChart, value];
	}

	public resetActiveChart(): void {
		const activeChart = [...this.activeChart];
		this.activeChart = [...activeChart.slice(0, 1)];
	}

	public setSymbol(value: string): void {
		const charts = [...this.charts];
		const activeChart = [...this.activeChart];
		this.charts = charts.map((chart) => {
			return activeChart.indexOf(chart.id) >= 0 ? { ...chart, symbol: value } : chart;
		});
	}

	public setLayout(value: TChart['layout']): void {
		const charts = [...this.charts];
		const activeChart = [...this.activeChart];
		this.charts = charts.map((chart) => {
			return activeChart.indexOf(chart.id) >= 0 ? { ...chart, layout: value } : chart;
		});
	}
}

export default createContext<ChartContextProvider>(
	Resolver.isSet && Resolver.isRegistered(ChartContextProvider)
		? Resolver.resolve(ChartContextProvider)
		: ChartContextProvider.instance
);
