type Class<T extends Object = Object> = Function & { prototype: T };
type Optional<T = any> = T | null | undefined;
type DefaultConstructor<T extends Object = Object> = Class<T> & (new () => T);

/**
 * Interface for dependency resolvers.
 */
export interface IResolver {
	/**
	 * Resolves a dependency.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns An instance of T if successful, otherwise null.
	 */
	resolve<T>(type: Class<T>): T;

	/**
	 * Resolves a dependency.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns All instances of T if successful.
	 */
	resolveAll<T>(type: Class<T>): T[];

	/**
	 * Determines whether a dependency of the specified type is registered.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns True if an instance of the specified type is registered, otherwise false.
	 */
	isRegistered<T>(type: Class<T>): boolean;
}

/**
 * Interface for dependency containers.
 */
export interface IDependencyContainer {
	/**
	 * Gets the resolver from the container
	 */
	resolver: IResolver;

	/**
	 * Registers an instance of type T to be stored in the container.
	 * @template T
	 * @param {T} T The type of the instance.
	 * @param type A class instance of T
	 * @param instance An instance of T
	 * @returns An instance of {@link IDependencyContainer}
	 */
	register<T>(type: Class<T>, instance: T): IDependencyContainer;

	/**
	 * Registers an instance of type T to be stored in the container.
	 * @template T, TImpl
	 * @param {T} T The type of the instance.
	 * @param {TImpl} TImpl Type to register for instantiation.
	 * @param type A class instance of T
	 * @param typeImpl A class instance of TImpl
	 * @returns An instance of {@link IDependencyContainer}
	 */
	register<T, TImpl extends T = T>(type: Class<T>, typeImpl: DefaultConstructor<TImpl>): IDependencyContainer;

	/**
	 * Registers a function which returns an instance of type T
	 * @template T
	 * @param {T} T The type of the instance.
	 * @param type A class instance of T
	 * @param factory A function which returns an instance of type T
	 * @returns An instance of {@link IDependencyContainer}
	 */
	register<T>(type: Class<T>, factory: (resolver: IResolver) => T): IDependencyContainer;

	/**
	 * Registers a type to instantiate for type T as singleton.
	 * @template T, TImpl
	 * @param {T} T The type of the instance.
	 * @param {TImpl} TImpl Type to register for instantiation.
	 * @param type A class instance of T
	 * @param typeImpl A class instance of TImpl
	 * @returns An instance of {@link IDependencyContainer}
	 */
	registerSingle<T, TImpl extends T = T>(type: Class<T>, typeImpl: DefaultConstructor<TImpl>): IDependencyContainer;

	/**
	 * Registers a function which returns an instance of type T for singleton.
	 * @template T
	 * @param {T} T The type of the instance.
	 * @param type A class instance of T
	 * @param factory A function which returns an instance of type T
	 * @returns An instance of {@link IDependencyContainer}
	 */
	registerSingle<T>(type: Class<T>, factory: (resolver: IResolver) => T): IDependencyContainer;
}

class DefaultResolver implements IResolver {
	private readonly m_resolverDelegate: (type: Class) => IterableIterator<any>;

	public constructor(resolverDelegate: (type: Class) => IterableIterator<any>) {
		this.m_resolverDelegate = resolverDelegate;
	}

	/**
	 * Resolves a dependency.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns An instance of T if successful, otherwise null.
	 */
	public resolve<T>(type: Class<T>): T {
		const iterator: IterableIterator<T> = this.m_resolverDelegate(type);
		const value: T = iterator.next()?.value;
		return value;
	}

	/**
	 * Resolves a dependency.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns All instances of T if successful.
	 */
	public resolveAll<T>(type: Class<T>): T[] {
		const list: T[] = new Array<T>();
		const iterator: IterableIterator<T> = this.m_resolverDelegate(type);
		let value: T = iterator.next()?.value();
		while (value != null) {
			list.push(value);
			value = iterator.next()?.value();
		}
		return list;
	}

	/**
	 * Determines whether a dependency of the specified type is registered.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns True if an instance of the specified type is registered, otherwise false.
	 */
	public isRegistered<T>(type: Class<T>): boolean {
		return this.resolve<T>(type) != null;
	}
}

export class DependencyContainer implements IDependencyContainer {
	private readonly m_resolver: IResolver;
	private readonly m_services: Map<Class, Array<any>>;
	private readonly m_registeredServices: Map<Class, Array<(resolver: IResolver) => any>>;

	public get resolver(): IResolver {
		return this.m_resolver;
	}

	public constructor() {
		this.m_services = new Map<Class, any[]>();
		this.m_registeredServices = new Map<Class, Array<(resolver: IResolver) => any>>();
		this.m_resolver = new DefaultResolver((type: Class) => this.resolveAll(type));
	}

	/**
	 * Registers an instance of type T to be stored in the container.
	 * @template T
	 * @param {T} T The type of the instance.
	 * @param type A class instance of T
	 * @param instance An instance of T
	 * @returns An instance of {@link IDependencyContainer}
	 */
	public register<T>(type: Class<T>, instance: T): this;

	/**
	 * Registers an instance of type T to be stored in the container.
	 * @template T, TImpl
	 * @param {T} T The type of the instance.
	 * @param {TImpl} TImpl Type to register for instantiation.
	 * @param type A class instance of T
	 * @param typeImpl A class instance of TImpl
	 * @returns An instance of {@link IDependencyContainer}
	 */
	public register<T, TImpl extends T = T>(type: Class<T>, typeImpl: DefaultConstructor<TImpl>): this;

	/**
	 * Registers a function which returns an instance of type T
	 * @template T
	 * @param {T} T The type of the instance.
	 * @param type A class instance of T
	 * @param factory A function which returns an instance of type T
	 * @returns An instance of {@link IDependencyContainer}
	 */
	public register<T>(type: Class<T>, factory: (resolver: IResolver) => T): this;

	public register<T, TImpl extends T = T>(
		type: Class<T>,
		impl: TImpl | DefaultConstructor<TImpl> | ((resolver: IResolver) => TImpl)
	): this {
		if (typeof impl != 'function' && impl instanceof type) {
			if (!this.m_services.has(type)) {
				this.m_services.set(type, new Array<any>());
			}
			const services = this.m_services.get(type);
			if (Array.isArray(services)) {
				services.push(impl);
			}
		} else if (impl === type || impl instanceof type || (impl as Class<TImpl>).prototype instanceof type) {
			if (!this.m_registeredServices.has(type)) {
				this.m_registeredServices.set(type, new Array<(resolver: IResolver) => TImpl>());
			}
			const services = this.m_registeredServices.get(type);
			if (Array.isArray(services)) {
				services.push((resolver: IResolver) => new (impl as unknown as DefaultConstructor<TImpl>)());
			}
		} else if (typeof impl == 'function') {
			if (!this.m_registeredServices.has(type)) {
				this.m_registeredServices.set(type, new Array<(resolver: IResolver) => TImpl>());
			}
			const services = this.m_registeredServices.get(type);
			if (Array.isArray(services)) {
				services.push(impl as (resolver: IResolver) => TImpl);
			}
		}

		return this;
	}

	/**
	 * Registers a type to instantiate for type T as singleton.
	 * @template T, TImpl
	 * @param {T} T The type of the instance.
	 * @param {TImpl} TImpl Type to register for instantiation.
	 * @param type A class instance of T
	 * @param typeImpl A class instance of TImpl
	 * @returns An instance of {@link IDependencyContainer}
	 */
	public registerSingle<T, TImpl extends T = T>(type: Class<T>, typeImpl: DefaultConstructor<TImpl>): this;

	/**
	 * Registers a function which returns an instance of type T for singleton.
	 * @template T
	 * @param {T} T The type of the instance.
	 * @param type A class instance of T
	 * @param factory A function which returns an instance of type T
	 * @returns An instance of {@link IDependencyContainer}
	 */
	public registerSingle<T>(type: Class<T>, factory: (resolver: IResolver) => T): this;

	public registerSingle<T, TImpl extends T = T>(
		type: Class<T>,
		impl: DefaultConstructor<TImpl> | ((resolver: IResolver) => TImpl)
	): this {
		if (impl === type || impl instanceof type || (impl as DefaultConstructor<TImpl>).prototype instanceof type) {
			if (!this.m_services.has(type)) {
				this.m_services.set(type, new Array<any>());
			}
			const services = this.m_services.get(type);
			if (Array.isArray(services)) {
				services.push(new (impl as DefaultConstructor<TImpl>)());
			}
		} else if (typeof impl == 'function') {
			if (!this.m_services.has(type)) {
				this.m_services.set(type, new Array<any>());
			}
			const services = this.m_services.get(type);
			if (Array.isArray(services)) {
				services.push((impl as (resolver: IResolver) => TImpl)(this.m_resolver));
			}
		}

		return this;
	}

	private *resolveAll(type: Class): IterableIterator<any> {
		if (this.m_services.has(type)) {
			const list: Array<any> = this.m_services.get(type) as Array<any>;
			for (const service of list) {
				yield service;
			}
		}

		if (this.m_registeredServices.has(type)) {
			const list: Array<(resolver: IResolver) => any> = this.m_registeredServices.get(type) as Array<
				(resolver: IResolver) => any
			>;
			for (const serviceFunc of list) {
				yield serviceFunc(this.m_resolver);
			}
		}
	}
}

/**
 * A quick access wrapper for IResolver implementations
 */
export class Resolver {
	private static _instance: Optional<IResolver> = null;

	/**
	 * Gets the {@link IResolver} instance.
	 * @returns The resolver instance.
	 * @throws Error if resolver has not been set.
	 */
	private static get instance(): IResolver {
		if (!this.isSet) {
			throw new Error('IResolver has not been set. Please set it by calling the Resolver.setResolver method.');
		}
		return this._instance as IResolver;
	}

	/**
	 * Sets the {@link IResolver} instance.
	 * @param value The resolver instance to set.
	 * @throws Error if resolver has already been set - instance can only be set once to prevent mix-ups.
	 */
	private static set instance(value: IResolver) {
		if (this.isSet) {
			throw new Error(
				'IResolver can only be set once. If this was intentional you should call the Resolver.ResetResolver method first.'
			);
		}
		this._instance = value;
	}

	/**
	 * Gets a value indicating whether the resolver has been set.
	 * @returns True if the resolver instance has been set, otherwise false
	 * @see setResolver
	 */
	public static get isSet(): boolean {
		return this._instance != null;
	}

	/**
	 * Sets the resolver instance.
	 * @param resolver An instance of IResolver implementation.
	 */
	public static setResolver(resolver: IResolver): void {
		this.instance = resolver;
	}

	/**
	 * Gets the resolver instance.
	 * @param resolver An instance of IResolver implementation.
	 */
	public static getResolver(): IResolver {
		return this.instance;
	}

	/**
	 * Resets the resolver instance.
	 * @param newInstance An new instance of IResolver implementation.
	 */
	public static resetResolver(newInstance: Optional<IResolver>): void {
		this._instance = newInstance;
	}

	/**
	 * Resolves a dependency.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns An instance of T if successful, otherwise null.
	 */
	public static resolve<T>(type: Class<T>): T {
		return this.instance.resolve<T>(type);
	}

	/**
	 * Resolves a dependency.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns All instances of T if successful, otherwise null.
	 */
	public static resolveAll<T>(type: Class<T>): T[] {
		return this.instance.resolveAll<T>(type);
	}

	/**
	 * Determines whether a dependency of the specified type is registered.
	 * @template T
	 * @param {T} T The type of the dependency.
	 * @param type A class instance of T
	 * @returns True if an instance of the specified type is registered, otherwise false.
	 */
	public static isRegistered<T>(type: Class<T>): boolean {
		return this.instance.isRegistered<T>(type);
	}
}
