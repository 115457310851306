import React from 'react';

import Routes from '../../../../../setup/routes';
import { AppComponentType } from '../../../../../utils/functions/enums';

import { Item } from './';

const Markets = () => {
	return (
		<Item
			component={AppComponentType.Markets}
			route={Routes.trader.markets}
			icon={'globe'}
			tooltip={'wtr:MARKETS'}
		/>
	);
};

export default Markets;
