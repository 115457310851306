import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export interface ITokenPair {
	accessToken: string;
	refreshToken: string;
}

const useRefreshJWTToken = () => {
	const mutationFn = async (tokenPair: ITokenPair) => {
		const response = await axios({
			url: `/auth/refresh`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			data: tokenPair,
		});

		localStorage.setItem('accessToken', response.data.accessToken);
		localStorage.setItem('refreshToken', response.data.refreshToken);

		return response.data;
	};

	return useMutation({
		mutationKey: ['useRefreshJWTToken'],
		mutationFn,
		useErrorBoundary: (error: any) => error.response?.status >= 500,
	});
};

export default useRefreshJWTToken;
