import React, { useContext } from 'react';
import { NumericFormat } from 'react-number-format';

import { isCommaSeparator } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../../../../contexts/AppContext';

import styles from './FormattedNumberInput.module.scss';

interface FormattedNumberInputProps {
	value: any;
	onChange: (value: number) => void;
	decimalPrecision?: number;
	disabled?: boolean;
	isAllowed?: (value: number) => boolean;
	allowNegative?: boolean;
}

const FormattedNumberInput = React.memo(
	({ value, decimalPrecision, disabled, onChange, isAllowed, allowNegative = false }: FormattedNumberInputProps) => {
		const appContext = useContext(AppContext);
		const { languageSettings } = appContext;
		return (
			<NumericFormat
				className={styles.input}
				type="text"
				thousandSeparator={isCommaSeparator(languageSettings) ? '.' : ','}
				decimalSeparator={isCommaSeparator(languageSettings) ? ',' : '.'}
				decimalScale={decimalPrecision || 9}
				allowNegative={allowNegative}
				value={value}
				disabled={disabled}
				isAllowed={({ floatValue }) => {
					if (!isAllowed) return true;
					return isAllowed(floatValue!);
				}}
				onValueChange={({ floatValue }) => onChange(floatValue!)}
			/>
		);
	},
	(prevProps, nextProps) => {
		return prevProps && nextProps && prevProps.value === nextProps.value;
	}
);

export default FormattedNumberInput;
