import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../contexts/AppContext';
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext';
import {
	checkHasUserDemoAccount,
	getAnyLiveUserAccount,
	SubscriptionCheckStatus,
	SubscriptionStatus,
} from '../functions/subscriptionUtils';

import authStore, { AuthStore } from '../../store/authStore';

import useSubscriptionInfo from './useSubscriptionInfo';

const useHandleDemoPendingExpiry = (subscriptionCheckStatus: SubscriptionCheckStatus) => {
	const appContext = useContext(AppContext);
	const { addNotifications, resetNotifications } = useContext(NotificationsContext);
	const [subscriptionInfo] = useSubscriptionInfo();
	const { t } = useTranslation();

	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);

	const triggerDemoExpiryNotifications = () => {
		resetNotifications();
		addNotifications([
			{ timestamp: '', type: NotificationType.alert, content: t('wtr:DEMO_ACCOUNT_EXPIRES_SOON'), action: '' },
		]);
	};

	useEffect(() => {
		if (isLiveMode || !appContext.accountStats.length) {
			return;
		}

		// Has NO Demo acc --> hence it can't expire --> no notification needed ;-)
		if (!checkHasUserDemoAccount(appContext)) {
			return;
		}

		// Has Demo acc but NO Live acc --> Demo acc expiry pending --> notify

		if (!getAnyLiveUserAccount(appContext)) {
			triggerDemoExpiryNotifications();
			return;
		}

		// Has both DEMO acc and LIVE acc --> wait for BE to return subscription status
		if (subscriptionCheckStatus === 'DONE') {
			// Live acc is not ACTIVE --> Demo acc expiry pending --> notify
			if (subscriptionInfo?.status !== SubscriptionStatus.Active) {
				triggerDemoExpiryNotifications();
				return;
			}
		}
	}, [isLiveMode, appContext.accountStats, subscriptionCheckStatus, subscriptionInfo]);
};

export default useHandleDemoPendingExpiry;
