import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import loaderSVG from '../../../images/loader.svg';
import { default as AppContext } from '../../../contexts/AppContext';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../utils/hooks/useObservable';

import styles from './Loading.module.scss';

interface LoadingProps {
	size?: string;
	className?: string;
}

const Loading: React.FC<LoadingProps> = ({ size, className }) => {
	const appContext = useContext(AppContext);
	const forceRerender = useForceRerender();
	const theme = appContext.appTheme;
	const { t } = useTranslation();

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

	return (
		<div className={cn(styles.loader, className)}>
			<img
				className={cn(theme === 'dark' && styles.whiteLoader)}
				src={loaderSVG}
				alt={t('en:LOADING')}
				height={size || '100'}
				width={size || '100'}
			/>
		</div>
	);
};

export default Loading;
