import React, { lazy, useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ERROR from '../../../gateways/errorMessages';
import Routes from '../../../setup/routes';
import useResizableContainersWidth from '../../../utils/hooks/useResizableContainersWidth';
import NewsPopup from '../Markets/NewsWidget/NewsPopup';
import { AuthGuard } from '../../components/AuthGuard/AuthGuard';
import Newsfeed from '../Dashboard/Newsfeed/Newsfeed';
import i18n from '../../../setup/i18n';
import AppContext from '../../../contexts/AppContext';
import WindowContext from '../../../contexts/WindowContext';
import DashboardContext from '../../../contexts/DashboardContext';

import usePromiseFactory from '../../../utils/hooks/usePromiseFactory';
import useObservable from '../../../utils/hooks/useObservable';
import useForceRerender from '../../../utils/hooks/useForceRerender';
import { closeChildWindows } from '../../../utils/functions/closeChildWindows';
import { createWatchlistMap, DEFAULT_FEED_ID } from '../../../utils/functions/WatchlistUtils';
import { TicketLayout } from '../../../utils/functions/enums';
import { TWatchlists } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { MarketItem } from '../../../gateways/RfpGateway/rfp.types';

import useHandleSubscriptionConstraints from '../../../utils/hooks/useHandleSubscriptionConstraints';
import { UserOperationType } from '../../../utils/functions/subscriptionUtils';
import WtrResizable from '../../components/Resizable/WtrResizable';
import { addToastNotification, removeToastNotification } from '../../../utils/functions/toastNotificationUtils';

import orderStore from '../../../store/OrderStore/orderStore';
import authStore from '../../../store/authStore';
import useSaveUserPreferences from '../../../utils/mutations/useSaveUserPreferences';

import TradeBoard from './TradeBoard';
import Watchlist from './Watchlist/Watchlist';
import tradingAccountStore from '../../../store/tradingAccountStore';
import watchlistStore from '../../../store/WatchListStore/watchListStore';
import { isPreviewProduction, isProduction } from '../../../setup/config';

import ClosePositionTicket from './CloseTradeTicket/ClosePositionTicket';
import CancelOrderTicket from './CloseTradeTicket/CancelOrderTicket';
import ConfirmOrderModal from './ChartPanel/NewOrderModals/ConfirmOrderModal/ConfirmOrderModal';
import OrderInformation from './ChartPanel/NewOrderModals/OrderTicketModal/OrderInformation/OrderInformation';
import RightNavigation from './Navigation/RightNavigation/RightNavigation';
import PositionsPanel from './PositionsPanel/PositionsPanel';
import TradeTicket from './TradeTicket';

import lazyRetry from '../../../utils/functions/lazyRetry';
import styles from './Dashboard.module.scss';

import 'moment/locale/de';
import 'moment/locale/ja';
import 'moment/locale/ms-my';
import 'moment/locale/pt-br';
import 'moment/locale/zh-cn';
import 'moment/locale/th';
import 'moment/locale/ar-sa';
import 'moment/locale/vi';
import 'moment/locale/es';
import 'moment/locale/it';
import { getLanguageToUse } from '../../../utils/functions/calculations';
import tradingViewStore from '../../../store/tradingViewStore';

const ChartPanel = lazy(() => lazyRetry(() => import('./ChartPanel/ChartPanel')));

const Dashboard = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const windowContext = useContext(WindowContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const { t } = useTranslation();

	const [windowWidth] = useState<number>(window.innerWidth);

	const tradingMode = authStore.use.tradingMode();
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
	const isOneClickTradingActive = tradingAccountStore.use.isOneClickTradingActive();

	const reset = orderStore.use.reset();

	const languageSettings = appContext.languageSettings;
	const notification = dashboardContext.notification;
	const rfpConnectionError = dashboardContext.rfpConnectionError;
	const closePosition = dashboardContext.modalToggle.closePosition;
	const closeOrder = dashboardContext.modalToggle.closeOrder;
	const confirmOrderTicket = dashboardContext.newOrderModalToggle.confirmOrder;
	const showOrderTicket = dashboardContext.showOrderTicket;
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const showOrderInformation = dashboardContext.showOrderInformation;
	const showTradeNewsfeed = dashboardContext.showNewsFeed;
	const showCloseTicket = dashboardContext.showCloseTicket;
	const showConfirmTicket = dashboardContext.showConfirmTicket;
	const showCancelTicket = dashboardContext.showCancelTicket;
	const tradeInformation = dashboardContext.tradeInformation;
	const selectedNewsContent = dashboardContext.selectedNewsContent;
	const openOrderTicket = dashboardContext.newOrderModalToggle.orderTicket;
	const marketItems = dashboardContext.marketItems;

	// const oneClickTrading = dashboardContext.oneClickTrading;

	const currentWatchList = watchlistStore.use.currentWatchList();
	const { mutate: savePreferences } = useSaveUserPreferences();

	useObservable(appContext.getPropertyChangeStream('languageSettings'), () => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'loadingState',
			'notification',
			'reconnection',
			'rfpConnectionError',
			'inactiveTime',
			'tradeInformation',
			'selectedNewsContent',
			'marketItems',
			'applicationStatus',
			'tradingAccount',
			'accountType',
			'selectedInstrument',
			'startTradingNow',
			'ssoTradingAccount',
			// 'oneClickTrading',
			'showOrderTicket',
			'showOrderInformation',
			'showConfirmTicket',
			'showNewsFeed',
			'showCloseTicket',
			'newOrderModalToggle',
			'modalToggle',
			'showCancelTicket'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'showOrderTicket',
			'showOrderInformation',
			'showConfirmTicket',
			'showCloseTicket',
			'showCancelTicket',
			'showNewsFeed',
			'newOrderModalToggle',
			'modalToggle'
		),
		() => {
			forceRerender();
		}
	);

	window.addEventListener('beforeunload', () => {
		sessionStorage.clear();
		closeChildWindows(windowContext);
	});

	useEffect(() => {
		reset();
	}, []);

	// TODO: Move the language handler to a higher level or hook when rewrite the language related features
	useEffect(() => {
		const localLanguage = localStorage.getItem('language');
		let languageToUse = (languageSettings as string) ?? localLanguage;

		// this has been added as a countermeasure to update the Japanese account preferences language,
		// all of them has English saved in the preferences
		if ((isProduction || isPreviewProduction) && appContext.isJapanAccount && languageToUse !== 'ja') {
			languageToUse = 'ja';
		}

		(async () => {
			await i18n.changeLanguage(languageToUse);

			if (localLanguage && appContext.userPreferences) {
				appContext.userPreferences!.user_prefs.platform.lang._code = languageToUse;
				appContext.languageSettings = languageToUse;
				appContext.isArabic = languageToUse === 'ar-SA';
				savePreferences();
			}
			moment.locale(getLanguageToUse(languageToUse));
		})();
	}, [languageSettings]);

	useEffect(() => {
		if (marketItems.length > 0) {
			// Create a map of marketItems in order to quick filter the instruments
			//TODO use the map in the RFP gateway
			const mapMarketItems = new Map<string, MarketItem>();
			marketItems.forEach((element) => mapMarketItems.set(`${element.feedId}-${element.code}`, element));

			// Filter items which are no longer available in marketItems
			let isChanged = false;
			const filteredWatchlists: TWatchlists[] = [];
			dashboardContext.watchlist.forEach((watchlist) => {
				if (watchlist._feedId === DEFAULT_FEED_ID) {
					watchlist.instrument = watchlist.instrument.filter((element) => {
						const hasItem = mapMarketItems.has(`${watchlist._feedId}-${element._code}`);
						if (!hasItem) {
							isChanged = true;
						}
						return hasItem;
					});
					filteredWatchlists.push(watchlist);
				}
			});

			// Preset new filteredWatchlists
			if (isChanged) {
				dashboardContext.watchlist = filteredWatchlists;
				dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
			}

			if (!dashboardContext.loadingState.tradingAccounts) {
				dashboardContext.loadingState = { ...dashboardContext.loadingState, tradingAccounts: true };
			}
			// Change instrument names to Chinese
			if (languageSettings?.includes('zh-Han')) {
				marketItems.forEach((item) => {
					let instruName = item.fullName.trim().replace(/:|-/gi, '').replace(/\s+/gi, ' ');
					item.fullName = t(`instruments:${instruName}`);
				});
			}
		}
	}, [
		currentWatchList,
		dashboardContext.loadingState,
		dashboardContext.mappedWatchlist,
		dashboardContext.watchlist,
		marketItems,
	]);

	useEffect(() => {
		dashboardContext.accountType = 'cfd';
		if (rfpConnectionError && rfpConnectionError.headers) {
			dashboardContext.notification = {
				isActive: true,
				message: ERROR.RFP_TRADING_ACCOUNT,
				type: 'error',
			};
		}
	}, [rfpConnectionError.headers]);

	const errorNotifRef = useRef<string>('');
	const visibleNotif = useRef<boolean>(false);

	useEffect(() => {
		if (notification.isActive && !visibleNotif.current) {
			visibleNotif.current = true;
			errorNotifRef.current = addToastNotification(
				'fatal',
				'Error connecting to server. Reconnecting...',
				'Server Error'
			);
		} else if (!notification.isActive && visibleNotif.current) {
			removeToastNotification(errorNotifRef.current);
			visibleNotif.current = false;
		}
	}, [notification]);

	// Want to open/modify a position? Not so fast!
	// Is this a Japan account with some sort of constraint?
	const handleSubscriptionsConstraints = useHandleSubscriptionConstraints();
	let isTradingConstrained = false;
	if (isJapanSubscriptionAccount && tradingMode === 'LIVE') {
		if (showOrderTicket || showConfirmTicket || showCloseTicket || showCancelTicket) {
			let userOperation = undefined;
			if (showCloseTicket) {
				userOperation = UserOperationType.CLOSE;
			} else if (showCancelTicket) {
				userOperation = UserOperationType.CANCEL;
			}
			const constraint = handleSubscriptionsConstraints(dashboardContext.selectedInstrument, userOperation);

			if (constraint) {
				isTradingConstrained = true;
				dashboardContext.showOrderTicket = false;
				dashboardContext.showConfirmTicket = false;
				dashboardContext.showCancelTicket = false;
			}
		}
	}

	const hasModal =
		(!isTradingConstrained &&
			ticketLayout === TicketLayout.Dock &&
			(showOrderTicket ||
				showConfirmTicket ||
				showCloseTicket ||
				confirmOrderTicket ||
				closeOrder ||
				showCancelTicket)) ||
		showOrderInformation ||
		showTradeNewsfeed;

	const { resizableContainersWidthRestriction } = useResizableContainersWidth(hasModal);

	return (
		<>
			<div className={cn(styles.container, hasModal && styles.hasModal)}>
				<WtrResizable
					panelName="watchlist"
					maxWidth={resizableContainersWidthRestriction('watchlist')}
					enableResizing={windowWidth > 1240}
				>
					<div id="watchlistPanel" className={styles.leftPanel}>
						<div className={styles.watchlistPanel} data-testid="dashboard">
							{isOneClickTradingActive ? <TradeBoard /> : <Watchlist />}
							<Switch>
								<AuthGuard
									exact
									isValidSession={appContext.isLoggedIn}
									path={Routes.trader.charts}
									component={ChartPanel}
								/>
							</Switch>
						</div>
					</div>
				</WtrResizable>

				<div className={styles.rightPanel}>
					<div className={styles.rightTopPanel}>
						<div className={cn(styles.chartPanel, styles.panelBackground)}>
							<ChartPanel />
						</div>

						{hasModal && (
							<WtrResizable
								panelName="tradeTicket"
								enableResizing={windowWidth > 1240}
								maxWidth={resizableContainersWidthRestriction('tradeTicket')}
							>
								<div className={styles.ticket}>
									<div className={cn(styles.ticket, hasModal ? styles.showTicket : styles.ticketPanel)}>
										{showOrderTicket && <TradeTicket />}
										{showOrderInformation && <OrderInformation />}
										{showTradeNewsfeed && <Newsfeed />}
										{selectedNewsContent !== -1 && <NewsPopup />}
										{showConfirmTicket && <ConfirmOrderModal tradeRequest={tradeInformation || ({} as any)} />}
										{showCloseTicket && <ClosePositionTicket />}
										{(showCancelTicket || closeOrder) && <CancelOrderTicket />}
									</div>
								</div>
							</WtrResizable>
						)}
						<div className={cn(styles.rightNav, styles.panelBackground)}>
							<RightNavigation />
						</div>
					</div>

					<WtrResizable panelName="positionsGrid">
						<div className={cn(styles.ordersPanel, styles.panelBackground)}>
							<PositionsPanel />
						</div>
					</WtrResizable>
				</div>
			</div>

			{ticketLayout === TicketLayout.Undock && openOrderTicket && <TradeTicket />}
			{ticketLayout === TicketLayout.Undock && confirmOrderTicket && (
				<ConfirmOrderModal tradeRequest={tradeInformation || ({} as any)} />
			)}
			{ticketLayout === TicketLayout.Undock && closeOrder && <CancelOrderTicket />}
			{ticketLayout === TicketLayout.Undock && closePosition && <ClosePositionTicket />}
		</>
	);
};

export default React.memo(Dashboard);
