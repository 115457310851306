import {create} from './create';

import createSelectors from './createSelectors';

export interface StatusStoreValues {
    checkApplicationStatus: any;
    userProfileInfo: any;
    checkAccountStatus: any;
    infoMessage: string;
    showModal: boolean;
    status: string | undefined;

    hasLiveAccount: boolean | undefined;
    hasDemoAccount: boolean | undefined;
    hasAnyAccount: boolean | undefined;

    permissions: {
        accountMenuAction: string | boolean | undefined;
        accountMenuLabel: string | undefined;
        marginLevelAction: string | boolean | undefined;
        marginLevelLabel: string | undefined;
        signalsAction: string | boolean | undefined;
        tradeButtons: string | boolean | undefined;
        mainMenuAction: string | boolean | undefined;
        reports: string | boolean | undefined;
        addAccount: string | boolean | undefined;
        manageFunds: string | boolean | undefined;
        tradersGym: boolean | undefined;
        portalPage: string | boolean | undefined;
        notificationBell: boolean | undefined;
    };
    canTrade: boolean;
    tradingAccount: any;
    isTraderGymActive: boolean;

    unlockGym: boolean;
}

export const initialStateAccountStatusStore: StatusStoreValues = {
    checkApplicationStatus: {},
    userProfileInfo: {},
    checkAccountStatus: {},
    infoMessage: '',
    showModal: false,
    status: 'EMPTY',
    hasLiveAccount: undefined,
    hasDemoAccount: undefined,
    hasAnyAccount: undefined,
    permissions: {
        accountMenuAction: undefined,
        accountMenuLabel: undefined,
        marginLevelAction: undefined,
        marginLevelLabel: undefined,
        signalsAction: undefined,
        tradeButtons: undefined,
        mainMenuAction: undefined,
        reports: undefined,
        addAccount: undefined,
        manageFunds: undefined,
        tradersGym: undefined,
        portalPage: undefined,
        notificationBell: undefined,
    },
    canTrade: false,
    tradingAccount: {},
    isTraderGymActive: false,
    unlockGym: false,
};

export interface StatusStoreActions {
    setHasLiveAccount: (hasLiveAccount: boolean) => void;
    setHasDemoAccount: (hasDemoAccount: boolean) => void;
    setCheckApplicationStatus: (response: any) => void;
    setStatus: (newStatus: string) => void;
    setUserProfileInfo: (user: string) => void;
    setCheckAccountStatus: (response: any) => void;

    setInfoMessage: (infoMessageToChange: string) => void;
    setPermissions: (permission: any) => void;
    setShowModal: (toShowModal: boolean) => void;
    setTradingAccount: (tradingAccount: any) => void;
    setIsTraderGymActive: (isActive: boolean) => void;
    setCanTrade: (canTrade: boolean) => void;
    setShowAccountStatus: () => void;
    setUnlockGym: (unlockGym: boolean) => void;
    reset: () => void;
}

export type StatusStore = StatusStoreValues & StatusStoreActions;

const accountStatusStore = create<StatusStore>()((set: any) => ({
    ...initialStateAccountStatusStore,

    setCheckApplicationStatus: (response) =>
        set(() => ({
            checkApplicationStatus: response,
        })),
    setStatus: (newStatus) => set(() => ({status: newStatus ? newStatus : 'EMPTY'})),
    setIsTraderGymActive: (isActive) => set(() => ({isTraderGymActive: isActive})),
    setUserProfileInfo: (user) => set(() => ({userProfileInfo: user})),
    setCheckAccountStatus: (response) => {
        let hasLiveAccount = false;
        let hasAnyAccount = false;
        let hasDemoAccount = false;

        if (response.length > 0) {
            hasAnyAccount = true;
        }
        if (response.length > 0) {
            response.forEach((account: any) => {
                set(() => ({tradingAccount: account}));
                if (account.account.type === 'LIVE') {
                    hasLiveAccount = true;
                }
                if (account.account.type === 'DEMO') {
                    hasDemoAccount = true;
                }
                if (hasLiveAccount && hasDemoAccount) return;
            });
        }
        set(() => ({
            checkAccountStatus: response,
            hasLiveAccount,
            hasDemoAccount,
            hasAnyAccount,
        }));
    },

    setHasLiveAccount: (hasLiveAccount: boolean) => set({hasLiveAccount}),
    setHasDemoAccount: (hasDemoAccount: boolean) => set({hasDemoAccount}),
    setInfoMessage: (infoMessageToChange) => set(() => ({infoMessage: infoMessageToChange})),
    setPermissions: (permission) => set(() => ({permissions: permission})),
    setShowModal: (toShowModal) => set(() => ({showModal: toShowModal})),
    setTradingAccount: (tradingAccount) => set(() => ({tradingAccount: tradingAccount})),
    setShowAccountStatus: () =>
        set((state: StatusStore) => ({
            showModal: true,
            infoMessage: state.status,
        })),
    setCanTrade: (canTrade) =>
        set(() => ({
            canTrade: canTrade,
        })),

    setUnlockGym: (unlockGym: boolean) => set({unlockGym}),
    reset: () => set({...initialStateAccountStatusStore}),
}));

export default createSelectors(accountStatusStore);
