import { useContext } from 'react';

import DashboardContext from '../../contexts/DashboardContext';
import { MarketItem } from '../../gateways/RfpGateway/rfp.types';
import { Optional } from '../functions/Nullable';
import { getSubscriptionConstraints, UserOperationType } from '../functions/subscriptionUtils';

import authStore, { AuthStore } from '../../store/authStore';

import useSubscriptionInfo from './useSubscriptionInfo';

const useSubscriptionConstraints = () => {
	const dashboardContext = useContext(DashboardContext);
	const [subscriptionInfo] = useSubscriptionInfo();
	const isDemoMode = authStore((store: AuthStore) => store.isDemoMode);

	const getConstraints = (marketItem?: Optional<MarketItem>, operation?: UserOperationType) => {
		if (isDemoMode) return null;
		return getSubscriptionConstraints(
			subscriptionInfo || null,
			marketItem?.code || null,
			dashboardContext.marketItems,
			operation
		);
	};

	return getConstraints;
};

export default useSubscriptionConstraints;
