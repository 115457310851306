import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

type IconConfig = [IconPrefix, IconName];

const line: IconConfig = ['fab', 'line'];
const telegram: IconConfig = ['fab', 'telegram'];
const twitter: IconConfig = ['fab', 'twitter'];

const seaConfig = {
	key: 'dbdb970c-5b15-4c23-97f1-abf5bb0f197e',
	locale: 'zh-cn',
	channels: [
		{
			href: 'https://page.line.me/?accountId=thinkmarkets',
			icon: line,
		},
		{ href: 'http://t.me/ThinkMarkets_SEA_bot', icon: telegram },
		{ href: 'https://twitter.com/messages/compose?recipient_id=1602552057738989568', icon: twitter },
	],
};

const CONFIG: {
	[key: string]: { key: string; locale: string; channels: Array<{ href: string; icon: IconConfig }> };
} = {
	default: {
		key: 'f0585f41-3237-4e8e-aaed-96c64eee884c',
		locale: 'en',
		channels: [
			{ href: 'https://page.line.me/?accountId=786azhee', icon: line },
			{ href: 'http://t.me/ThinkMarkets_Global_bot', icon: telegram },
			{ href: 'https://twitter.com/messages/compose?recipient_id=136399657', icon: twitter },
		],
	},
	'zh-Hans': { ...seaConfig, locale: 'zh-cn' },
	'zh-Hant': { ...seaConfig, locale: 'zh-tw' },
	ja: {
		key: '45bab9a6-ee0c-4db3-85bb-0e71fc8d4024',
		locale: 'ja',
		channels: [
			{
				href: 'https://page.line.me/?accountId=039wycms',
				icon: line,
			},
			{ href: 'http://t.me/ThinkMarkets_Japan_bot', icon: telegram },
			{ href: 'https://twitter.com/messages/compose?recipient_id=1544602193185058816', icon: twitter },
		],
	},
};

export default (locale: string) => {
	return CONFIG[locale] || { ...CONFIG.default, locale };
};
