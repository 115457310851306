import React, { useState, useEffect, useContext } from 'react';

import FormattedNumberInput from '../FormattedNumberInput';

import { QuantityType } from '../../../../../../utils/functions/enums';
import { convertAmountToLot, convertLotToAmount } from '../../../../../../utils/functions/calculations';
import { MarketItem } from '../../../../../../gateways/RfpGateway/rfp.types';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import orderStore from '../../../../../../store/OrderStore/orderStore';

interface LotsAwareInputProps {
	value: number;
	onChange: (value: number) => void;
	roundLot?: number;
	marketItem?: MarketItem;
	disabled?: boolean;
	isAllowed?: (value: number) => boolean;
}

const LotsAwareInput = ({ value, roundLot, disabled, onChange, isAllowed }: LotsAwareInputProps) => {
	const dashboard = useContext(DashboardContext);
	const quantityType = dashboard.quantityType === 'Amount' ? QuantityType.Amount : QuantityType.Lots;
	const [formattedValue, setFormattedValue] = useState<number>(value);
	const [prevValue, setPrevValue] = useState<number | undefined>(undefined);
	const [prevQuantityType, setQuantityType] = useState<number | undefined>(undefined);
	const lot = orderStore((state) => state.current.amountInfo?.rndLot);
	const rndLot = lot ?? roundLot;

	useEffect(() => {
		if (prevValue !== value || prevQuantityType !== quantityType || rndLot !== undefined) {
			formatValue();
			setPrevValue(value);
			setQuantityType(quantityType);
		}
	}, [value, quantityType, rndLot]);

	const formatValue = () => {
		if (quantityType && rndLot) {
			if (quantityType === QuantityType.Lots) {
				const lotValue = convertAmountToLot(value, rndLot);
				setFormattedValue(lotValue);
			} else {
				setFormattedValue(value);
			}
		}
	};

	const handleChange = (value: number) => {
		if (quantityType && rndLot) {
			if (quantityType === QuantityType.Lots && rndLot) {
				const amountValue = convertLotToAmount(value, rndLot);
				onChange(amountValue);
			} else {
				onChange(value);
			}
		}
	};

	return (
		<FormattedNumberInput value={formattedValue} disabled={disabled} onChange={handleChange} isAllowed={isAllowed} />
	);
};

export default LotsAwareInput;
