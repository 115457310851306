import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAccountIconOptions } from '../../../Settings/settingsOptions';

import authStore, { UserPhoneNumbersProps, UserProfile } from '../../../../../../store/authStore';

import AppContext from '../../../../../../contexts/AppContext';

import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';

import { useFillContextsFromPreferences } from '../../../../../../utils/hooks/preferences/useFillContextsFromPreferences';

import PhoneCodeDropdown from './PhoneCodeDropdown/PhoneCodeDropdown';

import AvatarIcons from './AvatarIcons';

import styles from './SettingsModule.module.scss';
import useUpdateUserProfile from '../../../../../../utils/queries/useUpdateUserProfile';
import useUserProfile from '../../../../../../utils/queries/useUserProfile';
import { Resolver } from '../../../../../../utils/functions/Ioc';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';
import RfpGateway from '../../../../../../gateways/RfpGateway/RfpGateway';
import { RFP } from '../../../../../../gateways/RfpGateway/rfpConstants';
import useGetPhoneCodesAndFlags from '../../../../../../utils/mutations/useGetPhoneCodesAndFlags';

interface platformSettingsProps {
	settings: any;
	setOpenSettings: (v: boolean) => void;
	handleCloseModal: (v: boolean) => void;
}

enum HandleChangeTypes {
	AccountAvatar = 'accountAvatar',
	PhoneNumber = 'phoneNumber',
	SecondPhoneNumber = 'SecondPhoneNumber',
}

interface ChangePhoneState extends Partial<WithdrawCryptoState> {
	phone: string;
	phoneCountryCode: number;
	mobile: string;
	mobileCountryCode: number;
	page?: string;
}

type WithdrawCryptoState = {
	query: string;
	amount: string;
	cryptoId: number;
	processorName: string;
	address: string;
};

const ProfileSettings: React.FC<platformSettingsProps> = ({ settings, setOpenSettings, handleCloseModal }) => {
	const { t } = useTranslation();
	const userProfile = authStore.use.userProfile();
	// const setPhoneNumbers = authStore.use.setPhoneNumbers();
	// const userPhoneNumbers = authStore.use.userPhoneNumbers();
	const dropdownOptionsRef = useRef<HTMLDivElement>(null);
	const [openImgModal, setOpenImgModal] = useState<boolean>(false);
	const [imgModal, setImgModal] = useState<number>(0);
	const setAccountAvatar = authStore.use.setAccountAvatar();
	const accountAvatar = authStore.use.accountAvatar();
	const setUserProfile = authStore.use.setUserProfile();
	const isDemoMode = authStore.use.isDemoMode();
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [changeInPhone, setChangeInPhone] = useState<boolean>(false);
	const [changeInPhoneSecond, setChangeInPhoneSecond] = useState<boolean>(false);
	const [isLoadingSaveData, setIsLoadingSaveData] = useState<boolean>(false);
	const restOfTheWorldCode = 'XX';
	const rfpGatewayConnection = Resolver.resolve(RfpGateway);
	const appContext = useContext(AppContext);
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const avatarFromPrefs = appContext.userPreferences!.user_prefs.platform.accountAvatar;

	// const [phoneNumber, setPhoneNumber] = useState<string>('');
	// const [phoneCode, setPhoneCode] = useState<string>('');
	// const [phoneNumberSecond, setPhoneNumberSecond] = useState<string>('');
	// const [phoneCodeSecond, setPhoneCodeSecond] = useState<string>('');

	// const { mutate: updateProfile } = useUpdateUserProfile();
	const { mutate: savePreferences } = useSaveUserPreferences();
	const {
		data,
		isLoading: isLoadingFlagsAndCodes,
		isError,
		mutate: fetchPhoneCodesAndFlags,
	} = useGetPhoneCodesAndFlags();

	// const handleSavePhoneCode = (phoneCodeType: string, phoneCode: string) => {
	// 	// Create a new object based on the current userPhoneNumbers state
	// 	const updatedPhoneNumbers: UserPhoneNumbersProps = {
	// 		...userPhoneNumbers,
	// 		phoneCountryCode: phoneCodeType === 'phoneCode' ? phoneCode : userPhoneNumbers.phoneCountryCode,
	// 		mobileCountryCode: phoneCodeType === 'phoneCodeSecond' ? phoneCode : userPhoneNumbers.mobileCountryCode,
	// 	};

	// 	setPhoneNumbers(updatedPhoneNumbers);

	// 	handleUploadPhoneNumber(updatedPhoneNumbers, true);
	// };

	// useEffect(() => {
	// 	setPhoneNumber(userPhoneNumbers.phone || userProfile?.phone?.phone || '');
	// 	setPhoneCode(userPhoneNumbers.phoneCountryCode || userProfile?.phone?.phoneCountryCode || '');
	// 	setPhoneNumberSecond(userPhoneNumbers.mobile || userProfile?.phone?.mobile || '');
	// 	setPhoneCodeSecond(userPhoneNumbers.mobileCountryCode || userProfile?.phone?.mobileCountryCode || '');
	// }, [userPhoneNumbers, userProfile.phone]);

	useEffect(() => {
		fetchPhoneCodesAndFlags();
	}, [fetchPhoneCodesAndFlags]);

	const handleUpdateSettings = async (type: string, value: any) => {
		if (type === 'accountAvatar') {
			setImgModal(value);
			setAccountAvatar(value);
			setOpenImgModal(false);

			appContext.userPreferences!.user_prefs.platform.accountAvatar = value;

			await savePreferences();
			fillContextsFromPreferences(appContext.userPreferences!);
		}

		if (type === HandleChangeTypes.PhoneNumber) {
			const phoneNumberState: ChangePhoneState = {
				phone: value.phone.number || '',
				phoneCountryCode: value.phone.code || 0,
				mobile: value.mobile.number || '',
				mobileCountryCode: value.mobile.code || 0,
			};
		}
	};

	const onClickOpenImgModal = () => {
		setOpenImgModal(!openImgModal);
	};

	const findImage = () => {
		const currentImage = getAccountIconOptions().find((image) => image.value === accountAvatar);

		if (currentImage) {
			return currentImage.image.src;
		}

		return getAccountIconOptions()[0].image.src;
	};

	// const handleUploadPhoneNumber = (updatedNumbers: any, phoneCodes?: boolean, dropdownId?: string) => {
	// 	setIsLoadingSaveData(true);

	// 	let phone = {};
	// 	if (phoneCodes) {
	// 		phone = {
	// 			phone: updatedNumbers.phone !== '' ? updatedNumbers.phone : phoneNumber,
	// 			phoneCountryCode: updatedNumbers.phoneCountryCode !== '' ? updatedNumbers.phoneCountryCode : phoneCode,
	// 			mobile: updatedNumbers.mobile !== '' ? updatedNumbers.mobile : phoneNumberSecond,
	// 			mobileCountryCode: updatedNumbers.mobileCountryCode !== '' ? updatedNumbers.mobileCountryCode : phoneCodeSecond,
	// 		};
	// 	} else {
	// 		phone = {
	// 			phone: phoneNumber,
	// 			phoneCountryCode: phoneCode,
	// 			mobile: phoneNumberSecond,
	// 			mobileCountryCode: phoneCodeSecond,
	// 		};
	// 	}

	// 	console.log('phone', phone);

	// 	updateProfile(
	// 		{
	// 			id: userProfile.id,
	// 			firstName: userProfile.firstName,
	// 			lastName: userProfile.lastName,
	// 			fullName: userProfile.fullName,
	// 			email: userProfile.email,
	// 			cif: userProfile.cif,
	// 			title: userProfile.title,
	// 			brand: userProfile.brand,
	// 			dateOfBirth: userProfile.dateOfBirth,
	// 			employmentStatus: userProfile.employmentStatus,
	// 			country: {
	// 				id: userProfile.country.id,
	// 				name: userProfile.name,
	// 				code2: userProfile.country.code2,
	// 				code3: userProfile.countrycode3,
	// 				phoneCode: phoneCode,
	// 				organization: {
	// 					id: userProfile.organization.id,
	// 					name: userProfile.organization.name,
	// 					guid: userProfile.organization.guid,
	// 					defaultLeverage: userProfile.organization.defaultLeverage,
	// 				},
	// 				legacyOrganization: {
	// 					id: userProfile.country.legacyOrganization.id,
	// 					name: userProfile.country.legacyOrganization.name,
	// 					guid: userProfile.country.legacyOrganization.guid,
	// 					defaultLeverage: userProfile.country.legacyOrganization.defaultLeverage,
	// 				},
	// 				cTraderCountryId: userProfile.country.cTraderCountryId,
	// 				used: userProfile.country.used,
	// 				european: userProfile.country.european,
	// 				isRequireEnglishNames: userProfile.country.isRequireEnglishNames,
	// 			},

	// 			address: userProfile.address,
	// 			phone: {
	// 				...phone,
	// 			},
	// 			classification: userProfile.classification,
	// 			partnerStatus: userProfile.partnerStatus,
	// 			thinkTraderEULA: userProfile.thinkTraderEULA,
	// 			approved: userProfile.approved,
	// 			idNumber: userProfile.idNumber,
	// 			nationality: {
	// 				id: userProfile.nationality.id,
	// 				name: userProfile.nationality.name,
	// 				code2: userProfile.nationality.code2,
	// 				code3: userProfile.nationality.code3,
	// 				phoneCode: phoneCode,
	// 				organization: {
	// 					id: userProfile.nationality.organization.id,
	// 					name: userProfile.nationality.organization.name,
	// 					guid: userProfile.nationality.organization.guid,
	// 					defaultLeverage: userProfile.nationality.organization.defaultLeverage,
	// 				},
	// 			},

	// 			visitorId: userProfile.visitorId,
	// 			maxDemoAccounts: userProfile.maxDemoAccounts,
	// 			twoFAenabled: userProfile.twoFAenabled,
	// 			additionalAttributes: userProfile.additionalAttributes,
	// 			additionalTaxInfo: userProfile.additionalTaxInfo,
	// 			phoneVerified: userProfile.phoneVerified,
	// 			isADemoAccount: isDemoMode,
	// 		},
	// 		{
	// 			onSuccess: async (data: any) => {
	// 				setIsLoadingSaveData(false);

	// 				if (data.data?.payload[0].status !== 'OK') {
	// 					setErrorMessage(true);
	// 				} else {
	// 					setChangeInPhone(false);
	// 					setChangeInPhoneSecond(false);
	// 					if (phoneCodes) {
	// 						setPhoneNumbers({
	// 							phone: updatedNumbers.phone !== '' ? updatedNumbers.phone : phoneNumber,
	// 							phoneCountryCode: updatedNumbers.phoneCountryCode !== '' ? updatedNumbers.phoneCountryCode : phoneCode,
	// 							mobile: updatedNumbers.mobile !== '' ? updatedNumbers.mobile : phoneNumberSecond,
	// 							mobileCountryCode:
	// 								updatedNumbers.mobileCountryCode !== '' ? updatedNumbers.mobileCountryCode : phoneCodeSecond,
	// 						});
	// 					} else {
	// 						setPhoneNumbers({
	// 							phone: phoneNumber,
	// 							phoneCountryCode: phoneCode,
	// 							mobile: phoneNumberSecond,
	// 							mobileCountryCode: phoneCodeSecond,
	// 						});
	// 					}
	// 				}
	// 			},
	// 			onError: (err) => {
	// 				setIsLoadingSaveData(false);

	// 				setErrorMessage(true);
	// 			},
	// 		}
	// 	);
	// };

	// const handleInputChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setErrorMessage(false);
	// 	setChangeInPhone(true);
	// 	let { value } = event.target;
	// 	value = value.replace(/\D/g, '');
	// 	setPhoneNumber(value.toString());
	// };

	// const handleInputChangePhoneNumberSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setErrorMessage(false);
	// 	setChangeInPhoneSecond(true);
	// 	let { value } = event.target;
	// 	value = value.replace(/\D/g, '');
	// 	setPhoneNumberSecond(value.toString());
	// };

	return (
		<div className={styles.platformTab}>
			<div className={styles.closeIcon} onClick={handleCloseModal}>
				<FontAwesomeIcon icon={['fal', 'xmark-large']} className={styles.icon} />
			</div>
			<div className={styles.profileSetingsHeader}>
				<div className={styles.platformTabName}>{t('en:PERSONAL_DETAILS')}</div>
			</div>
			<div className={styles.profileSetingsContent}>
				<div className={styles.settingsPairs}>
					<div className={styles.settingsPairsName}>{t('wtr:SETTINGS_PROFILE_PICTURE')}</div>
					<div
						className={cn(styles.settingsPairsImgDropdown, styles.justifyContentFlexEnd)}
						onClick={onClickOpenImgModal}
					>
						<div className={styles.dropdownIconWrapper}>
							{avatarFromPrefs ? (
								<AvatarIcons avatarPath={findImage()} />
							) : (
								<FontAwesomeIcon
									className={cn(styles['large'], styles.defaultImage, styles.dropdownDefaultImage)}
									icon={['fal', 'user']}
								/>
							)}
						</div>
						<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />

						{openImgModal && (
							<div className={styles.imgContainer} ref={dropdownOptionsRef}>
								{getAccountIconOptions().map((icon, index) => {
									return (
										<div key={index}>
											{
												<img
													alt=""
													src={icon.image.src}
													onClick={() => {
														handleUpdateSettings(HandleChangeTypes.AccountAvatar, icon.value);
													}}
												/>
											}
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
				<div className={styles.settingsPairs}>
					<div className={styles.settingsPairsName}>{t('en:NAME')}</div>
					<div className={styles.settingsPairsMeta}>{userProfile.fullName}</div>
				</div>
				<div className={styles.settingsPairs}>
					<div className={styles.settingsPairsName}>{t('en:Email')}</div>
					<div className={styles.settingsPairsMeta}>{userProfile.email}</div>
				</div>
				{/* <div
					className={cn(
						errorMessage && changeInPhone ? [styles.settingsPairs, styles.settingsPairsError] : styles.settingsPairs
					)}
				>
					<div className={styles.settingsPairsName}>{t('en:PHONE')}</div>
					{userProfile?.phone && (
						<div>
							<div className={cn(styles.settingsPairsPhone, styles.phoneNumberAndCode)}>
								<PhoneCodeDropdown
									phoneCodeType={'phoneCode'}
									handleSavePhoneCode={handleSavePhoneCode}
									code={phoneCode || userProfile.phone.phoneCountryCode}
									data={data}
									isLoadingFlagsAndCodes={isLoadingFlagsAndCodes}
									isLoadingSaveData={isLoadingSaveData && changeInPhone}
									isError={isError}
								/>
								<div className={styles.settingsPairsPhoneNumber}>
									<input
										id="number"
										type="text"
										value={phoneNumber}
										name="phone"
										onChange={handleInputChangePhoneNumber}
										onBlur={handleUploadPhoneNumber}
										inputMode="numeric"
										pattern="[0-9]*"
									/>
								</div>
							</div>
							{errorMessage && changeInPhone && (
								<div className={styles.errorMessagePhone}>{t('en:SETTINGS_INVALID_PHONE_NUMBER')}</div>
							)}
						</div>
					)}
				</div> */}
				{/* <div
					className={cn(
						errorMessage && changeInPhoneSecond
							? [styles.settingsPairs, styles.settingsPairsError]
							: styles.settingsPairs
					)}
				>
					<div className={styles.settingsPairsName}>{t('en:PHONE')} 2</div>
					{userProfile?.phone && (
						<div>
							<div className={cn(styles.settingsPairsPhone, styles.phoneNumberAndCode)}>
								<PhoneCodeDropdown
									phoneCodeType={'phoneCodeSecond'}
									handleSavePhoneCode={handleSavePhoneCode}
									code={phoneCodeSecond || userProfile.phone.phoneCountryCode}
									data={data}
									isLoadingFlagsAndCodes={isLoadingFlagsAndCodes}
									isLoadingSaveData={isLoadingSaveData && changeInPhoneSecond}
									isError={isError}
								/>
								<div className={styles.settingsPairsPhoneNumber}>
									<input
										id="number2"
										name="phone2"
										value={phoneNumberSecond}
										onChange={handleInputChangePhoneNumberSecond}
										onBlur={handleUploadPhoneNumber}
										inputMode="numeric"
										pattern="[0-9]*"
									/>
								</div>
							</div>
							{errorMessage && changeInPhoneSecond && (
								<div className={styles.errorMessagePhone}>{t('en:SETTINGS_INVALID_PHONE_NUMBER')}</div>
							)}
						</div>
					)}
				</div> */}
			</div>
		</div>
	);
};

export default ProfileSettings;
