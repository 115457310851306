import React, { useContext, useMemo } from 'react';

import styles from './SearchResult.module.scss';
import { MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from '../../../../../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import InstrumentIcons from '../../../../../shared/InstrumentIcons/InstrumentIcons';
import InstrumentIcon from '../../../../components/GroupBadge/InstrumentIcon';

type TSearchResultProps = {
	result: MarketItem;
	existsInWatchlist: boolean;
	onUpdateWatchlist: any;
	searchTerm: string;
	watchlists: string[];
};

const SearchResult = React.memo(
	({ result, existsInWatchlist, onUpdateWatchlist, searchTerm, watchlists }: TSearchResultProps) => {
		const { t } = useTranslation();
		const getTranslatedWLName = useGetTranslatedWLName();

		watchlists.forEach((watchlistName, index, thisArray) => {
			thisArray[index] = getTranslatedWLName(watchlistName);
		});

		// //Translate Popular Markets
		// useEffect(() => {
		// 	watchlists.forEach((watchlistName, index, thisArray) => {
		// 		thisArray[index] = getTranslatedWLName(watchlistName);
		// 	});
		// }, [watchlists]);

		const resultContainer = useMemo(() => {
			const renderMatchingName = () => {
				const upperName = result.fullName.toUpperCase();
				const searchString = searchTerm.toUpperCase();
				let match = <span className={styles.resultName}>{result.fullName}</span>;

				if (upperName.includes(searchString)) {
					const startIndex = upperName.indexOf(searchString);
					const endIndex = startIndex + searchString.length;
					const highlighted = (
						<span className={styles.matchName}>{result.fullName.substring(startIndex, endIndex)}</span>
					);
					match = (
						<span className={styles.resultName}>
							{result.fullName.substring(0, startIndex)}
							{highlighted}
							{result.fullName.substring(endIndex, result.fullName.length)}
						</span>
					);
				}
				return match;
			};

			const renderMatchingCode = () => {
				const code = result.exchangeTicker ? result.exchangeTicker : result.code;
				const searchString = searchTerm.toUpperCase();
				let match = <span className={styles.code}>{code}</span>;
				if (code.includes(searchString)) {
					const unhighlighted = code.split(searchString);
					const highlighted = <span className={styles.matchText}>{searchString}</span>;
					match = (
						<span className={styles.code}>
							{unhighlighted[0]}
							{highlighted}
							{unhighlighted[1]}
						</span>
					);
				}

				return match;
			};

			return (
				<div className={styles.leftContainer}>
					<InstrumentIcon marketItem={result} />
					<div className={styles.textGroup}>
						{renderMatchingCode()}
						{renderMatchingName()}
					</div>
				</div>
			);
		}, []);

		return (
			<div key={result.code} className={styles.resultItem} onClick={onUpdateWatchlist}>
				<div className={styles.resultItemContainer}>
					{result.fullName.length >= 45 ? (
						<OverlayTrigger
							key={`${result.fullName}tip`}
							delay={{ show: 750, hide: 0 }}
							placement="bottom"
							overlay={
								<Tooltip className="my-tooltip" id={`${result.fullName}tip`} style={{ zIndex: '9999' }}>
									{result.fullName}
								</Tooltip>
							}
						>
							{resultContainer}
						</OverlayTrigger>
					) : (
						resultContainer
					)}
					<OverlayTrigger
						key={`searchResult${result.code}`}
						delay={{ show: 750, hide: 0 }}
						placement="left"
						overlay={
							<Tooltip className="my-tooltip" style={{ zIndex: 999999999 }} id={`searchResult${result.code}`}>
								{existsInWatchlist ? (
									<div>
										{t('wtr:EXISTS_IN')} {'"' + watchlists.join(' ", " ') + '"'}
									</div>
								) : (
									<div
										dangerouslySetInnerHTML={{
											__html: t('wtr:ADD_TO_WATCHLIST', {
												instrument: result.exchangeTicker ? result.exchangeTicker : result.code,
											}),
										}}
									></div>
								)}
							</Tooltip>
						}
					>
						<div>
							<FontAwesomeIcon
								icon={existsInWatchlist ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
								className={existsInWatchlist ? styles.existsIcon : styles.addToWatchlistIcon}
							/>
						</div>
					</OverlayTrigger>
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		const keys: Array<keyof typeof prevProps> = ['result', 'existsInWatchlist', 'searchTerm'];
		return keys.every((key) => prevProps[key] == nextProps[key]);
	}
);

export default SearchResult;
