import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import DashboardContext from '../../../../../../contexts/DashboardContext';
import useObservable from '../../../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../../../utils/hooks/useForceRerender';

import { Item } from './';

const RightNavNews = () => {
	const { t } = useTranslation();
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const dashboardContext = useContext(DashboardContext);

	const { showNewsFeed } = dashboardContext;

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'showOrderTicket',
			'showOrderInformation',
			'showNewsFeed',
			'showCloseTicket',
			'showCancelTicket'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	const handleClickNews = () => {
		dashboardContext.closeAllOtherTabs();
		dashboardContext.showNewsFeed = !showNewsFeed;
	};

	return (
		<Item
			onClick={handleClickNews}
			tooltipText={t('en:NEWS')}
			selected={dashboardContext.showNewsFeed}
			icon={[showNewsFeed ? 'far' : 'fal', 'newspaper']}
		/>
	);
};

export default RightNavNews;
