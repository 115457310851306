import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

const useApplicationStatus = (options: UseQueryOptions) => {
	options.queryKey = ['applicationStatus'];

	options.queryFn = async () => {
		try {
			const applicationStatus: 'CFD' = 'CFD';
			const response = await axios({
				url: '',
				data: {
					payload: [{ module: 'application', action: 'check_application_status', parameters: { applicationStatus } }],
				},
			});
			return response.data.payload[0].result;
		} catch (e) {}
	};

	return useQuery(options);
};

export default useApplicationStatus;
