import { SubscribeBarsCallback } from '../../../charting_library';
import ChartUtils from '../../../chartiq/GridViewCharts/ChartUtils';
import { HistoryTick, TimeScale } from '../../../gateways/RfpGateway/rfp.types';

import { GymHistoryRequest } from '../HistoryData/GymHistoryRequest';
import { GymSimulation } from '../Simulations/GymSimulation';

export enum DataItemPlayerStatus {
	Unknown = 0,
	Playing = 1,
	Pause = 2,
	Buffering = 3,
}

export class PlayerDataItem {
	readonly TP_PLAYER_EMPTY_INDEX = -1;
	readonly TP_PLAYER_MAX_SPEED = 0.25;

	onTick: SubscribeBarsCallback = () => {};

	playerCurrentIndex: number = this.TP_PLAYER_EMPTY_INDEX;
	waitingData: boolean = false;

	/*
	 * Player History Request
	 * By default is undefine, if player timeInterval != chart timeInterval then must be created separate player request
	 */
	playerRequest: GymHistoryRequest | undefined;

	didCalledInitialRequest: boolean = false;

	/*
	 * Player History Data Items
	 */
	private _playerDataItems: HistoryTick[] | undefined = undefined;

	waitingState = DataItemPlayerStatus.Unknown;

	public get playerDataItems(): HistoryTick[] | undefined {
		return this._playerDataItems;
	}

	public set playerDataItems(value: HistoryTick[] | undefined) {
		// console.debug(`set playerDataItems=`, value?.length);
		this._playerDataItems = value;
		this.playerCurrentIndex = this.TP_PLAYER_EMPTY_INDEX;
	}

	hasDataItems() {
		return this.playerItemsCount > 0;
	}

	public get playerItemsCount(): number {
		return this.playerDataItems?.length ?? 0;
	}
}

export class GymDataItemPlayer {
	private static instance: GymDataItemPlayer;

	/**
	 * The GymDataItemPlayer's constructor should always be private to prevent direct
	 * construction calls with the `new` operator.
	 */
	private constructor() {}

	/**
	 * The static method that controls the access to the singleton instance.
	 */
	public static sharedInstance(): GymDataItemPlayer {
		if (!GymDataItemPlayer.instance) {
			GymDataItemPlayer.instance = new GymDataItemPlayer();
		}
		return GymDataItemPlayer.instance;
	}

	// Events
	playerTimeScaleChanged?(sender: GymDataItemPlayer, timeScale: TimeScale): void;
	playerCurrentIndexChanged?(sender: GymDataItemPlayer, index: number): void;
	playerCurrentIndexChangedForControls?(sender: GymDataItemPlayer, index: number): void;
	playerStatusChanged?(sender: GymDataItemPlayer, status: DataItemPlayerStatus): void;
	playerWaitingStateChanged?(sender: GymDataItemPlayer, waitingState: boolean): void;
	playerDidReachEnd?(sender: GymDataItemPlayer): void;

	// Map of player data items
	private playerDataItemsMap: Map<string, PlayerDataItem> = new Map();

	private _autoPlayingSpeed: number = 1;

	private _playerStatus = DataItemPlayerStatus.Unknown;

	private _simulation: GymSimulation | undefined = undefined;

	getPlayerSelectedSymbol(): string | undefined {
		return this._simulation ? ChartUtils.buildGymSymbol(this._simulation) : undefined;
	}

	getItem(): PlayerDataItem | undefined {
		const selectedSymbol = this.getPlayerSelectedSymbol();
		if (!selectedSymbol) {
			return undefined;
		}
		const item = this.playerDataItemsMap.get(selectedSymbol);
		return item;
	}

	private timer?: any;

	play() {
		const playerItem = this.getItem();
		if (
			playerItem &&
			playerItem.hasDataItems() &&
			playerItem.playerCurrentIndex !== playerItem.TP_PLAYER_EMPTY_INDEX &&
			this.playerStatus !== DataItemPlayerStatus.Playing
		) {
			this.playerStatus = DataItemPlayerStatus.Playing;
			return true;
		}
		this.playerStatus = DataItemPlayerStatus.Unknown;
		return false;
	}

	canPlayPrevious = (playerItem = this.getItem()) =>
		!!playerItem &&
		playerItem.hasDataItems() &&
		playerItem.playerCurrentIndex !== playerItem.TP_PLAYER_EMPTY_INDEX &&
		playerItem.playerCurrentIndex > 0;

	playPrevious() {
		const playerItem = this.getItem();

		if (!!playerItem && this.canPlayPrevious(playerItem)) {
			this.playerStatus = DataItemPlayerStatus.Pause;
			playerItem.playerCurrentIndex--;
			// console.info('playerItem.playerCurrentIndex: playPrevious: ', playerItem.playerCurrentIndex);

			this.notifyCurrentIndexChanged();
			return true;
		}

		this.playerStatus = DataItemPlayerStatus.Pause;
		return false;
	}

	playNext() {
		const playerItem = this.getItem();
		if (
			playerItem &&
			playerItem.hasDataItems() &&
			playerItem.playerCurrentIndex !== playerItem.TP_PLAYER_EMPTY_INDEX &&
			playerItem.playerItemsCount > playerItem.playerCurrentIndex + 1
		) {
			playerItem.playerCurrentIndex++;
			// console.info('playerItem.playerCurrentIndex: playNext: ', playerItem.playerCurrentIndex);
			this.notifyCurrentIndexChanged();
			return true;
		}

		return false;
	}

	pause() {
		const playerItem = this.getItem();
		if (playerItem) {
			this.playerStatus = DataItemPlayerStatus.Pause;
		}
	}

	setInWaitingState() {
		const playerItem = this.getItem();
		if (playerItem) {
			playerItem.waitingState = this.playerStatus;
			this.pause();

			if (this.playerWaitingStateChanged) {
				this.playerWaitingStateChanged(this, true);
			}
		}
	}

	recoverFromWaitingState() {
		const playerItem = this.getItem();
		if (playerItem) {
			this.playerStatus = playerItem.waitingState;

			if (this.playerWaitingStateChanged) {
				this.playerWaitingStateChanged(this, false);
			}
		}
	}

	isPlaying() {
		return this.playerStatus === DataItemPlayerStatus.Playing;
	}

	getCurrentHistoryTick(): HistoryTick | undefined {
		const playerItem = this.getItem();
		if (
			playerItem &&
			playerItem.playerDataItems &&
			playerItem.playerCurrentIndex > playerItem.TP_PLAYER_EMPTY_INDEX &&
			playerItem.playerItemsCount > playerItem.playerCurrentIndex
		) {
			return playerItem.playerDataItems[playerItem.playerCurrentIndex];
		}
		return undefined;
	}

	getTimerTimeInterval(): number {
		// Convert in milliseconds
		return this.autoPlayingSpeed * 1000;
	}

	public get autoPlayingSpeed(): number {
		return this._autoPlayingSpeed;
	}

	/*
	 * In Seconds, Max value is 0.25, default value is 1 second
	 */
	public set autoPlayingSpeed(value: number) {
		const playerItem = this.getItem();
		if (playerItem) {
			if (value > playerItem.TP_PLAYER_MAX_SPEED) {
				this._autoPlayingSpeed = value;
			} else {
				this._autoPlayingSpeed = value;
			}
		}

		if (this.playerStatus === DataItemPlayerStatus.Playing) {
			this.invalidateTimer();
			this.startTimer();
		} else {
			this.playerStatus = DataItemPlayerStatus.Pause;
		}
	}

	changeTimeScale(timeScale: TimeScale) {
		if (this.playerTimeScaleChanged) {
			this.playerTimeScaleChanged(this, timeScale);
		}
	}

	public get playerItemsCount(): number {
		const playerItem = this.getItem();
		if (playerItem) {
			return playerItem.playerDataItems?.length ?? 0;
		}
		return 0;
	}

	resetPlayerDataItems() {
		this.playerDataItemsMap.clear();
	}

	removeAllItems() {
		const playerItem = this.getItem();
		if (playerItem) {
			playerItem.playerDataItems = undefined;
			playerItem.playerCurrentIndex = playerItem.TP_PLAYER_EMPTY_INDEX;
		}
	}

	resetPlayerDataItem(simulation: GymSimulation) {
		// console.debug(`resetPlayerDataItem code=${simulation.code} sim_id=${simulation.id}`);
		const selectedSymbol = ChartUtils.buildGymSymbol(simulation);
		if (selectedSymbol) {
			const playerItem = this.playerDataItemsMap.get(selectedSymbol);
			if (playerItem) {
				this.playerDataItemsMap.delete(selectedSymbol);
			}
		}
	}

	resetPlayerDataItemWithGuid(guid: string) {
		// console.debug(`resetPlayerDataItem guid=${guid}`);
		this.playerDataItemsMap.delete(guid);
	}

	setNewPlayerDataItem(simulation: GymSimulation, playerDataItem: PlayerDataItem) {
		this.pause();
		this._simulation = simulation;
		const selectedSymbol = ChartUtils.buildGymSymbol(simulation);
		if (!this.playerDataItemsMap.has(selectedSymbol)) {
			this.playerDataItemsMap.set(selectedSymbol, playerDataItem);
		}
	}

	setCurrentItemIndex(index: number): boolean {
		const playerItem = this.getItem();
		if (playerItem && playerItem.hasDataItems()) {
			playerItem.playerCurrentIndex = index;

			this.notifyCurrentIndexChanged();

			return true;
		}
		return false;
	}

	forceNotifyCurrentIndexChanged() {
		this.notifyCurrentIndexChanged();
	}

	notifyCurrentIndexChanged() {
		const playerItem = this.getItem();
		if (
			playerItem &&
			playerItem.playerCurrentIndex > playerItem.TP_PLAYER_EMPTY_INDEX &&
			playerItem.playerItemsCount > playerItem.playerCurrentIndex
		) {
			if (this.playerCurrentIndexChanged) {
				this.playerCurrentIndexChanged(this, playerItem.playerCurrentIndex);
			}
			// Unfortunately there is no other way to notify the player controls on the last index change whe it gets to 0 going backward
			if (this.playerCurrentIndexChangedForControls) {
				this.playerCurrentIndexChangedForControls(this, playerItem.playerCurrentIndex);
			}
		}
	}

	public get playerStatus() {
		return this._playerStatus;
	}

	public set playerStatus(value) {
		if (this._playerStatus !== value) {
			this._playerStatus = value;

			switch (this.playerStatus) {
				case DataItemPlayerStatus.Unknown:
				case DataItemPlayerStatus.Pause:
				case DataItemPlayerStatus.Buffering:
					this.invalidateTimer();
					break;
				case DataItemPlayerStatus.Playing:
					this.startTimer();
					break;
			}

			if (this.playerStatusChanged) {
				this.playerStatusChanged(this, this.playerStatus);
			}
		}
	}

	// Events

	playerReadyToPlay() {
		const res = this.playNext();
		if (!res) {
			this.playerStatus = DataItemPlayerStatus.Pause;
		}
	}

	// Utils Methods

	startTimer() {
		this.invalidateTimer();
		this.timer = setInterval(() => this.playerReadyToPlay(), this.getTimerTimeInterval());
	}

	invalidateTimer() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	destroy() {
		this.pause();
		this.invalidateTimer();

		this.playerTimeScaleChanged = undefined;
		this.playerCurrentIndexChanged = undefined;
		this.playerCurrentIndexChangedForControls = undefined;
		this.playerStatusChanged = undefined;
		this.playerWaitingStateChanged = undefined;
		this.playerDidReachEnd = undefined;
	}
}
