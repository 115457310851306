import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../../contexts/AppContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import useForceRerender from '../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../utils/hooks/useObservable';

import useWindowSize from '../../../../utils/hooks/useWindowSize';
import Routes from '../../../../setup/routes';
import LiveChat from '../../../../shared/LiveChat';
import { TradersGymContext, TradersGymContextType } from '../../../../pages/TradersGym/TradersGymContext';
import { AppComponentType, ApplicationStatus } from '../../../../utils/functions/enums';
import { IStatusConfigEnum } from '../../../components/Permissions/config.types';
import authStore from '../../../../store/authStore';
import accountStatusStore, { StatusStore } from '../../../../store/accountStatusStore';
import { updateDocumentTitle } from '../../../../utils/functions/documentUtils';

import { Calendar, Guide, Management, Markets, Signals, Trade, TradersGym } from './Items';
import styles from './Navigation.module.scss';
import { OneClickTradingToggle, ThemeToggle } from './IconToggles';
import tradingAccountStore from '../../../../store/tradingAccountStore';

const Navigation = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const gymContext = useContext(TradersGymContext);
	const { tradersGymContext, setGymProps } = gymContext as TradersGymContextType;
	const permissions = accountStatusStore((store: StatusStore) => store.permissions);
	const hasLiveAccount = accountStatusStore.use.hasLiveAccount();
	const status = accountStatusStore.use.status();
	const portalPage = permissions?.portalPage;
	const isJapanAccount = authStore.use.isJapanAccount();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	const forceRerender = useForceRerender();
	const windowSize = useWindowSize();
	const history = useHistory();
	const location = history.location;
	const [helpOpen, setHelpOpen] = useState<boolean>(false);
	const { t } = useTranslation();

	const isDemoMode = authStore.use.isDemoMode();
	const isLiveMode = authStore.use.isLiveMode();

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());
	useObservable(
		dashboardContext.getPropertyChangeStream('presentComponentType'),
		() => windowSize.height <= 620 && forceRerender()
	);

	useEffect(() => {
		if (location.pathname === Routes.trader.charts && windowSize.width >= 1024) {
			history.push(Routes.trader.watchlist);
		}
	}, [windowSize]);

	// Updated only to change the page title temporal
	// UNFORTUNATELY, In order for the back and forward browser buttons to work we need to implement this useEffect
	// Should refactor this in the future!!!
	useEffect(() => {
		if (portalPage === undefined) return;
		const location = window.location.pathname;
		let pageTitlePrefix = '';
		dashboardContext.closeAllOtherTabs();

		if (location !== Routes.trader.tradersGym) {
			setGymProps({ isActive: false });
		}

		switch (location) {
			case Routes.trader.watchlist:
				pageTitlePrefix = 'en:Trade';

				dashboardContext.presentComponentType = AppComponentType.Watchlist;
				break;
			case Routes.trader.markets:
				pageTitlePrefix = 'wtr:MARKETS';

				dashboardContext.presentComponentType = AppComponentType.Markets;
				break;
			case Routes.trader.signals:
				pageTitlePrefix = 'wtr:SIGNALS';

				dashboardContext.presentComponentType = AppComponentType.Signals;
				break;
			case Routes.trader.calendar:
				pageTitlePrefix = 'en:NAVIGATION_CALENDAR';

				dashboardContext.presentComponentType = AppComponentType.Calendar;
				break;
			case Routes.trader.openAccount:
				pageTitlePrefix = 'en:OPEN_LIVE_ACCOUNT';

				if (isDemoMode || isJapanAccount || (hasLiveAccount && status === ApplicationStatus.APPROVED) || (isLiveMode && !hasLiveAccount)) {
					dashboardContext.presentComponentType = AppComponentType.OpenAccount;
				} else {
					dashboardContext.presentComponentType = AppComponentType.CompleteOnboarding;
				}

				break;

			case Routes.trader.funds:
				pageTitlePrefix = 'wtr:LEFT_NAV_FUNDS';

				let route = Routes.trader.funds;

				if (portalPage === IStatusConfigEnum.deposits) {
					dashboardContext.presentComponentType = AppComponentType.Funds;
					route = Routes.trader.funds;
				} else if (portalPage === IStatusConfigEnum.fork) {
					dashboardContext.presentComponentType = AppComponentType.CompleteOnboarding;
					route = Routes.trader.openAccount;
				} else if (portalPage === IStatusConfigEnum.relevantStep) {
					dashboardContext.presentComponentType = AppComponentType.UploadDocuments;
					route = Routes.trader.uploadDocuments;
				} else if (portalPage === IStatusConfigEnum.statusPage) {
					dashboardContext.presentComponentType = AppComponentType.Status;
					route = Routes.trader.status;
				} else if (isJapanAccount) {
					dashboardContext.presentComponentType = AppComponentType.OpenAccount;
					route = Routes.trader.openAccount;
				}

				history.replace(route);
				break;
			case Routes.trader.tradersGym:
				pageTitlePrefix = 'en:MORE_SWITCH_GYM';

				setGymProps({ isActive: true });
				dashboardContext.presentComponentType = AppComponentType.TradersGym;
				break;
			case Routes.trader.tutorials:
				pageTitlePrefix = appContext.isJapanAccount ? 'en:USER_GUIDE' : 'Think Academy';
				dashboardContext.presentComponentType = AppComponentType.Tutorials;
				break;
			case Routes.trader.reports:
				pageTitlePrefix = 'en:REPORTS';

				dashboardContext.presentComponentType = AppComponentType.Reports;
				break;
			default:
				pageTitlePrefix = 'en:Trade';

				dashboardContext.presentComponentType = AppComponentType.Watchlist;
		}

		updateDocumentTitle(t(pageTitlePrefix));
	}, [window.location.pathname, portalPage]);

	return (
		<div className={styles.navContainer}>
			<div className={styles.navMenuItemsContainer}>
				<div className={styles.mainMenu}>
					<>
						<Trade />
						<Markets />
						<Signals />
						<Calendar />
						<Management />
						<TradersGym />
						<Guide />
					</>
				</div>
				<div>
					{!helpOpen && (
						<div className={styles.toggles}>
							{!tradersGymContext.isActive && <OneClickTradingToggle />}
							<ThemeToggle />
						</div>
					)}
					{!isFundedTrader && <LiveChat onToggle={(isOpen: boolean) => setHelpOpen(isOpen)} menuStyle="vertical" />}
				</div>
			</div>
		</div>
	);
};

export default Navigation;
