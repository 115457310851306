import credentials from '../../setup/credentials';

import { submitDynamicForm } from './submitDynamicForm';

export interface RedirectAction {
	type: string;
	url: string;
	fields: {
		[key: string]: string;
	};
}

const handleDepositRedirect = (action: RedirectAction, acceptCharset?: string) => {
	credentials.clearCredentials();
	const actionType = action.type;

	if (actionType.toLowerCase() === 'post') {
		submitDynamicForm(action.type, action.url, action.fields, acceptCharset);
	} else if (actionType === 'redirect') {
		window.open(action.url, '_self');
	} else {
		console.debug('===>>> Unexpected action type for redirect!');
	}
};

export default handleDepositRedirect;
