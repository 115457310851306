import { WindowContextProvider } from '../../contexts/WindowContext';

export const closeChildWindows = (context: WindowContextProvider) => {
	const childWindows = context.childWindows;
	if (childWindows.size > 0) {
		childWindows.forEach((childWindow: Window | null) => {
			if (childWindow !== null) {
				childWindow.close();
			}
		});
	}
};
