export default class TypeUtils {
	public static is<T>(subject: unknown, evaluator: (subject: any) => boolean): subject is T {
		return evaluator(subject);
	}

	public static propertyNames<T = any>(object: T, inherited: boolean = false): string[] {
		const propertyNames: string[] = Object.getOwnPropertyNames(object);
		const prototype = inherited ? Object.getPrototypeOf(object) : null;
		if (prototype != null) {
			Array.prototype.push.apply(propertyNames, this.propertyNames(prototype));
		}
		return prototype == null ? propertyNames : Array.from(new Set<string>(propertyNames));
	}
}

export type Optional<T = any> = T | null | undefined;

export type Impossible<T extends keyof any> = { [P in T]: never };

export type Sealed<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;

export type KeysOf<T> = (keyof T)[];

export type ValuesOf<T> = T[keyof T];

export type ArrayElementType<T extends any[] = any[]> = Exclude<ReturnType<T['pop']>, undefined>;

export type PropertyIntersection<T, U> = { [K in Extract<keyof T, keyof U>]: U[K] extends T[K] ? K : never }[Extract<
	keyof T,
	keyof U
>];

export type Intersection<T, U> = {
	[K in PropertyIntersection<T, U>]: T[K];
};

export type ExcludeProperty<T, U extends keyof T> = {
	[K in Exclude<keyof T, U>]: T[K];
};

export type ExcludeProperties<T, U extends Array<keyof T>> = {
	[K in Exclude<keyof T, U[keyof U]>]: T[K];
};

export type ExcludePropertiesOfType<T, U> = {
	[K in PropertyIntersection<{ [K in keyof T]: T[K] extends U ? never : T[K] }, T>]: T[K];
};

export type ImmutableMap<K, V> = ExcludeProperty<Map<K, V>, 'set' | 'clear' | 'delete'>;

export type Comparator<T extends any = any> = (value1: T, value2: T) => boolean;
