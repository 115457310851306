import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';

import { Optional } from '../../utils/functions/Nullable';
import { stripHtmlTags } from '../../utils/functions/stripHtmlTags';
import uniqueId from '../../utils/functions/uniqueId';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
	isChecked: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	labelContents?: Optional<string>;
	isLabelContentsInteractive?: boolean;
	checkedValue?: Optional<string>;
	id?: Optional<string>;
}

const Checkbox = ({
	isChecked,
	onChange,
	labelContents,
	checkedValue,
	isLabelContentsInteractive,
	id,
}: CheckboxProps) => {
	const checkboxId = id || uniqueId();

	return (
		<div className={styles.container}>
			<input
				type="checkbox"
				className={styles.input}
				checked={isChecked}
				onChange={onChange}
				value={checkedValue || ''}
				id={checkboxId}
			/>
			<div className={styles.text} dangerouslySetInnerHTML={{ __html: labelContents }}></div>
			<label className={cn(styles.label, isLabelContentsInteractive && styles.constrainedLabel)} htmlFor={checkboxId}>
				{labelContents && stripHtmlTags(labelContents)}
			</label>
			<FontAwesomeIcon icon={['fal', 'square']} className={cn(styles.icon, styles.unCheckedIcon)} />
			<FontAwesomeIcon icon={['fal', 'check-square']} className={cn(styles.icon, styles.checkedIcon)} />
		</div>
	);
};

export default Checkbox;
