import { TradingMode } from '../gateways/RfpGateway/rfp.types';

const OBFUSCATION_ITERATIONS = 3;
const TOKEN_KEY = 'uToken';

type TCredentials = {
	username?: string | null | undefined;
	password?: string | null | undefined;
	tfboSessionId?: string | null | undefined;
	tfboToken?: string | null | undefined;
	tradingMode?: TradingMode | null | undefined;
	enableSSO?: boolean;
	sso_token?: string;
	createdAt?: number;
};

const prepareAndEncodeData = (value: TCredentials): string => {
	const creds = { ...value };
	delete creds.password;
	creds.createdAt = Date.now();

	let result = JSON.stringify(creds);
	// for maximum security (he he) Base64 the data 3 times; no way to break this
	// tried to implement a Caesar shift but was not working and thought this is secure enough
	for (let i = 0; i <= OBFUSCATION_ITERATIONS; i++) {
		result = Buffer.from(result).toString('base64');
	}

	return result;
};

const decodeData = (value: string | null): object => {
	if (!value) return {};
	// if for some reason decoding fails we fallback to an empty object
	try {
		let result = value;
		for (let i = 0; i <= OBFUSCATION_ITERATIONS; i++) {
			result = Buffer.from(result, 'base64').toString('utf8');
		}
		return JSON.parse(result.toString());
	} catch {
		return {};
	}
};

class CredentialsManager {
	private static _credentials: TCredentials = {};

	private constructor() {}

	public static getCredentials(): Readonly<TCredentials> {
		const creds = decodeData(localStorage.getItem(TOKEN_KEY));

		return { ...creds, ...this._credentials };
	}

	public static setCredentials(value: TCredentials): void {
		this._credentials = { ...this._credentials, ...value };
		localStorage.setItem(TOKEN_KEY, prepareAndEncodeData(this._credentials));
	}

	public static clearCredentials(): void {
		localStorage.removeItem(TOKEN_KEY);
	}
}

export default {
	getCredentials: CredentialsManager.getCredentials.bind(CredentialsManager),
	setCredentials: CredentialsManager.setCredentials.bind(CredentialsManager),
	clearCredentials: CredentialsManager.clearCredentials.bind(CredentialsManager),
};
