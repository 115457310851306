type Optional<T = any> = T | null | undefined;

export default class Lazy<T> {
	private m_value: Optional<T> = null;
	private m_function: Optional<(input?: any) => T> = null;

	public get hasValue(): boolean {
		return this.m_function == null;
	}

	public constructor(supplier: () => T);
	public constructor(supplier: (input: any) => T);
	public constructor(supplier: (() => T) | ((input: any) => T)) {
		if (supplier == null) {
			throw new Error('supplier cannot be null');
		}
		this.m_function = (input?: any) => {
			return supplier(input);
		};
	}

	public getValue(): T;
	public getValue<U>(input: U): T;
	public getValue<U>(input?: U): T {
		if (this.m_function != null) {
			this.loadValue<U>(input);
		}
		return this.m_value as T;
	}

	private loadValue<U = any>(input?: U): void {
		if (this.m_function != null) {
			this.m_value = this.m_function(input);
			this.m_function = null;
		}
	}
}
