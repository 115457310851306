import { MarketItem } from '../../gateways/RfpGateway/rfp.types';
import { Optional } from '../functions/Nullable';
import { UserOperationType } from '../functions/subscriptionUtils';

import useSubscriptionConstraints from './useSubscriptionConstraints';
import useSubscriptionModal from './useSubscriptionModal';

const useHandleSubscriptionConstraints = () => {
	const getSubscriptionConstraints = useSubscriptionConstraints();
	const { setModalStateProps } = useSubscriptionModal();

	const handleSubscriptionsConstraints = (marketItem?: Optional<MarketItem>, operation?: UserOperationType) => {
		const constraint = getSubscriptionConstraints(marketItem, operation);
		if (!constraint) {
			return null;
		}

		setModalStateProps({
			reason: constraint,
			isOpen: true,
			instrument: marketItem,
		});

		return constraint;
	};

	return handleSubscriptionsConstraints;
};

export default useHandleSubscriptionConstraints;
