import React, { useContext, useEffect, useRef, useState } from 'react';

import { faWifiSlash, faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HCaptcha from '@hcaptcha/react-hcaptcha';

import Modal from '../../../shared/Modal/Modal';
import { default as RfpGateway } from '../../../gateways/RfpGateway/RfpGateway';
import { Resolver } from '../../../utils/functions/Ioc';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import { default as AppContext } from '../../../contexts/AppContext';

import Button from '../../../shared/Button/Button';

import useForceRerender from '../../../utils/hooks/useForceRerender';
import useRefreshJWTToken from '../../../utils/mutations/useRefreshJWTToken';

import usePromiseFactory from '../../../utils/hooks/usePromiseFactory';
import useLogout from '../../../utils/hooks/useLogout';
import authStore from '../../../store/authStore';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../../setup/config';
import { RFPConnectionErrorType, RFPConnectPayload } from '../../../gateways/RfpGateway/rfp.types';

import styles from './ReconnectModal.module.scss';

const ReconnectModal = React.memo(
	() => {
		const appContext = useContext(AppContext);

		const logOut = useLogout();
		const userProfile = authStore.use.userProfile();
		const tradingMode = authStore.use.tradingMode();

		const forceRerender = useForceRerender();
		const promiseFactory = usePromiseFactory();
		const tt = useShortTranslation('wtr:RECONN_MOD_');

		// const pageHiddenStatus = usePageVisibility();
		const reconnectAttemptsCounter = useRef<number>(0);
		const [isOnline, setIsOnline] = useState<boolean>(true);

		const rfpGateway = Resolver.resolve(RfpGateway);
		const reconnectModal = appContext.reconnectModal;

		const captchaRef = useRef<HCaptcha>(null);

		const reconnectIntervalMilliseconds = parseInt(process.env.REACT_APP_RECONNECT_INTERVAL!);
		const reconnectAttemptsLimit = parseInt(process.env.REACT_APP_RECONNECT_ATTEMPTS!);

		const [reconnectAttemptsLimitReached, setReconnectAttemptsLimitReached] = useState<boolean>(false);

		const reconnectionInProcess = useRef<boolean>(false);
		const { mutate: refreshJWTToken } = useRefreshJWTToken();

		useObservable(appContext.getPropertyChangeStream('reconnectModal'), () => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		});

		// Add event listeners to check online status
		useEffect(() => {
			window.addEventListener('offline', () => {
				//console.log('offline');
				setIsOnline(false);
			});
			window.addEventListener('online', () => {
				//console.log('online');
				setIsOnline(true);
			});

			return () => {
				window.removeEventListener('offline', () => {
					setIsOnline(false);
				});
				window.removeEventListener('online', () => {
					setIsOnline(true);
				});
			};
		}, []);

		// useEffect(() => {
		// 	if (rfpGateway) {
		// 		if (pageHiddenStatus) {
		// 			//console.log(' App is in background');
		// 			// rfpGateway.setAppInBackgroundMode = true;
		// 		} else {
		// 			//console.log('App is visible');
		// 			// rfpGateway.setAppInBackgroundMode = false;
		// 		}
		// 	}
		// }, [pageHiddenStatus]);

		// Disconnect RFP Gateway and update reconnectModal state
		const disconnect = async () => {
			if (rfpGateway) {
				try {
					await rfpGateway.disconnect();
					appContext.reconnectModal = true;
				} catch (err) {
					console.debug('Error disconnecting from RFP gateway in Reconnect modal isOnline hook. Exception: ', err);
				}
			}
		};

		// Show reconnect modal if network status is offline and reconnect session is not initialized after ping-pong timeout
		useEffect(() => {
			if (!isOnline && !reconnectModal) {
				disconnect();
			} else {
				if (rfpGateway.connected) {
					appContext.reconnectModal = false;
				} else {
					if (isOnline && reconnectModal) {
						if (reconnectionInProcess.current === false) {
							handleRetry();
						}
					}
				}
			}
		}, [isOnline]);

		// Start reconnecting session if WebSocket connection has timed out OR network status is offline
		useEffect(() => {
			if (!rfpGateway.connected && reconnectModal) {
				handleReconnect();
			}
		}, [rfpGateway.connected, reconnectModal]);

		const attemptReconnect = async () => {
			// Try to connect RFP
			if (!rfpGateway.connected) {
				reconnectionInProcess.current = true;
				try {
					const rfpPayload = {
						username: userProfile.email,
						authToken: localStorage.getItem(ACCESS_TOKEN_KEY),
						tradingMode,
						lang: appContext.languageSettings,
					};

					const connectResult = await rfpGateway.connect(() => rfpPayload as RFPConnectPayload);

					reconnectionInProcess.current = false;
					// console.debug('Reconnect attempt result: ', connectResult);
					if (appContext.reconnectModal) {
						if (connectResult.success) {
							appContext.reconnectModal = false;
							// TODO WTR-4764: Improve the reload mechanism by implementing an event approach to notify all components using data from the WSS to update their data after a period of inactivity.
							window.location.reload();
						} else if (connectResult.error === RFPConnectionErrorType.AuthenticationFailed) {
							const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
							const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

							if (accessToken && refreshToken) {
								await refreshJWTToken(
									{ accessToken, refreshToken },
									{
										onError: (error) => {
											console.debug('Error refreshing token in Reconnect modal. Exception: ', error);
											logOut();
										},
										onSuccess: async () => {
											attemptReconnect();
										},
									}
								);
							} else {
								setTimeout(() => {
									logOut();
								}, 0);
							}
						}
					}

					return { connectResult: connectResult };
				} catch (error) {
					// console.debug('Error connecting to RFP gateway (reconnection). Exception: ', error);
					reconnectionInProcess.current = false;
				}
			} else {
				if (rfpGateway.connected) {
					appContext.reconnectModal = false;
				}
			}

			return { connectResult: undefined };
		};

		const handleReconnect = async () => {
			if (reconnectionInProcess.current === true) {
				// console.debug('Reconnection in process, skipping reconnect attempt');
				return;
			}

			console.debug(`handleReconnect ... ${new Date()}`);
			const attemptsLimitReached = reconnectAttemptsCounter.current >= reconnectAttemptsLimit;
			if (!rfpGateway.connected && !attemptsLimitReached) {
				const startAttemptTimestamp = new Date().getTime();
				// console.debug(`+ reconnect ${reconnectAttemptsCounter.current} to RFP gateway... ${new Date()}`);
				await attemptReconnect();

				// console.debug(`- 1 reconnect to RFP gateway... ${new Date()}`);

				const endAttemptTimestamp = new Date().getTime();
				const reconnectInterval = endAttemptTimestamp - startAttemptTimestamp;

				if (reconnectInterval < reconnectIntervalMilliseconds) {
					await new Promise((resolve) => setTimeout(resolve, reconnectIntervalMilliseconds - reconnectInterval));
				}

				// console.debug(`- 2 reconnect to RFP gateway... ${new Date()}`);

				if (appContext.reconnectModal) {
					reconnectAttemptsCounter.current++;

					// If reconnect attempts limit is reached, set reconnectAttemptsLimitReached to true
					if (reconnectAttemptsCounter.current >= reconnectAttemptsLimit) {
						setReconnectAttemptsLimitReached(true);
						return;
					}

					// Try to reconnect after a certain interval
					handleReconnect();
				}
			}
		};

		const handleRetry = async () => {
			// call disconnect logic
			rfpGateway.disconnect();

			// Reset reconnect attempts counter
			reconnectAttemptsCounter.current = 0;

			setReconnectAttemptsLimitReached(false);

			// call reconnect logic
			handleReconnect();
		};

		const handleLogout = () => {
			try {
				logOut();
				appContext.modalId = null;
			} catch (error) {
				console.debug('Error logging out in Reconnect modal. Exception: ', error);
			}
		};

		// TODO RFP.queueErrors
		return (
			<Modal
				backdrop="static"
				centered
				contentClassName={styles.reconnectModal}
				show={appContext.isLoggedIn && reconnectModal}
			>
				<Modal.Header className={styles.modalHeader}>
					{reconnectAttemptsLimitReached ? tt('2_HEAD') : tt('1_HEAD')}
				</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					<div className={styles.iconContainer}>
						<FontAwesomeIcon
							className={styles.failedIcon}
							icon={reconnectAttemptsLimitReached ? faWifiSlash : faSync}
							size="6x"
							spin={!reconnectAttemptsLimitReached}
						/>
					</div>
					<div className={styles.modalText}>{reconnectAttemptsLimitReached ? tt('2_TEXT') : ''}</div>
					{process.env.REACT_APP_DISABLE_CAPTCHA !== 'true' && (
						<HCaptcha
							sitekey={process.env.REACT_APP_HCAPTCHA_KEY!}
							onVerify={() => {}}
							onError={(error) => console.debug('Captcha error: ', error)}
							ref={captchaRef}
							size="invisible"
						/>
					)}
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					{reconnectAttemptsLimitReached ? (
						<>
							<Button className={styles.button} variant="outline" label={tt('2_BTN_LOGOUT')} onClick={handleLogout} />
							<Button className={styles.button} variant="primary" label={tt('2_BTN_RETRY')} onClick={handleRetry} />
						</>
					) : (
						<Button className={styles.button} variant="outline" label={tt('1_BTN_CANCEL')} onClick={handleLogout} />
					)}
				</Modal.Footer>
			</Modal>
		);
	},
	() => true
);

export default ReconnectModal;
