import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { SHA384 } from 'sha2';

import axios from 'axios';

import { useContext } from 'react';

import useSaveUserPreferences from '../mutations/useSaveUserPreferences';
import AppContext from '../../contexts/AppContext';
import useStoreAndLoadPreferences from '../hooks/preferences/useStoreAndLoadPreferences';
import useUpdatePipsPreferences from '../hooks/preferences/useUpdatePipsPreferences';

type CustomOptions = {
	email: string;
};

const useUserPreferences = (opts: UseQueryOptions & CustomOptions) => {
	const { email, ...options } = opts;
	const { mutate: saveUserPreferences } = useSaveUserPreferences();
	const appContext = useContext(AppContext);
	const storeAndLoadPreferences = useStoreAndLoadPreferences();
	const updatePipsPreferences = useUpdatePipsPreferences();
	options.queryKey = ['useUserPreferences', email];
	options.queryFn = async () => {
		try {
			const response = await axios({
				url: '/v2/preferences',
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: SHA384(`${email}Bji4xKz9cPTac4B7ruET`).toString('hex'),
				},
				params: {
					email,
				},
			});
			const preferencesData = JSON.parse(decodeURIComponent(atob(response.data?.preference)));
			storeAndLoadPreferences(preferencesData);
			updatePipsPreferences();
			if (appContext.userPreferences) {
				localStorage.setItem('language', appContext.languageSettings);
				appContext.userPreferences.user_prefs.platform.lang._code = appContext.languageSettings;
				saveUserPreferences();
			}

			return preferencesData;
		} catch (e) {
			storeAndLoadPreferences();
			updatePipsPreferences();
			console.log('Error fetching user preferences. Fallback to defaults', e);
			return appContext.userPreferences;
		}
	};
	return useQuery(options);
};

export default useUserPreferences;
