import { create } from 'zustand';
import createSelectors from './createSelectors';

interface TradingViewStore {
	isSearchOpen: boolean;
	firstPressedButton: string;
	compSymbol: string;
	isCompSymbolMode: boolean;
	openOrderInformation: boolean;
	ticketLayout: string;
	setOpenOrderInformation: (value: boolean) => void;
	setIsSearchOpen: (value: boolean) => void;
	setFirstPressedButton: (value: string) => void;
	setCompSymbol: (value: string) => void;
	setIsCompSymbolMode: (value: boolean) => void;
	setTicketLayout: (value: string) => void;
}

const TradingViewStore = create<TradingViewStore>((set) => ({
	isSearchOpen: false,
	firstPressedButton: '',
	compSymbol: '',
	isCompSymbolMode: false,
	openOrderInformation: false,
	ticketLayout: 'Dock',
	setOpenOrderInformation: (value: boolean) => set((state) => ({ openOrderInformation: value })),
	setIsSearchOpen: (value: boolean) => set((state) => ({ isSearchOpen: value })),
	setIsCompSymbolMode: (value: boolean) => set((state) => ({ isCompSymbolMode: value })),
	setFirstPressedButton: (value: string) => set((state) => ({ firstPressedButton: value })),
	setCompSymbol: (value: string) => set((state) => ({ compSymbol: value })),
	setTicketLayout: (value: string) => set((state) => ({ ticketLayout: value })),
}));

export default createSelectors(TradingViewStore);
