import { ApplicationStatus } from '../../../utils/functions/enums';

import { ActionsConfig } from './config.types';

const withoutLiveAccount_inLiveMode: ActionsConfig = {
	[ApplicationStatus.EMPTY]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:OPEN_LIVE_ACCOUNT',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:OPEN_LIVE_ACCOUNT',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.APPROVED]: {
		accountMenuAction: false,
		accountMenuLabel: false,
		marginLevelAction: false,
		marginLevelLabel: false,
		signalsAction: false,
		tradeButtons: false,
		mainMenuAction: false,
		addAccount: false,
		manageFunds: false,
		reports: false,
		tradersGym: false,
		portalPage: false,
		notificationBell: false,
	},
	[ApplicationStatus.FUNDED_TRADER]: {
		accountMenuAction: false,
		accountMenuLabel: false,
		marginLevelAction: false,
		marginLevelLabel: false,
		signalsAction: false,
		tradeButtons: false,
		mainMenuAction: false,
		addAccount: false,
		manageFunds: false,
		reports: false,
		tradersGym: true,
		portalPage: false,
		notificationBell: false,
	},

	[ApplicationStatus.LEVEL1_APPROVED]: {
		accountMenuAction: false,
		accountMenuLabel: false,
		marginLevelAction: false,
		marginLevelLabel: false,
		signalsAction: false,
		tradeButtons: false,
		mainMenuAction: false,
		addAccount: false,
		manageFunds: false,
		reports: false,
		tradersGym: false,
		portalPage: false,
		notificationBell: false,
	},
	[ApplicationStatus.DUPLICATE]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.INCOMPLETE]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:COMPLETE_APPLICATION',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:COMPLETE_APPLICATION',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},

	// Group of statuses with same behavior:
	//PENDING_ID_ADDRESS
	//PENDING_ID
	//PENDING_ADDRESS
	//PENDING_APPROPRIATENESS_TEST
	//PENDING_PROPER_DOCUMENTS
	//PENDING_RG227
	//PENDING_BLURRY_CROPPED_ID
	//PENDING_BLURRY_POA
	//MANUAL_DOCS

	[ApplicationStatus.PENDING_ID_ADDRESS]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_ID]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_ADDRESS]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_APPROPRIATENESS_TEST]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_PROPER_DOCUMENTS]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},

	[ApplicationStatus.PENDING_RG227]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_BLURRY_CROPPED_ID]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_BLURRY_POA]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},
	[ApplicationStatus.MANUAL_DOCS]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'en:Upload Documents',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'en:Upload Documents',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'relevantStep',
		notificationBell: false,
	},

	// Group of statuses with same behavior:
	// PENDING_REVIEW
	// PENDING_E_VERIFICATION
	// PENDING_TRANSLATION,
	// PENDING_KYC

	[ApplicationStatus.PENDING_REVIEW]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'statusPage',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_E_VERIFICATION]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'statusPage',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_TRANSLATION]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'statusPage',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_KYC]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'statusPage',
		notificationBell: false,
	},

	// Group of statuses with same behavior:
	// DENIED
	// PENDING_TRANSFER
	// PENDING_NEW_TnC_ACCEPTANCE,
	// FAILED
	// PENDING
	// INTERNAL_TESTING

	[ApplicationStatus.DENIED]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_TRANSFER]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_NEW_TnC_ACCEPTANCE]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.FAILED]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.INTERNAL_TESTING]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: false,
		reports: false,
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},

	// Group of statuses with same behavior:
	// PENDING_IP_MISMATCH
	// PENDING_DOB_MISMATCH
	// PENDING_DOB_ADDRESS_MISMATCH
	// PENDING_ADDRESS_MISMATCH
	// PENDING_E_VERIFICATION_RETRY
	// PENDING_SOF
	// PENDING_NO_KYC_DOCS
	// PENDING_EMAIL
	// PENDING_NO_ID

	[ApplicationStatus.PENDING_IP_MISMATCH]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_DOB_MISMATCH]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_DOB_ADDRESS_MISMATCH]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_ADDRESS_MISMATCH]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_E_VERIFICATION_RETRY]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_SOF]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_NO_KYC_DOCS]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_EMAIL]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
	[ApplicationStatus.PENDING_NO_ID]: {
		accountMenuAction: 'accountStatus',
		accountMenuLabel: 'wtr:ACCOUNT_STATUS',
		marginLevelAction: 'accountStatus',
		marginLevelLabel: 'wtr:ACCOUNT_STATUS',
		signalsAction: 'accountStatus',
		tradeButtons: 'accountStatus',
		mainMenuAction: 'account',
		addAccount: 'accountStatus',
		manageFunds: 'accountStatus',
		reports: 'accountStatus',
		tradersGym: 'accountStatus',
		portalPage: 'accountStatus',
		notificationBell: false,
	},
};

export default withoutLiveAccount_inLiveMode;
