import { useContext } from 'react';

import AppContext from '../../../contexts/AppContext';
import { TWatchlists } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import authStore, { AuthStore } from '../../../store/authStore';
import useSaveUserPreferences from '../../mutations/useSaveUserPreferences';
import tradingAccountStore from '../../../store/tradingAccountStore';

const useSaveWatchlistToPreferences = () => {
	const appContext = useContext(AppContext);
	const { mutate: savePreferences } = useSaveUserPreferences();

	const tradingMode = authStore((store: AuthStore) => store.tradingMode);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanSpread = tradingAccountStore.use.isJapanSpread();
	return (preference: TWatchlists[], isSpreadBettingWatchlist?: boolean) => {
		if (appContext.userPreferences) {
			const accountToUpdate = appContext.userPreferences.user_prefs.watchlists.account.filter((accountType) => {
				if (isSpreadBettingAccount || isSpreadBettingWatchlist) {
					return accountType._type.toUpperCase() === tradingMode && accountType.accountType === 'SpreadBetting';
				}  if (isJapanSpread) {
					return accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'JapanSpread';
				} else {
					return accountType._type.toUpperCase() === tradingMode && accountType?.accountType === undefined;
				}
			});
			accountToUpdate.forEach((account) => {
				account.watchlist = preference;
			});

			savePreferences();
		}
	};
};

export default useSaveWatchlistToPreferences;
