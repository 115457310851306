import {
  AccountMarketType,
	HistoryTick,
	MarketItem,
	PriceQuote,
	RFPDataObjectType,
	TradingAccount,
	TradingInstruments,
	TradingPositionState,
} from '../../../gateways/RfpGateway/rfp.types';
import { MarginAccountType, TradingAccountType } from '../../../utils/functions/enums';
import { ITradersGymContext } from '../TradersGymContext';
import { GymPositionManager } from '../Positions/GymPositionManager';
import { GymTradingPosition } from '../Positions/GymTradingPosition';
import { GymDataItemPlayer } from '../Player/GymDataItemPlayer';
import {calculateMarginLevel} from "../../../utils/functions/calculations";

export class GymTradingAccount implements TradingAccount {
	id: number;
	accountType: TradingAccountType = TradingAccountType.GYM;
	baseCurrency: string;
	credit: number = 0;
	netProfit: number = 0; // Not be used for GYM
	leverage: number;
	preferredFeed: string = 'VTFeed';
	providerAccountId: string = '';
	accountNumber?: number = undefined;
	providerId: number = 0;
	isLoggedIn: boolean = true;
	usedMargin: number = 0;
	marginCalculationType?: number | undefined;
	val?: MarketItem | undefined;
	tradingInstruments?: TradingInstruments | undefined;
	accountMarketType: AccountMarketType;

	private _balance: number = NaN;
	private _grossProfit: number = 0;

	// in the rest of the application the trading positions were reworked to
	// be dicts with position.postId used as keys;
	// this is not needed to be done here right now but would be nice to be done here as well
	// for consistency
	activePositions: GymTradingPosition[] = [];
	closedPositions: GymTradingPosition[] = [];

	isJapanAccount: boolean = false;
	enabled: boolean = true;
	dataObjectType = RFPDataObjectType.TradingAccount;

	positionManager: GymPositionManager;

	constructor(
		id: number,
		baseCurrency: string,
		deposit: number,
		leverage: number,
		tradingInstruments: TradingInstruments | undefined,
		accountMarketType: AccountMarketType,
	) {
		this.id = id;
		this.providerAccountId = `${id}`;
		this.accountNumber = +id;
		this.baseCurrency = baseCurrency;
		this.balance = deposit;
		this.leverage = leverage;
		this.tradingInstruments = tradingInstruments;
		this.accountMarketType = accountMarketType;

		if (baseCurrency === 'JPY') {
			this.isJapanAccount = true;
			this.marginCalculationType = MarginAccountType.Japan;
		} else {
			this.marginCalculationType = MarginAccountType.Unhedged;
		}

		this.positionManager = new GymPositionManager(this, GymDataItemPlayer.sharedInstance());
	}

	public get balance(): number {
		return this._balance;
	}

	public set balance(value: number) {
		this._balance = value < 0 ? 0 : value;
	}

	public get equity(): number {
		return this.grossProfit + this.balance;
	}

	public get freeMargin(): number {
		return this.equity - this.usedMargin;
	}

	public get grossProfit(): number {
		return this._grossProfit;
	}

	public set grossProfit(value: number) {
		this._grossProfit = value;
		this.netProfit = value;
	}

	public get marginLevel(): number {
		return calculateMarginLevel({equity: this.equity, usedMargin: this.usedMargin});
	}

	updatePositions(dataItem: HistoryTick, priceQuote: PriceQuote, simulationId: string) {
		this.positionManager.updatePositions(dataItem, priceQuote, simulationId);
		this.checkForMarginCall();
	}

	checkForMarginCall() {
		if (this.freeMargin <= 0) {
			this.positionManager.marginCall();
		}
	}

	notifyAccountUpdate() {
		// Send event to remove all components from presenter
		const event = new CustomEvent('gymTradingAccountUpdated');
		document.dispatchEvent(event);

		this.storeAccount();
	}

	calcUsedMargin() {
		let marginSum = 0;
		this.activePositions.forEach((position) => {
			if (position.state === TradingPositionState.open) {
				if (position.oP && !isNaN(position.oP)) {
					const positionUsedMargin = position.calcUsedMargin(this);
					if (!isNaN(positionUsedMargin)) {
						marginSum += positionUsedMargin;
					}
				}
			}
		});

		this.usedMargin = marginSum;
	}

	private static buildKey(id: number, accountMarketType: AccountMarketType) {
		return `tradersGym_account_${id}_${accountMarketType}`;
	}

	private static ignoreProps(this: any, key: any, value: any) {
		return (['tradingInstruments', 'activePositions', 'closedPositions', 'positionManager'].indexOf(key) > -1) ? undefined : value;
	}

	storeAccount() {
		if (localStorage) {
			localStorage.setItem(GymTradingAccount.buildKey(this.id, this.accountMarketType), JSON.stringify(this, GymTradingAccount.ignoreProps));
		}
	}

	static restoreAccount(id: number, accountMarketType: AccountMarketType): GymTradingAccount | undefined {
		if (localStorage) {
			const accountData = localStorage.getItem(GymTradingAccount.buildKey(id, accountMarketType));
			if (accountData) {
				const account = JSON.parse(accountData);
				if (account) {
					const copyAccount = new GymTradingAccount(
						account.id,
						account.baseCurrency,
						account.deposit,
						account.leverage,
						undefined,
						account.accountMarketType
					);
					Object.assign(copyAccount, account);

					return copyAccount;
				}
			}
		}
		return undefined;
	}

	/**
	 * Will remove all simulations and positions
	 * @param tradersGymContext Gym context
	 */
	resetAccount(tradersGymContext: ITradersGymContext) {
		if (localStorage) {
			// Remove simulations
			if (tradersGymContext.simulationsManager) {
				tradersGymContext.simulationsManager.removeAllSimulations();
			}

			// Remove selected simulation
			tradersGymContext.lastHistoryTick = undefined;
			tradersGymContext.priceQuote = undefined;
			tradersGymContext.simulation = undefined;

			// Delete all positions
			this.positionManager.deleteAllPositions();
		}
	}

	// Memory management
	destroy() {}
}
