import { useContext } from 'react';

import WindowContext from '../../../contexts/WindowContext';
import authStore, { AuthStore, TradingMode } from '../../../store/authStore';
import { closeChildWindows } from '../../functions/closeChildWindows';

const useSwitchMode = () => {
	const setTradingMode = authStore((store: AuthStore) => store.setTradingMode);
	const windowContext = useContext(WindowContext);

	return (mode: TradingMode) => {
		closeChildWindows(windowContext);
		setTradingMode(mode);
		window.location.reload();
	};
};

export const useSwitchToDemo = () => {
	const switchMode = useSwitchMode();
	return () => {
		document.body.click();
		switchMode(TradingMode.Demo);
	};
};

export const useSwitchToLive = () => {
	const switchMode = useSwitchMode();
	return () => {
		document.body.click();
		switchMode(TradingMode.Live);
	};
};
