import {
	checkIsGlobalOrderSizeExceeded,
	checkIsTierOrderSizeExceeded,
	checkIsVolumeExceeded,
	SubscriptionModalReason,
} from '../functions/subscriptionUtils';

import useSubscriptionInfo from './useSubscriptionInfo';

import useSubscriptionModal from './useSubscriptionModal';

const useHandleOrderConstraint = () => {
	const [subscriptionInfo] = useSubscriptionInfo();
	const { setModalStateProps } = useSubscriptionModal();

	const handleOrderLimit = (orderSize: number) => {
		if (
			subscriptionInfo.maxVolume !== null &&
			subscriptionInfo.remainingVolume !== null &&
			checkIsVolumeExceeded(subscriptionInfo)
		) {
			setModalStateProps({
				reason: SubscriptionModalReason.Volume,
				isOpen: true,
			});
			return SubscriptionModalReason.Volume;
		}
		if (checkIsGlobalOrderSizeExceeded(orderSize)) {
			setModalStateProps({
				reason: SubscriptionModalReason.OrderSizeGlobal,
				isOpen: true,
			});
			return SubscriptionModalReason.OrderSizeGlobal;
		}
		if (checkIsTierOrderSizeExceeded(orderSize, subscriptionInfo)) {
			setModalStateProps({
				reason: SubscriptionModalReason.OrderSizeUpgradable,
				isOpen: true,
			});
			return SubscriptionModalReason.OrderSizeUpgradable;
		}

		return null;
	};

	return handleOrderLimit;
};

export default useHandleOrderConstraint;
