import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from '../../features/Dashboard/Newsfeed/Newsfeed.module.scss';

const EmptyNews = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className={styles.empty}>
				<FontAwesomeIcon className={styles.emptyIcon} icon={['fal', 'newspaper']}></FontAwesomeIcon>
				<div className={styles.message}>{t('wtr:NO_NEWS_INSTRUMENT')}</div>
			</div>
		</>
	);
};

export default EmptyNews;
