import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject, useContext, useRef } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import WtrTooltip from '../../../../../shared/WtrTooltip/WtrTooltip';

import { AppComponentType } from '../../../../../utils/functions/enums';
import DashboardContext from '../../../../../contexts/DashboardContext';

import { TradersGymContext, TradersGymContextType } from '../../../../../pages/TradersGym/TradersGymContext';

import styles from './Item.module.scss';
const noop = () => {};
interface ItemProps {
	icon: IconName;
	route: string;
	tooltip: string;
	component?: AppComponentType;
	preClick?: () => boolean | Promise<boolean>;
	skipIndicator?: boolean;
	itemClass?: string;
	iconClass?: string;
	containerClass?: string;
	disableTooltip?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	refNeeded?: boolean;
	setParentRef?: (ref: RefObject<HTMLDivElement>) => void;
}

const Item = ({
	icon,
	route,
	component,
	tooltip,
	skipIndicator = false,
	itemClass,
	containerClass,
	iconClass,
	preClick = () => true,
	disableTooltip = false,
	disabled = false,
	isLoading = false,
	refNeeded,
	setParentRef = noop,
}: ItemProps) => {
	const { t } = useTranslation();
	const gymContext = useContext(TradersGymContext);
	const { tradersGymContext } = gymContext as TradersGymContextType;
	const parentRef: RefObject<HTMLDivElement> = useRef(null);

	if (refNeeded) setParentRef(parentRef);

	const dashboardContext = useContext(DashboardContext);
	const history = useHistory();

	const handleItemClick = async () => {
		if (disabled) {
			return;
		}

		if (tradersGymContext.isActive) {
			tradersGymContext.isActive = false;
		}

		const preclickResult = await preClick();
		if (preclickResult) {
			dashboardContext.closeAllOtherTabs();
			history.push(route);
			if (component !== undefined) dashboardContext.presentComponentType = component;
		}
	};

	const active = history.location.pathname === route;

	return (
		<WtrTooltip disabled={disableTooltip} content={t(tooltip || '')} position="right center">
			<div
				ref={parentRef}
				className={cn(styles.container, active && styles.active, containerClass)}
				onClick={handleItemClick}
			>
				<div
					className={cn({
						[styles.iconContainer]: true,
						[styles.itemDisabled]: disabled,
						[styles.itemLoading]: isLoading,
						[itemClass!]: itemClass,
						[styles.indicator]: !skipIndicator,
					})}
				>
					<FontAwesomeIcon
						className={cn({
							[styles.icon]: true,
							[styles.iconDisabled]: disabled,
							[styles.iconLoading]: isLoading,
							[iconClass!]: iconClass,
						})}
						icon={[active ? 'fas' : 'fal', icon]}
					/>
				</div>
			</div>
		</WtrTooltip>
	);
};

export default Item;
