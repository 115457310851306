import { useMutation} from '@tanstack/react-query';
import axios from 'axios';

import { SUBSCR_MGMT_PROV } from '../../setup/config';


const useCancelSubscription = (accountNumber?: null | string) => {
	const mutationKey = ['useCancelSubscription', accountNumber];

	const mutationFn = async (_: any) => {
		try {
			const response = await axios({
				url: '',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					payload: [
						{
							module: 'subscription',
							action: 'cancelSubscription',
							parameters: {
								accountNumber,
								provider: SUBSCR_MGMT_PROV,
							},
						},
					],
				},
			});
			return response.data.payload[0].result;
		} catch (e) {}
	};

	return useMutation({ mutationKey, mutationFn});
};

export default useCancelSubscription;
