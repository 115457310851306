import React from 'react';

import { Trade, Info, News } from './Items';

const RightNavigation = () => {
	return (
		<>
			<Trade />
			<Info />
			<News />
		</>
	);
};

export default RightNavigation;
