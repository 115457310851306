import { immer } from 'zustand/middleware/immer';

import { create } from '../create';
import createSelectors from '../createSelectors';

import { PipStoreTypes } from './pipStore.types';
import { initialState } from './pipStore.initial';

const pipStore = create<PipStoreTypes>()(
	immer((set) => ({
		...initialState,
		setStopLossPips: (pips: number) => {
			set((state) => {
				state.stopLoss = isNaN(pips) ? initialState.stopLoss : pips;
			});
		},

		setTakeProfitPips: (pips: number) => {
			set((state) => {
				state.takeProfit = isNaN(pips) ? initialState.takeProfit : pips;
			});
		},
	}))
);

export default createSelectors(pipStore);
