import { useContext } from 'react';
import AppContext from '../../contexts/AppContext';
import positionsStore from '../../store/PositionsStore/positionsStore';

const useGridHeight = (rowVirtualizer: any, windowHeaderHeight: number = 50) => {
	const appContext = useContext(AppContext);
	const isChildWindow = appContext.isChildWindow;

	const positionPanelHeight = positionsStore.use.positionPanelHeight();
	const gridHeight = positionsStore.use.gridHeight();

	const documentHeight = document.documentElement.clientHeight;
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	if (isChildWindow) {
		if (!isSafari) {
			return {};
		} else {
			return { height: documentHeight - windowHeaderHeight }; // window height - header
		}
	} else if (rowVirtualizer.getTotalSize() + 25 > gridHeight - positionPanelHeight || isSafari) {
		return {
			height: gridHeight - positionPanelHeight, //tells scrollbar how big the table is
			position: 'relative',
		};
	} else {
		return {};
	}
};

export default useGridHeight;
