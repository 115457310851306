import React from 'react';

import styles from './ToastNotificationContent.module.scss';

export type ToastNotificationType = 'success' | 'danger' | 'fatal' | undefined;

interface IToastNotificationContent {
	type: ToastNotificationType;
	message: string;
	title?: string;
}

const TYPE_TO_CLASS_SUFFIX_MAP = {
	success: 'Info',
	danger: 'Error',
	fatal: 'Fatal',
};

const ToastNotificationContent = ({ type = 'success', title, message }: IToastNotificationContent) => (
	<div className={styles[`notification${TYPE_TO_CLASS_SUFFIX_MAP[type]}`]}>
		<div className={styles.icon}></div>
		<div className={styles.body}>
			{title && (
				<div className={styles.title}>
					<div className={styles.toastTitle}>{title}</div>
				</div>
			)}
			<div className={styles.messageContainer}>
				<p className={styles.message}>{message}</p>
			</div>
		</div>
	</div>
);

export default ToastNotificationContent;
