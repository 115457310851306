import { useTranslation } from 'react-i18next';
import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppContext from '../../../../../../contexts/AppContext';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import Nullable, { Optional } from '../../../../../../utils/functions/Nullable';
import { PriceQuote, TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import moment from 'moment/moment';
import {
	convertAmountToLot,
	getInstrumentDetails,
	getPositionPipsChange,
} from '../../../../../../utils/functions/calculations';
import { getGeneralFormatDate } from '../../../../../../utils/functions/subscriptionUtils';

const useClosedTable = () => {
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const languageSettings = appContext.languageSettings;
	const detailedInformation = dashboardContext.detailedInformation;
	const tradingPositions = dashboardContext.getTradingPositions();
	const closeTrade = dashboardContext.closeTrade;

	const selectedTradingAccount = useSelectedTradingAccount()?.id;
	const activeTradingAccount = useSelectedTradingAccount();
	const panelSize = positionsStore.use.panelSize();

	const addCommas = (current: number | string, decPrec?: number | null, isPipsChange?: boolean | null) => {
		decPrec = decPrec ? decPrec : current.toString().split('.')[1]?.length ?? 0;

		if (isPipsChange) decPrec = 2;

		return formatNumberWithCommas(current, decPrec, languageSettings);
	};

	const closedPositionsBySymbol = useMemo(() => {
		let closedPositionsBySymbol = [] as unknown as Map<string, TradingPosition[]>;
		if (closeTrade === 'All') {
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}

		if (closeTrade === 'Today') {
			const todayDate: any = new Date();
			const todayDateFormat = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				const closeDate = new Date(position.cT || 0);
				const closeDateFormat = `${closeDate.getDate()}/${closeDate.getMonth() + 1}/${closeDate.getFullYear()}`;
				if (
					position.state === TradingPositionState.closed &&
					todayDateFormat === closeDateFormat &&
					position.aId === activeTradingAccount?.id
				) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}
		if (closeTrade === '1 Week') {
			const subtractWeek: any = new Date();
			const oneWeekDate: any = subtractWeek.setDate(subtractWeek.getDate() - 7);
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				if (
					position.state === TradingPositionState.closed &&
					position.aId === activeTradingAccount?.id &&
					oneWeekDate <= position.cT!
				) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}
		if (closeTrade === '1 Month') {
			const subtractMonth: any = new Date();
			const oneMonthDate: any = subtractMonth.setMonth(subtractMonth.getMonth() - 1);
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				if (
					position.state === TradingPositionState.closed &&
					position.aId === activeTradingAccount?.id &&
					oneMonthDate <= position.cT!
				) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}
		if (closeTrade === '3 Months') {
			const todayDate: any = Date.now();
			const subtractMonths: any = new Date();
			const threeMonthDate: any = subtractMonths.setMonth(subtractMonths.getMonth() - 3);
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				if (
					position.state === TradingPositionState.closed &&
					position.aId === activeTradingAccount?.id &&
					threeMonthDate <= position.cT! &&
					position.cT! <= todayDate
				) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}
		if (closeTrade === 'YTD') {
			const todayDate: any = Date.now();
			const getYTD: any = new Date(new Date().getFullYear(), 0, 1);
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				if (
					position.state === TradingPositionState.closed &&
					position.aId === activeTradingAccount?.id &&
					getYTD <= position.cT! &&
					position.cT! <= todayDate
				) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}
		if (closeTrade === '1 Year') {
			const todayDate: any = Date.now();
			const subtractYear: any = new Date();
			const yearDate: any = subtractYear.setFullYear(subtractYear.getFullYear() - 1);
			closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
				if (
					position.state === TradingPositionState.closed &&
					position.aId === activeTradingAccount?.id &&
					yearDate <= position.cT! &&
					position.cT! <= todayDate
				) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>());
		}

		return closedPositionsBySymbol;
	}, [tradingPositions, selectedTradingAccount, closeTrade]);

	const tableData = useMemo(() => {
		const items: any[] = [];

		const mapClosedData = (position: TradingPosition, positionLength: number, index: number) => {
			if (detailedInformation) {
				const marketItem = rfpGatewayContext?.getMarketItem(position.code, position.f);
				let selectedInstrumentDetails = getInstrumentDetails(
					detailedInformation,
					activeTradingAccount ? [activeTradingAccount] : [],
					marketItem?.code as string
				);
				const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
				const lots = convertAmountToLot(position.qty || 0, instrumentRndLot);
				const codeOrTicker =
					marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
						? marketItem.exchangeTicker
						: position.code;

				const openOnTranslated = appContext.isJapanAccount
					? getGeneralFormatDate(position.oT, false, true)
					: moment(position.oT).format('D/MMM/YYYY').toString().toUpperCase();
				const closedOnTranslated = appContext.isJapanAccount
					? getGeneralFormatDate(position.cT, false, true)
					: moment(position.cT).format('D/MMM/YYYY').toString().toUpperCase();

				items.push({
					id: positionLength !== 1 ? index : position.code,
					posId: position.posId,
					headID: -1,
					instrument: codeOrTicker,
					side: tt(`${position.side}`),
					Amount: addCommas(position.qty ? Math.round(position.qty * 100) / 100 : 0),
					Lots: addCommas(lots ? Math.round(lots * 100) / 100 : 0),
					TakeProfit: position.tp
						? addCommas(parseFloat((position.tp as number)?.toString()).toFixed(marketItem?.decPrec))
						: '',
					StopLoss: position.sl
						? addCommas(parseFloat((position.sl as number)?.toString()).toFixed(marketItem?.decPrec))
						: '',
					open: addCommas(position.oP?.toFixed(marketItem?.decPrec) ?? 0),
					closed: addCommas(position.cP?.toFixed(marketItem?.decPrec) ?? 0),
					openDate: openOnTranslated,
					closeOn: closedOnTranslated,
					netPL: position.netProfit,
					grossPL: position.grossProfit,
					pipsChange: getPositionPipsChange(position),
					oP: position.oP!.toFixed(marketItem?.decPrec),
					Type: position.type || '',
					qty: position.qty,
					code: position.code,
					qCcy: position.qCcy,
					prc: position.prc,
					comm: position.comm,
					swap: position.swap,
					cP: position.cP!.toFixed(marketItem?.decPrec),
					isGroupRow: false,
					time: moment(position.oT).format('HH:mm:ss').toLocaleUpperCase(),
					closedTime: moment(position.cT).format('HH:mm:ss').toLocaleUpperCase(),
					comment: position.comment,
					dividend: position.dividend,
					subRows: [],
				});
			}
		};

		//populate grid records with individual positions
		if (closeTrade === 'All') {
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					numPositions = numPositions + 1;
					mapClosedData(position, positionLength, index);
				}
			});
		}

		if (closeTrade === 'Today') {
			const todayDate: any = new Date();
			const todayDateFormat = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					const closeDate = new Date(position.cT || 0);
					const closeDateFormat = `${closeDate.getDate()}/${closeDate.getMonth() + 1}/${closeDate.getFullYear()}`;
					if (closeDateFormat === todayDateFormat) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				}
			});
		}
		if (closeTrade === '1 Week') {
			const todayDate: any = Date.now();
			const subtractWeek: any = new Date();
			const oneWeekDate: any = subtractWeek.setDate(subtractWeek.getDate() - 7);
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					if (oneWeekDate <= position.cT! && position.cT! <= todayDate) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				}
			});
		}
		if (closeTrade === '1 Month') {
			const todayDate: any = Date.now();
			const subtractMonth: any = new Date();
			const oneMonthDate: any = subtractMonth.setMonth(subtractMonth.getMonth() - 1);
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					if (oneMonthDate <= position.cT! && position.cT! <= todayDate) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				}
			});
		}
		if (closeTrade === '3 Months') {
			const todayDate: any = Date.now();
			const subtractMonths: any = new Date();
			const threeMonthDate: any = subtractMonths.setMonth(subtractMonths.getMonth() - 3);
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					if (threeMonthDate <= position.cT! && position.cT! <= todayDate) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				}
			});
		}
		if (closeTrade === 'YTD') {
			const todayDate: any = Date.now();
			const getYTD: any = new Date(new Date().getFullYear(), 0, 1);
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					if (getYTD <= position.cT! && position.cT! <= todayDate) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				}
			});
		}
		if (closeTrade === '1 Year') {
			const todayDate: any = Date.now();
			const subtractYear: any = new Date();
			const yearDate: any = subtractYear.setFullYear(subtractYear.getFullYear() - 1);
			let numPositions = 0;
			tradingPositions.forEach((position, index) => {
				const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);
				if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
					if (yearDate <= position.cT! && position.cT! <= todayDate) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				}
			});
		}

		return items;
	}, [tradingPositions, selectedTradingAccount, closedPositionsBySymbol, panelSize]);

	return tableData;
};

export default useClosedTable;
