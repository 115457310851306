import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const useSetupMFA = () => {
	const mutationFn = async () => {
		try {
			const response = await axios({
				url: `/user/tfa/generate`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (e: any) {
			return e.response.data;
		}
	};

	return useMutation({
		mutationKey: ['useSetupMFA'],
		mutationFn,
	});
};

export default useSetupMFA;
