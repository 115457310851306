import React from 'react';

import cn from 'classnames';

import styles from './InputError.module.scss';

interface InputErrorProps {
	content?: string;
	error: boolean;
	className?: string;
	children?: React.ReactNode;
}

const InputError: React.FC<InputErrorProps> = ({ content, error, className, children }) => {
	const errorMessage = content || children;
	return <div className={cn(className, styles.errorMessage)}>{error && <>{errorMessage}</>}</div>;
};

export default InputError;
