import TTSubscriptionManager from '../../utils/functions/subscriptionManager';
import { TradingPositionSide, TradingPositionType } from '../../utils/functions/enums';

import {
	HistorySubscription,
	MarketItem,
	SubscriptionAction,
	TimeScale,
	TradingPositionState,
	PriceType,
} from './rfp.types';

export interface OrderConfirmType {
	orderType?: 'BUY' | 'SELL';
	selectedInstrument: MarketItem;
	selectedTradingAccount: any;
	tradeSize: number;
	lotAmountQty?: number;
	typeOfOrder?: TradingPositionType;
	limitPrice?: any;
	stopLossValues?: any;
	profitValues?: any;
	posId?: any;
	reqId?: any;
	state?: TradingPositionState;
	expiryDate?: any;
}

export const newTradeRequest = (orderConfirmObj: OrderConfirmType) => {
	if (orderConfirmObj.typeOfOrder === TradingPositionType.Market) {
		return {
			acctId: orderConfirmObj.selectedTradingAccount,
			code: orderConfirmObj.selectedInstrument.code,
			feedId: orderConfirmObj.selectedInstrument.feedId,
			limitLevel: orderConfirmObj.profitValues || 0,
			reqId: orderConfirmObj.reqId ? orderConfirmObj.reqId : +new Date().getMilliseconds(),
			stopLevel: orderConfirmObj.stopLossValues || 0,
			tradeSide: orderConfirmObj.orderType,
			tradeSize: orderConfirmObj.tradeSize,
			lotAmountQty: orderConfirmObj.lotAmountQty,
		};
	}
	if (
		orderConfirmObj.typeOfOrder === TradingPositionType.Limit ||
		orderConfirmObj.typeOfOrder === TradingPositionType.Stop
	) {
		return {
			acctId: orderConfirmObj.selectedTradingAccount,
			code: orderConfirmObj.selectedInstrument.code,
			expiration: orderConfirmObj.expiryDate,
			feedId: orderConfirmObj.selectedInstrument.feedId,
			limitLevel: orderConfirmObj.profitValues || 0,
			priceLevel: orderConfirmObj.limitPrice,
			reqId: +new Date().getMilliseconds(),
			stopLevel: orderConfirmObj.stopLossValues || 0,
			tradeSide: orderConfirmObj.orderType,
			tradeType: orderConfirmObj.typeOfOrder.toUpperCase(),
			tradeSize: orderConfirmObj.tradeSize,
			lotAmountQty: orderConfirmObj.lotAmountQty,
		};
	}
};

export const editPositionRequest = (tradingAccount: any, selectedPosition: any) => {
	return {
		acctId: tradingAccount,
		posId: selectedPosition.posId,
		reqId: +new Date().getMilliseconds(),
		limitLevel: 0,
		stopLevel: 0,
	};
};

export const modifyOrderRequest = (modifyOrderConfirmObj: OrderConfirmType) => {
	if (
		modifyOrderConfirmObj.typeOfOrder === TradingPositionType.Limit ||
		modifyOrderConfirmObj.typeOfOrder === TradingPositionType.Stop
	) {
		return {
			reqId: +new Date().getMilliseconds(),
			acctId: modifyOrderConfirmObj.selectedTradingAccount,
			posId: modifyOrderConfirmObj.posId,
			priceLevel: modifyOrderConfirmObj.limitPrice,
			expiration: modifyOrderConfirmObj.expiryDate,
			limitLevel: modifyOrderConfirmObj.profitValues || null,
			stopLevel: modifyOrderConfirmObj.stopLossValues || null,
			tradeSide: modifyOrderConfirmObj.orderType,
			tradeSize: modifyOrderConfirmObj.tradeSize,
			tradeType: modifyOrderConfirmObj.typeOfOrder.toUpperCase(),
		};
	}
	if (modifyOrderConfirmObj.typeOfOrder === TradingPositionType.Market) {
		return {
			reqId: +new Date().getMilliseconds(),
			acctId: modifyOrderConfirmObj.selectedTradingAccount,
			posId: modifyOrderConfirmObj.posId,
			limitLevel: modifyOrderConfirmObj.profitValues || null,
			stopLevel: modifyOrderConfirmObj.stopLossValues || null,
			tradeSide: modifyOrderConfirmObj.orderType,
			tradeSize: modifyOrderConfirmObj.tradeSize,
			tradeState: modifyOrderConfirmObj.state,
		};
	}
};

export const historySubscription = (
	subscriptionType: SubscriptionAction,
	marketItem: MarketItem,
	timescale: TimeScale
): HistorySubscription => {
	return {
		reqId: TTSubscriptionManager.instance.getHistoryRequestId(marketItem.code, timescale, 'Bid'),
		action: subscriptionType,
		feedId: marketItem.feedId,
		code: marketItem.code,
		priceType: 'Bid',
		timescale: timescale || 'TS_1MIN',
	};
};
