import { create } from 'zustand';

import createSelectors from '../createSelectors';

import { PriceQuote } from '../../gateways/RfpGateway/rfp.types';

export type QuoteState = {
	quotes: Record<
		string,
		{ sellDirection: number; buyDirection: number; previousQuote: PriceQuote; currentQuote: PriceQuote }
	>;
	setQuote: (quote: PriceQuote) => void;
	maxVolatility: number;
	setMaxVolatility: (value: number) => void;
};

const calcSellDirection = (currentQuote: PriceQuote, previousQuote?: PriceQuote, prevSellDirection?: number) => {
	if (previousQuote && currentQuote.b !== previousQuote.b) {
		return currentQuote.b > previousQuote.b ? -1 : 1;
	}
	return prevSellDirection ?? 0;
};

const calcBuyDirection = (currentQuote: PriceQuote, previousQuote?: PriceQuote, prevBuyDirection?: number) => {
	if (previousQuote && currentQuote.a !== previousQuote.a) {
		return currentQuote.a > previousQuote.a ? -1 : 1;
	}
	return prevBuyDirection ?? 0;
};

const quoteStore = create<QuoteState>((set) => ({
	quotes: {},
	setQuote: (quote: PriceQuote) => {
		set((state) => ({
			quotes: {
				...state.quotes,
				[quote.c]: {
					sellDirection: calcSellDirection(
						quote,
						state.quotes[quote.c]?.previousQuote,
						state.quotes[quote.c]?.sellDirection
					),
					buyDirection: calcBuyDirection(
						quote,
						state.quotes[quote.c]?.previousQuote,
						state.quotes[quote.c]?.buyDirection
					),
					previousQuote: state.quotes[quote.c]?.currentQuote || quote,
					currentQuote: quote,
				},
			},
		}));
	},
	maxVolatility: 1,
	setMaxVolatility: (value: number) => {
		set((state) => ({
			...state,
			maxVolatility: value,
		}));
	},
}));

export default createSelectors(quoteStore);
