import React, { useState, useEffect, useContext } from 'react';

import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import isWindowStyles from '../../../../../utils/functions/isWindowStyles';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';

import styles from './WatchlistHeader.module.scss';

interface WatchlistHeaderProps {
	isEquity: boolean;
	window?: boolean;
	editLayer: boolean;
	clickCount?: any;
	id?: string;
}

const WatchlistHeader = React.memo<WatchlistHeaderProps>(
	({ isEquity, window, editLayer, clickCount }) => {
		const appContext = useContext(AppContext);
		const forceRerender = useForceRerender();
		const theme = appContext.appTheme;
		const currentWatchList = watchListStore.use.currentWatchList();
		const [sort, setSort] = useState<string>('manual');
		const { t } = useTranslation();

		useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

		useEffect(() => {
			setSort('manual');
		}, [currentWatchList]);

		const handleInstrumentClick = () => {
			if (sort === 'manual') {
				setSort('ascend');
			} else if (sort === 'ascend') {
				setSort('descend');
			} else if (sort === 'descend') {
				setSort('manual');
			}
		};

		return (
			<div className={cn(!editLayer ? styles.header : styles.headerEdit)}>
				{isEquity ? <div className={styles.delayedQuote}>{t('dashboard:DELAYED_QUOTE')}</div> : null}
				<div className={cn(styles.watchlistAccordion, styles[`${theme}WatchListAccordion`])}>
					<div
						className={isWindowStyles(
							!editLayer ? styles.instrumentHeader : styles.editInstrument,
							styles.windowInstrument,
							window
						)}
						onClick={() => handleInstrumentClick()}
					>
						{t('en:INSTRUMENT')}
						{!editLayer && sort === 'ascend' && (
							<FontAwesomeIcon className={styles.instrumentChevron} icon={['fas', 'angle-up']} size="1x" />
						)}
						{!editLayer && sort === 'descend' && (
							<FontAwesomeIcon className={styles.instrumentChevron} icon={['fas', 'angle-down']} size="1x" />
						)}
					</div>

					{!editLayer && (
						<div className={styles.columnLabelContainer}>
							<div className={styles.columnLabel}>| {t('en:SELL')}</div>
							<div className={styles.columnLabel}>| {t('en:BUY')}</div>
						</div>
					)}
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		const keys: Array<keyof typeof prevProps> = ['isEquity', 'window'];
		return keys.every((key) => prevProps[key] == nextProps[key]);
	}
);

export default WatchlistHeader;
