import TagManager from 'react-gtm-module';

import { isPreviewProduction, isProduction } from './config';

const tagManagerArgs = {
	gtmId: 'GTM-P4PNWVM',
};

if (isProduction || isPreviewProduction) {
	TagManager.initialize(tagManagerArgs);
}
