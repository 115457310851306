import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import DashboardContext from '../../../../../../contexts/DashboardContext';
import useObservable from '../../../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../../../utils/hooks/useForceRerender';

import { Item } from './';

const RightNavInfo = () => {
	const { t } = useTranslation();
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const dashboardContext = useContext(DashboardContext);

	const { showOrderInformation } = dashboardContext;

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'showOrderTicket',
			'showOrderInformation',
			'applicationStatus',
			'tradingAccount',
			'showConfirmTicket',
			'showNewsFeed'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	const handleClickInfo = () => {
		dashboardContext.closeAllOtherTabs();
		dashboardContext.showOrderInformation = !showOrderInformation;
		dashboardContext.toggleAccordionMenu = '0';

		//Should we display Modals instead of the Info?
	};

	return (
		<Item
			onClick={handleClickInfo}
			tooltipText={t('en:INFORMATION')}
			selected={dashboardContext.showOrderInformation}
			icon={[showOrderInformation ? 'fas' : 'fal', 'info-circle']}
		/>
	);
};

export default RightNavInfo;
