import { EventEmitter } from 'events';

import { isPreviewProduction, isProduction } from '../../setup/config';

import { default as Nullable, Optional } from './Nullable';
import { default as TypeUtils } from './TypeUtils';
import { default as PromiseFactory } from './PromiseFactory';

type Dispatchable<T = any> = {
	id: string | number;
	data: T;
	timestamp: Optional<Date>;
};

type DispatchHandlerConfig<T = any> = {
	dispatchInterval: number;
	dispatchAction: (data: T[]) => any;
};

interface IDispatchHandlerEventMap<T = any> {
	dispatch: (sender: DispatchHandler<T>, ...data: Dispatchable<T>[]) => any;
}

export class DispatchHandler<T = any> {
	private m_config: DispatchHandlerConfig<T>;
	private m_data: Map<string | number, Dispatchable<T>> = new Map<string | number, Dispatchable<T>>();
	private m_lastDataUpdate: Optional<Date> = null;
	private m_lastDispatch: Optional<Date> = null;
	private m_isDispatching: boolean = false;
	private m_timeoutInterval: any = null;
	private m_eventEmitter: EventEmitter = new EventEmitter();
	private m_asyncPromise: Optional<Promise<void>> = null;

	public get config(): Readonly<DispatchHandlerConfig<T>> {
		return this.m_config;
	}

	public get lastDataUpdate(): Optional<Date> {
		return this.m_lastDataUpdate;
	}

	public get lastDispatch(): Optional<Date> {
		return this.m_lastDispatch;
	}

	public get isDispatching(): boolean {
		return this.m_isDispatching;
	}

	public get isReady(): boolean {
		if (
			this.m_asyncPromise ||
			this.m_isDispatching ||
			this.m_lastDataUpdate == null ||
			(this.m_lastDispatch != null && this.m_lastDispatch >= this.m_lastDataUpdate)
		) {
			return false;
		} else {
			const lastDispatch = new Date();
			lastDispatch.setMilliseconds(lastDispatch.getMilliseconds() - this.config.dispatchInterval);
			return this.m_lastDispatch == null || this.m_lastDispatch <= lastDispatch;
		}
	}

	public constructor(config: DispatchHandlerConfig<T>) {
		this.m_config = { ...config };
		this.m_config.dispatchInterval = Math.max(Math.round(this.m_config.dispatchInterval), 1);
		Object.freeze(this.m_config);
	}

	public queue(dispatchable: T): this;
	public queue(dispatchable: Dispatchable<T>): this;
	public queue(dispatchable: Dispatchable<T> | T): this {
		this.m_lastDataUpdate = new Date();
		dispatchable = TypeUtils.is<Dispatchable>(
			dispatchable,
			(subject) => subject['id'] != null && subject['data'] != null
		)
			? { ...dispatchable, timestamp: this.m_lastDataUpdate }
			: {
					id: `${this.m_lastDataUpdate.getTime()}-${Math.random()}`,
					timestamp: this.m_lastDataUpdate,
					data: dispatchable,
			  };
		this.m_data.set(dispatchable.id, dispatchable);
		return this;
	}

	public remove(dispatchable: Dispatchable<T>): this;
	public remove(dispatchable: string | number): this;
	public remove(dispatchable: Dispatchable<T> | string | number): this {
		TypeUtils.is<Dispatchable>(dispatchable, (subject) => subject['id'] != null)
			? this.m_data.delete(dispatchable.id)
			: this.m_data.delete(dispatchable);
		return this;
	}

	public has(dispatchable: Dispatchable<T>): boolean;
	public has(dispatchable: string | number): boolean;
	public has(dispatchable: Dispatchable<T> | string | number): boolean {
		return TypeUtils.is<Dispatchable>(dispatchable, (subject) => subject['id'] != null)
			? this.m_data.has(dispatchable.id)
			: this.m_data.has(dispatchable);
	}

	public stop(): void {
		this.m_timeoutInterval != null && clearInterval(this.m_timeoutInterval);
		this.m_timeoutInterval = null;
	}

	public run(): void {
		this.doDispatch();
	}

	public runAsync(): Promise<void> {
		const promise = (this.m_asyncPromise =
			this.m_asyncPromise ||
			PromiseFactory.default
				.delay(Math.random() * (50 - 10) + 10, (resolve, reject) => {
					//this.doDispatch().finally(resolve)
					this.doDispatch();
					resolve();
				})
				.then(() => {
					this.m_asyncPromise = null;
				}));
		return promise;
	}

	public on<K extends keyof IDispatchHandlerEventMap>(event: K, listener: IDispatchHandlerEventMap[K]): this {
		this.m_eventEmitter.on(event, listener);
		return this;
	}

	public off<K extends keyof IDispatchHandlerEventMap>(event: K, listener: IDispatchHandlerEventMap[K]): this {
		this.m_eventEmitter.off(event, listener);
		return this;
	}

	public once<K extends keyof IDispatchHandlerEventMap>(event: K, listener: IDispatchHandlerEventMap[K]): this {
		this.m_eventEmitter.once(event, listener);
		return this;
	}

	private emit<K extends keyof IDispatchHandlerEventMap>(
		event: K,
		...data: Parameters<IDispatchHandlerEventMap[K]>
	): this {
		this.m_eventEmitter.emit(event, ...data);
		return this;
	}

	private doDispatch(): void {
		//get last dispatch and last data update
		const lastDataUpdate: Optional<Date> = this.m_lastDataUpdate;
		const lastDispatch: Optional<Date> = this.m_lastDispatch;

		//don't dispatch if we're already dispatching or we haven't received new data since last dispatch
		if (this.m_isDispatching || lastDataUpdate == null || (lastDispatch != null && lastDispatch >= lastDataUpdate)) {
			return;
		}
		//if(this.m_isDispatching || lastDataUpdate == null || (lastDispatch != null && lastDispatch >= lastDataUpdate)) { return Promise.resolve() }

		//set isUpdating to true to avoid multiple, concurrent executions of this method
		this.m_isDispatching = true;

		//set last dispatch to current time
		this.m_lastDispatch = new Date();

		//get data array
		const data: Dispatchable<T>[] = Array.from(this.m_data.values());

		//execute UI update action
		this.dispatch(data)
			.then(() => {
				data.forEach(
					(value) => value.timestamp === this.m_data.get(value.id)?.timestamp && this.m_data.delete(value.id)
				);
				this.emit('dispatch', this, ...data);
			})
			.catch(() => (this.m_lastDispatch = lastDispatch))
			.finally(() => (this.m_isDispatching = false));

		/*const promise = this.dispatch(data)
			.then(() => {
				data.forEach((value) => (value.timestamp === this.m_data.get(value.id)?.timestamp) && this.m_data.delete(value.id))
				this.emit('dispatch', this, ...data)
			})
			.catch(() => this.m_lastDispatch = lastDispatch)
		promise.finally(() => this.m_isDispatching = false)
		return promise as Promise<void>*/
	}

	private dispatch(data: Dispatchable<T>[]): Promise<any> {
		/*try {
			const result = this.config.dispatchAction(data.map(value => value.data))
			if(TypeUtils.is<Promise<any>>(result, (subject) => { return typeof (subject?.then) === 'function' && typeof (subject?.catch) === 'function'})) {
				return result
			}
			else {
				return Promise.resolve()
			}
		}
		catch {
			return Promise.reject()
		}*/

		return new Promise<void>(async (resolve, reject) => {
			try {
				this.config.dispatchAction(data.map((value) => value.data));
				resolve();
			} catch (error) {
				if (!isProduction || !isPreviewProduction) {
					console.error(error);
				}
				reject();
			}
		});
	}
}

export default class Dispatcher {
	private m_handlers: Map<string, DispatchHandler<any>> = new Map<string, DispatchHandler<any>>();
	private m_isDispatching: boolean = false;
	private m_isRunning: boolean = false;
	private m_dispatchInterval: number = 50;
	private m_timerInterval: any = null;

	public get handlers(): ReadonlyMap<string, DispatchHandler<any>> {
		return this.m_handlers;
	}

	public get dispatchInterval(): number {
		return this.m_dispatchInterval;
	}

	public set dispatchInterval(value: number) {
		this.m_dispatchInterval = Math.floor(Math.max(value, 0));
	}

	public setHandler<T extends object = object>(
		id: string,
		handler: DispatchHandler<T> | DispatchHandlerConfig<T>
	): this {
		this.removeHandler(id);
		this.m_handlers.set(id, handler instanceof DispatchHandler ? handler : new DispatchHandler<T>(handler));
		return this;
	}

	public setHandlers(handlers: { [key: string]: DispatchHandler<any> | DispatchHandlerConfig<any> }): this {
		for (const key in handlers) {
			this.setHandler(key, handlers[key]);
		}
		return this;
	}

	public getHandler(id: string): Optional<DispatchHandler<any>> {
		return this.m_handlers.get(id);
	}

	public removeHandler(id: string): this {
		const handler = this.getHandler(id);
		if (handler != null) {
			handler.stop();
			this.m_handlers.delete(id);
		}
		return this;
	}

	public hasHandler(id: string): boolean {
		return this.m_handlers.has(id);
	}

	public clearHandlers(): this {
		this.stop();
		this.m_handlers.clear();
		return this;
	}

	public dispatch(handlerId: string, data: any): boolean {
		const handler = this.getHandler(handlerId);
		if (TypeUtils.is<DispatchHandler>(handler, (subject) => typeof subject?.queue === 'function')) {
			handler.queue(data);
			return true;
		}
		return false;
	}

	public start(): void {
		this.stop();
		this.m_isRunning = true;
		this.m_timerInterval = setInterval(() => this.runHandlers(), this.dispatchInterval);
	}

	public stop(): void {
		this.m_isRunning = false;
		this.handlers.forEach((handler, key) => handler.stop());
		if (this.m_timerInterval != null) {
			clearInterval(this.m_timerInterval);
		}
	}

	private runHandlers(): void {
		if (this.m_isRunning && !this.m_isDispatching) {
			this.m_isDispatching = true;
			/*const promises: Promise<any>[] = []
			this.m_handlers.forEach((handler, id) => {
				if(handler.isReady) {
					promises.push(handler.runAsync())
				}
			})
			if(promises.length < 1) {
				this.m_isDispatching = false
			}
			else {
				Promise.allSettled(promises).then(() => this.m_isDispatching = false)
			}*/

			/*const promise = new Promise<void>(async (resolve, reject) => {
				const values = Array.from(this.m_handlers.values())
				for (let index = 0; index < values.length; index++) {
					const value = values[index];
					if(value.isReady) {
						await value.runAsync()
					}
				}
				resolve()
			})
			promise.then(() => this.m_isDispatching = false)*/

			let promise: Promise<any> = Promise.resolve();
			this.m_handlers.forEach((handler, id) => {
				if (handler.isReady) {
					promise = promise.then(() => handler.runAsync());
					//promise = promise.then(async () => await handler.runAsync())
				}
			});
			promise.then(() => (this.m_isDispatching = false));
		}
	}
}
