import React, { useContext } from 'react';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import { addCommas } from '../helpers';
import AppContext from '../../../../../../contexts/AppContext';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';

interface CalcPLPipsProps {
	data: any;
	closed?: boolean;
}

const CalcPLPips = ({ data, closed = false }: CalcPLPipsProps) => {
	const appContext = useContext(AppContext);
	const languageSettings = appContext.languageSettings;

	const quotes = quoteStore.use.quotes();

	const record = data.row.original;

	if (closed) {
		const profitLossPipsValue = isNaN((record.pipsChange || Number.NaN) as number) ? 0 : (record.pipsChange as number);
		return (
			<div style={{ direction: 'ltr' }} className={profitLossPipsValue > 0 ? styles.greenCurrent : styles.redCurrent}>
				{addCommas(profitLossPipsValue?.toFixed(2), languageSettings)}
			</div>
		);
	}

	if (record.isGroupRow) {
		record.pipsChange = record.subRows.reduce((totalPl: number, gridRecord: any) => {
			return +totalPl + (isNaN((gridRecord.position?.pips || Number.NaN) as number) ? 0 : +gridRecord?.position?.pips!);
		}, 0);
	} else {
		record.pipsChange = isNaN((record.position?.pips || Number.NaN) as number) ? 0 : (record.position?.pips as number);
	}

	return (
		<div style={{ direction: 'ltr' }} className={record.pipsChange > 0 ? styles.greenCurrent : styles.redCurrent}>
			{addCommas(record.pipsChange, languageSettings, record.decPrec || 2, true)}
		</div>
	);
};

export default CalcPLPips;
