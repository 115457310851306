import { HistoryTick, PriceQuote, TradingPositionState } from '../../../gateways/RfpGateway/rfp.types';
import { TradingPositionSide, TradingPositionType } from '../../../utils/functions/enums';
import { GymTradingAccount } from '../Accounts/GymTradingAccount';
import { GymDataItemPlayer } from '../Player/GymDataItemPlayer';

import { GymPositionUtils } from './GymPositionUtils';

import { GymTradingPosition } from './GymTradingPosition';

export interface GymPositionManagerDelegate {
	positionClosed?(sender: GymPositionManager, position: GymTradingPosition): void;
	positionOpened?(sender: GymPositionManager, position: GymTradingPosition): void;
	positionModified?(sender: GymPositionManager, position: GymTradingPosition, prevPosition: GymTradingPosition): void;
	positionUpdated?(sender: GymPositionManager, position: GymTradingPosition): void;
	positionDeleted?(sender: GymPositionManager, position: GymTradingPosition): void;
	positionRestored?(sender: GymPositionManager, position: GymTradingPosition): void;
	positionManagerMarginCall?(sender: GymPositionManager): void;
}

export class GymPositionManager {
	private readonly account: GymTradingAccount;
	private player: GymDataItemPlayer;
	private delegates: Set<GymPositionManagerDelegate> = new Set();

	constructor(account: GymTradingAccount, player: GymDataItemPlayer) {
		this.account = account;
		this.player = player;
	}

	addDelegate(delegate: GymPositionManagerDelegate) {
		this.delegates.add(delegate);
	}

	removeDelegate(delegate: GymPositionManagerDelegate) {
		this.delegates.delete(delegate);
	}

	// private removeActivePositionWithId(positionId: number | undefined) {
	// 	const index = this.account.activePositions.findIndex((position) => {
	// 		return position.posId === positionId;
	// 	});
	// 	if (index) {
	// 		this.account.activePositions.splice(index, 1);
	// 	}
	// }

	// removeActivePositionsAfterHistoryTick(dataItem: HistoryTick, priceQuote: PriceQuote, simulationId: string) {
	// 	const feedId = dataItem.feedId;
	// 	const code = dataItem.code;
	// 	const shouldStoreAccountData = this.account.activePositions.length > 0;
	//
	// 	this.account.activePositions.forEach((position) => {
	// 		if (position.posId && position.f === feedId && position.code === code && position.simulationId === simulationId) {
	// 			const openTimeInMillis = position.oT ?? 0 * 1000;
	// 			if (openTimeInMillis > dataItem.closeTime) {
	// 				GymTradingPosition.removePositionStore(position.simulationId, position.posId);
	// 				this.removeActivePositionWithId(position.posId);
	//
	// 				this.account.calcUsedMargin();
	// 				this.account.grossProfit = this.calcGrossProfit();
	//
	// 				// Notify delegate
	// 				this.delegates.forEach((delegate) => {
	// 					delegate.positionDeleted && delegate.positionDeleted(this, position);
	// 				});
	// 			} else {
	// 				position.lastDataCloseTime = dataItem.closeTime;
	// 				position.lastDataClosePrice = dataItem.close;
	//
	// 				position.calcProfit(this.account, priceQuote, this.account.baseCurrency);
	//
	// 				GymTradingPosition.storePosition(position);
	//
	// 				this.account.grossProfit = this.calcGrossProfit();
	//
	// 				//Notify delegate
	// 				this.delegates.forEach((delegate) => {
	// 					delegate.positionUpdated && delegate.positionUpdated(this, position);
	// 				});
	// 			}
	// 		}
	// 	});
	//
	// 	if (shouldStoreAccountData) {
	// 		this.account.storeAccount();
	// 	}
	// }

	updatePositions(dataItem: HistoryTick, priceQuote: PriceQuote, simulationId: string) {
		const feedId = dataItem.feedId;
		const code = dataItem.code;

		for (let i = 0; i < this.account.activePositions.length; i++) {
			const position = this.account.activePositions[i];
			if (position.f === feedId && position.code === code && position.simulationId === simulationId) {
				const oldPosState = position.state;

				let changed = false;
				try {
					changed = position.updatePosition(this.account, dataItem, priceQuote);
				} catch (e) {
					if (position.state === TradingPositionState.deleted) {
						this.account.activePositions.splice(i, 1);

						// Move back with one index
						i--;

						// Notify delegate
						this.delegates.forEach((delegate) => {
							delegate.positionDeleted && delegate.positionDeleted(this, position);
						});

						// TODO GYM - present Alert
						//[Utilities showAlertView:getLocalizedString(@"WARNING", nil) message:exception.reason];

						this.player.pause();
						continue;
					}
				}

				if (changed) {
					if (position.state === TradingPositionState.closed) {
						this.account.closedPositions.push(position);
						this.account.activePositions.splice(i, 1);

						// Move back with one index
						i--;

						this.account.calcUsedMargin();
						this.account.balance += position.grossProfit;
						this.account.grossProfit = this.calcGrossProfit();

						this.account.storeAccount();

						// Notify delegate
						this.delegates.forEach((delegate) => {
							delegate.positionClosed && delegate.positionClosed(this, position);
						});
					} else if (oldPosState === TradingPositionState.pending && position.state === TradingPositionState.open) {
						this.account.calcUsedMargin();

						// Notify delegate
						this.delegates.forEach((delegate) => {
							delegate.positionOpened && delegate.positionOpened(this, position);
						});
					} else {
						// Notify delegate
						this.delegates.forEach((delegate) => {
							delegate.positionUpdated && delegate.positionUpdated(this, position);
						});
					}
				} else {
					GymTradingPosition.storePosition(position);
					// Notify delegate
					this.delegates.forEach((delegate) => {
						delegate.positionUpdated && delegate.positionUpdated(this, position);
					});
				}
			}
		}

		const grossProfit = this.calcGrossProfit();
		if (this.account.grossProfit !== grossProfit) {
			this.account.grossProfit = grossProfit;
			this.account.notifyAccountUpdate();
		}
	}

	validatePendingPosition(position: GymTradingPosition): boolean {
		if (isNaN(position.qty) || position.qty <= 0) {
			throw Error('Position Amount is invalid!');
		}

		const openPrice = position.prc;
		switch (position.side) {
			case TradingPositionSide.Buy: {
				// Check the position open price
				switch (position.type) {
					case TradingPositionType.Limit: {
						if (openPrice >= position.lastDataClosePrice) {
							throw Error('Pending BUY LIMIT position - Open Price is invalid!');
						}
						break;
					}
					case TradingPositionType.Stop: {
						if (openPrice <= position.lastDataClosePrice) {
							throw Error('Pending BUY STOP position - Open Price is invalid!');
						}
						break;
					}
					default:
						throw Error('Pending position - tradeType is invalid!');
				}

				// Stop Loss must be lower than position price
				if (position.hasStopLoss() && position.sl >= openPrice) {
					throw Error('Position Stop Loss is invalid!');
				}

				// Take Profit must be bigger than position price
				if (position.hasTakeProfit() && position.tp <= openPrice) {
					throw Error('Position Take Profit is invalid!');
				}
				break;
			}
			case TradingPositionSide.Sell: {
				// Check the position open price
				switch (position.type) {
					case TradingPositionType.Limit: {
						if (openPrice <= position.lastDataClosePrice) {
							throw Error('Pending SELL LIMIT position - Open Price is invalid!');
						}
						break;
					}
					case TradingPositionType.Stop: {
						if (openPrice >= position.lastDataClosePrice) {
							throw Error('Pending SELL STOP position - Open Price is invalid!');
						}
						break;
					}
					default:
						throw Error('Pending position - Trade Type is invalid!');
				}

				// Stop Loss must be bigger than position price
				if (position.hasStopLoss() && position.sl <= openPrice) {
					throw Error('Position Stop Loss is invalid!');
				}

				// Take Profit must be lower than position price
				if (position.hasTakeProfit() && position.tp >= openPrice) {
					throw Error('Position Take Profit is invalid!');
				}
				break;
			}
		}

		return true;
	}

	validateOpenPosition(position: GymTradingPosition): boolean {
		if (isNaN(position.qty) || position.qty <= 0) {
			throw Error('Position Amount is invalid!');
		}

		switch (position.side) {
			case TradingPositionSide.Buy: {
				// Stop Loss must be lower than current price
				if (position.hasStopLoss() && position.sl >= position.lastDataClosePrice) {
					throw Error('Position Stop Loss is invalid!');
				}

				// Take Profit must be bigger than current price
				if (position.hasTakeProfit() && position.tp <= position.lastDataClosePrice) {
					throw Error('Position Take Profit is invalid!');
				}
				break;
			}
			case TradingPositionSide.Sell: {
				// Stop Loss must be bigger than current price
				if (position.hasStopLoss() && position.sl <= position.lastDataClosePrice) {
					throw Error('Position Stop Loss is invalid!');
				}

				// Take Profit must be lower than current price
				if (position.hasTakeProfit() && position.tp >= position.lastDataClosePrice) {
					throw Error('Position Take Profit is invalid!');
				}
				break;
			}
		}

		if (!GymPositionUtils.validateMargin(this.account, position)) {
			throw Error('Position rejected. Insufficient Available Margin!');
		}

		return true;
	}

	restorePosition(position: GymTradingPosition) {
		switch (position.state) {
			case TradingPositionState.pending:
			case TradingPositionState.open: {
				// Add it in active positions
				this.account.activePositions.push(position);
				break;
			}
			case TradingPositionState.closed: {
				// Add in closed positions
				this.account.closedPositions.push(position);
				break;
			}
			default:
				break;
		}
		// Notify delegate
		this.delegates.forEach((delegate) => {
			delegate.positionRestored && delegate.positionRestored(this, position);
		});
	}

	openOrder(position: GymTradingPosition) {
		// Validation
		if (this.account.activePositions.findIndex((pos) => pos.posId === position.posId) !== -1) {
			throw Error('Position is already opened');
		}

		switch (position.state) {
			case TradingPositionState.pending: {
				this.validatePendingPosition(position);
				break;
			}
			case TradingPositionState.open: {
				// Set open price
				if (this.validateOpenPosition(position)) {
					position.oT = position.lastDataCloseTime;
				}
				break;
			}
			default:
				throw Error('Invalid state for open position');
		}

		// Add it in active positions
		this.account.activePositions.push(position);

		this.account.calcUsedMargin();

		this.account.grossProfit = this.calcGrossProfit();

		this.account.storeAccount();
		GymTradingPosition.storePosition(position);

		// Notify delegate
		this.delegates.forEach((delegate) => {
			delegate.positionOpened && delegate.positionOpened(this, position);
		});
	}

	modifyPosition(position: GymTradingPosition) {
		const index = this.account.activePositions.findIndex((pos) => pos.posId === position.posId);
		if (index !== -1) {
			const prevPosition = this.account.activePositions[index];
			// Validate
			switch (position.state) {
				case TradingPositionState.pending: {
					this.validatePendingPosition(position);
					break;
				}
				case TradingPositionState.open: {
					this.validateOpenPosition(position);
					break;
				}
				default:
					throw Error('Position has invalid state');
			}

			this.account.activePositions[index] = position;

			// Notify delegate
			this.delegates.forEach((delegate) => {
				delegate.positionModified && delegate.positionModified(this, position, prevPosition);
			});

			GymTradingPosition.storePosition(position);
		} else {
			throw Error('Position is not active');
		}
	}

	partialClosePosition(position: GymTradingPosition, quantity: number, dataItem: HistoryTick, priceQuote: PriceQuote) {
		if (position.posId) {
			// Create a copy of position with reduced quantity and close it
			const posCopy = GymTradingPosition.restoreTradingPosition(position.simulationId, position.posId);
			if (posCopy) {
				posCopy.marketItem = position.marketItem;
				posCopy.posId = GymPositionUtils.generatePositionId();
				posCopy.qty = quantity;

				posCopy.updatePosition(this.account, dataItem, priceQuote);

				// reduce position quantity
				position.qty -= quantity;
				position.updatePosition(this.account, dataItem, priceQuote);
				GymTradingPosition.storePosition(position);

				//Notify delegate
				this.delegates.forEach((delegate) => {
					delegate.positionOpened && delegate.positionOpened(this, position);
				});

				this.closePosition(posCopy);
			}
		}
	}

	closePosition(position: GymTradingPosition) {
		// Remove from active positions
		const index = this.account.activePositions.findIndex((pos) => pos.posId === position.posId);
		if (index !== -1) {
			this.account.activePositions.splice(index, 1);
		}

		switch (position.state) {
			case TradingPositionState.pending: {
				if(position.posId) {
					GymTradingPosition.removePositionStore(position.simulationId, position.posId);
				}

				break;
			}
			case TradingPositionState.open: {
				// Marked as closed
				position.cT = position.lastDataCloseTime;
				position.cP = position.lastDataClosePrice;
				position.setPositionInClosedState(this.account.baseCurrency);
				position.state = TradingPositionState.closed;
				// Add in closed positions
				this.account.closedPositions.push(position);
				GymTradingPosition.storePosition(position);
				break;
			}
			default:
				console.log('Position has invalid state');
				break;
		}

		this.account.calcUsedMargin();

		this.account.grossProfit = this.calcGrossProfit();

		if (position.state === TradingPositionState.closed) {
			this.account.balance += position.grossProfit;
		}

		this.account.storeAccount();

		// Notify delegate
		this.delegates.forEach((delegate) => {
			delegate.positionClosed && delegate.positionClosed(this, position);
		});
	}

	deletePositionById(posId: number) {
		let pos =
			this.account.activePositions.find((pos) => pos.posId === posId) ??
			this.account.closedPositions.find((pos) => pos.posId === posId);
		if (pos) {
			this.deletePosition(pos);
		}
	}

	deletePosition(position: GymTradingPosition) {
		if (position.posId) {
			if (position.state === TradingPositionState.closed) {
				const index = this.account.closedPositions.findIndex((pos) => pos.posId === position.posId);
				if (index === -1) {
					return;
				}

				this.account.closedPositions.splice(index, 1);
			} else {
				const index = this.account.activePositions.findIndex((pos) => pos.posId === position.posId);
				if (index === -1) {
					return;
				}

				this.account.activePositions.splice(index, 1);
			}

			this.account.calcUsedMargin();

			if (!isNaN(position.grossProfit)) {
				if (position.state === TradingPositionState.closed) {
					this.account.balance -= position.grossProfit;
				}

				this.account.grossProfit = this.calcGrossProfit();
			}

			this.account.storeAccount();

			GymTradingPosition.removePositionStore(position.simulationId, position.posId);

			// Notify delegate
			this.delegates.forEach((delegate) => {
				delegate.positionDeleted && delegate.positionDeleted(this, position);
			});
		}
	}

	deleteAllPositionsWithSimulationId(simulationId: string) {
		const allPosition = [
			...this.account.activePositions.filter((value) => value.simulationId === simulationId),
			...this.account.closedPositions.filter((value) => value.simulationId === simulationId),
		];
		for (let i = 0; i < allPosition.length; i++) {
			this.deletePosition(allPosition[i]);
		}
	}

	deleteAllPositions() {
		const allPosition = [...this.account.activePositions, ...this.account.closedPositions];
		for (let i = 0; i < allPosition.length; i++) {
			this.deletePosition(allPosition[i]);
		}
	}

	calcGrossProfit() {
		let grossProfit = 0.0;

		this.account.activePositions.forEach((position) => {
			if (position.grossProfit) {
				grossProfit += position.grossProfit;
			}
		});

		return grossProfit;
	}

	marginCall() {
		const shouldPresentLiquidationMsg = this.account.activePositions.length > 0;
		for (let i = 0; i < this.account.activePositions.length; i++) {
			const position = this.account.activePositions[i];
			if (position.state === TradingPositionState.open) {
				this.closePosition(position);
				position.comment = 'Margin Call';
			} else {
				this.deletePosition(position);
			}
			i--;
		}

		this.player.pause();

		if (shouldPresentLiquidationMsg) {
			// Notify delegate
			this.delegates.forEach((delegate) => {
				delegate.positionManagerMarginCall && delegate.positionManagerMarginCall(this);
			});
		}
	}
}
