import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../../shared/Button/Button';

import statusStore from '../../../../../store/accountStatusStore';

import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

import styles from './SignalsPopup.module.scss';

interface SignalsPopupProps {
	handleSignalPopupCta: () => void;
	setOpenSignalPopup: (open: boolean) => void;
	signalsAction: string;
}

const SignalsPopup = React.forwardRef(
	({ setOpenSignalPopup, handleSignalPopupCta, signalsAction }: SignalsPopupProps, ref) => {
		const { t } = useTranslation();

		const hasLiveAccount = statusStore.use.hasLiveAccount();

		return (
			<div ref={ref} className={styles.popupContainer}>
				<span className={styles.title}>
					{hasLiveAccount ? t('wtr:UNLOCK_SIGNALS_TITLE_LIVE') : t('wtr:UNLOCK_SIGNALS_TITLE_DEMO')}
				</span>

				<div className={styles.content}>
					<Trans i18nKey={hasLiveAccount ? 'wtr:UNLOCK_SIGNALS_TEXT_LIVE' : 'wtr:UNLOCK_SIGNALS_TEXT_DEMO'} />
				</div>
				<div className={styles.buttonsContainer}>
					<Button
						className={styles.button}
						label={t('en:CANCEL')}
						variant="outline"
						size="lg"
						onClick={() => setOpenSignalPopup(false)}
					/>
					<Button
						className={styles.button}
						label={
							signalsAction === IStatusConfigEnum.balanceCheck ? t('en:DEPOSIT_FUNDS') : t('en:CREATE_ACCOUNT_TITLE')
						}
						variant="primary"
						size="lg"
						onClick={handleSignalPopupCta}
					/>
				</div>
			</div>
		);
	}
);

export default SignalsPopup;
