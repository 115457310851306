import React from 'react';

import DatePicker from 'react-datepicker';

import styles from './TradeTicketCalendar.module.scss';

interface TradeTicketCalendarProps {
	selectedDate: Date;
	handleDateSelection: (date: Date) => void;
	handleCloseCalendar: () => void;
	datePickerLocale: string;
}

const TradeTicketCalendar = ({
	selectedDate,
	handleDateSelection,
	handleCloseCalendar,
	datePickerLocale,
}: TradeTicketCalendarProps) => {
	return (
		<div className={styles.calendarContainer}>
			<DatePicker
				selected={selectedDate}
				startDate={new Date()}
				onChange={handleDateSelection}
				minDate={new Date()}
				endDate={new Date()}
				inline
				calendarClassName={styles.calendarInput}
				onClickOutside={handleCloseCalendar}
				locale={datePickerLocale}
			/>
		</div>
	);
};

export default TradeTicketCalendar;
