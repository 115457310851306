import { useContext, useEffect, useRef } from 'react';

import AppContext from '../../contexts/AppContext';
import RfpGatewayContext from '../../contexts/RfpGatewayContext';
import { RFPDataObjectType } from '../../gateways/RfpGateway/rfp.types';

import usePresentTradeEvent from './usePresentTradeEvent';

export default (eventCallback?: (event: any) => any) => {
	if (eventCallback === null) {
		return;
	}

	const subIdRef = useRef<string | undefined>(undefined);
	const rfpGateway = useContext(RfpGatewayContext);
	const appContext = useContext(AppContext);

	if (!eventCallback) {
		eventCallback = usePresentTradeEvent();
	}

	useEffect(() => {
		if (rfpGateway) {
			if (subIdRef.current) {
				rfpGateway.unsubscribeFor(subIdRef.current);
			}

			subIdRef.current = rfpGateway.subscribeFor(RFPDataObjectType.TradeEvent, (tradeEvent) => {
				// Display Events
				if (appContext.isLoggedIn && tradeEvent.events) {
					tradeEvent.events.forEach((event: any) => {
						eventCallback!(event);
					});
				}
			});
		}
		return () => {
			if (rfpGateway && subIdRef.current) {
				rfpGateway.unsubscribeFor(subIdRef.current);
			}
		};
	}, [rfpGateway]);
};
