import React, { useState, createContext } from 'react';

export enum NotificationType {
	alert = 'ALERT',
	info = 'INFO',
}

export interface INotification {
	timestamp: string;
	type: NotificationType;
	content: JSX.Element | JSX.Element[] | string | any;
	action: any;
}
export interface IComunnityItem {
	timestamp: string;
	type: NotificationType;
	content: JSX.Element | JSX.Element[] | string | any;
	action: any;
}

interface INotificationsContext {
	notifications: INotification[];
	addNotifications: (newValues: INotification[]) => void;
	resetNotifications: () => void;
}

interface IProviderProps {
	children?: React.ReactNode;
	// children?: JSX.Element | JSX.Element[] | null | boolean;
}

const defaultContext: INotificationsContext = {
	notifications: [],
	addNotifications: (_: INotification[]) => {},
	resetNotifications: () => {},
};

export const NotificationsContext = createContext<INotificationsContext>(defaultContext);

const NotificationsContextProvider = ({ children }: IProviderProps) => {
	const [notifications, setNotifications] = useState<INotification[]>(defaultContext.notifications);

	const addNotifications = (newValues: INotification[]) => {
		setNotifications((prevValues) => [...prevValues, ...newValues]);
	};

	const resetNotifications = () => {
		setNotifications([]);
	};

	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				addNotifications,
				resetNotifications,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
};

export default NotificationsContextProvider;
