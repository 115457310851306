const getUrlWithAppendedQueryString = (currentUrl: string, extraQueryString: string) => {
	if (!extraQueryString) {
		return currentUrl;
	}

	const currentUrlObj = new URL(currentUrl, document.location.origin);
	const hasCurrentUrlQuestMark = currentUrlObj.search || currentUrl.charAt(currentUrl.length - 1) === '?';

	const newUrl = currentUrl + (hasCurrentUrlQuestMark ? '&' : '?') + extraQueryString;
	return newUrl;
};

export default getUrlWithAppendedQueryString;
