import {useEffect, useState} from 'react';

import {TradingAccount} from '../../gateways/RfpGateway/rfp.types';

const usePreferredFeedId = (account?: TradingAccount) => {
    const [preferredFeedId, setPreferredFeedId] = useState<string>('');

    useEffect(() => {
        if (!account) return;
        setPreferredFeedId(account.preferredFeed);
    }, [account]);

    return preferredFeedId === 'TIFeed' ? 'VTFeed' : preferredFeedId;
};

export default usePreferredFeedId;
