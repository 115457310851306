import { useMutation } from '@tanstack/react-query';
import axios from 'axios';


const useGenerateSSOToken = () => {
	const mutationFn = async (_: any) => {
		try {
			const response = await axios({
				url: `/internal/sso`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (e) {
			return e.response.data;
		}
	};

	return useMutation({
		mutationKey: ['useGenerateSSOToken'],
		mutationFn,
	});
};

export default useGenerateSSOToken;
