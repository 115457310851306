import { init as initApm } from '@elastic/apm-rum';

import { versionNumber } from './config';

const client = initApm({
	// Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
	serviceName: 'Web Trader',

	// Set custom APM Server URL (default: http://localhost:8200)
	serverUrl: process.env.REACT_APP_MONITORING_URL,

	// Set the service version (required for source map feature)
	serviceVersion: versionNumber,

	// Set the service environment
	environment: process.env.NODE_ENV,
});

export default client;
