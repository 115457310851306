import React from 'react';

import Routes from '../../../../../setup/routes';
import { AppComponentType } from '../../../../../utils/functions/enums';

import { Item } from './';

const Calendar = () => {
	return (
		<Item
			component={AppComponentType.Calendar}
			route={Routes.trader.calendar}
			icon={'calendar'}
			tooltip="en:NAVIGATION_CALENDAR"
		/>
	);
};

export default Calendar;
