interface NewsKeywords {
	[key: string]: string[];
}

export const newsKeywords: NewsKeywords = {
	LCO: ['BRENT'],
	NGAS: ['NATURAL GAS'],
	WTC: ['WEST TEXAS'],
	WTI: ['WEST TEXAS'],
	AUS200: ['ASX 200'],
	ES: ['S&P 500'],
	SPX500: ['S&P 500'],
	US30: ['DOW JONES'],
	YM: ['DOW JONES'],
	NAS100: ['NASDAQ'],
	NQ: ['NASDAQ'],
	ESTX50: ['EURO STOXX 50'],
	FD: ['DAX'],
	GER30: ['DAX'],
	UK100: ['FTSE 100'],
	FRA40: ['CAC'],
	US2000: ['RUSSELL MID CAP'],
	ZAR40: ['SOUTH AFRICA 40'],
	JPN225: ['NIKKEI'],
	HK50: ['HONG KONG 50'],
	CHINA50: ['CHINA 50'],
	XAUUSD: ['GOLD'],
	XAGUSD: ['SILVER'],
	XPTUSD: ['PLATINUM'],
	BTCUSD: ['BITCOIN'],
	ETHUSD: ['ETHEREUM'],
};

export function getKeywords(code: string) {
	return [
		...(newsKeywords[code] ?? []),
		...(newsKeywords[code.slice(0, 2)] ?? []),
		...(newsKeywords[code.slice(0, 3)] ?? []),
	];
}
