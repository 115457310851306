import React from 'react';
import { Modal as SemanticUiModal } from 'semantic-ui-react';

import cn from 'classnames';

import styles from './WtrModal.module.scss';

const WtrModal = ({ children, ...rest }: any) => {
	rest.className = cn(rest.className, styles.wtrModal);
	return (
		<SemanticUiModal {...rest}>
			{children}
		</SemanticUiModal>
	);
};

WtrModal.Header = SemanticUiModal.Header;
WtrModal.Content = SemanticUiModal.Content;
WtrModal.Description = SemanticUiModal.Description;
WtrModal.Actions = SemanticUiModal.Actions;

export default WtrModal;
