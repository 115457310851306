import { HistoryTick } from '../../../gateways/RfpGateway/rfp.types';

import { GymHistoryRequest } from './GymHistoryRequest';

export class GymHistoryDataStore {
	request: GymHistoryRequest;
	dataItems: HistoryTick[] = [];

	constructor(request: GymHistoryRequest) {
		this.request = request;
	}

	addDataItems(items: HistoryTick[]) {
		this.dataItems.push(...items);

		//BOOL shouldLogRemove = true;
		// Filter for bad data
		while (this.dataItems.length > 0) {
			const item = this.dataItems[this.dataItems.length - 1];
			if (item.closeTime > this.request.endTime) {
				// if(shouldLogRemove) {
				//     shouldLogRemove = false;
				//     _logINFO(@"Remove dataItem: %@", item);
				// }
				this.dataItems.pop();
			} else {
				break;
			}
		}
	}
}
