import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './AccountUserIcon.module.scss';
import authStore from '../../../../../../store/authStore';
import { getAccountIconOptions } from '../../../Settings/settingsOptions';

interface IAccountUserIconProps {
	accountPopupIsOpen?: boolean;
	size?: 'small' | 'large';
}

const AccountUserIcon = ({ accountPopupIsOpen, size = 'small' }: IAccountUserIconProps) => {
	const accountAvatar = authStore.use.accountAvatar();
	const findImage = () => {
		const currentImage = getAccountIconOptions().find((image) => image.value === accountAvatar);

		if (currentImage) {
			return currentImage.image.src;
		}

		return getAccountIconOptions()[0].image.src;
	};

	if (accountAvatar) {
		return <img src={findImage()} alt="Avatar" />;
	}
	return <FontAwesomeIcon className={styles[size]} icon={[accountPopupIsOpen ? 'fas' : 'fal', 'user']} />;
};

export default AccountUserIcon;
