import React, { FC } from 'react';
import styles from './OrHzDivider.module.scss';

const OrHzDivider: FC<{ text: string }> = ({ text }) => (
	<div className={styles.divider}>
		<div className={styles.line} />
		<span className={styles.text}>{text}</span>
		<div className={styles.line} />
	</div>
);

export default OrHzDivider;
