import React from 'react';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import MoneyFormatter from '../../../../../components/MoneyFormatter';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';

interface CalcNetPlProps {
	data: any;
	closed?: boolean;
}

const CalcNetPl = ({ data, closed = false }: CalcNetPlProps) => {
	const quotes = quoteStore.use.quotes();

	const record = data.row.original;

	if (closed) {
		return (
			<div className={cn(styles.alignRight, record.netPL > 0 ? styles.greenCurrent : styles.redCurrent)}>
				<MoneyFormatter money={parseFloat(record.netPL)} />
			</div>
		);
	}

	if (record.isGroupRow) {
		record.netPL = record.subRows.reduce((totalPl: number, gridRecord: any) => {
			return (
				+totalPl +
				(isNaN((gridRecord.position?.netProfit || Number.NaN) as number) ? 0 : +gridRecord?.position?.netProfit!)
			);
		}, 0);
	} else {
		record.netPL = (record.position?.netProfit || Number.NaN).toFixed(2);
	}

	const netPLValue = isNaN((record.netPL || Number.NaN) as number) ? 0 : (record.netPL as number);

	return (
		<div className={cn(styles.alignRight, netPLValue > 0 ? styles.greenCurrent : styles.redCurrent)}>
			<MoneyFormatter money={Number(netPLValue)} />
		</div>
	);
};

export default CalcNetPl;
