import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSubscriptionModal from '../../../../../../utils/hooks/useSubscriptionModal';
import { SubscriptionModalReason, SubscriptionStatus } from '../../../../../../utils/functions/subscriptionUtils';
import useSubscriptionInfo from '../../../../../../utils/hooks/useSubscriptionInfo';

import styles from './SubscriptionOptions.module.scss';

const SubscriptionOptions = ({ setAccountPopupIsOpen }: { setAccountPopupIsOpen: (a: boolean) => void }) => {
	const { t } = useTranslation();
	const [subscriptionInfo] = useSubscriptionInfo();
	const { setModalStateProps } = useSubscriptionModal();

	const handleManageClick = () => {
		setAccountPopupIsOpen(false);
		setModalStateProps({
			reason: SubscriptionModalReason.ManageSubscription,
			isOpen: true,
		});
	};

	const handleStopClick = () => {
		setAccountPopupIsOpen(false);
		setModalStateProps({
			reason: SubscriptionModalReason.ConfirmStop,
			isOpen: true,
			text: null,
		});
	};

	// const handleCancelClick = () => {
	// 	setAccountPopupIsOpen(false);
	// 	setModalStateProps({
	// 		reason: SubscriptionModalReason.ConfirmCancel,
	// 		isOpen: true,
	// 		text: null,
	// 	});
	// };

	const hasManageSubscriptionOption = [SubscriptionStatus.Active, SubscriptionStatus.Unpaid].includes(
		subscriptionInfo.status!
	);

	return (
		<div className={styles.optionsWrapper}>
			{hasManageSubscriptionOption && (
				<div className={styles.option} onClick={handleManageClick}>
					<FontAwesomeIcon icon={['far', 'pen']} />
					<span>{t('wtr:MANAGE_SUBSCRIPTIONS')}</span>
				</div>
			)}
			{subscriptionInfo.status === SubscriptionStatus.Active ? (
				<>
					<div className={styles.option} onClick={handleStopClick}>
						<FontAwesomeIcon icon={['far', 'pause-circle']} />
						<span>{t('wtr:SUSPEND_SUBSCRIPTION')}</span>
					</div>
					{/*<div className={styles.cancelOption} onClick={handleCancelClick}>*/}
					{/*	<FontAwesomeIcon icon={['far', 'times-circle']} />*/}
					{/*	<span>{t('wtr:CANCEL_SUBSCRIPTION')}</span>*/}
					{/*</div>*/}
				</>
			) : null}
		</div>
	);
};

export default SubscriptionOptions;
