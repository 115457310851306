import React from 'react';
import { Store } from 'react-notifications-component';

import ToastNotificationContent, {
	ToastNotificationType,
} from '../../views/components/ToastNotificationContent/ToastNotificationContent';

export const addToastNotification = (
	type: ToastNotificationType,
	message: string,
	title?: string,
	duration: number = 5000
): string => {
	const notificationId = Store.addNotification({
		type: type === 'fatal' ? 'danger' : type,
		insert: 'top',
		container: 'top-right',
		content: <ToastNotificationContent type={type} title={title} message={message} />,
		animationIn: ['animate__animated', 'animate__faster', 'animate__slideInRight'],
		animationOut: ['animate__animated', 'animate__slideOutRight'],
		dismiss: {
			duration,
			showIcon: true,
		},
	});

	setTimeout(() => {
		// Make sure the notification disappears
		Store.removeNotification(notificationId);
	}, duration);

	return notificationId;
};

export const removeToastNotification = (toastNotificationId: string) => {
	Store.removeNotification(toastNotificationId);
};
