import React, { useContext } from 'react';
import { Dropdown as SemanticUiDropdown } from 'semantic-ui-react';

import AppContext from '../../../contexts/AppContext';
import './WtrDropdown.module.scss';

const WtrDropdown = ({ ...rest }) => {
	const { appTheme } = useContext(AppContext);

	rest.className = `${rest.className || ''} ${appTheme}`;

	return <SemanticUiDropdown {...rest} />;
};

export default WtrDropdown;
