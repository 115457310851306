import React from 'react';

import getCurrencyFlag, { CurrencyFlag } from '../../../../../images/flags';

import styles from './AccountCurrency.module.scss';

interface IAccountCurrencyProps {
	code: CurrencyFlag;
}

const AccountCurrency = ({ code }: IAccountCurrencyProps) => (
	<img src={getCurrencyFlag(code)} alt="Flag Logo" className={styles.flag} />
);

export default AccountCurrency;
