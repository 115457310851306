import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface ChangePasswordParameters {
	currentPassword: string;
	newPassword: string;
}

const useChangePassword = () => {
	const mutationFn = async (parameters: ChangePasswordParameters) => {
			const response = await axios({
				url: '',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					payload: [
						{
							module: 'authentication',
							action: 'change_password',
							parameters: {
								password: parameters.currentPassword,
								new_password: parameters.newPassword
							},
						},
					],
				},
			});
			return response.data.payload[0].result;
	};

	return useMutation({
		mutationKey: ['useChangePassword'],
		mutationFn,
	});
};

export default useChangePassword;
