import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../contexts/AppContext';

import i18n from '../../setup/i18n';

import Horizontal from './Horizontal';
import Vertical from './Vertical';

import styles from './LiveChat.module.scss';

import { getConfig } from './';

interface w extends Window {
	zEACLoaded?: boolean;
	zEWebpackACJsonp?: [];
	zEmbed?: () => void;
}

const LiveChat = ({
	onToggle,
	menuStyle = 'horizontal',
}: {
	onToggle?: (state: boolean) => void;
	menuStyle?: 'horizontal' | 'vertical';
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const zdOpen = useRef<boolean>(false);
	const container = useRef<HTMLDivElement>(null);
	const { languageSettings } = useContext(AppContext);
	const { t } = useTranslation();

	const languageToUse = useMemo(() => languageSettings || i18n.language, [languageSettings, i18n.language]);

	const handleZDCleanup = () => {
		if (window.zE) {
			delete window.zE;
		}
		if ((window as unknown as w).zEACLoaded) {
			delete (window as unknown as w).zEACLoaded;
		}
		if ((window as unknown as w).zEWebpackACJsonp) {
			delete (window as unknown as w).zEWebpackACJsonp;
		}
		if ((window as unknown as w).zEmbed) {
			delete (window as unknown as w).zEmbed;
		}
	};

	useEffect(() => {
		if (container.current && languageToUse) {
			const config = getConfig(languageToUse);
			window.zE && window.zE('messenger', 'close');

			const oldScript = document.getElementById('ze-snippet');
			oldScript && oldScript.remove();

			handleZDCleanup();

			const script = document.createElement('script');
			script.id = 'ze-snippet';
			script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.key}`;
			script.onload = () => {
				window.zE('messenger', 'close');
				window.zE('messenger:set', 'locale', config.locale);

				// Reload the widget
				window.zE('messenger:on', 'open', () => {
					setIsOpen(false);
					onToggle && onToggle(false);
					zdOpen.current = true;
				});
				window.zE('messenger:on', 'close', () => {
					setIsOpen(false);
					onToggle && onToggle(false);
					zdOpen.current = false;
				});
			};

			container.current.appendChild(script);
		}
	}, [languageToUse]);

	const toggleState = () => {
		setIsOpen(!isOpen);
		onToggle && onToggle(!isOpen);
	};

	const handleZDClick = () => {
		setIsOpen(false);
		window.zE && window.zE('messenger', zdOpen.current ? 'close' : 'open');
	};

	const horizontal = menuStyle === 'horizontal';
	const channels = useMemo(() => getConfig(languageToUse).channels, [languageToUse]);

	return (
		<>
			<div className={horizontal ? styles.liveChat : ''} ref={container}>
				{horizontal ? (
					<Horizontal isOpen={isOpen} onZDClick={handleZDClick} onToggle={toggleState} channels={channels as any} />
				) : (
					<Vertical isOpen={isOpen} onZDClick={handleZDClick} onToggle={toggleState} channels={channels as any} />
				)}
			</div>
		</>
	);
};

export default LiveChat;
