import React, { useContext, useRef, useState, useEffect, RefObject, createRef } from 'react';

import { useHistory } from 'react-router';

import { Trans, useTranslation } from 'react-i18next';

import AppContext from '../../../../../contexts/AppContext';

import Routes from '../../../../../setup/routes';
import { AppComponentType } from '../../../../../utils/functions/enums';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import DashboardContext from '../../../../../contexts/DashboardContext';

import useSignalsAccessRestriction from '../../../../../utils/hooks/useSignalsAccessRestriction';

import statusStore from '../../../../../store/accountStatusStore';

import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

import { signalsBpKey, signalsBpVal } from '../../../../../setup/config';

import authStore from '../../../../../store/authStore';

import Button from '../../../../../shared/Button';

import InfoModal from '../../../../../components/InfoModal/InfoModal';

import Item from './Item';

import tradingAccountStore from '../../../../../store/tradingAccountStore';

import styles from './Signals.module.scss';

const Signals = () => {
	const signalPopupRef = useRef<HTMLElement>(null);
	const [openSignalPopup, setOpenSignalPopup] = useState<boolean>(false);
	const [parentRef, setParentRef] = useState<RefObject<HTMLDivElement>>(createRef());
	const { t } = useTranslation();

	const permissions = statusStore((state: any) => state.permissions);
	const hasLiveAccount = statusStore.use.hasLiveAccount();

	const isJapanAccount = authStore.use.isJapanAccount();
	const isSouthAfricaAccount = authStore.use.isSouthAfricaAccount();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	const signalsAction = permissions?.signalsAction;
	const appContext = useContext(AppContext);
	const isArabic = !!appContext.isArabic || !!(localStorage.getItem('language') === 'ar-SA');
	const dashboardContext = useContext(DashboardContext);
	const history = useHistory();

	const { data: isUserAllowedToAccessSignals, refetch } = useSignalsAccessRestriction({ enabled: false });

	useEffect(() => {
		if (dashboardContext.marketItems !== undefined && dashboardContext.marketItems.length > 0) {
			refetch();
		}
	}, [dashboardContext.marketItems]);

	const handlePreClick = async (): Promise<boolean> => {
		// this is here so QA team can check the signals page on production
		// apparently we can't simulate transactions on Prod and whitelisting on the BE
		// is too hard

		if (signalsAction === undefined) {
			if (isUserAllowedToAccessSignals) return true;
			return false;
		}

		if (localStorage.getItem(signalsBpKey) === signalsBpVal) return true;

		if (signalsAction === IStatusConfigEnum.balanceCheck) {
			if (isUserAllowedToAccessSignals) return true;

			const { data: signalsAccessSecondCheck } = await refetch();

			if (signalsAccessSecondCheck) return true;
		}

		setOpenSignalPopup(true);
		document.body.addEventListener('mousedown', (event: MouseEvent): void => {
			if (signalPopupRef.current && !signalPopupRef.current.contains(event.target as any)) {
				setOpenSignalPopup(false);
			}
		});

		return false;
	};

	const handleSignalPopupCta = () => {
		if (signalsAction === undefined) {
			if (isUserAllowedToAccessSignals) return true;
			return false;
		}

		if (signalsAction === IStatusConfigEnum.balanceCheck) {
			dashboardContext.presentComponentType = AppComponentType.Deposits;
			history.push(Routes.trader.funds, { from: window.location.pathname });
		} else {
			dashboardContext.presentComponentType = AppComponentType.OpenAccount;
			history.push(Routes.trader.openAccount, { from: window.location.pathname });
		}
		setOpenSignalPopup(false);
	};

	if (isJapanAccount || isSouthAfricaAccount || isFundedTrader) return null;

	return (
		<div ref={parentRef}>
			<WtrPopup
				className=""
				trigger={
					<Item
						// refNeeded={true}
						// setParentRef={setParentRef}
						disableTooltip={openSignalPopup}
						preClick={handlePreClick}
						component={AppComponentType.Signals}
						route={Routes.trader.signals}
						icon={'signal'}
						tooltip={'wtr:SIGNALS'}
						isLoading={signalsAction ? !signalsAction : !isUserAllowedToAccessSignals}
					/>
				}
				pinned
				on="click"
				open={openSignalPopup}
				position={`${appContext.isArabic ? 'left' : 'right'} center`}
				content={<></>}
			/>
			{openSignalPopup && (
				<InfoModal
					closeInfoModal={() => setOpenSignalPopup(false)}
					additionalPositioning={{ x: -12.5, y: -5 }}
					arrow={isArabic ? 'right' : 'left'}
					parentRef={parentRef}
					header={
						<div className={styles.modalMessage}>
							<h1>{hasLiveAccount ? t('wtr:UNLOCK_SIGNALS_TITLE_LIVE') : t('wtr:UNLOCK_SIGNALS_TITLE_DEMO')}</h1>
						</div>
					}
					content={
						<div>
							<Trans i18nKey={hasLiveAccount ? 'wtr:UNLOCK_SIGNALS_TEXT_LIVE' : 'wtr:UNLOCK_SIGNALS_TEXT_DEMO'} />
						</div>
					}
					footer={
						<div className={styles.buttonsContainer}>
							<Button
								className={styles.button}
								label={t('en:CANCEL')}
								variant="outline"
								size="lg"
								isFullWidth={true}
								onClick={() => setOpenSignalPopup(false)}
							/>
							<Button
								size="lg"
								label={t('en:DEPOSIT_FUNDS')}
								className={styles.button}
								onClick={handleSignalPopupCta}
								variant="primary"
								isFullWidth={true}
							/>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default Signals;
