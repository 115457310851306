import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface ISetMFAStatus {
	enabled: boolean;
	code: string;
}

const useSetMFAStatus = () => {
	const mutationFn = async ({ enabled, code }: ISetMFAStatus) => {
			const response = await axios({
				url: `/user/tfa/${enabled ? 'enable' : 'disable'}`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					code,
				},
			});
			return response.data;
	};

	return useMutation({
		mutationKey: ['useSetMFAStatus'],
		mutationFn,
	});
};

export default useSetMFAStatus;
