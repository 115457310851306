export const simulateMouseEvent = function (element: any, eventName: any, coordX: any, coordY: any) {
	element.dispatchEvent(
		new MouseEvent(eventName, {
			view: window,
			bubbles: true,
			cancelable: true,
			clientX: coordX,
			clientY: coordY,
			button: 0,
		})
	);
};
