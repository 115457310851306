import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import styles from './SignalsEmptyState.module.scss';

interface SignalsEmptyStateProps {
	message: any;
	fullHeight?: boolean;
	clearFilters?: boolean;
	handleClearFilters?: () => void;
}

const SignalsEmptyState = ({ message, fullHeight, clearFilters, handleClearFilters }: SignalsEmptyStateProps) => {
	const { t } = useTranslation();
	return (
		<div className={cn(styles.container, fullHeight && styles.fullHeight)}>
			<FontAwesomeIcon className={styles.noSignalIcon} icon={['fas', 'signal-slash']} />
			<span className={styles.message}>{message}</span>
			{clearFilters && (
				<span className={styles.clearFilterText} onClick={handleClearFilters}>
					{t('wtr:CLEAR_FILTERS')}
				</span>
			)}
		</div>
	);
};

export default SignalsEmptyState;
