import React, { useContext, CSSProperties } from 'react';
import cn from 'classnames';

import { Long } from 'long';

import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import AppContext from '../../../../contexts/AppContext';
import { JAPAN_DATETIME_FORMAT } from '../../../../setup/config';
import { getFormattedDateHelper } from '../../../../utils/functions/subscriptionUtils';

import styles from './NewsWidgetStyles.module.scss';

const getFormattedNewsArticleTime = (datetime: number, isJapanAccount = false) => {
	const formatString = isJapanAccount ? JAPAN_DATETIME_FORMAT : 'ddd, MMMM Do YYYY, H:mm';
	return getFormattedDateHelper(datetime, formatString);
};

type TNewsArticleProps = {
	headline?: string;
	time: Long;
	id: number;
	customWidth?: CSSProperties;
	className?: string;
	onClick?: () => void;
};

const NewsArticle = ({ headline, time, id, className, customWidth, onClick }: TNewsArticleProps) => {
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);

	const handleClickedArticle = (id: number) => {
		if (onClick) {
			onClick();
			return;
		}
		dashboardContext.selectedNewsContent = id;
	};

	// One hour added as it was removed before in order to format it. Hour 24 was giving invalid date
	return (
		<div
			style={customWidth}
			className={cn(styles.articleContainer, className)}
			onClick={() => handleClickedArticle(id)}
		>
			<div className={styles.articleText}>
				<div className={styles.headline}>{headline}</div>
				<div className={styles.time}>
					{getFormattedNewsArticleTime(time as unknown as number, appContext.isJapanAccount)}
				</div>
			</div>
		</div>
	);
};
export default NewsArticle;
