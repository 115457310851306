import React from 'react';
import { Radio } from 'semantic-ui-react';

import authStore, { AuthStore } from '../../../../../../store/authStore';

import useGetMFAEnabled from '../../../../../../utils/queries/useGetMFAEnabled';

const MFAManagement = ({ setOpenSettings }: { setOpenSettings: (open: boolean) => void }) => {
	const { isLoading, data: isMFAEnabled } = useGetMFAEnabled();

	const setSetupMFA = authStore((store: AuthStore) => store.setSetupMFA);

	const setDisableMFA = authStore((store: AuthStore) => store.setDisableMFA);

	const toggleMFA = () => {
		if (isMFAEnabled) {
			setDisableMFA(true);
		} else {
			setSetupMFA(true);
		}

		setOpenSettings(false);
	};

	if (isLoading) return null;
	return <Radio toggle checked={isMFAEnabled} onChange={toggleMFA} />;

};

export default MFAManagement;
