import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import OTPInput from 'react-otp-input';

import WtrModal from '../../../../../../../shared/WtrModal';
import Button from '../../../../../../../shared/Button/Button';

import authStore from '../../../../../../../store/authStore';

import useSetupMFA from '../../../../../../../utils/mutations/useSetupMFA';

import useSetMFAStatus from '../../../../../../../utils/mutations/useSetMFAStatus';

import Loading from '../../../../../../components/Loading/Loading';

import InputError from '../../../../../../components/InputError/InputError';

import styles from './TwoFactorAuthModal.module.scss';

const TwoFactorAuthModal = () => {
	const [code, setCode] = useState<string>('');
	const [error, setError] = useState<string>('');

	const setupMFA = authStore.use.setupMFA();
	const setSetupMFA = authStore.use.setSetupMFA();
	const disableMFA = authStore.use.disableMFA();
	const setDisableMFA = authStore.use.setDisableMFA();
	const setIsMFAEnabled = authStore.use.setIsMFAEnabled();

	const { data, isLoading, mutate: generateKey } = useSetupMFA();

	const { mutate: enableMFA, isLoading: isLoadingManage } = useSetMFAStatus();

	const { t } = useTranslation();

	useEffect(() => {
		if (setupMFA) generateKey();
	}, [setupMFA]);

	useEffect(() => {
		const minimumCodeSymbols = 6;

		if (code.length === minimumCodeSymbols) {
			enableMFA(
				{ code, enabled: !disableMFA },
				{
					onSuccess: () => {
						setSetupMFA(false);
						setDisableMFA(false);
						setIsMFAEnabled(true);
						setCode('');
						setError('');
					},
					onError: (response: any) => {
						setError(response.response.data.description);
					},
				}
			);
		}
	}, [code]);

	const dismiss = () => {
		setCode('');
		setError('');
		setSetupMFA(false);
		setDisableMFA(false);
	};

	if (!setupMFA && !disableMFA) return null;

	return (
		<>
			<WtrModal size="small" open={setupMFA || disableMFA} className={styles.mfaModal}>
				<div className={styles.header}>
					<h4 className={styles.title}>{t('wtr:TWO_FACTOR_AUTHENTICATION')}</h4>{' '}
					<div className={styles.closeModal}>
						{' '}
						<FontAwesomeIcon icon={['fal', 'times']} size="lg" className={styles.iconTheme} onClick={dismiss} />
					</div>
				</div>
				<div className={styles.content}>
					<>
						{!isLoading || disableMFA ? (
							<>
								{data && setupMFA && (
									<>
										<p>{t('wtr:OPEN_THE_GOOGLE_AUTH_APP')}</p>
										<img className={styles.qrCode} alt="2FA QR Code" src={`data:image/png;base64,${data?.qrCode}`} />
										<p>{t('wtr:ENTER_MANUALLY')} </p>
										<p className={styles.key}>{data?.key}</p>
									</>
								)}

								<p>{disableMFA ? t('wtr:TO_DEACTIVATE_TWO_FA') : t('wtr:ENTER_ONE_TIME_CODE')}</p>

								<OTPInput
									value={code}
									inputType="number"
									onChange={setCode}
									numInputs={6}
									renderInput={(props) => <input {...props} disabled={isLoadingManage} />}
									inputStyle={styles.otpInput}
									containerStyle={styles.otpContainer}
									shouldAutoFocus={code.length === 0}
								/>
								{error && (
									<InputError className={styles.error} error content={t('wtr:PLEASE_CHECK_YOUR_VALIDATION_CODE')} />
								)}
							</>
						) : (
							<>
								<Loading />
								<div>{t('en:LOADING')}</div>
							</>
						)}
					</>
				</div>
				<WtrModal.Actions className={styles.actions}>
					<Button
						disabled={isLoading || isLoadingManage}
						label={t('en:CANCEL')}
						variant="secondary"
						size="lg"
						onClick={dismiss}
					/>
				</WtrModal.Actions>
			</WtrModal>
		</>
	);
};
export default TwoFactorAuthModal;
