import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import AppContext from '../../../../contexts/AppContext';

import WtrDropdown from '../../../../views/components/WtrDropdown/WtrDropdown';

import WtrSlider from '../../../../shared/WtrSlider/WtrSlider';

import { FilterConditions } from '../SignalsList';

import styles from './SignalsFilter.module.scss';
import { SignalsListType } from '../../Signals';

interface SignalFilterProps {
	setOpen: (open: boolean) => void;
	triggerAnimation: boolean;
	filterConditions: FilterConditions;
	handleChangeSlider: (value: number[] | number) => void;
	handleChangeDropdown: (dropdownName: 'assetClass' | 'orderType', { value }: any) => void;
	data: SignalsListType[];
}

const SignalsFilter = ({
	filterConditions,
	handleChangeSlider,
	handleChangeDropdown,
	triggerAnimation,
	setOpen,
	data,
}: SignalFilterProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const isArabic = appContext.isArabic;

	const sliderMarks = {
		20: '20%',
		40: '40%',
		60: '60%',
		80: '80%',
		100: '100%',
	};

	const assetClassOptions = [
		{
			text: t('en:All'),
			value: 'all',
		},
		// {
		// 	text: t('wtr:WTR_COMMODITIES'),
		// 	value: 'commodities',
		// },
		{
			text: t('wtr:CRYPTO'),
			value: 'crypto',
		},
		{
			text: t('wtr:ENERGY'),
			value: 'energy',
		},
		// {
		// 	text: t('wtr:ETFS'),
		// 	value: 'etfs',
		// },
		{
			text: t('wtr:WTR_FOREX'),
			value: 'fx',
		},
		{
			text: t('wtr:WTR_INDICES'),
			value: 'indices',
		},
		{
			text: t('wtr:METALS'),
			value: 'metals',
		},
		{
			text: t('wtr:SHARE_CFDS'),
			value: 'shareCfd',
		},
	];

	const availableAssetsClassOptions = assetClassOptions.filter(
		({ value }) =>
			value === 'all' ||
			data.some(({ marketItem }) => {
				if (marketItem?.grp.toLowerCase().includes('forex') && value === 'fx') {
					return true;
				}
				return marketItem?.grp.toLowerCase().includes(value);
			})
	);

	const statusOptions = [
		{
			text: t('en:All'),
			value: 'all',
		},
		{
			text: t('wtr:LIVE_TRADE'),
			value: 'Live Trade',
		},
		{
			text: t('wtr:SELL_LIMIT'),
			value: 'Sell Limit',
		},
		{
			text: t('wtr:SELL_STOP'),
			value: 'Sell Stop',
		},
		{
			text: t('wtr:BUY_LIMIT'),
			value: 'Buy Limit',
		},
		{
			text: t('wtr:BUY_STOP'),
			value: 'Buy Stop',
		},
	];

	return (
		<div
			className={cn(styles.container, triggerAnimation ? styles.easeInAnimation : styles.easeOutAnimation)}
			onAnimationEnd={() => {
				if (!triggerAnimation) setOpen(false);
			}}
		>
			<span className={styles.label}>{t('wtr:CONFIDENCE')}</span>
			<div className={styles.slider}>
				<WtrSlider
					range
					reverse={isArabic}
					min={20}
					max={100}
					marks={sliderMarks}
					allowCross={false}
					step={20}
					value={filterConditions.confidenceRange}
					onChange={handleChangeSlider}
					defaultValue={filterConditions.confidenceRange}
				/>
			</div>

			<span className={cn(styles.label, styles.padded)}>{t('wtr:ASSET_CLASS')}</span>
			<WtrDropdown
				selection
				button
				options={availableAssetsClassOptions}
				value={filterConditions.assetClass}
				onChange={(_: any, data: any) => handleChangeDropdown('assetClass', data)}
			/>

			<span className={cn(styles.label, styles.padded)}>{t('en:ORDER_TYPE')}</span>
			<WtrDropdown
				selection
				button
				options={statusOptions}
				value={filterConditions.orderType}
				onChange={(_: any, data: any) => handleChangeDropdown('orderType', data)}
			/>
		</div>
	);
};

export default SignalsFilter;
