import React from 'react';
import { getAccountIconOptions } from '../../../Settings/settingsOptions';
import authStore from '../../../../../../store/authStore';

const images: {
	[avatar: string]: {
		[key: string]: string;
	};
} = {
	avatars: {
		avatar1: '/avatars/1.svg',
		avatar2: '/avatars/2.svg',
	},
};

const AvatarIcons: React.FC<{ avatarPath: string }> = ({ avatarPath }) => {
	const accountAvatar = authStore.use.accountAvatar();

	const findImage = () => {
		const currentImage = getAccountIconOptions().find((image) => image.value === accountAvatar);

		if (currentImage) {
			return currentImage.image.src;
		}

		return getAccountIconOptions()[0].image.src;
	};
	return <img style={{ height: '100%', width: '100%' }} src={findImage()} alt="Avatar" />;
};

export default AvatarIcons;
