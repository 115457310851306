type Consumer<T = any> = (value: T) => void;
type Producer<T = any> = () => T;
type Mapper<T = any, U = any> = (value: T) => U;
type Predicate<T = any> = (value: T) => boolean;

export type Optional<T = any> = T | null | undefined;
export default class Nullable<T = any> {
	private static readonly _empty = new Nullable<any>(null, false);
	private readonly m_value: T;

	private constructor(value: T, requireNonNull: boolean = true) {
		if (requireNonNull && value == null) {
			throw new Error('value cannot be null or undefined');
		}
		this.m_value = value;
	}

	/**
	 * Indicates whether a value is present
	 * @returns `true` if there is a value present, otherwise `false`
	 */
	public isPresent(): boolean {
		return this.m_value != null;
	}

	/**
	 * If a value is present, invokes the specified consumer with the value; otherwise, does nothing.
	 * @param consumer the function to invoke if a value is present
	 * @returns a `Nullable` describing the value of this `Nullable` if a value is present; otherwise, an empty `Nullable`
	 */
	public run(consumer: Consumer<NonNullable<T>>): Nullable<T> {
		if (this.m_value != null) {
			consumer(this.m_value as NonNullable<T>);
		}
		return this;
	}

	/**
	 * Invokes the specified function using the present value, which may be null.
	 * @param consumer the function to invoke
	 * @returns a `Nullable` describing the value of this `Nullable` if a value is present; otherwise, an empty `Nullable`
	 * @see Consumer
	 */
	public runNullable(consumer: Consumer<T>): Nullable<T> {
		consumer(this.m_value);
		return this;
	}

	/**
	 * If a value is present, and the value matches the given predicate, returns a `Nullable` describing the value; otherwise, returns an empty `Nullable`.
	 * @param predicate a predicate to apply to the value, if present
	 * @returns a `Nullable` describing the value of this `Nullable` if a value is present and the value matches the given predicate; otherwise, an empty `Nullable`
	 * @see Predicate
	 */
	public filter(predicate: Predicate<NonNullable<T>>): Nullable<T> {
		return this.m_value == null ? this : predicate(this.m_value as NonNullable<T>) ? this : Nullable.empty();
	}

	/**
	 * If a value is present, applies the provided mapping function to it and, if the result is non-null, `Nullable` describing the result.  Otherwise, returns an empty `Nullable`.
	 * @param {U} U The return type of the mapping function
	 * @param mapper A mapping function to apply to the value, if present
	 * @returns a `Nullable` describing the result of applying the mapping function to the value of this `Nullable`, if a value is present; otherwise, an empty `Nullable`
	 * @see Mapper
	 */
	public map<U>(mapper: Mapper<NonNullable<T>, U>): Nullable<U> {
		return this.m_value != null ? Nullable.of(mapper(this.m_value as NonNullable<T>)) : Nullable.empty();
	}

	/**
	 * If a value is present, applies the provided `Nullable`-bearing
	 * mapping function to it and returns that result; otherwise, returns an empty
	 * `Nullable`.  This method is similar to {@link map()},
	 * but the provided mapper is one whose result is already a `Nullable`,
	 * and if invoked, {@link flatMap} does not wrap it with an additional `Nullable`.
	 * @param <U> The type parameter of the `Nullable` returned by the mapping function
	 * @param mapper The mapping function to apply to the value, if present
	 * @returns the result of applying the `Nullable`-bearing mapping function to the value of this `Nullable`, if a value is present; otherwise, an empty `Nullable`
	 * @see Mapper
	 */
	public flatMap<U>(mapper: Mapper<NonNullable<T>, Nullable<U>>): Nullable<U> {
		return Nullable.coalesce(
			this.m_value != null ? mapper(this.m_value as NonNullable<T>) : null,
			Nullable.empty()
		) as Nullable<U>;
	}

	/**
	 * Invokes the specified mapping function using the current value of this `Nullable`, which may be null.
	 * If the result is non-null, returns a `Nullable` describing the result.  Otherwise, returns an empty `Nullable`.
	 * This is similar to {@link map()}, with the exception that the mapping function is called even if the current value of this `Nullable` is null.
	 * @param {U} U the return type of the mapping function
	 * @param mapper a mapping function to apply to the value
	 * @returns a `Nullable` describing the result of applying the mapping function to the value of this `Nullable`
	 * @see Mapper
	 */
	public mapNullable<U>(mapper: Mapper<T, U>): Nullable<U> {
		return Nullable.of(mapper(this.m_value));
	}

	/**
	 * If a value is present in this `Nullable`, returns the value; otherwise, returns null.
	 * @returns the value held by this `Nullable`
	 */
	public get(): T {
		return this.m_value;
	}

	/**
	 * Returns the contained value, if present; otherwise, throws the specified error
	 * @param {X} X the type of the error to be thrown
	 * @param throwable the {@link Error} to throw if no value is present. If not supplied, a generic error will be thrown
	 * @returns the present value
	 * @throws X
	 */
	public getOrThrow<X extends Error>(throwable?: X | Producer<X>): NonNullable<T> {
		if (this.m_value != null) {
			return this.m_value as NonNullable<T>;
		}
		throw throwable == null ? new Error('No value present') : typeof throwable === 'function' ? throwable() : throwable;
	}

	/**
	 * Returns the value if present, otherwise returns {@param other}.
	 * @param other the value to be returned if there is no value present - may be null
	 * @returns the value, if present, otherwise other
	 */
	public orElse(other: T): T {
		return this.m_value != null ? this.m_value : other;
	}

	/**
	 * Returns the value if present, otherwise invokes {@param other} and returns the result of that invocation.
	 * @param other a {@link Producer} whose result is returned if no value is present
	 * @returns the value if present; otherwise, the result of invoking the supplied {@link Producer}
	 * @see Producer
	 */
	public orElseGet(other: Producer<T>): T {
		return this.m_value != null ? this.m_value : other();
	}

	/**
	 * Returns `Nullable` describing the value of this `Nullable` if a value is present; otherwise, invokes {@param other} and returns a `Nullable` describing the result of that invocation.
	 * @param other a {@link Producer} whose result is returned if no value is present
	 * @returns a `Nullable` describing the value of this `Nullable` if a value is present; otherwise, a `Nullable` describing the result of invoking the supplied {@link Producer}
	 * @see Producer
	 */
	public orElseGetNullable(other: Producer<T>): Nullable<T> {
		return this.m_value != null ? this : Nullable.of(other());
	}

	/**
	 * Invokes the specified function if no value is present.
	 * @param runnable the function to invoke
	 * @returns a `Nullable` describing the value of this `Nullable` if a value is present; otherwise, an empty `Nullable`
	 */
	public orElseRun(runnable: () => any): Nullable<T> {
		if (this.m_value == null) {
			runnable();
		}
		return this;
	}

	/**
	 * Indicates whether some other object is "equal to" this `Nullable`.
	 * The other object is considered equal if:
	 * - it is also a `Nullable` and;
	 * - both instances have no value present or;
	 * - the present values are "equal to" each other via "==" comparison
	 * @param other the object to be tested for equality with this instance
	 * @returns true if the other object is "equal to" this object; otherwise, false
	 */
	public equals(other: unknown): boolean {
		return this === other ? true : other instanceof Nullable && other.m_value === this.m_value;
	}

	/**
	 * Returns a non-empty string representation of this `Nullable` suitable for debugging.
	 * @returns the string representation of this instance
	 */
	public toString(): string {
		return this.m_value != null ? `Nullable[${this.m_value}]` : 'Nullable.empty';
	}

	/**
	 * Indicates whether the specified value is null or undefined
	 * @param value the value to evaluate
	 * @returns true if the specified value is null or undefined; otherwise, false
	 */
	public static isNullOrUndefined(value: unknown): boolean {
		return value === null || value === undefined;
	}

	/**
	 * Returns the first non-null value from the specified values.
	 * @param {T} T the type of the returned value
	 * @param values the values to evaluate
	 * @returns the first non-null value from the supplied values
	 */
	public static coalesce<T>(...values: T[]): Optional<T> {
		for (const value of values) {
			if (value != null) {
				return value;
			}
		}
		return null;
	}

	/**
	 * Returns an empty `Nullable` instance.  No value is present for this `Nullable`
	 * @param {T} T The type of the non-existent value
	 * @return an empty `Nullable`
	 */
	public static empty<T>(): Nullable<T> {
		return this._empty;
	}

	/**
	 * Returns a `Nullable` describing the specified value, if non-null; otherwise, returns an empty `Nullable`.
	 * @param {T} T the type of the value
	 * @param value the possibly-null value to describe
	 * @return a `Nullable` describing the specified value, if non-null; otherwise an empty `Nullable`
	 * @see empty()
	 */
	public static of<T>(value: Optional<T>): Nullable<T> {
		return value == null ? this.empty() : new Nullable(value);
	}
}
