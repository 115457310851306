import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	displayVolumeInfo,
	getGeneralFormatDate,
	getTierDisplayName,
} from '../../../utils/functions/subscriptionUtils';
import { tiers, tiersKeysMap } from '../../../setup/subscriptionsConfig';
import useMoneyFormatter from '../../../utils/hooks/useMoneyFormatter';
import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';

import useSubscriptionInfo from '../../../utils/hooks/useSubscriptionInfo';
import CurrencyDetails from '../CurrencyDetails/CurrencyDetails';

import styles from './SubscriptionDetails.module.scss';
import { formatNumberWithCommas } from '../../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../contexts/AppContext';

type ModalView = 'tier' | 'currencies';

const SubscriptionDetails = () => {
	const [modalView, setModalView] = useState<ModalView>('tier');
	const [subscriptionInfo] = useSubscriptionInfo();
	const appContext = useContext(AppContext);
	const { t } = useTranslation();

	const selectedTierInfo = tiers.find((tier) => tier.id === subscriptionInfo.tier);

	if (!selectedTierInfo) {
		return null;
	}

	const handleCurrencyDetailsClick = () => {
		setModalView('currencies');
	};

	const handleGoBack = () => {
		setModalView('tier');
	};

	const startDatetime = moment(subscriptionInfo.startDate).unix() * 1000;
	const expiryDatetime = moment(subscriptionInfo.expiryDate).unix() * 1000;
	const subscriptionTerm = `${getGeneralFormatDate(startDatetime, false, true)} - ${getGeneralFormatDate(
		expiryDatetime,
		false,
		true
	)}`;
	const tierName = subscriptionInfo.tier && getTierDisplayName(subscriptionInfo.tier);
	const subscriptionStatus = subscriptionInfo.status && t(`wtr:STATUS_${subscriptionInfo.status?.toUpperCase()}`);
	const currencyValueCell = (
		<>
			{selectedTierInfo.instruments.length} {t('wtr:PAIRS')}{' '}
			<button className={styles.btnViewCurrencyDetails} onClick={handleCurrencyDetailsClick}>
				{t('wtr:VIEW_DETAILS')}
			</button>
		</>
	);

	// keeping commented code below as I think it might be needed soon
	// const nextTierInfo = tiers.find((tier) => tier.id === subscriptionInfo.nextTier);
	// const nextPaymentAmount = formatAsMoney(selectedTierInfo.pricePerMonth) || null;

	const detailsRows = [
		[t('wtr:TIER'), tierName],
		[t('wtr:SUBSCRIPTION_STATUS'), subscriptionStatus],
		[t('wtr:SUBCRIPTION_TERM'), subscriptionTerm],
		[
			t('wtr:MINIMUM_TRADING_UNIT'),
			formatNumberWithCommas(selectedTierInfo.minPerOrder, 0, appContext.languageSettings),
		],
		[
			t('wtr:MAXIMUM_TRADING_UNIT'),
			formatNumberWithCommas(selectedTierInfo.maxPerOrder, 0, appContext.languageSettings),
		],
		[t('wtr:MAXIMUM_MOTHNLY_TRADING_VOLUME'), displayVolumeInfo(subscriptionInfo.maxVolume)],
		[t('wtr:TIER_MAX_POSITION'), formatNumberWithCommas(selectedTierInfo.maxPosition, 0, appContext.languageSettings)],
		[t('wtr:TRADING_CURRENCIES'), currencyValueCell],
		// keeping commented code below as I think it might be needed soon
		// [t('wtr:NEXT_WITHDRAWAL_DATE'), '?'],
		// [t('wtr:NEXT_PAYMENT_AMOUNT'), nextPaymentAmount],
	];

	if (modalView === 'tier') {
		return (
			<table className={styles.detailsTable}>
				{detailsRows.map((row) => (
					<tr>
						<td>{row[0]}</td>
						<td>{row[1]}</td>
					</tr>
				))}
			</table>
		);
	}

	if (modalView === 'currencies') {
		return <CurrencyDetails isOpen selectedTierInfo={selectedTierInfo} tiers={tiers} onGoBack={handleGoBack} />;
	}

	return null;
};

export default SubscriptionDetails;
