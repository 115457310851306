import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

import { create } from '../create';
import createSelectors from '../createSelectors';

interface WatchListStoreActions {
	setCurrentWatchList: (currentWatchList: any) => void;
	reset: () => void;
}

export interface WatchListStoreValues {
	currentWatchList: string;
}

export const initialStateWatchListStore: WatchListStoreValues = {
	currentWatchList: '',
};

export type WatchListStore = WatchListStoreValues & WatchListStoreActions;

const watchListStore = create<WatchListStore>()(
	persist(
		immer((set: any) => ({
			...initialStateWatchListStore,

			setCurrentWatchList: (currentWatchList: string) =>
				set({
					currentWatchList,
				}),
			reset: () => set({ ...initialStateWatchListStore }),
		})),
		{
			name: 'watchlist-store', // unique name
		}
	)
);

export default createSelectors(watchListStore);
