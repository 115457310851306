import React, { FC } from 'react';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';

import quoteStore from '../../../../../store/QuoteStore/quoteStore';

import styles from '../MarketsTable.module.scss';

interface VolatilityProgressBarProps {
  volatility: number;
}

const VolatilityProgressBar: FC<VolatilityProgressBarProps> = ({ volatility }) => {
  const maxVolatility = quoteStore.use.maxVolatility();

  return (
    <OverlayTrigger
      delay={{ show: 750, hide: 0 }}
      placement="top"
      overlay={
        <Tooltip className="my-tooltip" id={'marketsGridSettings'}>
          {volatility.toFixed(2)}
        </Tooltip>
      }
      trigger={['hover', 'focus']}
    >
      <div className={styles.progressBarContainer}>
        <ProgressBar
          className={styles.progressBar}
          variant="info"
          now={(100 * volatility) / maxVolatility}
        />
      </div>
    </OverlayTrigger>
  );
};

export default VolatilityProgressBar;