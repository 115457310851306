export const subscribeOpsPersistHelper = (
	initStatusName = 'subscribeOpStatus',
	initProviderStatusName = 'subscribeOpProviderStatus'
) => {
	const queryParams = new URLSearchParams(document.location.search);
	const subscribeOpStatus = queryParams.get(initStatusName);
	const subscribeOpProviderStatus = queryParams.get(initProviderStatusName);

	let queryPersistAppendix = '';
	if (subscribeOpStatus) {
		queryPersistAppendix += `subscribeOpStatus=${subscribeOpStatus}`;
		if (subscribeOpProviderStatus) {
			queryPersistAppendix += `&subscribeOpProviderStatus=${subscribeOpProviderStatus}`;
		}
	}

	return queryPersistAppendix;
};
