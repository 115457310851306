import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import createSelectors from '../createSelectors';
export type positionsState = {
	panelSize: number;
	positionTab: number;
	gridHeight: number;
	positionPanelHeight: number;
	setPositionTab: (tab: number) => void;
	setWatchListPanelSize: (size: number) => void;
	setGridHeight: (size: number) => void;
};

const positionsStore = create<positionsState>()(
	persist(
		immer((set: any) => ({
			panelSize: 0,
			positionTab: 0,
			gridHeight: 0,
			positionPanelHeight: 40,
			setPositionTab: (tab: number) => {
				set(() => ({
					positionTab: tab,
				}));
			},
			setWatchListPanelSize: (size: number) => {
				set(() => ({
					panelSize: size,
				}));
			},
			setGridHeight: (size: number) => {
				set(() => ({
					gridHeight: size,
				}));
			},
		})),
		{
			name: 'positions-store',
		}
	)
);

export default createSelectors(positionsStore);
