export interface WtrResizableConfigItem {
	minWidth?: number;
	minHeight?: number;
	maxWidth?: string | number; // this value can handle percentages
	maxHeight?: string | number;
	width?: string;
	height?: string;
	enable?: any;
	handleClasses?: any;
}

export interface WtrResizableConfig {
	[key: string]: WtrResizableConfigItem;
}

export const getWtrResizableConfig = (panelName: string | undefined, isArabic: boolean) => {
	if (!panelName) {
		return null;
	}

	const config: WtrResizableConfig = {
		watchlist: {
			minWidth: 330,
			maxWidth: '45%',
			height: '100%',
			enable: {
				top: false,
				right: !isArabic,
				bottom: false,
				left: isArabic,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				left: 'resizableHandle',
				right: 'resizableHandle',
			},
		},
		positionsGrid: {
			minHeight: 200,
			maxHeight: '60%',
			width: '100%',
			enable: {
				top: true,
				right: false,
				bottom: false,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				top: 'resizableHandle',
			},
		},
		tradeTicket: {
			minWidth: 370,
			maxWidth: 460,
			height: '100%',
			enable: {
				top: true,
				right: isArabic,
				bottom: false,
				left: !isArabic,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				left: 'resizableHandle',
				right: 'resizableHandle',
			},
		},
		marketsGroups: {
			minWidth: 140,
			maxWidth: isArabic ? 300 : 240,
			height: '100%',
			enable: {
				top: false,
				right: !isArabic,
				bottom: false,
				left: isArabic,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				left: 'resizableHandle',
				right: 'resizableHandle',
			},
		},
		marketsNews: {
			minHeight: 340,
			maxHeight: '80%',
			width: '100%',
			enable: {
				top: true,
				right: false,
				bottom: false,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				top: 'resizableHandle',
			},
		},
		simulationsList: {
			minWidth: 290,
			maxWidth: '45%',
			height: '100%',
			enable: {
				top: false,
				right: !isArabic,
				bottom: false,
				left: isArabic,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				left: 'resizableHandle',
				right: 'resizableHandle',
			},
		},
		simulationsGrid: {
			minHeight: 200,
			maxHeight: '60%',
			width: '100%',
			enable: {
				top: true,
				right: false,
				bottom: false,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				top: 'resizableHandle',
			},
		},
		signalsList: {
			minWidth: 290,
			maxWidth: '45%',
			height: '100%',
			enable: {
				top: false,
				right: !isArabic,
				bottom: false,
				left: isArabic,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				left: 'resizableHandle',
				right: 'resizableHandle',
			},
		},
		signalsOverview: {
			minHeight: 328,
			maxHeight: 400,
			width: '100%',
			enable: {
				top: true,
				right: false,
				bottom: false,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			},
			handleClasses: {
				top: 'resizableHandle',
			},
		},
		//This is used by the new design which currently isn`t implemented
		/* marketsNewsRight: {
				minWidth: 275,
				maxWidth: 370,
				height: '100%',
				enable: {
						top: false,
						right: isArabic ? true : false,
						bottom: false,
						left: isArabic ? false : true,
						topRight: false,
						bottomRight: false,
						bottomLeft: false,
						topLeft: false,
				},
				handleClasses: {
						left: 'resizableHandle',
						right: 'resizableHandle',
				},
		},
		marketSignals: {
				minWidth: 500,
				maxWidth: '40%',
				height: '100%',
				enable: {
						top: false,
						right: isArabic ? false : true,
						bottom: false,
						left: isArabic ? true : false,
						topRight: false,
						bottomRight: false,
						bottomLeft: false,
						topLeft: false,
				},
				handleClasses: {
						left: 'resizableHandle',
						right: 'resizableHandle',
				},
		},
		risers: {
				minWidth: 500,
				maxWidth: '40%',
				height: '100%',
				enable: {
						top: false,
						right: true,
						bottom: false,
						left: true,
						topRight: false,
						bottomRight: false,
						bottomLeft: false,
						topLeft: false,
				},
				handleClasses: {
						left: 'resizableHandle',
						right: 'resizableHandle',
				},
		},
		fallers: {
				minWidth: 500,
				maxWidth: '40%',
				height: '100%',
				enable: {
						top: false,
						right: isArabic ? true : false,
						bottom: false,
						left: isArabic ? false : true,
						topRight: false,
						bottomRight: false,
						bottomLeft: false,
						topLeft: false,
				},
				handleClasses: {
						left: 'resizableHandle',
						right: 'resizableHandle',
				},
		}, */
	};

	const keyToFetch = Object.keys(config).find((panelKey) => panelKey.toLowerCase() === panelName.toLowerCase());
	return keyToFetch ? config[keyToFetch] : null;
};
