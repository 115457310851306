import {BASE_DOCUMENT_TITLE} from "./constants";
//TODO: Move the definition of these functions to Utils folder as they are being used in a wider scope
import {formatNumberWithCommas} from "../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber";

export const updateDocumentTitleWithPriceQuote = (
	{displayName, price, decPrec, language}
		: {displayName: string, price: number, decPrec: number, language: string}
) => {
	const titlePrefix = `${displayName} ${formatNumberWithCommas(price, decPrec, language)}`;
	updateDocumentTitle(titlePrefix);
}

export const updateDocumentTitle = (prefix: string) => {
	document.title = prefix ? `${prefix} | ${BASE_DOCUMENT_TITLE}` : BASE_DOCUMENT_TITLE;
}
