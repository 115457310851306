import React, { FC, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as usePromiseFactory } from '../../../../../utils/hooks/usePromiseFactory';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';

import styles from './AddToWatchlist.module.scss';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

interface AddToWatchlistProps {
	watchlistName: string;
	maxReached?: boolean;
	// TODO: - Use the action prop to handle the edit watchlist icon functionality
	action: 'ADD' | 'EDIT';
}

const AddToWatchlist: FC<AddToWatchlistProps> = ({ watchlistName, maxReached }) => {
	const dashboardContext = useContext(DashboardContext);
	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();

	const selectedInstrument = dashboardContext.selectedInstrument;
	const mappedWatchlist = dashboardContext.mappedWatchlist;
	const selectedTradingAccountWatchlist = dashboardContext.watchlist;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

	useObservable(
		dashboardContext.getPropertyChangeStream('selectedInstrument', 'tradingAccount', 'watchlist', 'mappedWatchlist'),
		(change) => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => forceRerender());
		}
	);

	const handleAddToWatchlist = () => {
		if (maxReached) return;

		const updatePreferenceObject = selectedTradingAccountWatchlist.find(
			(watchlist) => watchlist._name === watchlistName
		);

		if (updatePreferenceObject) {
			updatePreferenceObject.instrument.push({ _code: selectedInstrument!.code });
			const watchlistObj = {
				...dashboardContext.mappedWatchlist,
				[watchlistName]: updatePreferenceObject.instrument,
			};
			dashboardContext.mappedWatchlist = watchlistObj;
		}

		saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
	};

	const handleRemoveFromWatchlist = () => {
		if (dashboardContext.mappedWatchlist !== null && selectedInstrument) {
			const updatePreferenceObject = selectedTradingAccountWatchlist.find(
				(watchlist) => watchlist._name === watchlistName
			);

			if (updatePreferenceObject) {
				const removed = Object.values(dashboardContext.mappedWatchlist[watchlistName]).filter(
					(item) => item._code !== selectedInstrument?.code
				);
				const watchlistObj = {
					...mappedWatchlist,
					[watchlistName]: removed,
				};
				dashboardContext.mappedWatchlist = watchlistObj;
				updatePreferenceObject.instrument = removed;
			}
		}

		saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
	};

	const exists = mappedWatchlist![watchlistName].map(({ _code }) => _code).includes(selectedInstrument!.code);

	return (
		<span>
			{exists && (
				<FontAwesomeIcon
					icon={['fas', 'check-circle']}
					className={styles.existsInWatchlistIcon}
					onClick={handleRemoveFromWatchlist}
				/>
			)}
			{!exists && (
				<FontAwesomeIcon
					icon={['far', 'plus-circle']}
					className={styles.addToWatchlistIcon}
					onClick={handleAddToWatchlist}
				/>
			)}
		</span>
	);
};

export default AddToWatchlist;
