import React, {useContext} from 'react';
import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';

import styles from './AccountStats.module.scss';

interface AccountPanelProps {
    label: string;
    value: string;
    hideDivider?: boolean;
    dividerSymbol?: string;
    valueColoring?: boolean;
}

const AccountStats: React.FC<AccountPanelProps> = ({label, value, hideDivider, dividerSymbol, valueColoring}) => {
    const appContext = useContext(AppContext);
    let coloring = styles.default;
    const parsedValue = parseFloat(value.substring(value.indexOf(' ') + 1));

    if (valueColoring) {
        if (parsedValue < 0) {
            coloring = styles.red;
        }

        if (parsedValue > 0) {
            coloring = styles.green;
        }
    }

    return (
        <div className={styles.container}>
            {!hideDivider && !appContext.isArabic && <div className={styles.divider}>{dividerSymbol}</div>}
            <div className={styles.statsContainer}>
                <label className={styles.statsLabel}>{label}</label>
                <div className={cn(styles.value, coloring)}>{value || 0}</div>
            </div>
            {!hideDivider && appContext.isArabic && <div className={styles.divider}>{dividerSymbol}</div>}
        </div>
    );
};

export default AccountStats;
