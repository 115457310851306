import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface FeedbackParameters {
	email?: string;
	score: number;
	comments: string;
}

const useSubmitFeedback = () => {
	const mutationFn = async (parameters: FeedbackParameters) => {
		try {
			const response = await axios({
				url: '',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					payload: [
						{
							module: 'profile',
							action: 'feedback',
							parameters: {
								context: 'webtrader:app-feedback',
								...parameters,
							},
						},
					],
				},
			});
			return response.data.payload[0].result.data;
		} catch (e) {}
	};

	return useMutation({
		mutationKey: ['useSubmitFeedback'],
		mutationFn,
	});
};

export default useSubmitFeedback;
