import { SubscriptionStatus, SubscriptionTier } from '../../utils/functions/subscriptionUtils';

export type TResponseStatus =
	| never
	| 'OK'
	| 'PENDING_APPROVAL'
	| 'SYS_ERR'
	| 'NOT_FOUND'
	| 'CHALLENGE'
	| 'VALIDATION_ERROR'
	| 'ALREADY_REGISTERED'
	| 'NOT_AUTHORIZED';

export type TLoginResponseStatus =
	| TResponseStatus
	| 'OK'
	| 'PENDING_APPROVAL'
	| 'PENDING_REVIEW'
	| 'PENDING_ID_ADDRESS'
	| 'PENDING_ID'
	| 'PENDING_ADDRESS'
	| 'NOT_AUTHORIZED';

export type TApplicationStatus =
	| never
	| 'APPROVED'
	| 'PENDING_REVIEW'
	| 'PENDING'
	| 'DENIED'
	| 'PENDING_ID_ADDRESS'
	| 'PENDING_ID'
	| 'PENDING_ADDRESS'
	| 'PENDING_APPROPRIATENESS_TEST'
	| 'PENDING_PROPER_DOCUMENTS'
	| 'PENDING_TRANSFER'
	| 'PENDING_NEW_TnC_ACCEPTANCE'
	| 'DUPLICATE'
	| 'INCOMPLETE'
	| 'INTERNAL_TESTING'
	| 'PENDING_TRANSLATION'
	| 'PENDING_RG227'
	| 'FAILED';

export type TUserAddress = {
	address1: string;
	city: string;
	postalCode: string;
	state: string;
};

export type TOrganization = {
	guid: string;
	id: number;
	name: string;
};

export type TPlatform = {
	id: number;
	name: string;
	code: string;
};

export type TCurrency = {
	code: string;
	id: number;
	name: string;
	symbol: string;
};

export type TUserPhone = {
	mobile: string;
	mobileCountryCode: number;
	phone: string;
	phoneCountryCode: number;
};

export type TLanguage = {
	id: number;
	language_code: string;
	language_name: string;
};

export type TCountry = {
	cTraderCountryId: number;
	code2: string;
	code3: string;
	currency: TCurrency;
	defaultLeverage: string;
	demoBalance: number;
	demoCurrency: TCurrency;
	eVerificationStatus: boolean;
	eVerificationType: {
		type: string;
		id: number;
	};
	european: boolean;
	forceEmailValidate: boolean;
	id: number;
	isRequireEnglishNames: boolean;
	legacyOrganization: TOrganization;
	liveCurrency: TCurrency;
	maxLeverage: string;
	name: string;
	organization: TOrganization;
	phoneCode: number;
	preKycFunds: number;
	region: number;
	riskProfile: string;
	tfaEnabled: boolean;
	used: boolean;
};

export type TAccountExpiry = {
	accountExpirationDate: string;
	isExpired: boolean;
	numberOfDaysRemaining: number;
};

export type Account = {
	id: number;
	accountNumber: number;
	type: 'LIVE' | 'DEMO' | 'GYM';
	currency: TCurrency;
	expires: TAccountExpiry;
	organization: TOrganization;
	platform: TPlatform;
};
export type TAccountStats = {
	account: Account;
	archived: boolean;
	balanceInUSD: number;
	balance: number;
	equity: number;
	margin: number;
	marginFree: number;
	hasOpenPositions: boolean;
	platformAccountType: TradingAccountType;
};

export enum AccountBrand {
	ThinkMarkets = 'ThinkMarkets',
}

export enum PlatformCode {
	ThinkTrader = 'ThinkTrader',
}

export enum AccountTradingType {
	CFD = 'CFD',
	CE = 'CE',
	SpreadBetting = 'spreadBetting',
}

export enum TradingAccountType {
	ThinkTrader = 'ThinkTrader',
	SpreadBetting = 'spreadBetting',
	JapanSpread = 'JapanSpread',
}

export type TTfboResponsePayload<TRESULT extends any, TSTATUS extends TResponseStatus | string = TResponseStatus> = {
	module: string;
	action: string;
	result: TRESULT;
	status: TSTATUS;
	details?: any;
};

export type TTfboRequestPayload<T extends any = any> = {
	module: string;
	action: string;
	parameters: T;
};

type TTfboPayloadContent = {
	'account.addDemo': {
		request: {
			first_name: string;
			last_name: string;
			email: string;
			phone: string;
			phonecode: number;
			country: number;
			currency: string;
			balance: number;
			leverage: number;
			platformCode: PlatformCode;
			password: string;
			brand: AccountBrand;
			platformAccountType: TradingAccountType;
			preferredLanguageId?: number | null | undefined;
			afs_aid?: string | null | undefined;
			afs_clickid?: string | null | undefined;
			collection_policy_agreed?: boolean | null | undefined;
			visitorId?: string | null | undefined;
		};
		response: any;
	};
	'account.stats': {
		request: null | undefined | {};
		response: {
			data: TAccountStats[];
			draw: number;
			recordsFiltered: number;
			recordsTotal: number;
		};
	};
	'account.getUserAccounts': {
		request: null | undefined | {};
		response: any;
	};
	'account.getSignalsList': {
		request: null | undefined | {};
		response: [];
	};
	'application.checkApplicationStatus': {
		request: null | undefined | {};
		response: {
			application_status: TApplicationStatus;
			appropriateness_level: string;
			organizationId: string;
			preKycRequired: boolean;
		};
	};
	'application.getApplicationInfo': {
		request: null | undefined | {};
		response: any;
	};
	'application.apps': {
		request: null | undefined | {};
		response: any;
	};
	'authentication.generateSsoToken': {
		request: {
			email: string;
		};
		response: {
			ssoToken: string;
		};
	};
	'authentication.validatessotoken': {
		request: {
			email: string;
			ssoToken: string;
		};
		response: any;
	};
	'authentication.changePassword': {
		request: {
			password: string;
			new_password: string;
		};
		response: any;
	};
	'authentication.forgotPassword': {
		request: {
			email_id: string;
			source: string;
		};
		response: any;
	};
	'authentication.login': {
		request: {
			email_id: string;
			password: string;
		};
		response: {
			last_name: string;
			session_life: number;
			id: number;
			first_name: string;
			last_login_date: string;
			token: string;
			keep_alive_interval: number;
		};
	};
	'authentication.logout': {
		request: null | undefined | {};
		response: {};
	};
	'authentication.isSessionValid': {
		request: null | undefined | {};
		response: {};
	};
	'emailValidation.isUserVerified': {
		request: null | undefined | {};
		response: boolean;
	};
	'emailValidation.sendVerificationCode': {
		request: {
			originCountry: number;
			accountHolderFirstName: string;
			accountHolderLastName: string;
			preferredLanguage: number;
			accountHolderEmail: string;
		};
		response: any;
	};
	'emailValidation.validateVerificationCode': {
		request: {
			otpValue: string;
			accountHolderEmail: string;
		};
		response: any;
	};
	'profile.feedback': {
		request: {
			email?: string | null;
			comments?: string;
			context?: string;
			score: number;
		};
		response: any;
	};
	'profile.getUser': {
		request: null | undefined | {};
		response: {
			additionalAttributes: {};
			address: TUserAddress;
			approved: boolean;
			brand: string;
			cif: string;
			classification: string;
			country: TCountry;
			currentEquity: number;
			dateOfBirth: string;
			email: string;
			employmentStatus: string;
			firstName: string;
			firstNameEnglish: string;
			fullName: string;
			id: number;
			isProfessionalClient: boolean;
			lastName: string;
			lastNameEnglish: string;
			maxDemoAccounts: number;
			maxInterAccountTransfers: number;
			maxLiveAccounts: number;
			nationality: {};
			organization: TOrganization;
			partnerStatus: string;
			passportId: string;
			phone: TUserPhone;
			preferredLanguage: TLanguage;
			thinkTraderEULA: boolean;
			title: string;
			twoFAenabled: boolean;
			visitorId: string;
		};
	};
	'profile.checkAgreementStatus': {
		request: null | undefined | {};
		response: {
			latestVersion: string;
			customerVersion: string;
		};
	};
	'profile.confirmAgreement': {
		request: {
			versionNumber: string;
		};
		response: {
			status: string;
		};
	};

	'utility.createHandshakeUrl': {
		request: {
			usi: string;
			page: string;
			lang: string;
		};
		response: string;
	};
	'utility.getCountries': {
		request: null | undefined | {};
		response: TCountry[];
	};
	'utility.getUserIp': {
		request: null | undefined | {};
		response: {
			country?: string | null | undefined;
			country_code?: string | null | undefined;
		};
	};
	'subscriptions.getSubscriptionStatus': {
		request: {
			provider: string;
			accountNumber: number;
		};
		response: {
			status: string;
			currentSubscriptionTier?: string | null | undefined;
			nextSubscriptionTier?: string | null | undefined;
		};
	};
	'subscriptions.subscribe': {
		request: {
			provider: string;
			accountNumber: number;
			tier: SubscriptionTier;
			source: string;
		};
		response: {};
	};
	'subscriptions.changeTier': {
		request: {
			provider: string;
			accountNumber: number;
			requestedTier: SubscriptionTier;
		};
		response: {};
	};
	'subscriptions.stopSubscription': {
		request: {
			provider: string;
			accountNumber: number;
		};
		response: {};
	};
	'subscriptions.cancelSubscription': {
		request: {
			provider: string;
			accountNumber: number;
		};
		response: {};
	};
	'subscriptions.resumeSubscription': {
		request: {
			provider: string;
			accountNumber: number;
		};
		response: {};
	};
};

export type TTfboGatewayRequestPayload<
	T extends Array<keyof typeof TfboApiMethodMeta>,
	U extends 'requestPayload' | 'responsePayload'
> = {
	[K in keyof T]: T[K] extends keyof typeof TfboApiMethodMeta ? (typeof TfboApiMethodMeta)[T[K]][U] : never;
};

export type TTfboGatewayResponse<T extends Array<keyof typeof TfboApiMethodMeta>> = {
	filter?: any;
	error?: {
		code?: any;
		description?: string;
	};
	validationErrors?: any;
	message?: string;
	id: number;
	prv_id: number;
	transmission_time: number;
	session_id: string;
	token: string;
	payload: TTfboGatewayRequestPayload<T, 'responsePayload'>;
};

export const TfboApiMethodMeta = {
	'account.addDemo': {
		module: 'account',
		action: 'adddemo',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['account.addDemo']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['account.addDemo']['response']>,
		authRequired: false,
	},
	'account.stats': {
		module: 'account',
		action: 'stats',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['account.stats']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['account.stats']['response']>,
		authRequired: true,
	},
	'account.getUserAccounts': {
		module: 'account',
		action: 'stats',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['account.getUserAccounts']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['account.getUserAccounts']['response']
		>,
		authRequired: true,
	},
	'account.getSignalsList': {
		module: 'account',
		action: 'getSignalsList',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['account.getSignalsList']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['account.getSignalsList']['response']>,
		authRequired: true,
	},
	'application.checkApplicationStatus': {
		module: 'application',
		action: 'check_application_status',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['application.checkApplicationStatus']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['application.checkApplicationStatus']['response']
		>,
		authRequired: true,
	},
	'application.apps': {
		module: 'application',
		action: 'apps',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['application.apps']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['application.apps']['response']>,
		authRequired: true,
	},
	'application.getApplicationInfo': {
		module: 'application',
		action: 'get_application_info',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['application.getApplicationInfo']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['application.getApplicationInfo']['response']
		>,
		authRequired: true,
	},
	'authentication.generateSsoToken': {
		module: 'authentication',
		action: 'generateSsoToken',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['authentication.generateSsoToken']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['authentication.generateSsoToken']['response']
		>,
		authRequired: false,
	},
	'authentication.validatessotoken': {
		module: 'authentication',
		action: 'validatessotoken',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['authentication.validatessotoken']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['authentication.validatessotoken']['response']
		>,
		authRequired: false,
	},
	'authentication.changePassword': {
		module: 'authentication',
		action: 'change_password',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['authentication.changePassword']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['authentication.changePassword']['response']
		>,
		authRequired: false,
	},
	'authentication.forgotPassword': {
		module: 'authentication',
		action: 'forgot_password',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['authentication.forgotPassword']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['authentication.forgotPassword']['response']
		>,
		authRequired: false,
	},
	'authentication.login': {
		module: 'authentication',
		action: 'login',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['authentication.login']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['authentication.login']['response']>,
		authRequired: false,
	},
	'authentication.logout': {
		module: 'authentication',
		action: 'logout',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['authentication.logout']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['authentication.logout']['response']>,
		authRequired: true,
	},
	'authentication.isSessionValid': {
		module: 'authentication',
		action: 'is_valid',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['authentication.isSessionValid']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['authentication.isSessionValid']['response']
		>,
		authRequired: true,
	},
	'emailValidation.isUserVerified': {
		module: 'emailvalidation',
		action: 'isuserverified',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['emailValidation.isUserVerified']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['emailValidation.isUserVerified']['response']
		>,
		authRequired: true,
	},
	'emailValidation.sendVerificationCode': {
		module: 'emailvalidation',
		action: 'send_verification_code',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['emailValidation.sendVerificationCode']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['emailValidation.sendVerificationCode']['response']
		>,
		authRequired: false,
	},
	'emailValidation.validateVerificationCode': {
		module: 'emailvalidation',
		action: 'verify_otp_code',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['emailValidation.validateVerificationCode']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['emailValidation.validateVerificationCode']['response']
		>,
		authRequired: false,
	},
	'profile.feedback': {
		module: 'profile',
		action: 'feedback',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['profile.feedback']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['profile.feedback']['response']>,
		authRequired: false,
	},
	'profile.getUser': {
		module: 'profile',
		action: 'get_user',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['profile.getUser']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['profile.getUser']['response']>,
		authRequired: true,
	},
	'profile.checkAgreementStatus': {
		module: 'profile',
		action: 'checkUserTermsAndConditions',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['profile.checkAgreementStatus']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['profile.checkAgreementStatus']['response']
		>,
		authRequired: true,
	},
	'profile.confirmAgreement': {
		module: 'profile',
		action: 'agreeToLatestTermsAndConditions',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['profile.confirmAgreement']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['profile.confirmAgreement']['response']
		>,
		authRequired: true,
	},

	'utility.createHandshakeUrl': {
		module: 'utility',
		action: 'createHandshakeURL',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['utility.createHandshakeUrl']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['utility.createHandshakeUrl']['response']
		>,
		authRequired: false,
	},
	'utility.getCountries': {
		module: 'utility',
		action: 'getCountries',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['utility.getCountries']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['utility.getCountries']['response']>,
		authRequired: false,
	},
	'utility.getUserIp': {
		module: 'utility',
		action: 'ip',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['utility.getUserIp']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<TTfboPayloadContent['utility.getUserIp']['response']>,
		authRequired: false,
	},
	'subscriptions.getSubscriptionStatus': {
		module: 'subscription',
		action: 'getSubscriptionStatus',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['subscriptions.getSubscriptionStatus']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['subscriptions.getSubscriptionStatus']['response']
		>,
		authRequired: true,
	},
	'subscriptions.subscribe': {
		module: 'subscription',
		action: 'subscribe',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['subscriptions.subscribe']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['subscriptions.subscribe']['response']
		>,
		authRequired: true,
	},
	'subscriptions.changeTier': {
		module: 'subscription',
		action: 'changeTier',
		requestPayload: null as unknown as TTfboRequestPayload<TTfboPayloadContent['subscriptions.changeTier']['request']>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['subscriptions.changeTier']['response']
		>,
		authRequired: true,
	},
	'subscriptions.stopSubscription': {
		module: 'subscription',
		action: 'stopSubscription',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['subscriptions.stopSubscription']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['subscriptions.stopSubscription']['response']
		>,
		authRequired: true,
	},
	'subscriptions.cancelSubscription': {
		module: 'subscription',
		action: 'cancelSubscription',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['subscriptions.cancelSubscription']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['subscriptions.cancelSubscription']['response']
		>,
		authRequired: true,
	},
	'subscriptions.resumeSubscription': {
		module: 'subscription',
		action: 'restartSubscription',
		requestPayload: null as unknown as TTfboRequestPayload<
			TTfboPayloadContent['subscriptions.resumeSubscription']['request']
		>,
		responsePayload: null as unknown as TTfboResponsePayload<
			TTfboPayloadContent['subscriptions.resumeSubscription']['response']
		>,
		authRequired: true,
	},
} as const;
