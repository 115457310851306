import { useEffect, useRef } from 'react';
import { Optional } from '../functions/Nullable';
import { default as PromiseFactory } from '../functions/PromiseFactory';

class Hooks {
	public static usePromiseFactory(): PromiseFactory;
	public static usePromiseFactory(promiseFactory: PromiseFactory): PromiseFactory;
	public static usePromiseFactory(promiseFactory?: Optional<PromiseFactory>): PromiseFactory {
		const promiseFactoryRef = useRef<Optional<PromiseFactory>>();
		if (promiseFactoryRef.current == null) {
			promiseFactoryRef.current = promiseFactory || new PromiseFactory();
		}
		useEffect(() => {
			return () => {
				promiseFactoryRef.current = null;
			};
		}, []);
		return promiseFactoryRef.current!;
	}
}

export default Hooks.usePromiseFactory;
