import { AppContextProvider } from '../../contexts/AppContext';
import { defaultCFDPreference } from '../../gateways/UserPreferencesGateway/defaultCFDPreference';
import { TPreferenceResponse } from '../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

export const storeUserPreferences = (appContext: AppContextProvider) => {
	const convertUserPref = JSON.stringify(appContext.userPreferences);

	if (convertUserPref !== null && convertUserPref !== 'null') {
		localStorage.setItem(`userPref-${appContext.email}`, convertUserPref);
	}
};

export const getSavedPreferencesForUser = (appContext: AppContextProvider) => {
	try {
		return JSON.parse(localStorage.getItem(`userPref-${appContext.email}`) ?? '{}');
	} catch (error) {
		console.error('Error getting saved preferences for user', error);
	}

	return undefined;
};

export const fillUserPrefLocally = (appContext: AppContextProvider, isLiveMode: boolean) => {
	try {
		const localUserPref = localStorage.getItem(`userPref-${appContext.email}`);
		if (localUserPref !== null && localUserPref !== 'null') {
			const parsedUserPref: TPreferenceResponse = JSON.parse(localUserPref);
			appContext.userPreferences = parsedUserPref;
		} else {
			appContext.userPreferences = defaultCFDPreference(isLiveMode ? 'Live' : 'Demo');
			appContext.userPreferenceError = true;
		}
	} catch (error) {
		console.error('Error filling user preferences locally', error);
	}
};

export const loadUserPrefLocally = (appContext: AppContextProvider) => {
	try {
		if (localStorage.getItem(`userPref-${appContext.email}`)) {
			const localUserPref = localStorage.getItem(`userPref-${appContext.email}`)!;
			const parsedUserPref: TPreferenceResponse = JSON.parse(localUserPref);
			if (parsedUserPref) {
				appContext.userPreferences = parsedUserPref;
			}
		}
	} catch (error) {
		console.error('Error loading user preferences locally', error);
	}
};
