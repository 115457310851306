import React, { useContext, useEffect, useState, useRef } from 'react';

import AppContext from '../../contexts/AppContext';
import DashboardContext from '../../contexts/DashboardContext';
import useForceRerender from '../../utils/hooks/useForceRerender';
import useObservable from '../../utils/hooks/useObservable';
import usePromiseFactory from '../../utils/hooks/usePromiseFactory';
import { AppComponentType } from '../../utils/functions/enums';

import { CalendarLangMap, calendarLangMap } from '../../views/features/Dashboard/Settings/settingsOptions';

import useCalendarToken from '../../utils/queries/useCalendarToken';
import StringUtils from '../../utils/functions/StringUtils';
import authStore, { AuthStore } from '../../store/authStore';

import i18n from "../../setup/i18n";

import styles from './Insights.module.scss';

const Insights = () => {
	const [url, setUrl] = useState('');
	const iframeContainerRef = useRef<HTMLDivElement>(null);

	const appContext = useContext(AppContext);
	const theme = appContext.appTheme;
	const dashboardContext = useContext(DashboardContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const userProfile = authStore((store: AuthStore) => store.userProfile);

	const { data: token } = useCalendarToken({
		usi: userProfile.email!,
		lang: calendarLangMap[(appContext.languageSettings || i18n.language) as CalendarLangMap],
	});

	useObservable(dashboardContext.getPropertyChangeStream('presentComponentType'), () => {
		promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
			if (dashboardContext.presentComponentType !== AppComponentType.Watchlist) {
				return;
			}
			forceRerender();
		});
	});

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => {
		forceRerender();
	});

	useEffect(() => {
		if (token && StringUtils.isString(token)) {
			setUrl(token.replace('thinkmarkets', `thinkmarkets${theme === 'dark' ? '_dark' : ''}`));
		}
	}, [theme, token]);

	return (
		<div className={styles.headerContainer}>
			<div ref={iframeContainerRef} className={styles.iframeContainer}>
				{url && (
					<iframe
						name="econ-insight"
						className={styles.iframeEdit}
						id="tradingcentral"
						allowFullScreen={true}
						frameBorder="0"
						scrolling="yes"
						width="100%"
						height="100%"
						title="iframe"
						src={url}
					></iframe>
				)}
			</div>
		</div>
	);
};
export default Insights;
