import { PostMessageToTpType } from '../../views/features/ThinkPortal/IframeThinkPortal';

const postMessageToTP = (data: PostMessageToTpType) => {
	const tpIframe = document.getElementById('tp-iframe') as HTMLIFrameElement;
	if (tpIframe.contentWindow) {
		tpIframe.contentWindow.postMessage(data, `${process.env.REACT_APP_THINK_PORTAL}`);
	}
};

export default postMessageToTP;
