import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { INotification } from '../../../../../contexts/NotificationsContext';
// import cn from 'classnames';
import uniqueId from '../../../../../utils/functions/uniqueId';

import emptyListIcon from '../../../../../images/empty-notification-list.svg';
import NotificationsItem from '../NotificationsItem';

import styles from './NotificationsList.module.scss';

interface INotificationsList {
	notifications: INotification[];
}

const NotificationsList = ({ notifications }: INotificationsList) => {
	const { t } = useTranslation();
	const hasNotifications = notifications.length > 0;
	const notificationRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!notificationRef.current) return;
	}, [notificationRef]);

	return (
		<div className={styles.popup} ref={notificationRef}>
			<div className={styles.header}>{t('wtr:NOTIFICATION_POPUP_TITLE')}</div>
			<div className={styles.content}>
				{!hasNotifications && (
					<div className={styles.emptyList}>
						<img className={styles.emptyListIcon} src={emptyListIcon} alt="" />
						<p className={styles.emptyText}>{t('wtr:NOTIFICATION_POPUP_EMPTY_TEXT')}</p>
						<p className={styles.emptySubText}>{t('wtr:NOTIFICATION_POPUP_EMPTY_SUBTEXT')}</p>
					</div>
				)}

				{hasNotifications && (
					<div className={styles.list}>
						{notifications.map((notification) => (
							<NotificationsItem notification={notification} key={uniqueId()} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default NotificationsList;
