import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { SubscriptionModalReason } from '../functions/subscriptionUtils';

import useSubscriptionModal from './useSubscriptionModal';

const useHandleSubscribeResponse = () => {
	const { setModalStateProps } = useSubscriptionModal();
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
		// providerStatus=INCOMPLETE
		// switch (status) {
		// 	case 'ACTIVE':
		// 		// setError('ACTIVE');``
		// 		break;
		// 	case 'DENIED':
		// 	case 'CANCELED':
		// 	case 'ERROR':
		// 		// setError('DENIED');
		// 		// setErrorReason(query?.providerStatus as string);
		// 		break;
		// 	case 'EDIT_ERROR':
		// 		// setError('EDIT_ERROR');
		// 		// setErrorReason(query?.providerStatus as string);
		// 		break;
		// 	case 'INCOMPLETE':
		// 		// setError('INCOMPLETE');
		// 		break;
		// 	default:
		// 		break;
		// }

		const queryParams = new URLSearchParams(document.location.search);
		const subscribeOpStatus = queryParams.get('subscribeOpStatus');
		const subscribeOpProviderStatus = queryParams.get('subscribeOpProviderStatus');

		if (subscribeOpStatus) {
			history.replace(location.pathname);

			const text =
				subscribeOpStatus.toUpperCase() === 'ACTIVE'
					? t('wtr:STATUS_ACTIVE')
					: `${t('wtr:SUBSCRIBE_FAILED_TEXT')}\n${subscribeOpStatus}\n/ ${subscribeOpProviderStatus}`;

			setModalStateProps({
				reason: SubscriptionModalReason.SubscribeResponse,
				isOpen: true,
				text,
			});
		}
	}, []);
};

export default useHandleSubscribeResponse;
