import React, { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../hooks/useClickOutside';

import { MarketsGroupName } from '../../../../../utils/functions/enums';

import styles from './TableSettingsModal.module.scss';

interface TableSettingsModalProps {
	columns: any[];
	selectedCategory: string;
	setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
}

const TableSettingsModal: FC<TableSettingsModalProps> = ({ columns, selectedCategory, setShowSettingsModal }) => {
	const { t } = useTranslation();

	const settingsRef = useRef<HTMLDivElement>(null);

	// Click outside or escape key press hook
	useClickOutside(settingsRef, () => setShowSettingsModal(false));

	const changeCaption = useMemo(() => {
		const caption = t('wtr:CHANGE');
		return caption[caption.length - 1] === ':' ? caption.slice(0, -1) : caption;
	}, []);

	const translations: { [key: string]: string } = useMemo(() => {
		return {
			symbol: t('en:SYMBOL'),
			name: t('wtr:FULL_NAME'),
			volatility: t('wtr:VOLATILITY'),
			currency: t('en:CURRENCY'),
			change: changeCaption,
			low: t('en:LOW'),
			high: t('en:HIGH'),
			buy: t('en:BUY'),
			sell: t('en:SELL'),
			settings: t('en:SETTINGS'),
		};
	}, []);

	return (
		<div ref={settingsRef} className={cn(styles.tableSettingsPopup)}>
			<div className={cn(styles.tableSettingsTitle)}>{t('wtr:COLUMN_CHOOSER')}</div>

			<div className={cn(styles.tableSettingOptionsContainer)}>
				{columns.map((column) => {
					const isDynamicCategorySelected =
						selectedCategory === MarketsGroupName.Risers ||
						selectedCategory === MarketsGroupName.Fallers ||
						selectedCategory === MarketsGroupName.MostVolatile ||
						selectedCategory === MarketsGroupName.RisersSB ||
						selectedCategory === MarketsGroupName.FallersSB ||
						selectedCategory === MarketsGroupName.MostVolatileSB;

					const isChangeDisabled = column.id === 'change' && !isDynamicCategorySelected;

					if (
						column.id === 'symbol' ||
						column.id === 'buy' ||
						column.id === 'sell' ||
						column.id === 'settings' ||
						column.id === 'volatility' ||
						isChangeDisabled
					) {
						return;
					}

					return (
						<div key={column.id} className={cn(styles.checkboxContainer)}>
							<label htmlFor={column.id} className={styles.container}>
								<input
									{...{
										id: column.id,
										className: styles.checkbox,
										type: 'checkbox',
										checked: isChangeDisabled ? false : column.getIsVisible(),
										onChange: column.getToggleVisibilityHandler(),
										disabled: isChangeDisabled,
									}}
								/>
								<div className={`${styles.optionText} ${isChangeDisabled && styles.disabledCheckbox}`}>
									{translations[column.id] || column.id}
								</div>
								<span className={`${styles.checkmark} ${isChangeDisabled && styles.disabledCheckbox}`} />
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default TableSettingsModal;
