export const SW_UPDATE_NOTIFICATION_DIALOG_ID = 'sw-update-notification';
export const IS_VISIBLE_DIALOG_CLASS = 'isDialogVisible';

export const showSkipWaitingPrompt =  () => {
    console.info("showSkipWaitingPrompt")
    const notificationDialog = document.getElementById(SW_UPDATE_NOTIFICATION_DIALOG_ID);
    if (notificationDialog) {
        notificationDialog.classList.add(IS_VISIBLE_DIALOG_CLASS);
    }
};

export const hideSkipWaitingPrompt =  () => {
    console.info("hideSkipWaitingPrompt")

    const notificationDialog = document.getElementById(SW_UPDATE_NOTIFICATION_DIALOG_ID);
    if (notificationDialog) {
        notificationDialog.classList.remove(IS_VISIBLE_DIALOG_CLASS);
    }
};
