import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../contexts/AppContext';

import styles from './LiveChat.module.scss';
import XIcon from '../../images/SvgComponents/X';

const Horizontal = ({
  isOpen,
  onZDClick,
  onToggle,
  channels
}: {
  isOpen: boolean;
  onZDClick: () => void;
  onToggle: () => void;
  channels: [any];
}) => {
  const [_, setIsHover] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const theme = appContext.appTheme;

  return (
    <div className={styles.horizontal}>
      <div className={cn(styles.buttons, isOpen ? styles.isOpen : '')}>
        <FontAwesomeIcon onClick={() => onToggle()} className={styles.icon} icon={['fal', 'times']}/>
        {channels.map(({ icon, href }) => (
          <a target="_blank" rel="noopener noreferrer" href={href} key={icon[1]} onClick={onToggle}>
            {
              icon[1] === 'twitter' ? (
                <XIcon
                  className={cn(styles.xicon)}
                  fill={theme !== 'dark' ? '#1C242E' : '#fff'}
                />
              ) : (
                <FontAwesomeIcon className={styles.icon} icon={icon}/>
              )
            }
          </a>
        ))}
        <button className={styles.zendesk} onClick={onZDClick}>
          <FontAwesomeIcon
            className={cn(styles.icon, theme === 'dark' && styles.iconLight)}
            icon={['far', 'comment-alt-dots']}
          />
        </button>
      </div>
      <div
        onClick={onToggle}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={styles.triggerIcon}
      >
        <FontAwesomeIcon icon={['fas', 'message-dots']}/>
      </div>
    </div>
  );
};

export default Horizontal;
