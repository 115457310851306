import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'classnames';

import { default as RfpGatewayContext } from '../../../contexts/RfpGatewayContext';
import { default as AppContext } from '../../../contexts/AppContext';
import { default as DashboardContext } from '../../../contexts/DashboardContext';

import { RFP } from '../../../gateways/RfpGateway/rfpConstants';
import { PriceQuote } from '../../../gateways/RfpGateway/rfp.types';
import { default as usePromiseFactory } from '../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';
import { Optional } from '../../../utils/functions/Nullable';
import { TWatchlists } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import Button from '../../../shared/Button/Button';
import WtrPopup from '../WtrPopup/WtrPopup';
import Instrument from '../../features/Dashboard/Watchlist/Instrument/Instrument';
import { formatNumberWithCommas } from '../../features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import { instrumentGroupProps } from '../../../utils/functions/constants';

import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
	instrumentExistsInAnyWatchlist,
	instrumentExistsInWatchlist,
	invalidWatchlistName,
	maxInstrumentsPerWatchlist,
	maxWatchlistsReached,
} from '../../../utils/functions/WatchlistUtils';
import usePreferredFeedId from '../../../utils/hooks/usePreferredFeedId';

import InstrumentContext from '../../../contexts/InstrumentContext';

import WatchlistsList from '../../features/Dashboard/Watchlist/WatchlistsList';

import CreateWatchlistButton from '../../features/Dashboard/Watchlist/CreateWatchlistButton';

import useGetTranslatedWLName from '../../../utils/hooks/useGetTranslatedWLName';
import DailyPercent from '../DailyPercent/DailyPercent';

import { TicketLayout } from '../../../utils/functions/enums';
import { TradersGymContext, TradersGymContextType } from '../../../pages/TradersGym/TradersGymContext';

import useSaveWatchlistToPreferences from '../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../store/tradingAccountStore';
import watchListStore from '../../../store/WatchListStore/watchListStore';
import InstrumentIcon from '../GroupBadge/InstrumentIcon';
import TradingSessionTooltip from '../Shared/TradingSessionTooltip';
import tradingViewStore from '../../../store/tradingViewStore';

import styles from './InstrumentHeader.module.scss';

type InstrumentHeaderProps = {
	showButtons?: boolean;
	resizeHeader?: boolean;
	headerActions?: boolean;
	undock?: any;
	onCloseButtonCLick?: () => void;
};

const InstrumentHeader = React.memo<InstrumentHeaderProps>(
	({ showButtons, resizeHeader, headerActions, undock, onCloseButtonCLick }) => {
		const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

		const appContext = useContext(AppContext);
		const dashboardContext = useContext(DashboardContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);
		const instrumentContext = useContext(InstrumentContext);
		const gymContext = useContext(TradersGymContext) as TradersGymContextType;
		const { tradersGymContext } = gymContext;

		const promiseFactory = usePromiseFactory();
		const forceRerender = useForceRerender();
		const { t } = useTranslation();
		const tt = useShortTranslation('en:');

		const [openWatchlist, setOpenWatchlist] = useState<boolean>(false);
		const [create, setCreate] = useState<boolean>(false);
		const [listName, setListName] = useState<string>('');
		const [disabled, setDisabled] = useState<boolean>(false);
		const [currentPriceQuote, setCurrentPriceQuote] = useState<Optional<PriceQuote>>(undefined);

		const subIdRef = useRef<string | undefined>(undefined);

		const [high, setHigh] = useState<string | any>('0');
		const [low, setLow] = useState<string | any>('0');

		const [nameError, setNameError] = useState<string>('');
		const [maxWatchlistsError, setMaxWatchlistsError] = useState(false);
		const getTranslatedWLName = useGetTranslatedWLName();

		const isArabic = appContext.isArabic;
		const languageSettings = appContext.languageSettings;
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		const watchlist = dashboardContext.watchlist;
		const mappedWatchlist = dashboardContext.mappedWatchlist;
		const marketItem = dashboardContext.selectedInstrument;
		const marketItems = dashboardContext.marketItems;
		const isChildWindow = appContext.isChildWindow ?? false;
		const activeTradingAccount = useSelectedTradingAccount();
		const preferredFeedId = usePreferredFeedId(activeTradingAccount);
		const isTradersGymActive = tradersGymContext.isActive;
		const currentWatchList = watchListStore.use.currentWatchList();
		const traderGymPriceQuote = tradersGymContext.priceQuote;
		const closedTicket = dashboardContext.modalToggle.closePosition;
		const openOrderInformation = tradingViewStore.use.openOrderInformation();
		const setOpenOrderInformation = tradingViewStore.use.setOpenOrderInformation();
		const ticketLayout = tradingViewStore.use.ticketLayout();

		useObservable(
			appContext.getPropertyChangeStream('appTheme', 'languageSettings', 'accountStats'),
			async (_change) => {
				await promiseFactory.throttle('appContext.propertyChanged', 100);
				forceRerender();
			}
		);

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'watchlist',
				'mappedWatchlist',
				'selectedPosition',
				'selectedInstrument',
				'marketItems',
				'tradingAccount',
				'previousDayClosePrices'
			),
			async (_change) => {
				await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
				forceRerender();
			}
		);

		useEffect(() => {
			existsInWatchlist();
		}, [marketItem, watchlist, mappedWatchlist]);

		useEffect(() => {
			listName.trim().length === 0 && setNameError('');
		}, [listName]);

		useEffect(() => {
			if (dashboardContext.mappedWatchlist !== null && maxWatchlistsReached(dashboardContext.mappedWatchlist!)) {
				return setDisabled(true);
			} else if (listName.trim().length === 0) {
				return setDisabled(true);
			} else {
				return setDisabled(false);
			}
		}, [listName]);

		useEffect(() => {
			if (mappedWatchlist !== null && maxWatchlistsReached(mappedWatchlist)) {
				setMaxWatchlistsError(true);
			} else {
				setMaxWatchlistsError(false);
			}
		}, [openWatchlist]);

		useEffect(() => {
			if (marketItem && rfpGatewayContext) {
				rfpGatewayContext.send(RFP.queuePreviousDayClosePrice, { code: [marketItem.code] });
			}
		}, [marketItem, rfpGatewayContext]);

		/**
		 * Checks if the instrument exists in a watchlist
		 */
		const existsInWatchlist = () => {
			let matches = 0;
			if (mappedWatchlist !== null && marketItem) {
				Object.keys(mappedWatchlist).forEach((name: string) => {
					instrumentExistsInWatchlist(mappedWatchlist, name, marketItem) && matches++;
				});
			}

			return matches > 0;
		};

		const DisplayIcon = () => {
			const instrumentExists = existsInWatchlist();

			return (
				<FontAwesomeIcon
					icon={instrumentExists ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
					className={instrumentExists ? styles.existsInWatchlistIcon : styles.addToWatchlistIcon}
					onClick={handleOpenWatchlistMenu}
					id="icon"
				/>
			);
		};

		const handleOpenWatchlistMenu = (_e: any) => {
			//If only 1 watchlist is present, then add or remove the instrument directly
			let preferenceToUpdate = watchlist.find((list) => list._name === currentWatchList);
			if (
				dashboardContext.mappedWatchlist !== null &&
				Object.keys(dashboardContext.mappedWatchlist).length === 1 &&
				marketItem
			) {
				const isMaxedOut = maxInstrumentsPerWatchlist(dashboardContext.mappedWatchlist, currentWatchList);
				if (preferenceToUpdate) {
					if (
						!instrumentExistsInWatchlist(dashboardContext.mappedWatchlist, currentWatchList, marketItem) &&
						!isMaxedOut
					) {
						preferenceToUpdate.instrument = [...preferenceToUpdate.instrument, { _code: marketItem.code }];
					} else {
						preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
							(symbol) => symbol._code !== marketItem.code
						);
					}
					dashboardContext.watchlist = watchlist;
					dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
					saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
				}
			} else {
				setOpenWatchlist(!openWatchlist);
				setCreate(false);
			}
		};

		const handleCreateWatchlist = () => {
			if (!maxWatchlistsError) {
				setCreate(!create);
				setOpenWatchlist(!openWatchlist);
			}
		};

		const handleInputChange = (e: any) => {
			setListName(e.target.value);
			nameError && setNameError('');
		};

		const handleCancelWatchlist = () => {
			setCreate(false);
			setNameError('');
			setListName('');
		};

		const verifyWatchlistName = () => {
			if (dashboardContext.mappedWatchlist !== null) {
				const validateName = invalidWatchlistName(listName.trim(), dashboardContext.mappedWatchlist!);
				if (!validateName) {
					setNameError('');
					handleAddNewWatchlist();
				} else {
					setNameError(t('wtr:NAME_IN_USE'));
				}
			}
		};

		const handleAddNewWatchlist = () => {
			if (
				listName.trim().length > 0 &&
				mappedWatchlist !== null &&
				marketItem &&
				!maxWatchlistsReached(mappedWatchlist)
			) {
				const newWatchlistEntry: TWatchlists = {
					instrument: [{ _code: marketItem.code }],
					_name: listName.trim(),
					_feedId: preferredFeedId !== '' ? preferredFeedId : DEFAULT_FEED_ID,
					_id: listName.trim(),
					_sortOrder: 'None',
					_sortValue: 'Instrument',
				};
				dashboardContext.watchlist.push(newWatchlistEntry);
				const updatedWatchlistMap = createWatchlistMap(dashboardContext.watchlist);
				dashboardContext.mappedWatchlist = updatedWatchlistMap;
				saveWatchlistToPreferences(dashboardContext.watchlist);
				setListName('');
				setCreate(!create);
				setOpenWatchlist(false);
			}
		};

		useEffect(() => {
			if (traderGymPriceQuote) {
				setCurrentPriceQuote(traderGymPriceQuote);
			}
		}, [traderGymPriceQuote]);

		useEffect(() => {
			if (marketItem && rfpGatewayContext) {
				// unsubscribe previous quotes
				if (subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				}

				// subscribe quotes
				subIdRef.current = rfpGatewayContext.subscribePriceQuote(marketItem.feedId, [marketItem.code], (priceQuote) => {
					if (!isTradersGymActive) {
						setCurrentPriceQuote(priceQuote);
					}
				});
			}
			return () => {
				// unsubscribe quotes on unmount
				if (rfpGatewayContext && subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
					subIdRef.current = undefined;
				}
			};
		}, [marketItem, rfpGatewayContext]);

		useEffect(() => {
			if (currentPriceQuote && marketItem) {
				setHigh(currentPriceQuote.h.toFixed(marketItem.decPrec));
				setLow(currentPriceQuote.l.toFixed(marketItem.decPrec));
			}
		}, [currentPriceQuote]);

		const tooltip = useMemo(() => {
			let watchlists: string[] = [];
			let inWatchlist: boolean = false;
			if (dashboardContext.mappedWatchlist !== null && marketItem) {
				inWatchlist = instrumentExistsInAnyWatchlist(dashboardContext.mappedWatchlist, marketItem);
				if (inWatchlist) {
					watchlists = instrumentContext.watchlistsforInstrument(dashboardContext.mappedWatchlist, marketItem);
					watchlists.forEach((watchlistName, index, thisArray) => {
						thisArray[index] = getTranslatedWLName(watchlistName);
					});
				}
			}
			return inWatchlist ? (
				<div>
					{t('wtr:EXISTS_IN')} {'"' + watchlists.join(' ", " ') + '"'}
				</div>
			) : (
				<div
					dangerouslySetInnerHTML={{
						__html: t('wtr:ADD_TO_WATCHLIST', {
							instrument: marketItem?.displayName,
						}),
					}}
				></div>
			);
		}, [marketItem, dashboardContext.mappedWatchlist]);

		const handleOpenInfoClick = () => {
			setOpenOrderInformation(!openOrderInformation);
		};

		return (
			<div
				className={cn(
					resizeHeader ? styles.resizedWrapper : styles.wrapper,
					ticketLayout === TicketLayout.Undock && styles.undocked
				)}
			>
				<div className={styles.headerContainer}>
					<div className={styles.title}>
						{marketItem && (
							<>
								{!openOrderInformation ? (
									<div className={styles.iconContainer}>
										{ticketLayout === TicketLayout.Undock &&
											!dashboardContext.newOrderModalToggle.confirmOrder &&
											!dashboardContext.showConfirmTicket &&
											!closedTicket && (
												<div className={styles.infoIcon} onClick={handleOpenInfoClick}>
													<FontAwesomeIcon icon={['fas', 'info-circle']} />
												</div>
											)}
										<OverlayTrigger
											key="instrumentCategoryInfo"
											delay={{ show: 750, hide: 0 }}
											placement="bottom"
											overlay={
												<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
													{marketItem.grp && t(instrumentGroupProps[marketItem.grp]?.name)}
												</Tooltip>
											}
										>
											<div className={styles.badge}>
												<InstrumentIcon marketItem={marketItem} />
											</div>
										</OverlayTrigger>
									</div>
								) : (
									<div className={styles.iconContainer}>
										<div className={styles.icon} onClick={handleOpenInfoClick}>
											<FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon>
										</div>
									</div>
								)}
							</>
						)}
						<div>
							<div className={styles.instrumentStats}>
								<span className={styles.shortInstrumentName}>{marketItem && marketItem.displayName}</span>
								{marketItem && <TradingSessionTooltip marketItem={marketItem} />}

								{!isTradersGymActive && (
									<DailyPercent
										currentPriceQuote={currentPriceQuote}
										decimalPrecision={marketItem ? marketItem.decPrec : 0}
									/>
								)}
							</div>
							{/* Full instrument name */}
							<div className={styles.instrumentFullName}>{marketItem && marketItem.fullName}</div>
						</div>
					</div>
					{headerActions ? (
						!isTradersGymActive && (
							<div className={styles.iconGroup}>
								<WtrPopup
									open={openWatchlist}
									onClose={() => setOpenWatchlist(false)}
									className={styles.popupContainer}
									on="click"
									position="bottom right"
									trigger={
										<OverlayTrigger
											key="watchlistIconInstrumentHeader"
											delay={{ show: 750, hide: 0 }}
											placement={isArabic ? 'right' : 'left'}
											overlay={
												<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
													{tooltip}
												</Tooltip>
											}
										>
											<div>
												<DisplayIcon />
											</div>
										</OverlayTrigger>
									}
									content={
										<div className={styles.watchlistsListContainer}>
											<WatchlistsList />
											<CreateWatchlistButton
												maxWatchlistsError={maxWatchlistsError}
												handleCreateWatchlist={handleCreateWatchlist}
											/>
										</div>
									}
								/>
							</div>
						)
					) : (
						<div className={styles.closeTicket}>
							{!openOrderInformation &&
								!dashboardContext.newOrderModalToggle.confirmOrder &&
								!dashboardContext.showConfirmTicket &&
								!isChildWindow && (
									<div className={styles.iconContainer}>
										<OverlayTrigger
											key="detachOrderTicket"
											delay={{ show: 750, hide: 0 }}
											placement={isArabic ? 'right' : 'left'}
											overlay={
												<Tooltip className="my-tooltip">
													{ticketLayout === TicketLayout.Undock ? t('en:ATTACH') : t('en:DETACH')}
												</Tooltip>
											}
										>
											<div className={styles.icon}>
												<FontAwesomeIcon icon={['fas', 'external-link-alt']} size="1x" onClick={undock} />
											</div>
										</OverlayTrigger>

									{ticketLayout === TicketLayout.Undock && (
										<FontAwesomeIcon
											icon={['fas', 'times']}
											style={{ width: 19, height: 19, cursor: 'pointer' }}
											onClick={onCloseButtonCLick}
										/>
									)}
								</div>
							)}
						</div>
					)}
					{create && (
						<div className={styles.createContainer}>
							<div className={styles.createHeader}>
								<span>{tt('CREATE_WATCHLIST')}</span>
							</div>
							<div className={styles.inputContainer}>
								<span className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</span>
								<input
									className={nameError ? styles.errorInput : styles.input}
									type="text"
									maxLength={20}
									value={listName}
									onChange={handleInputChange}
								/>
								<div className={styles.messageRow}>
									{nameError.length > 0 && <div className={styles.errorMessage}>{nameError}</div>}
									<span className={styles.inputCount}>{listName.trim().length}/20</span>
								</div>
							</div>
							<div className={styles.buttonContainer}>
								<Button variant="secondary" size="sm" label={tt('CANCEL')} onClick={handleCancelWatchlist} />
								<Button
									variant="primary"
									size="sm"
									label={tt('CREATE')}
									disabled={disabled}
									onClick={verifyWatchlistName}
								/>
							</div>
						</div>
					)}
				</div>
				<div className={styles.highLowBox}>
					<div className={styles.textTitleLow}>
						{tt('LOW')}:{' '}
						<span className={styles.lowValue}>
							{isNaN(+low.toString()?.replaceAll(',', ''))
								? t('wtr:NA')
								: formatNumberWithCommas(parseFloat(low), marketItem?.decPrec || 0, languageSettings)}
						</span>
					</div>
					<div className={styles.textTitleHigh}>
						{tt('HIGH')}:{' '}
						<span className={styles.highValue}>
							{isNaN(+high.toString()?.replaceAll(',', ''))
								? t('wtr:NA')
								: formatNumberWithCommas(parseFloat(high), marketItem?.decPrec || 0, languageSettings)}
						</span>
					</div>
				</div>

				{showButtons && marketItems && marketItem && (
					<div className={styles.buttons}>
						<Instrument
							marketItem={marketItem}
							showPriceStats={false}
							spreadPosition={true}
							resizeButton={true}
							showInstrumentName={false}
							showButtonText={true}
							index={1}
							parent="instrumentHeader"
							onChartClick={() => console.log('clicked')}
							buttonsClass={styles.bidAskSpreadButtons}
						/>
					</div>
				)}
			</div>
		);
	},
	(prevProps, nextProps) => {
		const keys: Array<keyof typeof prevProps> = ['showButtons', 'resizeHeader', 'headerActions', 'undock'];
		return keys.every((key) => prevProps[key] === nextProps[key]);
	}
);

export default InstrumentHeader;
