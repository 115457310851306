export type AppConfig = {
	API_URL: string;
	TWOFA_URL: string;
};

const getConfig = (apiHost: string, twoFAHost: string): AppConfig => {
	return {
		API_URL: `${apiHost}/nsdata`,
		TWOFA_URL: `${twoFAHost}/2fa`,
	};
};

let environment = getConfig(process.env.REACT_APP_API_URL!, process.env.REACT_APP_2FA_URL!);

export const LIVECHAT_JAPAN_DEPT = 'ライブチャットによるサポート';

export const SUPPORT_EMAIL_GENERAL = 'support@thinkmarkets.com';
export const SUPPORT_EMAIL_JAPAN = 'support-jp@thinkmarkets.com';

export const tfboSourceParam = 'webTrader';

export const SUBSCR_MGMT_PROV = 'BjpSub';

export const GENERAL_DATE_FORMAT = 'DD/MMM/YYYY';
export const GENERAL_DATETIME_FORMAT = 'DD/MMM/YYYY hh:mm';
export const GENERAL_DATETIMES_FORMAT = 'DD/MMM/YYYY H:mm:ss';

export const JAPAN_DATE_FORMAT = 'yyyy/MM/DD'; // 'yyyy/MM/D日';
export const JAPAN_DATETIME_FORMAT = 'yyyy/MM/DD HH:mm'; // 'yyyy年MM月D日 HH:mm';
export const JAPAN_DATETIMES_FORMAT = 'yyyy/MM/DD H:mm:ss';

export { environment };

export const ENVIRONMENT_NAME = process?.env?.REACT_APP_ENV;

export const isDevelopment = ENVIRONMENT_NAME === 'development';
export const isStaging = ENVIRONMENT_NAME === 'staging';
export const isProduction = ENVIRONMENT_NAME === 'production';
export const isPreviewProduction = ENVIRONMENT_NAME === 'preview-production';

export const versionNumber = process.env.REACT_APP_VERSION;

export const DX_COLUMN_CHOOSER_SIZE = { width: 350, height: 350 };

export const MFA_ACCESS_TOKEN_KEY = 'mfaAccessToken';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const USER_EMAIL_KEY = 'emai';

export const signalsBpKey = 'EMmXGbF89RM7iizI';
export const signalsBpVal = 'C1KzLpPmo3uIjZR7';

export const TV_UAT_REDIRECT_LOGIN_URL = 'https://tvstaging-oauth.thinkmarkets.com/login';
export const TV_PROD_REDIRECT_LOGIN_URL = 'https://tvprod-oauth.thinkmarkets.com/login';
