import React, { useState, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fullStar } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

import commentIcon from '../../../images/comment.svg';
import Modal from '../../../shared/Modal/Modal';
import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as AppContext } from '../../../contexts/AppContext';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';

import Button from '../../../shared/Button/Button';

import oneStar from '../../../images/feedbackStars/oneStar.png';
import twoStars from '../../../images/feedbackStars/twoStars.png';
import threeStars from '../../../images/feedbackStars/threeStars.png';
import fourStars from '../../../images/feedbackStars/fourStars.png';
import fiveStars from '../../../images/feedbackStars/fiveStars.png';

import useSubmitFeedback from '../../../utils/mutations/useSubmitFeedback';
import authStore, { AuthStore } from '../../../store/authStore';

import styles from './FeedbackModal.module.scss';

const FeedbackModal = () => {
	const appContext = useContext(AppContext);

	const forceRerender = useForceRerender();
	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');

	const showFeedbackModal = appContext.feedbackModal;

	const [feedbackInput, setFeedbackInput] = useState('');
	const [ratingStars, setRatingStars] = useState(Array(5).fill(emptyStar));
	const [showFeedbackSubmitScreen, setShowFeedbackSubmitScreen] = useState<boolean>(false);
	const [starsImage, setStarsImage] = useState<string | null>(null);

	const { email } = authStore((store: AuthStore) => store.userProfile);
	const { mutate: submitFeedback } = useSubmitFeedback();

	const mapFeedbackScore = (score: number): number => {
		//TODO: OPTIMISE ME
		switch (score) {
			case 1:
				return 0;
			case 2:
				return 0.25;
			case 3:
				return 0.5;
			case 4:
				return 0.75;
			case 5:
				return 1;
			default:
				return 0;
		}
	};

	const handleFeedbackInput = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
		setFeedbackInput(value);
	};

	const handleCancelFeedback = () => {
		setFeedbackInput('');
		setShowFeedbackSubmitScreen(false);
		setRatingStars(Array(5).fill(emptyStar));
		setStarsImage(null);
		appContext.feedbackModal = false;
	};

	const handleFeedbackSubmit = () => {
		setShowFeedbackSubmitScreen(true);
		const score = mapFeedbackScore(ratingStars.filter((star: IconDefinition) => star.prefix === 'fas').length);
		const requestPayload = {
			email,
			score: score,
			comments: feedbackInput,
		};
		submitFeedback(requestPayload, {
			onSuccess: () => setFeedbackInput(''),
			onError: (err) => console.debug('Error retrieving profile feedback from TFBO gateway.  Exception: ', err),
		});
	};

	const handleRatingClick = (idx: number) => {
		switch (idx) {
			case 0:
				setStarsImage(oneStar);
				break;
			case 1:
				setStarsImage(twoStars);
				break;
			case 2:
				setStarsImage(threeStars);
				break;
			case 3:
				setStarsImage(fourStars);
				break;
			case 4:
				setStarsImage(fiveStars);
				break;
		}
		ratingStars.forEach((star: IconDefinition, index: number) => {
			if (index <= idx) {
				ratingStars[index] = fullStar;
			} else {
				ratingStars[index] = emptyStar;
			}
		});
		setRatingStars([...ratingStars]);
	};

	useObservable(appContext.getPropertyChangeStream('feedbackModal'), async (change) => {
		forceRerender();
	});

	const handleClose = (): void => {
		if (showFeedbackModal) {
			appContext.feedbackModal = false;
			handleCancelFeedback();
		}
	};

	return (
		<Modal
			backdrop="static"
			dialogClassName={styles.modalDialog}
			centered
			show={showFeedbackModal}
			onHide={handleCancelFeedback}
		>
			<Modal.Header className={styles.feedbackHeader}>
				<h2 className={styles.feedbackModalTitle}>{t('wtr:WTR_NAVIGATION_FEEDBACK')}</h2>
				<button className={styles.closeIcon} onClick={handleClose}>
					<FontAwesomeIcon icon={['fas', 'times']} />
				</button>
			</Modal.Header>

			{showFeedbackSubmitScreen && (
				<div className={styles.submittedContainer}>
					<div className={styles.thankYouMessage}>{tt('FEEDBACK_THANKS')}</div>
					<img src={commentIcon} alt="commentIcon"></img>
					<div className={styles.appreciateMessage}>{tt('APPRECIATE_MSG')}</div>
				</div>
			)}

			{!showFeedbackSubmitScreen && (
				<Modal.Body className={styles.modalBody}>
					<div className={styles.ratingContainer}>
						<div className={styles.questionText}>{t('en:FEEDBACK_STAR_TITLE')}</div>
						<div className={styles.starContainer}>
							{ratingStars.map((star: IconDefinition, idx: number) => {
								return (
									<span onClick={() => handleRatingClick(idx)} key={idx}>
										{starsImage && idx === 0 && <img className={styles.starsImage} src={starsImage} alt="star"></img>}
										<FontAwesomeIcon
											key={idx}
											icon={star}
											size="2x"
											style={{
												marginRight: '8px',
												cursor: 'pointer',
												visibility: star === fullStar ? 'hidden' : 'initial',
											}}
										></FontAwesomeIcon>
									</span>
								);
							})}
						</div>
					</div>

					<div className={styles.feedbackContainer}>
						<div className={styles.questionText}>{t('en:FEEDBACK_HINT')}</div>
						<textarea value={feedbackInput} onChange={handleFeedbackInput} className={styles.textArea}></textarea>
					</div>
				</Modal.Body>
			)}
			{!showFeedbackSubmitScreen && (
				<Modal.Footer className={styles.footerContainer}>
					<Button
						className={styles.button}
						size="lg"
						variant="secondary"
						label={t('en:CANCEL')}
						onClick={handleCancelFeedback}
					/>
					<Button
						className={styles.button}
						size="lg"
						variant="primary"
						label={t('en:UPDATE')}
						disabled={ratingStars.filter((star: IconDefinition) => star.prefix === 'fas').length === 0}
						onClick={handleFeedbackSubmit}
					/>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default FeedbackModal;
