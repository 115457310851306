import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ApmRoute } from '@elastic/apm-rum-react';

import './App.scss';

import Routes, { webTraderPath } from './setup/routes';

import './setup/registerIcons';
import './setup/monitoring';
import './setup/axios';

import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { Resolver } from './utils/functions/Ioc';
import AppContext from './contexts/AppContext';
import usePromiseFactory from './utils/hooks/usePromiseFactory';
import useObservable from './utils/hooks/useObservable';
import useForceRerender from './utils/hooks/useForceRerender';
import ThemeWrapper from './views/components/ThemeWrapper';
import { subscribeOpsPersistHelper } from './utils/functions/subscrOpParamsPersistHelper';
import TradersGymContextProvider from './pages/TradersGym/TradersGymContext';
import InstrumentContext, { InstrumentContextProvider } from './contexts/InstrumentContext';

import useInitialize from './utils/hooks/system/useInitialize';
import AccountStatusHandler from './views/components/Permissions/AccountStatusHandler';

import TwoFactorAuthModal from './views/features/Dashboard/Header/components/SettingsModal/TwoFactorAuthentication/TwoFactorAuthModal';

import DashboardContainerComponent from './views/features/DashboardContainerComponent/DashboardContainerComponent'; // import useWindowSize from './utils/hooks/useWindowSize';
import MarginRulesContext, { MarginRulesContextProvider } from './contexts/MarginRulesContext';
import { MarginRequirementsContextProvider } from './contexts/MarginRequirementsContext';
import lazyRetry from './utils/functions/lazyRetry';

const Login = lazy(() => lazyRetry(() => import('./pages/Login/Login')));
const MobileView = lazy(() => lazyRetry(() => import('./pages/Mobile/MobileView')));
const ForgotPassword = lazy(() => lazyRetry(() => import('./pages/ForgotPassword/ForgotPassword')));
const TwoFactorAuthentication = lazy(() =>
	lazyRetry(() => import('./pages/TwoFactorAuthentication/TwoFactorAuthentication'))
);
const Agreement = lazy(() => lazyRetry(() => import('./views/features/Agreement')));
const NewTraderWindow = lazy(() => lazyRetry(() => import('./views/components/NewTraderWindow/NewTraderWindow')));
const TradersGym = lazy(() => lazyRetry(() => import('./pages/TradersGym/TradersGym')));
const Signals = lazy(() => lazyRetry(() => import('./pages/Signals/Signals')));
// const NewUpdateNotification = lazy(() => import('./shared/NewUpdateNotification/NewUpdateNotification'));
const AccountStatusModal = lazy(() =>
	lazyRetry(() => import('./views/components/AccountStatusModal/AccountStatusModal'))
);

const App: React.FC = () => {
	const appContext = useContext(AppContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const instrumentContextProvider = Resolver.resolve(InstrumentContextProvider);
	const marginRulesProvider = Resolver.resolve(MarginRulesContextProvider);

	const queryPersistAppendix = subscribeOpsPersistHelper('status', 'providerStatus');

	useObservable(appContext.getPropertyChangeStream('isArabic'), () => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	useEffect(() => {
		if (appContext.isArabic) {
			document.documentElement.setAttribute('dir', 'rtl');
		} else {
			document.documentElement.removeAttribute('dir');
		}
	}, [appContext.isArabic]);

	useInitialize();

	return (
		<Suspense fallback="">
			<ThemeWrapper>
				<InstrumentContext.Provider value={instrumentContextProvider}>
					<MarginRulesContext.Provider value={marginRulesProvider}>
						<TradersGymContextProvider>
							<MarginRequirementsContextProvider>
								<AccountStatusHandler />
								<AccountStatusModal />
								<TwoFactorAuthModal />
								<Switch>
									<ApmRoute exact path={Routes.account.sso} />
									<ApmRoute exact path={Routes.trader.tradersGym} component={TradersGym} />
									<ApmRoute exact path={Routes.trader.signals} component={Signals} />
									<ApmRoute path={`${Routes.trader.detached}/:id`} component={NewTraderWindow} />
									<ApmRoute exact path={Routes.account.agreeToTermsAndConditions} component={Agreement} />
									<ApmRoute path={webTraderPath} component={DashboardContainerComponent} />
									<ApmRoute exact path={Routes.account.forgotPassword} component={ForgotPassword} />
									<ApmRoute exact path={Routes.account.login} component={Login} />
									<ApmRoute exact path={Routes.account.twoFactorAuthentication} component={TwoFactorAuthentication} />
									<ApmRoute exact path={Routes.account.mobile} component={MobileView} />
									<ApmRoute
										render={() => <Redirect to={{ pathname: Routes.account.login, search: queryPersistAppendix }} />}
									/>
								</Switch>
							</MarginRequirementsContextProvider>
						</TradersGymContextProvider>
					</MarginRulesContext.Provider>
				</InstrumentContext.Provider>
			</ThemeWrapper>
		</Suspense>
	);
};

export default App;
