import React from 'react';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { INotification, NotificationType } from '../../../../../contexts/NotificationsContext';

import styles from './NotificationsItem.module.scss';

const NotificationsItem = ({ notification }: { notification: INotification }) => {
	const IconMap: Record<string, IconProp> = {
		[NotificationType.alert]: ['far', 'exclamation-triangle'],
		[NotificationType.info]: ['far', 'sparkles'],
	};

	return (
		<div className={cn(styles.item, styles[notification.type.toLowerCase()])}>
			<div className={styles.iconContainer}>
				<FontAwesomeIcon
					icon={IconMap[notification.type]}
					className={cn(styles.icon, notification.type === NotificationType.alert && styles.alert)}
				/>
				<div className={styles.content}>{notification.content}</div>
			</div>
			{notification.action}
		</div>
	);
};

export default NotificationsItem;
