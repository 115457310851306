import React, { useState, useContext } from 'react';

import WtrModal from '../../../shared/WtrModal/WtrModal';

import AppContext from '../../../contexts/AppContext';

import styles from './JapanNewsDisclaimer.module.scss';

const JapanNewsDisclaimer = () => {
	const [openDisclaimerMessage, setOpenDisclaimerMessage] = useState<boolean>(false);
	const appContext = useContext(AppContext);

	if (!appContext.isJapanAccount) return null;
	return (
		<>
			<div onClick={() => setOpenDisclaimerMessage(true)} className={styles.disclaimerMessage}>
				ニュース配信に関する免責事項は+こちら+をご確認ください。
			</div>

			<WtrModal open={openDisclaimerMessage} size="tiny" onClose={() => setOpenDisclaimerMessage(false)}>
				<WtrModal.Header>{'MarketWinニュース'}</WtrModal.Header>
				<WtrModal.Content>
					<WtrModal.Description>
						<p>
							TF Global Markets
							Japan株式会社は、株式会社ジャパンエコノミックパルスから配信されたMarketWin24（本サービス）についてThinkTrader取引システムを通じて提供しておりますが、当社はその情報の正確性、配信の確実性などを保証するものではなく、本サービスに基づいて被ったいかなる損失・損害についても、一切の責任を負いません。
							・また、本サービスは、情報提供を目的としたものであり、売買の勧誘を目的としたものではありません。投資に関する最終決定はお客さまご自身の判断でなさいますようお願い致します。・なお、本サービスに記載されている情報は、株式会社ジャパンエコノミックパルスが情報提供を目的として作成したものであり、売買の勧誘を目的としたものではなく、本サービスに基づいて被ったいかなる損失・損害についても、株式会社ジャパンエコノミックパルスは一切の責任を負いません。また、本サービスの著作権は全て株式会社ジャパンエコノミックパルスに帰属します。無断の複製、転載、転送、販売、出版、配布等は法的に罰せられますのでご注意ください。
						</p>
					</WtrModal.Description>
				</WtrModal.Content>
			</WtrModal>
		</>
	);
};

export default JapanNewsDisclaimer;
