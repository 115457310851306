import React, { useContext } from 'react';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import uniqueId from '../../../../../../utils/functions/uniqueId';
import WtrTooltip from '../../../../../../shared/WtrTooltip/WtrTooltip';

import DashboardContext from '../../../../../../contexts/DashboardContext';

import styles from './Item.module.scss';

interface RightNavItemProps {
	onClick: () => void;
	tooltipText: string;
	selected: boolean;
	icon: IconProp;
}

/**
 * @param onClick - event handler
 * @param tooltipText - the content of the tooltip
 * @param selected - boolean
 * @param iconId - menu icon ID
 * @param icon - font awesome icon array
 */
const RightNavItem = ({ onClick, tooltipText, selected, icon }: RightNavItemProps) => {
	return (
		<WtrTooltip key={uniqueId()} content={tooltipText} position="left center">
			<div onClick={onClick} className={cn(styles.iconContainer, selected && styles.selected)}>
				<FontAwesomeIcon icon={icon} className={cn(styles.icon, selected && styles.selected)} />
			</div>
		</WtrTooltip>
	);
};

export default RightNavItem;
