import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';

import { AppComponentType } from '../../../../../utils/functions/enums';
import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

import Routes from '../../../../../setup/routes';

import AccountStatusCTA from '../../../../../shared/AccountStatusCTA/AccountStatusCTA';

import statusStore, { StatusStore } from '../../../../../store/accountStatusStore';

import authStore from '../../../../../store/authStore';

import { useSwitchToLive } from '../../../../../utils/hooks/system/useSwitchMode';

import SettingsOptions from './SettingsOptions';

import AccountUserIcon from './AccountUserIcon/AccountUserIcon';

import styles from './AccountMenuPopup.module.scss';

interface IAccountMenuPopupProps {
	setAccountPopupIsOpen: (a: boolean) => void;
	setOpenSettings: (a: boolean) => void;
}

const AccountMenuPopup = ({ setAccountPopupIsOpen, setOpenSettings }: IAccountMenuPopupProps) => {
	const history = useHistory();
	const setShowAccountStatus = statusStore((state: any) => state.setShowAccountStatus);
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const permissions = statusStore((store: StatusStore) => store.permissions);
	const accountMenuAction = permissions?.accountMenuAction;
	const accountMenuLabel = permissions?.accountMenuLabel;
	const { email } = appContext;
	const userProfile = authStore.use.userProfile();
	const [fullName, setFullname] = useState<string>(userProfile?.fullName || '');
	const switchToLive = useSwitchToLive();

	useEffect(() => {
		setFullname(userProfile?.fullName);
	}, [userProfile?.fullName]);

	const handleTpRedirectButton = (e: any) => {
		document.body.click();
		if (accountMenuAction === IStatusConfigEnum.switchToRealMoney) {
			switchToLive();
		} else if (accountMenuAction === IStatusConfigEnum.fund) {
			dashboardContext.presentComponentType = AppComponentType.Deposits;
			history.push(Routes.trader.funds, { from: window.location.pathname });
		} else if (accountMenuAction === IStatusConfigEnum.accountStatus) {
			setShowAccountStatus();
		}
	};
	// TODO: - Rewrite conditions in order to keep them consistent and don't mix UI button text with Context logic

	const handleTpRedirectReports = (action: string = '') => {
		document.body.click();
		if (action === IStatusConfigEnum.reports) {
			dashboardContext.presentComponentType = AppComponentType.Reports;
			history.push(Routes.trader.reports, { from: window.location.pathname });
		}
		if (action === IStatusConfigEnum.accountStatus) {
			setShowAccountStatus();
		}

		return;
	};

	const handleTpRedirectManageFunds = (action: string = '') => {
		document.body.click();
		if (action === IStatusConfigEnum.fund) {
			dashboardContext.presentComponentType = AppComponentType.Funds;
			history.push(Routes.trader.funds, { from: window.location.pathname });
		}
		if (action === IStatusConfigEnum.accountStatus) {
			setShowAccountStatus();
		}

		return;
	};

	return (
		<>
			<div className={styles.header}>
				<div className={styles.account}>
					<div className={cn([styles.userIconContainer, styles.userIconContainerFullWidth])}>
						<AccountUserIcon />
					</div>
					{fullName && <div className={styles.fullName}>{fullName}</div>}
					<div className={styles.email}>{email}</div>
				</div>
				<div className={styles.switchButtonContainer}>
					{accountMenuAction && (
						<AccountStatusCTA
							onClick={handleTpRedirectButton}
							size={'fluid'}
							variant="primary"
							isFullHeight={true}
							label={accountMenuLabel}
						/>
					)}
				</div>
			</div>
			<div className={styles.options}>
				<SettingsOptions
					setAccountPopupIsOpen={setAccountPopupIsOpen}
					setOpenSettings={setOpenSettings}
					handleTpRedirectReports={handleTpRedirectReports}
					handleTpRedirectManageFunds={handleTpRedirectManageFunds}
				/>
			</div>
		</>
	);
};

export default AccountMenuPopup;
