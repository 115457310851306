import { formattedNumber } from '../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import { SignalsListType } from './Signals';

type PriceType = 'entry' | 'stop' | 'takeProfit1' | 'takeProfit2' | 'res1' | 'res2' | 'res3' | 'sup1' | 'sup2' | 'sup3';
export const getRelevantPrice = (signal: SignalsListType, priceType?: PriceType) => {
	let priceKey;
	if (priceType === 'stop') {
		priceKey = 'stop';
	} else if (priceType === 'entry') {
		priceKey = signal.Report.action === 0 ? 'buy_' : 'sell_';
		priceKey += 'entry_target_1';
	} else if (priceType === 'takeProfit1') {
		priceKey = signal.Report.action === 0 ? 'buy_' : 'sell_';
		priceKey += 'target_1';
	} else if (priceType === 'takeProfit2') {
		priceKey = signal.Report.action === 0 ? 'buy_' : 'sell_';
		priceKey += 'target_2';
	} else if (priceType === 'res1') {
		priceKey = 'res_1';
	} else if (priceType === 'res2') {
		priceKey = 'res_2';
	} else if (priceType === 'res3') {
		priceKey = 'res_3';
	} else if (priceType === 'sup1') {
		priceKey = 'sup_1';
	} else if (priceType === 'sup2') {
		priceKey = 'sup_2';
	} else if (priceType === 'sup3') {
		priceKey = 'sup_3';
	}
	return signal.Report[priceKey as keyof typeof signal.Report];
};

export const getRelevantFormattedPrice = (
	signal: SignalsListType,
	priceType?: PriceType,
	isSpreadBettingAccount?: boolean
) => {
	const price = getRelevantPrice(signal, priceType);
	if (!price) return 0;
	return formattedNumber(
		price,
		signal.marketItem,
		!!isSpreadBettingAccount,
		undefined,
		undefined,
		undefined,
		undefined
	);
};
