import React, { useContext } from 'react';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import SharedModal from '../../../../../../shared/Modal/Modal';
import SharedButton from '../../../../../../shared/Button/Button';
import { forceCloseModal } from '../../../../../../utils/hooks/useForceCloseModal';
import { useTranslation } from 'react-i18next';
import { TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { GymTradingPosition } from '../../../../../../pages/TradersGym/Positions/GymTradingPosition';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';

interface GymRemoveModal {
	openRemoveModal: boolean;
	setOpenRemoveModal: (value: boolean) => void;
	record: any;
	closed?: boolean;
}
const GymRemoveModal = ({ openRemoveModal, setOpenRemoveModal, record, closed = false }: GymRemoveModal) => {
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;

	const { t } = useTranslation();

	const handleCancelRemove = () => {
		setOpenRemoveModal(false);
		forceCloseModal('fade modal');
	};

	const handleRemoveItem = () => {
		setOpenRemoveModal(false);
		if (record) {
			if (closed) {
				deleteClosedPosition();
			} else {
				deletePosition();
			}
		}
		forceCloseModal('fade modal');
	};

	const deletePosition = () => {
		const account = tradersGymContext.gymTradingAccount;
		if (account) {
			if (record.isGroupRow) {
				const positions = account.activePositions.filter(
					(pos) => pos.state === TradingPositionState.open && pos.simulationId === record.simulationId
				);
				if (positions) {
					positions.forEach((pos) => account.positionManager.deletePosition(pos));
				}
			} else {
				const pos = record.position as GymTradingPosition;
				if (pos) {
					account.positionManager.deletePosition(pos);
				}
			}
		}
	};

	const deleteClosedPosition = () => {
		const posId = record.posId;
		if (posId && tradersGymContext.gymTradingAccount) {
			tradersGymContext.gymTradingAccount.positionManager.deletePositionById(posId);
		}
	};

	return (
		<SharedModal show={openRemoveModal} centered dialogClassName={styles.modalRemoveOption}>
			<SharedModal.Header className={styles.modalTopHeader}>
				<SharedModal.Title className={styles.modalTitle}>{t('en:REMOVE')}</SharedModal.Title>
			</SharedModal.Header>
			<SharedModal.Body>
				<div className={styles.removeMessage}>{t('wtr:CONFIRM_REMOVE')}</div>
			</SharedModal.Body>
			<SharedModal.Footer className={styles.modifyFooter}>
				<div className={styles.actionButtons}>
					<SharedButton
						variant="secondary"
						size="lg"
						label={t('en:CANCEL')}
						className={styles.cancelButton}
						onClick={handleCancelRemove}
					/>
					<SharedButton
						variant="primary"
						size="lg"
						label={t('en:REMOVE')}
						className={styles.saveButton}
						onClick={handleRemoveItem}
					/>
				</div>
			</SharedModal.Footer>
		</SharedModal>
	);
};

export default GymRemoveModal;
