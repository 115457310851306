import React from 'react';

import cn from 'classnames';

import Slider from 'rc-slider';

import styles from './WtrSlider.module.scss';

/**
 * Wrapper component that allows you to stylize the rc-slider library
 */
const WtrSlider = ({ ...rest }: any) => {
	rest.className = cn(rest.className, styles.wtrSlider);

	return <Slider {...rest} />;
};

export default WtrSlider;
