import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../../../contexts/AppContext';
import useMoneyFormatter from '../../../../../utils/hooks/useMoneyFormatter';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import { formatNumberWithCommas } from '../../Watchlist/Instrument/formattedQuoteNumber';

import useObservable from '../../../../../utils/hooks/useObservable';

import { checkIsVolumeUnlimited } from '../../../../../utils/functions/subscriptionUtils';

import authStore, { AuthStore } from '../../../../../store/authStore';

import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

import {
	calculateEquity,
	calculateFreeMargin,
	calculateMarginLevel,
} from '../../../../../utils/functions/calculations';

import AccountStats from './AccountStats';
import MarginLevel from './MarginLevel';
import RemainingVolume from './RemainingVolume';
import CurrencyStats from './CurrencyStats';

import styles from './AccountPanel.module.scss';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

interface IAccountPanelProps {
	isSearchBarOpen: boolean;
}

const AccountPanel = ({ isSearchBarOpen }: IAccountPanelProps) => {
	const appContext = useContext(AppContext);
	const tt = useShortTranslation('en:');
	const { t } = useTranslation();
	const forceRerender = useForceRerender();
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);

	const [isMenuOpen, setIsMenuOpen] = useState(true);

	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), () => {
		forceRerender();
	});

	const selectedTradingAccount = useSelectedTradingAccount();

	// The variable accountMcLevel is used to determine the margin level for each account.
	// Ideally, this value should be retrieved from the backend service, based on the account settings.
	// However, for the time being, we will hardcode this value as a temporary workaround.
	const accountMcLevel = useMemo(() => (appContext.isJapanAccount ? 1.2 : 1.0), [appContext.isJapanAccount]);

	// const selectedAccountStats = tradingAccountStore.use.selectedAccountStats();
	// what follows is potentially one of the worst snippets of code I've written; ever
	// unfortuantely at the moment we don't have a better solution
	// what happens is that every time a tick for an instrument the user has an opened
	// position for the profit/loss is calculated inside websocket.ts using the calculations
	// utils and put on the dashbaordContext.tradingAccount[INDEX].netProfit
	// unfortunately with the observable contexts we are not notified for that change as it relies
	// on some quirks of JS so forceRerender on dashboardCtx.tradingaAccount change won't work
	// for the same reason we chan't use useMemo for selectedTradingAccount - we always need a fresh
	// value as the new data is inside the array;
	//
	// the solutions is: forceRerender every 1.5 seconds (this was used in the previous AccountPanel as well),
	// triggering the setTimeout on mount
	//
	// this is one of the first things that need to go once we have proper AccountContext :)
	//
	// self-invoking forceRerender function
	const rerender = () => {
		forceRerender();
		setTimeout(rerender, 1500);
	};
	// start the force-rerendering
	useEffect(rerender, []);

	const formatAsMoney = useMoneyFormatter(selectedTradingAccount);

	const accountProfitLoss = selectedTradingAccount?.netProfit || 0;
	const calculatedEquity = calculateEquity({
		balance: selectedTradingAccount?.balance || 0,
		profitLoss: accountProfitLoss,
	});
	const calculatedFreeMargin = parseFloat(
		calculateFreeMargin({
			equity: calculatedEquity,
			accountMcLevel,
			usedMargin: selectedTradingAccount?.usedMargin || 0,
		}).toFixed(2)
	);
	const calculatedMarginLevel = calculateMarginLevel({
		equity: calculatedEquity,
		usedMargin: selectedTradingAccount?.usedMargin || 0,
	});

	if (selectedTradingAccount) {
		selectedTradingAccount.freeMargin = calculatedFreeMargin;
	}

	useEffect(() => {
		const isAccountPanelOpen = localStorage.getItem('isAccountPanelOpen');

		if (isAccountPanelOpen === 'true') {
			setIsMenuOpen(true);
		} else if (isAccountPanelOpen === 'false') {
			setIsMenuOpen(false);
		} else {
			localStorage.setItem('isAccountPanelOpen', 'true');
			setIsMenuOpen(true);
		}
	}, []);

	const balance = formatAsMoney(selectedTradingAccount?.balance || 0);
	const profitLoss = formatAsMoney(+accountProfitLoss.toFixed(2));
	const equity = formatAsMoney(parseFloat(calculatedEquity.toFixed(2)));
	const marginValue = formatAsMoney(selectedTradingAccount?.usedMargin || 0);
	const availableMargin = formatAsMoney(calculatedFreeMargin);

	const marginLevel = formatNumberWithCommas(
		isNaN(parseFloat(calculatedMarginLevel.toFixed(2))) ? 0 : parseFloat(calculatedMarginLevel.toFixed(2)),
		2,
		appContext.languageSettings
	);

	const handleToggleAccountPanel = () => {
		localStorage.setItem('isAccountPanelOpen', (!isMenuOpen).toString());
		setIsMenuOpen(!isMenuOpen);
	};

	const mustShowRemainingVolume =
		isJapanSubscriptionAccount &&
		isLiveMode &&
		appContext.subscriptionInfo?.maxVolume &&
		!checkIsVolumeUnlimited(appContext.subscriptionInfo);

	let triggerAccountBalance = window.innerWidth <= 1680 ? isMenuOpen && !isSearchBarOpen : isMenuOpen;
	let iconButton: IconName = isMenuOpen && triggerAccountBalance ? 'caret-right' : 'caret-left';

	if (appContext.isArabic) {
		iconButton = isMenuOpen && triggerAccountBalance ? 'caret-left' : 'caret-right';
	}

	return (
		<div className={styles.container}>
			<div className={styles.accountPanelButton} onClick={handleToggleAccountPanel}>
				<FontAwesomeIcon icon={['fas', iconButton]} className={styles.buttonToggleIcon} />
			</div>

			{triggerAccountBalance && (
				<>
					<AccountStats
						label={t('wtr:WTR_CASH_BALANCE')}
						value={balance}
						hideDivider={!appContext.isArabic}
						dividerSymbol="+"
					/>

					<AccountStats
						label={tt('PROFIT_LOSS')}
						value={profitLoss}
						dividerSymbol={appContext.isArabic ? '=' : '+'}
						valueColoring
					/>

					<AccountStats label={tt('EQUITY')} value={equity} hideDivider={appContext.isArabic} dividerSymbol="=" />

					<MarginLevel
						formattedValues={{
							margin: marginValue,
							marginPercent: `${marginLevel}%`,
							availableMargin: availableMargin,
						}}
						marginLevel={parseFloat(calculatedMarginLevel.toFixed(2))}
						equity={parseFloat(calculatedEquity.toFixed(2))}
						margin={selectedTradingAccount?.usedMargin || 0}
						isGymPresenter={false}
					/>

					{mustShowRemainingVolume && <RemainingVolume baseCurrency={selectedTradingAccount?.baseCurrency} />}
				</>
			)}
			<CurrencyStats />
		</div>
	);
};

export default AccountPanel;
