import { metals, forex, commodities, crypto, efts, stocks, indices, energy } from '../../images';
import { ResolutionString } from '../../charting_library';

export const BASE_DOCUMENT_TITLE = 'ThinkTrader';

export const timezoneProperties = {
	DEFAULT_TIMEZONE: '',
};

export const instrumentGroupProps: {
	[key: string]: {
		name: string;
		icon: string;
	};
} = {
	Forex: {
		name: 'wtr:WTR_FOREX',
		icon: forex,
	},
	ForexSB: {
		name: 'wtr:WTR_FOREX',
		icon: forex,
	},
	Indices: {
		name: 'wtr:WTR_INDICES',
		icon: indices,
	},
	IndicesSB: {
		name: 'wtr:WTR_INDICES',
		icon: indices,
	},
	Stocks: {
		name: 'wtr:SHARE_CFDS',
		icon: stocks,
	},
	Energy: {
		name: 'wtr:ENERGY',
		icon: energy,
	},
	EnergySB: {
		name: 'wtr:ENERGY',
		icon: energy,
	},
	Commodities: {
		name: 'wtr:WTR_COMMODITIES',
		icon: commodities,
	},
	Crypto: {
		name: 'wtr:CRYPTO',
		icon: crypto,
	},
	Metals: {
		name: 'wtr:METALS',
		icon: metals,
	},
	MetalsSB: {
		name: 'wtr:METALS',
		icon: metals,
	},
	Equities: {
		name: 'wtr:SHARE_CFDS',
		icon: stocks,
	},
	CFD: {
		name: 'wtr:EQUITIES',
		icon: efts,
	},
	ETF: {
		name: 'wtr:EQUITIES',
		icon: efts,
	},
};

export const SUPPORTED_RESOLUTIONS = [
	'1',
	'2',
	'3',
	'5',
	'10',
	'15',
	'30',
	'60',
	// '120',
	// '180',
	'240',
	// '480',
	'1D',
	'1W',
	'1M',
] as ResolutionString[];

export const CALENDAR_MONTHS_SHORT_EN =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
