import { useTranslation } from 'react-i18next';
import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppContext from '../../../../../../contexts/AppContext';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import Nullable, { Optional } from '../../../../../../utils/functions/Nullable';
import { Subscription } from 'observable-fns';
import { PriceQuote, TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import moment from 'moment/moment';
import {
	convertAmountToLot,
	getCurrentPrice,
	getInstrumentDetails,
} from '../../../../../../utils/functions/calculations';
import { getGeneralFormatDate } from '../../../../../../utils/functions/subscriptionUtils';
import { TradingPositionType } from '../../../../../../utils/functions/enums';
import { DEFAULT_FEED_ID } from '../../../../../../utils/functions/WatchlistUtils';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';

const useOrderTable = () => {
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const languageSettings = appContext.languageSettings;
	const detailedInformation = dashboardContext.detailedInformation;
	const tradingPositions = dashboardContext.getTradingPositions();

	const selectedTradingAccount = useSelectedTradingAccount()?.id;
	const activeTradingAccount = useSelectedTradingAccount();
	const setQuote = quoteStore.use.setQuote();
	const panelSize = positionsStore.use.panelSize();

	const [columnOptionExpired, setColumnOptionExpired] = useState('');
	const priceFeed = useRef<any>();

	const addCommas = (current: number | string, decPrec?: number | null, isPipsChange?: boolean | null) => {
		decPrec = decPrec ? decPrec : current.toString().split('.')[1]?.length ?? 0;

		if (isPipsChange) decPrec = 2;

		return formatNumberWithCommas(current, decPrec, languageSettings);
	};

	const unsubscribeInstruments = () => {
		if (rfpGatewayContext && priceFeed.current) {
			rfpGatewayContext.unsubscribePriceQuote(priceFeed.current);
			priceFeed.current = undefined;
		}
	};

	const pendingPositionsBySymbol = useMemo(
		() =>
			tradingPositions.reduce((map, position) => {
				if (position.state === TradingPositionState.pending && position.aId === selectedTradingAccount) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>()),
		[tradingPositions, selectedTradingAccount]
	);

	const tableData = useMemo(() => {
		const items: any[] = [];

		const codes: string[] = [];

		pendingPositionsBySymbol.forEach((item, symbol) => {
			codes.push(symbol);
		});

		if (rfpGatewayContext) {
			if (priceFeed.current) {
				unsubscribeInstruments();
			}

			priceFeed.current = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, codes, (priceQuote) => {
				setQuote(priceQuote);
			});
		}

		pendingPositionsBySymbol.forEach((positions, symbol) => {
			let sellAmount = 0;
			let buyAmount = 0;
			let openDate: number[] = [];
			let expiredOrder: string = columnOptionExpired;
			let expiredDate: number[] = [];
			let nearestExpDate: string | number = Infinity;
			let oldestCreatedDate = Infinity;
			let farthestExpDate: string | number = 0;
			let newestCreatedDate = 0;
			positions.forEach((position) => {
				if (position.side === 'BUY') {
					buyAmount += position.qty || 0;
				} else {
					sellAmount += position.qty || 0;
				}
				openDate.push(position.oT!);
				const expDate = position.eT === 0 ? 'GTC' : position.eT;
				if (position.oT && moment(position.oT).valueOf() > newestCreatedDate) newestCreatedDate = position.oT;
				if (position.oT && moment(position.oT).valueOf() < oldestCreatedDate) oldestCreatedDate = position.oT;
				if (expDate && farthestExpDate !== 'GTC' && (expDate > farthestExpDate || expDate === 'GTC'))
					farthestExpDate = expDate === 'GTC' ? 'GTC' : expDate;
				if (
					position.eT &&
					(moment(position.eT).valueOf() < (nearestExpDate as number) ||
						nearestExpDate === 'GTC' ||
						(nearestExpDate === Infinity && expDate === 'GTC'))
				)
					nearestExpDate =
						expDate === 'GTC' && (nearestExpDate === Infinity || nearestExpDate === 'GTC') ? 'GTC' : position.eT;
			});
			const isGTC = expiredDate.includes(0);
			let gtcIndex = -1;
			if (isGTC) {
				gtcIndex = expiredDate.indexOf(0);
			}
			const expiredDateValue =
				expiredOrder === 'desc'
					? isGTC
						? expiredDate[gtcIndex]
						: expiredDate.sort().reverse()[0]
					: expiredDate.sort()[0];

			if (positions.length > 1 && detailedInformation) {
				const marketItem = rfpGatewayContext?.getMarketItem(symbol);
				const decPrec = marketItem?.decPrec ?? 2;
				const calAmount = buyAmount > 0 ? buyAmount - sellAmount : sellAmount;
				const squareAmount = calAmount === 0;
				const amount = calAmount < 0 ? calAmount * -1 : calAmount;
				let selectedInstrumentDetails = getInstrumentDetails(
					detailedInformation,
					activeTradingAccount ? [activeTradingAccount] : [],
					marketItem?.code as string
				);
				const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
				const lots = convertAmountToLot(amount, instrumentRndLot);
				const codeOrTicker =
					marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
						? marketItem.exchangeTicker
						: symbol;
				// const open = (summaryPrice / quantity).toFixed(decPrec)
				const sumValue = {
					amount,
					lots,
					// open,
					side: squareAmount ? 'SQUARE' : buyAmount > sellAmount ? 'BUY' : 'SELL',
					change: '',
					current: '',
					pL: '',
				};

				const obj = {
					position: undefined,
					id: symbol,
					headID: `${symbol}-1`,
					state: TradingPositionState.pending,
					instrument: `${codeOrTicker} (${positions.length})`,
					side: sumValue.side === 'SQUARE' ? t('wtr:SQUARE') : tt(`${sumValue.side}`),
					Amount: '', //addCommas(sumValue.amount ? Math.round(sumValue.amount * 100) / 100 : 0),
					Lots: addCommas(sumValue.lots ? Math.round(sumValue.lots * 100) / 100 : 0),
					createdOn: '',
					open: '',
					change: sumValue.change,
					current: '',
					TakeProfit: 'Head',
					StopLoss: 'Head',
					expiryOn: expiredDateValue === 0 && expiredOrder === 'desc' ? 2625689831785 : expiredDateValue,
					ExpiryOnTranslated: '',
					pL: '',
					'P/L Pips': '',
					Exposure: '',
					'Margin Usage': '',
					oP: '',
					Type: '',
					TypeTranslated: '',
					qty: '',
					code: symbol,
					qCcy: '',
					prc: '',
					comm: '',
					swap: '',
					isGroupRow: true,
					oldestCreatedDate: oldestCreatedDate,
					newestCreatedDate: newestCreatedDate,
					farthestExpDate: (farthestExpDate as string | number) === 'GTC' ? 'GTC' : farthestExpDate,
					nearestExpDate:
						(nearestExpDate as string | number) === 'GTC' || nearestExpDate === Infinity ? 'GTC' : nearestExpDate,
					lowestDTM: Infinity,
					highestDTM: 0,
					decPrec: decPrec,
					subRows: [],
				};

				items.push(obj);
			}
		});

		if (tradingPositions.length) {
			tradingPositions.forEach((position, index) => {
				const marketItem = position.marketItem;
				const positionLength = Nullable.of(pendingPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);

				if (
					position.state === TradingPositionState.pending &&
					position.aId === selectedTradingAccount &&
					detailedInformation
				) {
					const marketItem = position.marketItem ?? rfpGatewayContext?.getMarketItem(position.code);
					const decPrec = marketItem?.decPrec ?? 0;
					const currentPrice = getCurrentPrice(position.f, position.code, rfpGatewayContext);
					let selectedInstrumentDetails = getInstrumentDetails(
						detailedInformation,
						activeTradingAccount ? [activeTradingAccount] : [],
						marketItem?.code as string
					);
					const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
					const lots = convertAmountToLot(position.qty || 0, instrumentRndLot);
					const codeOrTicker =
						marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
							? marketItem.exchangeTicker
							: position.code;
					const expiry = position.eT === 0 ? ('GTC' as string) : position.eT;

					const createdOnTranslated = appContext.isJapanAccount
						? getGeneralFormatDate(position.oT, false, true)
						: moment(position.oT).format('D/MMM/YYYY').toString().toUpperCase();

					const parentItem = items.find((item) => item.code === position.code);

					const obj = {
						position: position,
						id: positionLength !== 1 ? index : position.code,
						headID: position.posId,
						posId: position.posId,
						aId: position.aId,
						state: position.state,
						instrument: codeOrTicker,
						side: tt(`${position.side}`),
						type: position.type === TradingPositionType.Limit ? tt('TYPE_LIMIT') : tt('TYPE_STOP'),
						typeTranslated: position.type === TradingPositionType.Limit ? tt('TYPE_LIMIT') : tt('TYPE_STOP'),
						Amount: addCommas(position.qty ? Math.round(position.qty * 100) / 100 : 0),
						Lots: addCommas(lots ? Math.round(lots * 100) / 100 : 0),
						createdOn: createdOnTranslated,
						open: addCommas(position.prc?.toFixed(decPrec) ?? NaN, decPrec),
						distanceToMarket: '',
						current: +(
							(position.side === 'SELL' ? currentPrice?.b.toFixed(decPrec) : currentPrice?.a.toFixed(decPrec)) ?? 0
						),
						TakeProfit: position.tp?.toFixed(decPrec) ?? '',
						StopLoss: position.sl?.toFixed(decPrec) ?? '',
						expiryOn: expiry === 'GTC' ? t('wtr:GTC') : moment(expiry).format('D/MMM/YYYY').toString().toUpperCase(),
						oP: position.oP,
						qty: position.qty,
						qCcy: position.qCcy,
						prc: position.prc,
						sl: position.sl,
						tp: position.tp,
						code: position.code,
						isGroupRow: false,
						time: moment(position.oT).format('HH:mm:ss').toLocaleUpperCase(),
						decPrec: decPrec,
					};

					if (parentItem) {
						parentItem.subRows.push(obj);
					} else {
						items.push(obj);
					}
				}
			});
		}

		items
			.filter((record) => record.isGroupRow)
			.forEach((record) => {
				if (record.subRows.length) {
					record.NetPL = record.subRows
						.filter((value: any) => value.code === record.code && !value.isGroupRow)
						.reduce((totalPl: number, gridRecord: any) => {
							return +totalPl + (isNaN((gridRecord.netPL || Number.NaN) as number) ? 0 : +gridRecord?.netPL!);
						}, 0);
					record.GrossPL = record.subRows
						.filter((value: any) => value.code === record.code && !value.isGroupRow)
						.reduce((totalPl: number, gridRecord: any) => {
							return +totalPl + (isNaN((gridRecord.grossPL || Number.NaN) as number) ? 0 : +gridRecord?.grossPL!);
						}, 0);
				}
			});

		return items;
	}, [tradingPositions, selectedTradingAccount, panelSize]);

	useEffect(() => {
		return () => {
			unsubscribeInstruments();
		};
	}, []);

	return tableData;
};

export default useOrderTable;
