import React, { FC } from 'react';

import quoteStore from '../../../../../store/QuoteStore/quoteStore';

export enum LowOrHighType {
	Low = 'low',
	High = 'high',
}

interface LowProps {
	marketItemCode: string;
	type: LowOrHighType;
}

const LowOrHigh: FC<LowProps> = ({ marketItemCode, type }): JSX.Element => {
	const quote = quoteStore((state) => state.quotes[marketItemCode]);

	if (!quote || !quote.currentQuote) {
		return <div>0</div>;
	}

	return (
		<div>
			{type === LowOrHighType.Low
				? isNaN(quote.currentQuote.l)
					? 'N/A'
					: String(quote.currentQuote.l)
				: isNaN(quote.currentQuote.h)
				? 'N/A'
				: String(quote.currentQuote.h)}
		</div>
	);
};

export default LowOrHigh;
