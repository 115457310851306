import * as ObservableFns from 'observable-fns';
import * as ThreadSafeObservableFns from 'threads/observable';
import { Comparator } from './TypeUtils';

export class Observable<T> extends ObservableFns.Observable<T> {}

export class Subject<T> extends ObservableFns.Subject<T> {}

export type Subscription<T> = ObservableFns.Subscription<T>;

type TObservableObjectKeys<T, K, U = unknown> = Exclude<U extends K ? keyof T : K, keyof IObservableObject<any, any>>;

type TObservableObjectChange<T, K, U = TObservableObjectKeys<T, K>> = {
	propertyName: U;
	newValue: U extends keyof T ? T[U] : any;
	oldValue: U extends keyof T ? T[U] : any;
};

export interface IObservableObject<T, K = keyof T> {
	getPropertyChangeStream<U extends TObservableObjectKeys<T, K>>(
		...props: U[]
	): Observable<TObservableObjectChange<T, U>>;
}

export class ObservableObject<T extends ObservableObject<T>, K = keyof T | unknown> implements IObservableObject<T, K> {
	protected readonly m_subject = new Subject<TObservableObjectChange<T, K>>();

	protected constructor() {}

	protected raiseAndSetIfChanged<U extends TObservableObjectKeys<T, K> & keyof T>(
		prop: U,
		newValue: T[U],
		oldValue: T[U],
		setter: (value: T[U]) => void
	): void {
		if (newValue !== oldValue) {
			setter(newValue);

			this.m_subject.next({ propertyName: prop, newValue: newValue as any, oldValue: oldValue as any });
		}
	}

	public getPropertyChangeStream<U extends TObservableObjectKeys<T, K>>(
		...props: U[]
	): Observable<TObservableObjectChange<T, U>> {
		const observable = Observable.from(this.m_subject) as unknown as Observable<TObservableObjectChange<T, U>>;
		return props == null || props.length < 1
			? observable
			: observable.filter((value) => props.indexOf(value.propertyName as U) >= 0);
	}

	public static for<T extends object, K extends keyof T = keyof T>(object: T): T & IObservableObject<T, K> {
		const subject = new Subject<TObservableObjectChange<T, K>>();
		const proxy = new Proxy<T>(object, {
			get: (target, prop, receiver) => {
				if (prop === 'getPropertyChangeStream') {
					const getPropertyChangeStream = (...props: K[]) => {
						const observable = Observable.from(subject);
						return props == null || props.length < 1
							? observable
							: observable.filter((value) => props.indexOf(value.propertyName as K) >= 0);
					};
					return getPropertyChangeStream.bind(target);
				} else {
					const value = (target as any)[prop];
					return typeof value === 'function' ? value.bind(target) : value;
				}
			},
			set: (target, prop, value, receiver) => {
				const currentValue = (target as any)[prop];
				value = typeof value === 'function' ? value.bind(target) : value;
				target[prop as K] = value;
				if (currentValue !== value) {
					subject.next({ propertyName: prop as any, newValue: value, oldValue: currentValue });
				}
				return true;
			},
		});
		return proxy as T & IObservableObject<T, K>;
	}
}

//export namespace Collections {

export interface IObservableSetChange<T = any> {
	type: 'add' | 'delete' | 'clear';
	values: T[];
}

export class ObservableSet<T = any> extends Set<T> {
	private readonly m_subject: Subject<IObservableSetChange<T>>;
	private readonly m_valueComparator?: Comparator<T> | null | undefined;

	public get changeStream(): Observable<IObservableSetChange<T>> {
		return Observable.from(this.m_subject);
	}

	public get valueComparator(): Comparator<T> | null | undefined {
		return this.m_valueComparator;
	}

	public constructor(values?: Iterable<T> | null, comparator?: Comparator<T> | null | undefined) {
		super(Array.from(values || []));
		this.m_valueComparator = comparator;
		this.m_subject = new Subject();
	}

	public has(value: T): boolean {
		return this.m_valueComparator != null
			? Array.from(this.values()).some((currentValue) => this.m_valueComparator!(currentValue, value))
			: super.has(value);
	}

	public add(...values: T[]): this {
		const additions: T[] = [];
		values.forEach((value) => {
			this.has(value) || (super.add(value) && additions.push(value));
		});
		if (additions.length > 0 && this.m_subject != null) {
			this.m_subject.next({ type: 'add', values: additions });
		}
		return this;
	}

	public delete(...values: T[]): boolean {
		const deletions: T[] = [];
		if (this.m_valueComparator != null) {
			Array.from(this).forEach((value) => {
				if (values.some((element) => this.m_valueComparator!(element, value))) {
					super.delete(value) && deletions.push(value);
				}
			});
		} else {
			values.forEach((value) => {
				super.delete(value) && deletions.push(value);
			});
		}
		if (deletions.length > 0 && this.m_subject != null) {
			this.m_subject.next({ type: 'delete', values: deletions });
		}
		return deletions.length > 0;
	}

	public clear(): this {
		if (this.size > 0) {
			const values = Array.from(this.values());
			super.clear();
			if (this.m_subject != null) {
				this.m_subject.next({ type: 'clear', values: values });
			}
		}
		return this;
	}

	public find(predicate: (value: T) => boolean): T | undefined {
		return Array.from(this).find(predicate);
	}

	public subscribe(
		onNext: (value: IObservableSetChange<T>) => void,
		onError?: ((error: any) => void) | undefined,
		onComplete?: (() => void) | undefined
	): Subscription<IObservableSetChange<T>> {
		return this.changeStream.subscribe(onNext);
	}

	public static from<T extends any = any>(
		values: Iterable<T>,
		comparator?: Comparator<T> | null | undefined
	): ObservableSet<T> {
		return new ObservableSet<T>(values, comparator);
	}
}

export interface IObservableMapChange<K extends any = any, V extends any = any> {
	type: 'add' | 'update' | 'delete' | 'clear';
	values: { key: K; newValue?: V; oldValue?: V }[];
}

export interface IObservableMapEntry<K extends any = any, V extends any = any> {
	key: K;
	value: V;
}

export class ObservableMap<K = any, V = any> extends Map<K, V> {
	private readonly m_subject: Subject<IObservableMapChange<K, V>>;
	private readonly m_keyComparator?: Comparator<K> | null | undefined;

	public get changeStream(): Observable<IObservableMapChange<K, V>> {
		return Observable.from(this.m_subject);
	}

	public get keyComparator(): Comparator<K> | null | undefined {
		return this.m_keyComparator;
	}

	public constructor(
		entries?: Iterable<readonly [K, V]> | null | undefined,
		keyComparator?: Comparator<K> | null | undefined
	) {
		super(entries || []);
		this.m_keyComparator = keyComparator;
		this.m_subject = new Subject();
	}

	public has(key: K): boolean {
		return super.has(this.getRealKey(key) as K);
	}

	public get(key: K): V | undefined {
		return super.get(this.getRealKey(key) as K);
	}

	public set(key: K, value: V): this;
	public set(values: IObservableMapEntry<K, V>[]): this;
	public set(key: K | IObservableMapEntry<K, V>[], value?: V): this {
		const values: IObservableMapEntry<K, V>[] = Array.isArray(key) ? key : [{ key: key, value: value as V }];
		const additions: Map<K, { key: K; newValue: V }> = new Map();
		const updates: Map<K, { key: K; newValue: V; oldValue: V }> = new Map();
		values.forEach((value) => {
			const realKey = this.getRealKey(value.key) as K;
			if (super.has(realKey)) {
				const currentValue = super.get(realKey);
				currentValue !== value.value &&
					updates.set(realKey, { key: realKey, newValue: value.value, oldValue: currentValue as V });
			} else {
				additions.set(value.key, { key: value.key, newValue: value.value });
			}
		});

		updates.forEach((value) => super.set(value.key, value.newValue));
		additions.forEach((value) => super.set(value.key, value.newValue));

		if (this.m_subject != null) {
			if (updates.size > 0) {
				this.m_subject.next({ type: 'update', values: Array.from(updates.values()) });
			}
			if (additions.size > 0) {
				this.m_subject.next({ type: 'add', values: Array.from(additions.values()) });
			}
		}

		return this;
	}

	public delete(...keys: K[]): boolean {
		const deletions: { key: K; oldValue: V }[] = [];
		keys.forEach((key) => {
			const realKey = this.getRealKey(key) as K;
			if (super.has(realKey)) {
				const currentValue = super.get(realKey);
				super.delete(realKey) && deletions.push({ key: realKey, oldValue: currentValue as V });
			}
		});
		if (deletions.length > 0 && this.m_subject != null) {
			this.m_subject.next({ type: 'delete', values: deletions });
		}
		return deletions.length > 0;
	}

	public clear(): this {
		if (this.size > 0) {
			const deletions: { key: K; oldValue: V }[] = [];
			this.forEach((value, key) => deletions.push({ key: key, oldValue: value }));
			super.clear();
			if (this.m_subject != null) {
				this.m_subject.next({ type: 'clear', values: deletions });
			}
		}
		return this;
	}

	public subscribe(onNext: (value: IObservableMapChange<K, V>) => void): Subscription<IObservableMapChange<K, V>> {
		return this.changeStream.subscribe(onNext);
	}

	private getRealKey(key: K): K | undefined {
		return this.m_keyComparator != null
			? Array.from(this.keys()).find((value) => this.m_keyComparator!(key, value))
			: key;
	}

	public static from<K extends any = any, V extends any = any>(
		values: Iterable<readonly [K, V]>,
		keyComparator?: Comparator<K> | null | undefined
	): ObservableMap<K, V> {
		return new ObservableMap<K, V>(values, keyComparator);
	}
}

export interface IObservableQueueChange<T extends any = any> {
	type: 'enqueue' | 'dequeue' | 'delete' | 'clear';
	values: T[];
}

export class ObservableQueue<T extends any = any> {
	private readonly m_items: T[];
	private readonly m_valueComparator?: Comparator<T> | null | undefined;
	private readonly m_maxLength: number | null | undefined;
	private readonly m_subject: Subject<IObservableQueueChange<T>>;

	public get maxLength(): number | null | undefined {
		return this.m_maxLength;
	}

	public get length(): number {
		return this.m_items.length;
	}

	public get changeStream(): Observable<IObservableQueueChange<T>> {
		return Observable.from(this.m_subject);
	}

	public get valueComparator(): Comparator<T> | null | undefined {
		return this.m_valueComparator;
	}

	public constructor(
		values?: Iterable<T> | null,
		comparator?: Comparator<T> | null | undefined,
		maxLength?: number | null | undefined
	) {
		this.m_items = Array.from(values || []);
		this.m_valueComparator = comparator;
		this.m_maxLength = maxLength;
		this.m_subject = new Subject();
	}

	public has(item: T): boolean {
		const predicate =
			this.m_valueComparator ||
			((val1: T, val2: T) => {
				return val1 === val2;
			});
		return this.m_items.some((currentValue) => predicate(currentValue, item));
	}

	public dequeue(): T | undefined {
		const value = this.m_items.pop();
		if (this.m_subject != null && value !== undefined) {
			this.m_subject.next({ type: 'dequeue', values: [value] });
		}
		return value;
	}

	public enqueue(...items: T[]): T[] {
		const enqueuedItems = items.filter((item) => !this.has(item));
		if (enqueuedItems.length > 0) {
			this.m_items.unshift(...enqueuedItems);
		}
		const removedItems =
			enqueuedItems.length > 0 && this.maxLength != null && this.length > this.maxLength
				? this.m_items.splice(this.maxLength)
				: [];
		if (this.m_subject != null && enqueuedItems.length > 0) {
			this.m_subject.next({ type: 'enqueue', values: enqueuedItems });
		}
		return removedItems;
	}

	public delete(...items: T[]): T[] {
		const deletions: T[] = [];
		const predicate =
			this.m_valueComparator ||
			((val1: T, val2: T) => {
				return val1 === val2;
			});
		items.forEach((item) => {
			const index = this.m_items.findIndex((value) => predicate(value, item));
			if (index >= 0) {
				Array.prototype.push.apply(deletions, this.m_items.splice(index, 1));
			}
		});
		if (this.m_subject != null && deletions.length > 0) {
			this.m_subject.next({ type: 'delete', values: deletions });
		}
		return deletions;
	}

	public clear(): this {
		const deletions =
			this.m_items != null && this.m_items.length > 0 ? this.m_items.splice(0, this.m_items.length) : [];
		if (this.m_subject != null && deletions.length > 0) {
			this.m_subject.next({ type: 'clear', values: deletions });
		}
		return this;
	}

	public values(): IterableIterator<T> {
		return (this.m_items || []).values();
	}

	public subscribe(
		onNext: (value: IObservableQueueChange<T>) => void,
		onError?: ((error: any) => void) | undefined,
		onComplete?: (() => void) | undefined
	): Subscription<IObservableQueueChange<T>> {
		return this.changeStream.subscribe(onNext);
	}

	public static from<T extends any = any>(
		values: Iterable<T>,
		comparator?: Comparator<T> | null | undefined,
		maxLength?: number | null | undefined
	): ObservableQueue<T> {
		return new ObservableQueue<T>(values, comparator, maxLength);
	}
}
//}

//export namespace Functions {
export const filter = ObservableFns.filter;
export const flatMap = ObservableFns.flatMap;
export const interval = ObservableFns.interval;
export const map = ObservableFns.map;
export const merge = ObservableFns.merge;
export const multicast = ObservableFns.multicast;
export const scan = ObservableFns.scan;
export const unsubscribe = ObservableFns.unsubscribe;
//}

export { ThreadSafeObservableFns as ThreadSafe };
