import React, { useContext, useEffect } from 'react';

import SignalsList from '../../../../../pages/Signals/SignalsList/SignalsList';
import useSignals from '../../../../../utils/queries/useSignals';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import DashboardContext from '../../../../../contexts/DashboardContext';

import styles from './MarketsSignalsList.module.scss';

const MarketSignalsList = () => {
	const { data: signals, isLoading, error, refetch } = useSignals();
	const dashboardContext = useContext(DashboardContext);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	useEffect(() => {
		if (dashboardContext.marketItems !== undefined && dashboardContext.marketItems.length > 0) {
			refetch();
		}
	}, [isSpreadBettingAccount, dashboardContext.marketItems]);

	return (
		<div className={styles.container}>
			<SignalsList
				doNavigate
				data={signals?.slice(0, 10)}
				hasHorizontalConfidenceLayout
				hasFilter={false}
				loading={isLoading}
				hasError={!!error}
			/>
		</div>
	);
};

export default MarketSignalsList;
