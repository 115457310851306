import React, { useContext, useMemo } from 'react';
import cn from 'classnames';

import AppContext from '../../../../../../contexts/AppContext';

import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';
import { getCurrentPrice } from '../../../../../../utils/functions/calculations';
import { MarketItem } from '../../../../../../gateways/RfpGateway/rfp.types';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';

import styles from '../../../../Markets/MarketsGrid/MarketsGrid.module.scss';

interface PercentChangeProps {
	info: any;
	marketItem: MarketItem;
}

const DetachWatchlistPercentChange = ({ info, marketItem }: PercentChangeProps) => {
	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const languageSettings = appContext.languageSettings;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const quotes = quoteStore.use.quotes();

	const calcChangePercentage = (marketItem: MarketItem) => {
		let dailyPercent = 0;
		const currentPriceQuote = getCurrentPrice(marketItem.feedId, marketItem.code, rfpGatewayContext);
		if (currentPriceQuote) {
			const previousClosePrice = dashboardContext.previousDayClosePrices[marketItem.code];
			const ask = currentPriceQuote.a;
			const bid = currentPriceQuote.b;

			if (ask && bid && previousClosePrice && previousClosePrice !== 0) {
				const averageCurrentPrice = (ask + bid) / 2;
				dailyPercent = ((averageCurrentPrice - previousClosePrice) / previousClosePrice) * 100;
			}
		}

		return dailyPercent;
	};

	const record = info.row.original;

	record.change = calcChangePercentage(marketItem);

	const isNumberPositive = useMemo(() => {
		if (!info.row.original) {
			return false;
		}

		return Number(record.change) > 0;
	}, [record.change]);

	return (
		<div
			className={cn({
				[styles.positive]: isNumberPositive,
				[styles.negative]: !isNumberPositive,
			})}
			style={{ direction: 'ltr' }}
		>
			{isNumberPositive ? '+' : ''}
			{record.change ? `${formatNumberWithCommas(record.change, 2, languageSettings)}%` : 0}
		</div>
	);
};

export default DetachWatchlistPercentChange;
