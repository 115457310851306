import React, { useContext } from 'react';

import { Modal as BootstrapModal, ModalProps } from 'react-bootstrap';

import AppContext from '../../contexts/AppContext';

/**
 * Wrapper component that allows you to stylize the Bootstrap Modal Component
 */
const Modal: React.FC<ModalProps> = ({ children, ...rest }: ModalProps) => {
	const { appTheme, isJapanAccount } = useContext(AppContext);
	rest.dialogClassName = `${rest.dialogClassName || ''} ${appTheme} ${isJapanAccount ? 'japanTheme' : ''}`;

	return <BootstrapModal {...rest}>{children}</BootstrapModal>;
};

export default Object.assign(Modal, {
	Body: BootstrapModal.Body,
	Header: BootstrapModal.Header,
	Title: BootstrapModal.Title,
	Footer: BootstrapModal.Footer,
	Dialog: BootstrapModal.Dialog,
	TRANSITION_DURATION: BootstrapModal.TRANSITION_DURATION,
	BACKDROP_TRANSITION_DURATION: BootstrapModal.BACKDROP_TRANSITION_DURATION,
});
