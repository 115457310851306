import React, { Dispatch, SetStateAction, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';

interface InfoIconProps {
	info: any;
	setShowClosedPositionTicket: Dispatch<SetStateAction<boolean>>;
}
const InfoIcon = ({ info, setShowClosedPositionTicket }: InfoIconProps) => {
	const orderIconsSize = '20px';

	const onInfoClick = () => {
		setShowClosedPositionTicket((state) => !state);
	};

	return (
		<div className={styles.triggerContainer} onClick={onInfoClick}>
			<FontAwesomeIcon
				icon={['fas', 'info-circle']}
				style={{ fontSize: orderIconsSize, marginLeft: '0' }}
				className={styles.iconTheme}
			/>
		</div>
	);
};

export default InfoIcon;
