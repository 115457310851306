import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Trans, useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../../contexts/DashboardContext';

import Button from '../../../../../shared/Button';
import { hasApprovedLiveAccount } from '../../../../../utils/functions/getAccountStats';

import Routes from '../../../../../setup/routes';
import { AppComponentType } from '../../../../../utils/functions/enums';

import statusStore, { StatusStore } from '../../../../../store/accountStatusStore';

import styles from './MarketSignalsGate.module.scss';
import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

const MarketSignalsGate = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const isLiveAccountApproved = hasApprovedLiveAccount(appContext, dashboardContext);
	const { t } = useTranslation();
	const history = useHistory();
	const permissions = statusStore((state: StatusStore) => state.permissions);

	const signalsAction = permissions.signalsAction;
	const setShowModal = statusStore((state: StatusStore) => state.setShowModal);
	const hasLiveAccount = statusStore((state: StatusStore) => state.hasLiveAccount);
	const [label, setLabel] = useState('');

	const handleCTAButtonClick = () => {
		if (signalsAction === IStatusConfigEnum.accountStatus) {
			setShowModal(true);
			appContext.statusModal = true;
		} else if (hasLiveAccount) {
			dashboardContext.presentComponentType = AppComponentType.Deposits;
			history.push(Routes.trader.funds, { from: window.location.pathname });
		} else {
			dashboardContext.presentComponentType = AppComponentType.OpenAccount;
			history.push(Routes.trader.openAccount, { from: window.location.pathname });
		}
	};

	useEffect(() => {
		if (!hasLiveAccount) {
			setLabel('en:OPEN_LIVE_ACCOUNT');
		} else if (signalsAction === IStatusConfigEnum.accountStatus) {
			setLabel('wtr:COMPLETE_APPLICATION');
		} else {
			setLabel('en:DEPOSIT_FUNDS');
		}
	}, [signalsAction, hasLiveAccount]);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<span className={styles.title}>
					{isLiveAccountApproved ? t('wtr:UNLOCK_SIGNALS_TITLE_LIVE') : t('wtr:UNLOCK_SIGNALS_TITLE_DEMO')}
				</span>
				<FontAwesomeIcon icon={['fal', 'lock']} className={styles.unlockIcon} />
				<p>
					<Trans i18nKey={isLiveAccountApproved ? 'wtr:UNLOCK_SIGNALS_TEXT_LIVE' : 'wtr:UNLOCK_SIGNALS_TEXT_DEMO'} />
				</p>
				<Button
					label={t(label)}
					variant="primary"
					size="fluid"
					className={styles.ctaButton}
					onClick={handleCTAButtonClick}
				/>
			</div>
		</div>
	);
};

export default MarketSignalsGate;
