import i18next from 'i18next';
import i18n from '../setup/i18n';

let ERROR = {
	RFP_TRADING_ACCOUNT: 'We are unable to connect to your trading account. Please try again later.',
	TFBO_CONNECTION_ERROR: 'We are having issues connecting to our servers. Please try again later.',
	TFBO_GET_USER_RECONNECTING: 'We are having issues gathering your user information. Reconnecting in ',
	TFBO_GET_USER: 'We are having issues gathering your user information. Please try again later.',
	TFBO_GET_ACCOUNT_STATS_RECONNECTING:
		'We are having issues gathering your user account information. Please try again later.',
	USER_PREFERENCES: 'We are having issues retrieving your saved preferences. Please try again later.',
};

i18next.loadLanguages(['wtr']).then(async () => {
	ERROR.RFP_TRADING_ACCOUNT = await i18n.t('wtr:ERROR_RFP_TRADING_ACCOUNT');
	ERROR.TFBO_CONNECTION_ERROR = await i18n.t('ERROR_TFBO_CONNECTION_ERROR');
	ERROR.TFBO_GET_USER_RECONNECTING = await i18n.t('ERROR_TFBO_GET_USER_RECONNECTING');
	ERROR.TFBO_GET_USER = await i18n.t('ERROR_TFBO_GET_USER');
	ERROR.TFBO_GET_ACCOUNT_STATS_RECONNECTING = await i18n.t('ERROR_TFBO_GET_ACCOUNT_STATS_RECONNECTING');
	ERROR.USER_PREFERENCES = await i18n.t('ERROR_USER_PREFERENCES');
});

export default ERROR;
