import { RefObject, useEffect } from 'react';

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void, checkForSVG: boolean = true) => {
  const handleClickOutside = (event: any) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as HTMLElement) &&
      (
        checkForSVG &&
        event.target.tagName.toLowerCase() !== 'svg' &&
        event.target.tagName.toLowerCase() !== 'path'
      )
    ) {
      callback();
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  });
};
