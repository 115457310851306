import { MarketItem } from '../../gateways/RfpGateway/rfp.types';

export function getSearchResults(marketItems: MarketItem[], value: string) {
	return marketItems.filter((marketItem: MarketItem) => {
		const isTicker =
			marketItem.exchangeTicker && marketItem.exchangeTicker.toLowerCase().indexOf(value.toLowerCase()) > -1;
		const isCode = marketItem.code.toLowerCase().indexOf(value.toLowerCase()) > -1;
		const isName = value.length > 1 && marketItem.fullName.toLowerCase().indexOf(value.toLowerCase()) > -1;
		return isCode || isName || isTicker;
	});
}
