import React, { useContext } from 'react';
import { Popup as SemanticUiPopup } from 'semantic-ui-react';

import cn from 'classnames';

import AppContext from '../../contexts/AppContext';

import styles from './WtrTooltip.module.scss';

/**
 * Wrapper component that allows you to stylize the Semantic UI React Popup Component and use it as a tooltip
 * If no position is specified the tooltip will default to position bottom center
 * Children should be wrapped in <div> in order to work properly
 */
const WtrTooltip = ({ children, position, ...rest }: any) => {
	const { appTheme, isArabic } = useContext(AppContext);

	rest.className = cn(rest.className, appTheme, styles.wtrTooltip);

	// be smarter about this
	if (position && isArabic) {
		if (position.includes('right')) {
			position = position.replace('right', 'left');
		}
	}

	rest.position = position || 'bottom center'; //if no position is specified the tooltip will default to position bottom center

	return <SemanticUiPopup {...rest} trigger={children} />;
};

export default WtrTooltip;
