import React, { useRef, useState } from 'react';

import { HistoryTick, MarketItem, PriceQuote, TradingPositionState } from '../../gateways/RfpGateway/rfp.types';

import { GymTradingAccount } from './Accounts/GymTradingAccount';
import { GymTradingPosition } from './Positions/GymTradingPosition';
import { GymSimulation } from './Simulations/GymSimulation';
import { GymSimulationsManager } from './Simulations/GymSimulationsManager';

export interface ITradersGymContext {
	gymTradingAccount?: GymTradingAccount;
	lastHistoryTick?: HistoryTick;
	priceQuote?: PriceQuote;
	spreadPriceDif?: number;
	isActive?: boolean;
	simulation?: GymSimulation;
	simulationList?: GymSimulation[];
	isOpenCreateSimulationModal?: boolean;
	simulationsManager?: GymSimulationsManager;
	searchInstrumentSelected?: MarketItem;
	isOpenResetTradersGymModal?: boolean;
	isPlaying?: boolean;
}

export type TradersGymContextType = {
	tradersGymContext: ITradersGymContext;
	setGymProps: (values: ITradersGymContext) => void;
	updateGymPriceQuote: (lastHistoryTick: HistoryTick) => void;
	resetOrderStore: () => void;
	getLastPriceQuote: () => PriceQuote | undefined;
	convertHistoryTickToPriceQuote: (historyTick: HistoryTick) => PriceQuote;
	getPositions: (state: TradingPositionState) => GymTradingPosition[];
};

export const TradersGymContext = React.createContext<TradersGymContextType | null>(null);

const TradersGymContextProvider: React.FC<React.ReactNode> = ({ children }) => {
	const initialState: ITradersGymContext = {
		gymTradingAccount: undefined,
		isActive: false,
		lastHistoryTick: undefined,
		priceQuote: undefined,
		spreadPriceDif: undefined,
		simulation: undefined,
		simulationList: [],
		isOpenCreateSimulationModal: false,
		simulationsManager: undefined,
		searchInstrumentSelected: undefined,
		isOpenResetTradersGymModal: false,
		isPlaying: false,
	};

	const [tradersGymContext, setTradersGymContext] = useState<ITradersGymContext>(initialState);

	const convertHistoryTickToPriceQuote = (historyTick: HistoryTick) => {
		//Spread support
		const priceOffset = tradersGymContext.spreadPriceDif ?? 0;
		return {
			a: historyTick.close + priceOffset,
			b: historyTick.close,
			c: historyTick.code,
			f: historyTick.feedId,
			h: historyTick.high,
			l: historyTick.low,
			t: historyTick.openTime,
		} as PriceQuote;
	};

	const getLastPriceQuote = () => {
		if (tradersGymContext.lastHistoryTick) {
			return convertHistoryTickToPriceQuote(tradersGymContext.lastHistoryTick);
		}
		return undefined;
	};

	// Used for throttle the store simulation, it need it because could cause performance issue
	const priceQuoteInThrottle = useRef<boolean>(false);
	// Throttle once per 333 millis
	const priceQuoteThrottleLimit = 333;

	const resetOrderStore = () => {
		setGymProps(initialState);
		if (priceQuoteInThrottle.current) {
			priceQuoteInThrottle.current = false;
		}
	};

	const updateGymPriceQuote = (lastHistoryTick: HistoryTick) => {
		if (!priceQuoteInThrottle.current) {
			priceQuoteInThrottle.current = true;

			setTimeout(() => {
				if (lastHistoryTick) {
					const quote = convertHistoryTickToPriceQuote(lastHistoryTick);
					setGymProps({ priceQuote: quote });
				}

				priceQuoteInThrottle.current = false;
			}, priceQuoteThrottleLimit);
		}
	};

	/**
	 * Set the tradersGymContext
	 * @param values the new values
	 */
	const setGymProps = (values: ITradersGymContext) => {
		setTradersGymContext((current) => {
			return { ...current, ...values };
		});
	};

	const getPositions = (state: TradingPositionState) => {
		return state === TradingPositionState.closed
			? tradersGymContext.gymTradingAccount?.closedPositions.filter((position) => {
					return position.state === state;
			  }) ?? []
			: tradersGymContext.gymTradingAccount?.activePositions.filter((position) => {
					return position.state === state;
			  }) ?? [];
	};

	return (
		<TradersGymContext.Provider
			value={{
				tradersGymContext,
				getLastPriceQuote,
				convertHistoryTickToPriceQuote,
				updateGymPriceQuote,
				setGymProps,
				resetOrderStore,
				getPositions,
			}}
		>
			{children}
		</TradersGymContext.Provider>
	);
};

export default TradersGymContextProvider;
