import { useState, useEffect, useRef } from 'react';

export default function useForceRerender(): () => void {
	const mounted = useRef(false);
	const [, setValue] = useState(Date.now());

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
	}, []);

	return () => {
		if (mounted.current) {
			setValue(Date.now());
		}
	};
}
