import { useQuery, UseQueryOptions } from '@tanstack/react-query';

// import axios from 'axios';

const useFetchDataFromYoutube = () => {
	// const queryKey = ['useFetchDataFromYoutube'];
	// const queryFn = async (): Promise<Record<string, any>[]> => {
	// 	try {
	// 		const response = await axios({
	// 			url: `${process.env.REACT_APP_YOUTUBE_PLAYLIST_ITEMS_API_URL}?part=snippet&playlistId=PLKC_I3Bz7HZTzR4vJnDTNVHBqmk5Zdwur&maxResults=50&key=${process.env.REACT_APP_YOUTUBE_PLAYLIST_ITEMS_API_KEYS}`,
	// 			method: 'GET',
	// 		});
	// 		return response.data.items;
	// 	} catch (e) {}
	// 	return [];
	// };
	// return useQuery<Record<string, any>[]>(queryKey, queryFn);
	const queryKey = ['useFetchDataFromYoutube'];
	const queryFn = async (): Promise<Record<string, any>[]> => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_YOUTUBE_PLAYLIST_ITEMS_API_URL}?part=snippet&playlistId=PLKC_I3Bz7HZTzR4vJnDTNVHBqmk5Zdwur&maxResults=50&key=${process.env.REACT_APP_YOUTUBE_PLAYLIST_ITEMS_API_KEYS}`
			);
			const data = await response.json();
			const items = data.items;
			return items;
		} catch (e) {}
		return [];
	};
	return useQuery<Record<string, any>[]>(queryKey, queryFn);
};

export default useFetchDataFromYoutube;
