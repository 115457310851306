import { useContext, useMemo } from 'react';

import DashboardContext from '../../../contexts/DashboardContext';

import {
  createMarketItemCodeMap
} from '../../../utils/functions/marketItems/marketItemGroupMapFormatter';

export const useMarketItemsMap = () => {
  const dashboardContext = useContext(DashboardContext);
  const marketItems = dashboardContext.marketItems;

  return useMemo(() => {
    if (marketItems.length === 0) {
      return {};
    }

    return createMarketItemCodeMap(marketItems);
  }, [marketItems.length]);
};
