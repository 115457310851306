import { useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import i18next from 'i18next';

import client from '../../../setup/monitoring';

import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';
import RfpGatewayContext from '../../../contexts/RfpGatewayContext';
import { RFPConnectPayload } from '../../../gateways/RfpGateway/rfp.types';
import connectWebSocket from '../../../gateways/RfpGateway/websocket';
import {
	ACCESS_TOKEN_KEY,
	isPreviewProduction,
	isProduction,
	REFRESH_TOKEN_KEY,
	USER_EMAIL_KEY,
} from '../../../setup/config';
import Routes from '../../../setup/routes';
import authStore, { UserStats, UserProfile, TradingMode } from '../../../store/authStore';
import getUrlWithAppendedQueryString from '../../functions/getUrlWithAppendedQueryString';
import { subscribeOpsPersistHelper } from '../../functions/subscrOpParamsPersistHelper';
import useApplicationStatus from '../../queries/useApplicationStatus';
import useUserProfile from '../../queries/useUserProfile';
import useUserStats from '../../queries/useUserStats';
import useRefreshJWTToken from '../../mutations/useRefreshJWTToken';
import useUserPreferences from '../../queries/useUserPreferences';
import accountStatusStore from '../../../store/accountStatusStore';
import useExchangeSSOCode from '../../mutations/useExchangeSSOCode';
import useStoreAndLoadPreferences from '../preferences/useStoreAndLoadPreferences';
import useAggreementStatus from '../../queries/useAggreementStatus';
import { AccountTradingType, TAccountStats, TradingAccountType } from '../../../gateways/TfboGateway/TfboGateway.types';
import useSaveUserPreferences from '../../mutations/useSaveUserPreferences';
import tradingAccountStore from '../../../store/tradingAccountStore';
import { loadUserPrefLocally } from '../../functions/fillUserPrefLocally';
import useUpdatePipsPreferences from '../preferences/useUpdatePipsPreferences';
import { useFillContextsFromPreferences } from '../preferences/useFillContextsFromPreferences';
import { getSavedInstrumentsOCTValue } from '../../../views/features/Dashboard/TradeBoard/TradeBoardItem/OneClickTradeTicket/utils';
import { RFP } from '../../../gateways/RfpGateway/rfpConstants';

const ALLOWED_TRADING_ACCOUNT_TYPES = [
	TradingAccountType.ThinkTrader,
	TradingAccountType.SpreadBetting,
	TradingAccountType.JapanSpread,
];

const useInitialize = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const rfpGateway = useContext(RfpGatewayContext);

	const isInitializedRef = useRef(false);
	const setIsFundedTrader = tradingAccountStore.use.setIsFundedTrader();
	const setCurrentAccountUrl = tradingAccountStore.use.setCurrentAccountUrl();
	const currentAccountUrl = tradingAccountStore.use.currentAccountUrl();
	const isAuthenticated = authStore.use.isAuthenticated();
	const isMFAEnabled = authStore.use.isMFAEnabled();
	const setUserProfile = authStore.use.setUserProfile();
	const userProfile = authStore.use.userProfile();
	const setUserStats = authStore.use.setUserStats();
	const setApplicationStatus = authStore.use.setApplicationStatus();
	const tradingMode = authStore.use.tradingMode();
	const setIsAuthenticated = authStore.use.setIsAuthenticated();
	const setIsMFAEnabled = authStore.use.setIsMFAEnabled();
	const setIsJapanAccount = authStore.use.setIsJapanAccount();
	const setIsSouthAfricaAccount = authStore.use.setIsSouthAfricaAccount();
	const setTradingMode = authStore.use.setTradingMode();
	const setUserProfileProp = authStore.use.setUserProfileProp();
	const setIsRfpConnected = authStore.use.setIsRfpConnected();

	const setCheckApplicationStatus = accountStatusStore.use.setCheckApplicationStatus();
	const setStatus = accountStatusStore.use.setStatus();
	const setCheckAccountStatus = accountStatusStore.use.setCheckAccountStatus();

	const storeAndLoadPreferences = useStoreAndLoadPreferences();
	const updatePipsPreferences = useUpdatePipsPreferences();
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const storedOCTValue = getSavedInstrumentsOCTValue();
	const restOfTheWorldCode = 'XX';

	const email = appContext.email ?? userProfile.email!;

	const { mutate: saveUserPreferences } = useSaveUserPreferences();

	const { refetch: fetchUserPreferences } = useUserPreferences({
		email,
		enabled: false,
		onSuccess: (data: any) => {
			storeAndLoadPreferences(data);
			updatePipsPreferences();
			saveUserPreferences();

			return;
		},
		onError: (err: any) => {
			storeAndLoadPreferences();
			updatePipsPreferences();
			saveUserPreferences();

			console.log('Error fetching user preferences. Fallback to defaults', err);

			return;
		},
	});

	const { refetch: fetchProfile } = useUserProfile({
		enabled: false,
		onSuccess: async (data: any) => {
			setUserProfile(data as UserProfile);
			if (!appContext.isJapanAccount && rfpGateway) {
				let code = data.country?.code2 ?? restOfTheWorldCode;
				rfpGateway.send(RFP.requestTTWatchList, {
					isoCode: code,
				});
			}
		},
		onError: (err) => {
			if (!appContext.isJapanAccount && rfpGateway) {
				rfpGateway.send(RFP.requestTTWatchList, {
					isoCode: restOfTheWorldCode,
				});
			}
			console.log('Error fetching user profile.', err);
		},
	});
	const { refetch: fetchUserStats } = useUserStats({
		enabled: false,
		onSuccess: (data: unknown) => {
			setUserStats(data as UserStats);
			setCheckAccountStatus(data);
			appContext.accountStats = (data as UserStats).filter((stat: any) => {
				// CE accounts are not allowed to trade on ThinkTrader
				return (
					stat.account.accountTradingType.accountTradingType !== AccountTradingType.CE &&
					stat.account.platform.name === TradingAccountType.ThinkTrader &&
					ALLOWED_TRADING_ACCOUNT_TYPES.includes(stat.platformAccountType)
				);
			}) as TAccountStats[];
		},
		onError: (err) => console.log('Error fetching user profile.', err),
	});
	const { refetch: fetchApplicationStatus } = useApplicationStatus({
		enabled: false,
		onSuccess: (data: any) => {
			setApplicationStatus(data);
			setCheckApplicationStatus(data);
			setStatus(data.application_status);
		},
		onError: (err) => console.log('Error fetching application status.', err),
	});

	const { refetch: fetchAggrementStatus } = useAggreementStatus({
		enabled: false,
		onSuccess: (data: any) => {
			if (tradingMode === TradingMode.Demo || data.customerVersion === data.latestVersion) {
				history.push(getUrlWithAppendedQueryString(Routes.trader.watchlist, queryPersistAppendix));
			} else {
				history.push(
					getUrlWithAppendedQueryString(
						Routes.account.agreeToTermsAndConditions,
						`${queryPersistAppendix}version=${data.latestVersion}`
					)
				);
			}
		},
		onError: (err: any) => console.log('Error fetching user preferences. Fallback to defaults', err),
	});

	const { mutate: refreshJWTToken } = useRefreshJWTToken();
	const { mutate: exchangeSSOCode } = useExchangeSSOCode();

	const history = useHistory();

	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const ssoCode = query.get('ssoToken');

	const queryPersistAppendix = subscribeOpsPersistHelper();

	const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
	const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

	useEffect(() => {
		if (!ssoCode) return;
		exchangeSSOCode(ssoCode, {
			onSuccess: async (data) => {
				const ssoEmail = query.get('email');
				setTradingMode(query.get('mode') as TradingMode);
				setUserProfileProp('email', ssoEmail);
				appContext.email = ssoEmail;
				appContext.isLoggedIn = true;
				setIsAuthenticated(true);
			},
			onError: (e) => {
				localStorage.removeItem(ACCESS_TOKEN_KEY);
				localStorage.removeItem(REFRESH_TOKEN_KEY);
				history.push(Routes.account.login);
			},
		});
	}, [ssoCode]);

	useEffect(() => {
		if (isAuthenticated) {
			initialize();
		}
	}, [isAuthenticated]);

	// In order to connect the user to the corresponding websocket we need to know the trading mode
	// and the fast hack is to reload the page
	useEffect(() => {
		if (isInitializedRef.current && isAuthenticated) {
			window.location.reload();
		}
	}, [tradingMode]);

	useEffect(() => {
		// disable the below as pop out charts are currently disabled
		// Do not handle authentication logic for child windows
		if (window.opener) {
			return;
		}

		//do not execute with initialize
		if (accessToken && refreshToken && !isAuthenticated && !ssoCode) {
			if (!appContext.isChildWindow) {
				history.push(getUrlWithAppendedQueryString(Routes.trader.watchlist, queryPersistAppendix));
				refreshJWTToken(
					{ accessToken, refreshToken },
					{
						onError: () => {
							localStorage.removeItem(ACCESS_TOKEN_KEY);
							localStorage.removeItem(REFRESH_TOKEN_KEY);
							history.push(Routes.account.login);
						},
						onSuccess: async () => {
							appContext.isLoggedIn = true;
							appContext.email = email;
							setUserProfileProp('email', email);
							setIsAuthenticated(true);
							setIsMFAEnabled(false);
						},
					}
				);
			}
		} else {
			history.push(Routes.account.login);
		}
	}, []);

	useEffect(() => {
		// if not jWT or MFA was already checked do nothing
		if (!isAuthenticated && isMFAEnabled === undefined) return;
		if (!isAuthenticated && isMFAEnabled) history.push(Routes.account.twoFactorAuthentication);
	}, [isMFAEnabled]);

	const loadUserPref = (isFundedTrader: boolean) => {
		// first load user preferences locally if they exist
		loadUserPrefLocally(appContext);
		if (storedOCTValue) {
			appContext.octStoredValues = storedOCTValue;
		}
		// if user preferences are not loaded from local storage fetch them from the backend
		if (!appContext.userPreferences) {
			fetchUserPreferences();
		} else {
			updateLanguageSettings();
			updatePipsPreferences();
			fillContextsFromPreferences(appContext.userPreferences!, isFundedTrader);
		}
	};

	const updateLanguageSettings = () => {
		if (!appContext.languageSettings || appContext.languageSettings.length === 0) {
			let languageToUse = localStorage.getItem('language') ?? 'en';
			// thIS has been added as a countermeasure to update the Japanese account preferences language,
			// all of them has English saved in the preferences
			if ((isProduction || isPreviewProduction) && appContext.isJapanAccount && languageToUse !== 'ja') {
				languageToUse = 'ja';
			}
			appContext.languageSettings = languageToUse;

			if (appContext.userPreferences) {
				localStorage.setItem('language', languageToUse);
				appContext.userPreferences.user_prefs.platform.lang._code = languageToUse;
				saveUserPreferences();
			}
		}
	};

	const connectRfp = async (connectResult: any) => {
		setIsRfpConnected(true);
		setIsFundedTrader(!!connectResult.fundedTrader);
		setCurrentAccountUrl(connectResult.rfpUrl);
		const rfpUrlStorage = JSON.parse(localStorage.getItem('rfpUrl') || '{}');
		const rfpUrlsObj = {
			...rfpUrlStorage,
			[email]: {
				url: connectResult.rfpUrl,
				mode: tradingMode,
			},
		};
		localStorage.setItem('rfpUrl', JSON.stringify(rfpUrlsObj));

		// if appContext has different trading mode set the one from the backend
		// mainly used for Trading Funded accounts
		if (connectResult && connectResult.login_mode) {
			if (connectResult.login_mode !== appContext.tradingMode) {
				appContext.tradingMode = connectResult.login_mode;
				setTradingMode(connectResult.login_mode);
			}
		}

		if (connectResult.prop && connectResult.prop['rfp.user.countryISO'] === 'JP') {
			setIsJapanAccount(true);
			appContext.isJapanAccount = true;
			appContext.canFetchSubscrInfo = true;
			if (isProduction || isPreviewProduction) {
				await i18next.changeLanguage('ja');
			}
			fetchAggrementStatus();
		} else {
			if (connectResult.prop && connectResult.prop['rfp.user.countryISO'] === 'ZA') {
				setIsSouthAfricaAccount(true);
			}

			history.push(getUrlWithAppendedQueryString(Routes.trader.watchlist, queryPersistAppendix));
		}

		loadUserPref(!!connectResult.fundedTrader);
		fetchProfile();
		fetchUserStats();
		fetchApplicationStatus();

		dashboardContext.accountType = 'cfd';
		const subscription = dashboardContext.getPropertyChangeStream('marketItems').subscribe((change) => {
			if (change.newValue && change.newValue.length > 0) {
				subscription.unsubscribe();
			}
		});

		isInitializedRef.current = true;
		client.setCustomContext({ tradingMode, lang: appContext.languageSettings });
		client.setUserContext({ email });
	};

	const initialize = async () => {
		if (!rfpGateway) return;

		try {
			let rfpUrlStorage = null;
			const rfpUrl = localStorage.getItem('rfpUrl');
			if (rfpUrl) {
				const storedData = JSON.parse(rfpUrl || '');
				if (storedData && storedData?.[email]?.mode === tradingMode) {
					rfpUrlStorage = storedData?.[email]?.url;
				}
			}
			const rfpPayload = {
				username: email,
				authToken: localStorage.getItem(ACCESS_TOKEN_KEY),
				tradingMode: tradingMode,
				lang: appContext.languageSettings,
				rfpUrl: rfpUrlStorage,
			};

			const connectResult = await rfpGateway.connect(() => rfpPayload as RFPConnectPayload);

			if (connectResult.success) {
				const res = connectWebSocket();
				connectRfp(connectResult);
				dashboardContext.rfpGatewayProvider = () => res;
			} else {
				setIsAuthenticated(false);
				history.push(Routes.account.login);
			}

			localStorage.removeItem('positionGridExpand');
			localStorage.removeItem('ordersGridExpand');
		} catch (e) {
			console.log(e);
			// if we fail to initialise the WS cleanup the JWT and do a hard reload
			// something is wrong big time
			localStorage.removeItem(ACCESS_TOKEN_KEY);
			localStorage.removeItem(REFRESH_TOKEN_KEY);
			localStorage.removeItem(USER_EMAIL_KEY);
			history.push(Routes.account.login);
			history.go(0);
		}
	};
};

export default useInitialize;
