import React from 'react';
import StringUtils from '../../../../../../utils/functions/StringUtils';
import { useTranslation } from 'react-i18next';

interface CalcCommentProps {
	data: any;
}
const CalcComment = ({ data }: CalcCommentProps) => {
	const { t } = useTranslation();

	const record = data.row.original;
	const comment = StringUtils.isNullOrEmpty(record.comment?.toString()) ? t('wtr:NA') : record.comment;
	return <div>{!record.isGroupRow && comment}</div>;
};

export default CalcComment;
