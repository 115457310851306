import React, { useEffect, useState, useContext, useRef } from 'react';

import cn from 'classnames';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import isWindowStyles from '../../../../../utils/functions/isWindowStyles';
import { MarketItem, PriceQuote, RFPDataObjectType } from '../../../../../gateways/RfpGateway/rfp.types';
import BidAskSpread from '../../../../components/BidAskSpread/BidAskSpread';
import { Optional } from '../../../../../utils/functions/Nullable';

import { default as usePromiseFactory } from '../../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';

import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../../contexts/DashboardContext';

import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';
import { TradersGymContext, TradersGymContextType } from '../../../../../pages/TradersGym/TradersGymContext';

import useIsInViewport from '../../../../../utils/hooks/useIsInViewport';

import styles from './Instrument.module.scss';
import { instrumentGroupProps } from '../../../../../utils/functions/constants';
import InstrumentIcon from '../../../../components/GroupBadge/InstrumentIcon';
import { useTranslation } from 'react-i18next';
import TradingSessionTooltip from '../../../../components/Shared/TradingSessionTooltip';
import tradingViewStore from '../../../../../store/tradingViewStore';

interface InstrumentProps {
	index: number;
	windows?: boolean;
	marketItem: MarketItem;
	onRemovalFromWatchlist?: () => void;
	onChartClick: () => void;
	showPriceStats?: boolean; //Optional prop to display Low/High values underneath buy/sell buttons
	spreadPosition?: boolean; //Optional prop to toggle shifting class of spread display
	resizeButton?: boolean; //Optional prop to toggle resized buttons class
	showButtonText?: boolean; //Optional prop to display "Buy" "Sell" words within buttons
	showInstrumentName?: boolean; //Optional prop to toggle display full instrument name next to button
	parent?: string; //Parent element
	showJapanTier?: boolean; // Use this prop if you want to display the Japan Tier badges
	buttonsClass?: string;
}

const Instrument = React.memo<InstrumentProps>(
	({
		index,
		marketItem,
		windows,
		onChartClick,
		showInstrumentName,
		showPriceStats,
		spreadPosition,
		resizeButton,
		showButtonText,
		parent,
		showJapanTier,
		buttonsClass,
	}) => {
		const appContext = useContext(AppContext);
		const dashboardContext = useContext(DashboardContext);
		const promiseFactory = usePromiseFactory();

		const forceRerender = useForceRerender();
		const rfpGatewayContext = useContext(RfpGatewayContext);

		const subIdRef = useRef<string | undefined>(undefined);
		const [currentPriceQuote, setCurrentPriceQuote] = useState<Optional<PriceQuote>>();

		const accountType = dashboardContext.accountType;
		const openInformation = dashboardContext.openInformation;
		const selectedInstrument = dashboardContext.selectedInstrument;
		const selectedPosition = dashboardContext.selectedPosition;
		const selectedType = dashboardContext.selectedType;
		const ticketLayout = tradingViewStore.use.ticketLayout();

		const gymContext = useContext(TradersGymContext) as TradersGymContextType;
		const { tradersGymContext } = gymContext;

		const { t } = useTranslation();

		const item = useRef(null);
		const isInViewport = useIsInViewport(item);

		useObservable(appContext.getPropertyChangeStream('appTheme', 'email'), () => {
			promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		});

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'accountType',
				'openInformation',
				'detailedInformation',
				'selectedInstrument',
				'selectedPosition',
				'selectedType',
				'tradingAccount'
			),
			() => {
				promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
					forceRerender();
				});
			}
		);

		const handleChartClick = () => {
			dashboardContext.closeAllOtherTabs();
			dashboardContext.selectedType = 'Instrument';
			dashboardContext.isEdit = false;
			onChartClick && onChartClick();
		};

		const isSelected = selectedPosition && selectedPosition[0] ? selectedPosition[0] : '';
		const isInstrument = selectedInstrument ? selectedInstrument?.code === marketItem?.code : false;
		const isGrid = isSelected?.code === marketItem?.code;

		//Function to dynamically select which container to size the trading buttons with (watchlist vs right hand panels)
		const containerSelector = () => {
			if (resizeButton) {
				return cn(
					isWindowStyles(styles.rightHandContainer, styles.windowContainer, windows),
					selectStyle,
					openInformation === index ? styles.showActions : null
				);
			} else {
				return cn(
					isWindowStyles(styles.container, styles.windowContainer, windows),
					selectStyle,
					openInformation === index ? styles.showActions : null,
					parent && styles[parent]
				);
			}
		};

		//Function to display the highlight on a selected instrument. When "resizeButton", no highlight shown (right hand panel)
		const displayHighlight = () => {
			if (resizeButton) {
				return isInstrument && selectedType === 'Instrument'
					? ''
					: isGrid && selectedType === 'Grid'
					? ''
					: styles.nonHighlightSelected;
			} else {
				return isInstrument
					? styles.highlightSelected
					: isGrid && selectedType === 'Grid'
					? styles.highlightSelected
					: styles.nonHighlightSelected;
			}
		};

		const selectStyle = displayHighlight();

		const renderTooltip = (props: any) => (
			<Tooltip className="my-tooltip" id="button-tooltip" {...props}>
				{marketItem!.fullName}
			</Tooltip>
		);

		useEffect(() => {
			if (tradersGymContext.priceQuote) {
				setCurrentPriceQuote(tradersGymContext.priceQuote);
			}
		}, [tradersGymContext.priceQuote]);

		useEffect(() => {
			// Walk around to the race condition for Quote subscription while InstrumentHeader rendering,
			// and the check isInViewport is getting false from time to time.
			const shouldSubscribe = isInViewport || parent === 'instrumentHeader';

			if (rfpGatewayContext && marketItem && shouldSubscribe && !tradersGymContext.isActive) {
				// Unsubscribe existing subscription
				if (subIdRef.current) {
					rfpGatewayContext.unsubscribeFor(subIdRef.current);
					subIdRef.current = undefined;
				}

				subIdRef.current = rfpGatewayContext.subscribeFor(RFPDataObjectType.PriceQuote, (priceQuote) => {
					if (priceQuote.c === marketItem.code) {
						setCurrentPriceQuote(priceQuote);
					}
				});

				//console.log(`✅︎ Subscribe instruments subId=${subIdRef.current} symbol=${marketItem.code}`);

				// Get latest price snapshot
				const priceQuote = rfpGatewayContext.getQuotePrices(marketItem.feedId, marketItem.code);
				if (priceQuote) {
					setCurrentPriceQuote(priceQuote);
				}

				// unsubscribe price quote on unmount
				return () => {
					if (rfpGatewayContext && subIdRef.current) {
						rfpGatewayContext.unsubscribeFor(subIdRef.current);
						//console.log(`❌ Unsubscribe instruments subId=${subIdRef.current}`);
						subIdRef.current = undefined;
					}
				};
			}
		}, [marketItem, rfpGatewayContext, isInViewport]);

		return (
			<div className={containerSelector()} data-testid="instrumentList" ref={item}>
				<div className={isWindowStyles(styles.TickerInfo, styles.windowTickerInfo, windows)}>
					{parent !== 'instrumentHeader' && (
						<div className={styles.iconContainer} onClick={() => handleChartClick()}>
							<OverlayTrigger
								key="instrumentCategoryInfo"
								delay={{ show: 750, hide: 0 }}
								placement="bottom"
								overlay={
									<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
										{marketItem.grp && t(instrumentGroupProps[marketItem.grp]?.name)}
									</Tooltip>
								}
							>
								<div className={styles.badge}>
									<InstrumentIcon marketItem={marketItem} />
								</div>
							</OverlayTrigger>
						</div>
					)}
					{parent !== 'instrumentHeader' && (
						<div
							className={cn(styles.tickerContainer, parent && styles[parent])}
							data-testid="chart"
							onClick={() => handleChartClick()}
						>
							<div className={styles.instrumentInfo}>
								{showInstrumentName && marketItem && (
									<div className={cn(styles.instrumentCodeAndSessionIconContainer)}>
										<div className={cn(styles.Symbol, styles.symbol)}>
											{marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
												? marketItem!.exchangeTicker
												: marketItem!.code}
										</div>

										<TradingSessionTooltip marketItem={marketItem} />
									</div>
								)}
							</div>
							{accountType !== 'equity' && showInstrumentName && (
								<>
									<OverlayTrigger placement="bottom" delay={{ show: 750, hide: 0 }} overlay={renderTooltip}>
										<div className={styles.symbolDescription} data-tip data-for="marketItem-name">
											{marketItem?.fullName}
										</div>
									</OverlayTrigger>
								</>
							)}
						</div>
					)}
					<div className={cn(styles.bidAskSpreadButtons, buttonsClass)}>
						<BidAskSpread
							showButtonText={showButtonText}
							resizeButton={resizeButton}
							showPriceStats={showPriceStats}
							spreadPosition={spreadPosition}
							instrument={currentPriceQuote}
							marketItem={marketItem}
							onChartClick={() => onChartClick()}
							id={index}
							ticketLayout={ticketLayout}
						/>
					</div>
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps === nextProps ||
			(prevProps &&
				nextProps &&
				(
					['index', 'ticker', 'marketItem', 'windows', 'onRemovalFromWatchlist', 'onChartClick'] as Array<
						keyof typeof prevProps
					>
				).every((key) => prevProps[key] === nextProps[key]))
		);
	}
);

export default Instrument;
