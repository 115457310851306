import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const useGetPhoneCodesAndFlags = () => {
	const mutationFn = async () => {
		const response = await axios({
			url: '',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				payload: [
					{
						module: 'Utility',
						action: 'getCountries',
						parameters: {
							showUnused: false,
						},
					},
				],
			},
		});
		return response.data.payload[0];
	};

	return useMutation({
		mutationKey: ['useGetPhoneCodesAndFlags'],
		mutationFn,
	});
};

export default useGetPhoneCodesAndFlags;
