import React, { useContext } from 'react';
import AppContext from '../../../contexts/AppContext';
import { Tooltip as BootstrapTooltip, TooltipProps } from 'react-bootstrap';
import './Tooltip.module.scss';

/**
 * Wrapper component that allows you to stylize the Bootstrap Tooltip Component
 */
const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(({ children, ...rest }: TooltipProps, ref) => {
	const { appTheme } = useContext(AppContext);
	rest.className = `${rest.className || ''} ${appTheme}`;

	return (
		<BootstrapTooltip ref={ref} {...rest}>
			{children}
		</BootstrapTooltip>
	);
});

export default Tooltip;
