import React, { useContext } from 'react';

import cn from 'classnames';

import DashboardContext from '../../../contexts/DashboardContext';
import { PriceQuote } from '../../../gateways/RfpGateway/rfp.types';
import { Optional } from '../../../utils/functions/Nullable';

import styles from './DailyPercent.module.scss';

interface DailyPercentProps {
	currentPriceQuote: Optional<PriceQuote>;
	decimalPrecision?: number;
}

const DailyPercent = ({ currentPriceQuote, decimalPrecision }: DailyPercentProps) => {
	const dashboardContext = useContext(DashboardContext);

	let dailyValue = 0;
	let dailyPercent = 0;

	const previousClosePrice = dashboardContext.selectedInstrument
		? dashboardContext.previousDayClosePrices[dashboardContext.selectedInstrument.code]
		: 0;

	if (!currentPriceQuote?.a || !currentPriceQuote?.b || !previousClosePrice) return null;

	// a - ask, b - bid
	const averageCurrentPrice = (currentPriceQuote!.a + currentPriceQuote!.b) / 2;

	dailyValue = averageCurrentPrice - previousClosePrice;

	dailyPercent = (dailyValue! / previousClosePrice) * 100;

	return (
		<div>
			<span className={cn(styles.dailyPercentage, dailyValue > 0 ? styles.positive : styles.negative)}>
				{`${dailyValue > 0 ? '+' : ''}${dailyValue.toFixed(decimalPrecision)}`}
			</span>
			<span className={cn(styles.dailyPercentage, dailyPercent > 0 ? styles.positive : styles.negative)}>
				{` (${dailyPercent > 0 ? '+' : ''}${dailyPercent?.toFixed(2)}%)`}
			</span>
		</div>
	);
};

export default DailyPercent;
