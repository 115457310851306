import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../contexts/AppContext';
import { getTierDisplayName } from '../functions/subscriptionUtils';

const useGetTranslatedWLName = () => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();

	const getTranslatedWLName = (watchlistName: string | null | undefined) => {
		if (!watchlistName) {
			return '';
		}

		if (watchlistName === 'Popular Markets') {
			return t('wtr:POPULAR_MARKETS');
		} else if (watchlistName === 'My Watchlist') {
			return t('wtr:MY_WATCHLIST');
		} else {
			const tierId = watchlistName.replace(/\s+/g, '');
			if (appContext.isJapanAccount) {
				return getTierDisplayName(tierId) || watchlistName;
			}

			return watchlistName;
		}
	};

	return getTranslatedWLName;
};

export default useGetTranslatedWLName;
