export const isPasswordSingleDigit = (value: string) => /\d/.test(value) && !!value?.length;
export const isPasswordValidLength = (value: string) => value?.length >= 8 && value?.length <= 30;
export const isPasswordSingleLetter = (value: string) => /[a-z]/.test(value) && !!value?.length;
export const isPasswordSingleUpperLetter = (value: string) => /[A-Z]/.test(value) && !!value?.length;
export const isPasswordSpecialCharacters = (value: string) =>
	/[(@!?~`"&',/:;<>#\$%\^\&*\)\|\{\}\\\[\]\(+=._-]{1,}/.test(value);
export const isPasswordLowercaseLetter = (value: string) => /[a-z]/.test(value) && !!value?.length;

export const isPasswordValid = (value: string) =>
	isPasswordSingleDigit(value) &&
	isPasswordValidLength(value) &&
	isPasswordSingleLetter(value) &&
	isPasswordSingleUpperLetter(value) &&
	isPasswordSpecialCharacters(value) &&
	isPasswordLowercaseLetter(value);
// /[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]_`{|}~]*$/
