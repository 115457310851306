import React, { useContext } from 'react';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import {
	formatNumberWithCommas,
	revertToTraditionalNumberFormat,
} from '../../../Watchlist/Instrument/formattedQuoteNumber';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import AppContext from '../../../../../../contexts/AppContext';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';

interface CalcDistanceToMarketProps {
	data: any;
}
const CalcDistanceToMarket = ({ data }: CalcDistanceToMarketProps) => {
	const appContext = useContext(AppContext);
	const languageSettings = appContext.languageSettings;

	const quotes = quoteStore.use.quotes();

	const record = data.row.original;

	const open: any = record.open ? Number(revertToTraditionalNumberFormat(record.open.toString(), languageSettings)) : 0;
	const distanceToMarket = Math.abs(record.current - open);
	record.distanceToMarket = !record.isGroupRow ? distanceToMarket : '';
	if (record.isGroupRow) {
		record.lowestDTM = distanceToMarket < (record.lowestDTM as number) ? distanceToMarket : record.lowestDTM;
		record.highestDTM = distanceToMarket > (record.highestDTM as number) ? distanceToMarket : record.highestDTM;
	}

	return (
		<span className={cn(styles.alignRight)}>
			{record.distanceToMarket ? formatNumberWithCommas(record.distanceToMarket, record.decPrec, languageSettings) : ''}
		</span>
	);
};

export default CalcDistanceToMarket;
