import React, { RefObject } from 'react';
import Draggable from 'react-draggable';
import ModalDialog, { ModalDialogProps } from 'react-bootstrap/ModalDialog';

export interface DraggableModalDialogProps extends ModalDialogProps {
	nodeRef?: RefObject<HTMLElement>;
}

const DraggableModalDialog = ({ nodeRef, ...props }: DraggableModalDialogProps) => {
	return (
		<Draggable nodeRef={nodeRef}>
			<ModalDialog {...props} />
		</Draggable>
	);
};

export default DraggableModalDialog;
