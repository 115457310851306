import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../shared/Button';

import WtrModal from '../../../../shared/WtrModal';

import styles from './ServiceIssuesModal.module.scss';

interface ServiceIssuesModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}
const ServiceIssuesModal = ({ open, setOpen }: ServiceIssuesModalProps) => {
	const { t } = useTranslation();

	return (
		<WtrModal open={open} size="mini">
			<WtrModal.Content>
				<WtrModal.Description className={styles.description}>
					<p>{t('wtr:OOPS_SOMETHING_WENT_WRONG')}</p>
				</WtrModal.Description>
			</WtrModal.Content>
			<WtrModal.Actions className={styles.footer}>
				<Button variant="primary" size="lg" label={t('en:OK')} onClick={() => setOpen(false)} />
			</WtrModal.Actions>
		</WtrModal>
	);
};

export default ServiceIssuesModal;
