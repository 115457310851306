import { ApplicationStatus } from '../../../utils/functions/enums';

export type AccountMenuAction = 'switchToRealMoney' | 'fund' | 'accountStatus' | 'accountStatus' | false;
export type AccountMenuLabel =
	| 'en:SWITCH_TO_REAL'
	| 'wtr:ACCOUNT_STATUS'
	| 'en:DEPOSIT_FUNDS'
	| 'wtr:COMPLETE_APPLICATION'
	| 'en:OPEN_LIVE_ACCOUNT'
	| 'en:Upload Documents'
	| false;
export type MarginLevelAction = 'switchToRealMoney' | 'fund' | 'accountStatus' | 'accountStatus' | false;
export type marginLevelLabel =
	| 'en:SWITCH_TO_REAL'
	| 'wtr:ACCOUNT_STATUS'
	| 'en:DEPOSIT_FUNDS'
	| 'wtr:COMPLETE_APPLICATION'
	| 'en:OPEN_LIVE_ACCOUNT'
	| 'en:Upload Documents'
	| false;
export type SignalsAction = 'balanceCheck' | 'accountUnderReview' | 'accountStatus' | 'accountStatus' | false;
export type TradeButtons = 'orderTicket' | 'accountStatus' | false;
export type MainMenuAction = 'account' | 'fund' | false;
export type AddAccount = 'accountStatus' | 'addDemoAccount' | 'addLiveAccount' | 'accountStatus' | false;
export type Reports = 'accountStatus' | 'reports' | false;

export type ManageFunds = 'accountStatus' | 'fund' | 'accountStatus' | false;
export type TradersGym = true | false | 'accountStatus';
export type PortalPage = 'deposits' | 'fork' | 'relevantStep' | 'statusPage' | 'accountStatus' | false;
export type NotificationBell = true | false;

export enum IStatusConfigEnum {
	accountStatus = 'accountStatus',
	reports = 'reports',
	accountUnderReview = 'accountUnderReview',
	fund = 'fund',
	balanceCheck = 'balanceCheck',
	addLiveAccount = 'addLiveAccount',
	addDemoAccount = 'addDemoAccount',
	statusPage = 'statusPage',
	fork = 'fork',
	relevantStep = 'relevantStep',
	deposits = 'deposits',
	switchToRealMoney = 'switchToRealMoney',
}

export interface IStatusConfig {
	accountMenuAction: AccountMenuAction;
	accountMenuLabel: AccountMenuLabel;
	marginLevelAction: MarginLevelAction;
	marginLevelLabel: marginLevelLabel;
	signalsAction: SignalsAction;
	tradeButtons: TradeButtons;
	mainMenuAction: MainMenuAction;
	addAccount: AddAccount;
	manageFunds: ManageFunds;
	reports: Reports;
	tradersGym: TradersGym;
	portalPage: PortalPage;
	notificationBell: NotificationBell;
}

export type ActionsConfig = { [key in ApplicationStatus]: IStatusConfig };
